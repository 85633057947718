import { METRIC_FUNC, WidgetGroupBySort } from '@reducers/dashboards/types'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'

export const formatSortFieldWithAggFunc = (sortField?: string, sortFieldAggFunc?: METRIC_FUNC): string => {
  const aggFuncPart = sortFieldAggFunc ? `:${sortFieldAggFunc}` : ''
  return `${sortField ?? ''}${aggFuncPart}`
}

export const updateGroupBySort = ({ newDisplayConfig, fieldKey, fieldValue, oldDisplayConfig }): WidgetGroupBySort => {
  const { field: currentSortField, aggFunc: currentSortAggFunc } = oldDisplayConfig?.groupBy?.sort ?? {}

  const updateSortField = (newField: string | undefined): void => {
    newDisplayConfig.groupBy.sort = { ...newDisplayConfig.groupBy.sort, field: newField }
  }

  const updateSortAggFunc = (newAggFunc: string): void => {
    newDisplayConfig.groupBy.sort = { ...newDisplayConfig.groupBy.sort, aggFunc: newAggFunc }
  }

  if (fieldKey === 'groupBy' && fieldValue) {
    // when the groupBy field changes and sorting is based on it, we need to update the sort field to the new groupBy field
    if (currentSortField && oldDisplayConfig.groupBy?.field.systemKey === currentSortField && !currentSortAggFunc) {
      updateSortField(fieldValue)
    }
  }

  if (fieldKey === 'metricFunc' && currentSortAggFunc) {
    updateSortAggFunc(fieldValue)

    // when the metric function changes and sorting is based on the metric field, we need to update the sort field to the new metric field
    if (currentSortAggFunc === METRIC_FUNC.TOTAL && fieldValue !== METRIC_FUNC.TOTAL) {
      updateSortField(newDisplayConfig.groupBy.metric.field?.systemKey)
    } else if (currentSortAggFunc !== METRIC_FUNC.TOTAL && fieldValue === METRIC_FUNC.TOTAL) {
      // when the metric function changes and sorting is based on the metric field but the new metric function is TOTAL, we need to remove the sort field
      updateSortField(undefined)
    }
  }

  if (fieldKey === 'metricField' && fieldValue) {
    // when the metric field changes and sorting is based on it, we need to update the sort field to the new metric field
    if (currentSortField && oldDisplayConfig.groupBy?.metric?.field?.systemKey === currentSortField && currentSortAggFunc) {
      updateSortField(fieldValue)
    }
  }

  if (fieldKey === 'sortField' && fieldValue) {
    const [updatedFieldValue, aggFunc] = fieldValue.split(':')
    newDisplayConfig.groupBy = {
      ...newDisplayConfig.groupBy,
      sort: {
        ...newDisplayConfig.groupBy.sort,
        field: aggFunc === METRIC_FUNC.TOTAL ? undefined : updatedFieldValue,
        aggFunc: aggFunc ? newDisplayConfig.groupBy.metric.func : undefined
      }
    }
  }

  if (fieldKey === 'sortOrder' && fieldValue) {
    newDisplayConfig.groupBy = {
      ...newDisplayConfig.groupBy,
      sort: {
        ...newDisplayConfig.groupBy.sort,
        order: fieldValue
      }
    }
  }

  return omitBy(newDisplayConfig.groupBy.sort, isUndefined) as WidgetGroupBySort
}
