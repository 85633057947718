import { WIDGET_TYPES } from '@root/store/reducers/dashboards/types'
import styled from 'styled-components'

interface TooltipContainerProps {
  type: WIDGET_TYPES
}

export const TooltipContainer = styled.div<TooltipContainerProps>`
  padding: ${({ theme }) => theme.spacing('space-250')};
  border: ${({ theme }) => `1px solid ${theme.palette.border.primary}`};
  background-color: ${({ theme }) => theme.palette.background.primary};
  max-width: 300px;
  transform: ${({ type }) => type === WIDGET_TYPES.PIE_CHART_WIDGET ? 'translateX(-90px) translateY(-20px)' : 'none'};
`
