import send from '@shared/redux-fetch'
import { CREATE_ACCESS_REVIEWS, GET_ACCESS_REVIEWS } from '@root/constants'
import { CreateAccessReviewsParams } from '@actions/accessReviews/types'
import { IdOrg } from '@root/store/types'

export const createAccessReviews = ({ idOrg, reviewers, idApps }: CreateAccessReviewsParams) => {
  return send(CREATE_ACCESS_REVIEWS, {
    url: `/api/orgs/${idOrg}/accessReviews`,
    method: 'POST',
    body: JSON.stringify({
      reviewers,
      idApps
    })
  })
}

export const getAccessReviews = ({ idOrg }: { idOrg: IdOrg }) => {
  return send(GET_ACCESS_REVIEWS, {
    url: `/api/orgs/${idOrg}/accessReviews`,
    method: 'GET'
  })
}
