import React, { ReactElement } from 'react'
import {
  TextBadge,
  TextBadgeColors,
  TextBadgeSize
} from '@toriihq/design-system'
import { TASK_STATUS } from '../types'

interface Props {
  status: TASK_STATUS
  statusLabel: string
}

const TASK_STATUS_COLORS: Record<TASK_STATUS, TextBadgeColors > = {
  [TASK_STATUS.IN_PROGRESS]: 'blue',
  [TASK_STATUS.NEEDS_ATTENTION]: 'yellow',
  [TASK_STATUS.CANCELED]: 'gray',
  [TASK_STATUS.COMPLETED]: 'green'
}

const TaskStatus = ({ status, statusLabel }: Props): ReactElement => {
  const color = TASK_STATUS_COLORS[status]

  return <TextBadge
    color={color}
    size={TextBadgeSize.Small}>
    {statusLabel}
  </TextBadge>
}

export default TaskStatus
