import { formFieldTypes } from '@root/constants'
import { ENTITY_TYPES, WIDGET_TYPES } from '@root/store/reducers/dashboards/types'
import { DropdownOptionsSource, EditableSection } from '@components/dashboard/widgets/types'

export const widgetInfoSection: EditableSection = {
  key: 'widgetInfo',
  fields: [
    {
      key: 'label',
      label: 'Label',
      type: formFieldTypes.singleLine,
      valuePathInWidget: 'displayConfig.label'
    },
    {
      key: 'entityType',
      label: 'Data source',
      type: formFieldTypes.dropdown,
      dropdownOptionsSource: DropdownOptionsSource.CLOSED_LIST,
      valuePathInWidget: 'entityType',
      options: [
        { label: 'Applications', value: ENTITY_TYPES.APPS },
        { label: 'Users', value: ENTITY_TYPES.USERS },
        { label: 'Contracts', value: ENTITY_TYPES.CONTRACTS },
        { label: 'Expenses', value: ENTITY_TYPES.TRANSACTIONS },
        { label: 'App Users', value: ENTITY_TYPES.APP_USERS }
      ],
      disabled: true
    },
    {
      key: 'widgetType',
      label: 'Widget type',
      type: formFieldTypes.dropdown,
      valuePathInWidget: 'type',
      dropdownOptionsSource: DropdownOptionsSource.CLOSED_LIST,
      options: [
        { label: 'Metric', value: WIDGET_TYPES.METRIC_WIDGET },
        { label: 'Pie chart', value: WIDGET_TYPES.PIE_CHART_WIDGET },
        { label: 'Bar chart', value: WIDGET_TYPES.BAR_CHART_WIDGET },
        { label: 'Table', value: WIDGET_TYPES.TABLE_WIDGET }
      ],
      disabled: true
    }
  ]
}
