import React, { Fragment } from 'react'
import * as Style from './style'
import { getValue } from '@shared/utils'
import { removeItemAtIndex, replaceItemAtIndex } from '@lenses/utils'
import { Button, ButtonType, ButtonSize, Spacer, Stack } from '@toriihq/design-system'
import { ADVANCED_FILTERS_DISPLAY_TYPE, displayTypeToComponent } from './consts'
import AndSeparator from '../_shared/andSeparator'
import EnableFor from '@components/enableFor'
import { KeyOption, ValueOption } from '@components/filters/keyOpValueFilter/types'
import { Filter } from '@shared/filters/types'

interface AdvancedFiltersProps {
  optionsKey: KeyOption[]
  optionsValuesPerKey: Record<string, ValueOption[]>
  filters: Filter[]
  onChange?: (filters: Filter[]) => void
  disabled?: boolean
  getTriggerPreview?: () => void
  supportClearAllFilters?: boolean
  fieldsAutoFocus?: boolean
  displayType?: typeof ADVANCED_FILTERS_DISPLAY_TYPE[keyof typeof ADVANCED_FILTERS_DISPLAY_TYPE]
  allowCustomValuesInMultiDropdown?: boolean
}

const AdvancedFilters = ({
  optionsKey = [],
  optionsValuesPerKey = {},
  filters = [],
  onChange,
  disabled = false,
  getTriggerPreview,
  supportClearAllFilters = true,
  fieldsAutoFocus = true,
  displayType = ADVANCED_FILTERS_DISPLAY_TYPE.HORIZONTAL,
  allowCustomValuesInMultiDropdown = false
}: AdvancedFiltersProps) => {
  const clearFilters = () => {
    onChange?.([])
  }

  const handleChange = (filter: Filter, index: number) => {
    const updatedFilters = replaceItemAtIndex(filters, filter, index)
    onChange?.(updatedFilters)
  }

  const handleAdd = () => {
    const filter = { id: Date.now() }
    const updatedFilters = [...filters, filter]
    onChange?.(updatedFilters)
  }

  const handleRemove = (index: number) => {
    const updatedFilters = removeItemAtIndex(filters, index)
    onChange?.(updatedFilters)
  }

  const Component = displayTypeToComponent[displayType]

  return (
    <Style.Main>
      <Stack direction='column' gap='space-200'>
        {filters.length ? (
          <Stack direction='column' gap='space-200'>
            {filters.map((filter, index) => {
              const shouldRenderAnd = index > 0 && displayType === ADVANCED_FILTERS_DISPLAY_TYPE.VERTICAL
              const optionsValues = optionsValuesPerKey[getValue(filter.key)]

              return Component && (
                <Fragment key={`filter-${index}`}>
                  <AndSeparator shouldRenderAnd={shouldRenderAnd} />
                  <Component
                    key={`${filter.id}`}
                    optionsKey={optionsKey}
                    optionsValues={optionsValues}
                    onChange={filter => handleChange(filter, index)}
                    onRemove={(e: React.MouseEvent) => {
                      e.stopPropagation()
                      return handleRemove(index)
                    }}
                    filter={filter}
                    disabled={disabled}
                    fieldsAutoFocus={fieldsAutoFocus}
                    allowCustomValuesInMultiDropdown={allowCustomValuesInMultiDropdown}
                  />
                </Fragment>
              )
            })}
          </Stack>
        ) : null}
        <Style.BottomActions>
          <Style.ButtonsContainer>
            <Button
              type={ButtonType.compact}
              size={ButtonSize.small}
              onClick={handleAdd}
              disabled={disabled}
              icon='Plus'
              label='Add filter'
            />
            {getTriggerPreview && (
              <Spacer left='space-100'>
                <EnableFor scopes={[]} allowForToriiAdmin>
                  <Button
                    type={ButtonType.secondary}
                    onClick={getTriggerPreview}
                    label='Preview'
                  />
                </EnableFor>
              </Spacer>
            )}
          </Style.ButtonsContainer>
          {supportClearAllFilters && (
            <Button
              type={ButtonType.compact}
              size={ButtonSize.small}
              disabled={disabled || filters.length === 0}
              onClick={clearFilters}
              label='Clear filters'
            />
          )}
        </Style.BottomActions>
      </Stack>
    </Style.Main>
  )
}

export default AdvancedFilters
