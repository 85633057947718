import React from 'react'
import { withRouter } from 'react-router-dom'
import { SYNC_ERRORS } from '@root/constants'
import StatusIcon from '@components/statusIcon'
import {
  TEST_RESULTS,
  CAPABILITY_TO_NAME
} from '@components/testConnection/constants'
import capitalize from 'lodash/capitalize'
import AlertBanner from '../alertBanner'
import DataInfoBox from '@components/dataInfoBox'

const TestConnection = ({ children }) => {
  return children
}

const bannerConfigurations = {
  [TEST_RESULTS.SUCCESS]: { colorName: 'successSubtle', iconName: 'Check', iconColor: 'success', bannerText: 'Test passed successfully' },
  [TEST_RESULTS.PARTIAL_SUCCESS]: { colorName: 'warningSubtle', iconName: 'Alert', iconColor: 'warning', bannerText: '{missingDataTypes} data was not returned in the test. All other data will still be synced when you connect the integration.' },
  [TEST_RESULTS.FAILED]: { colorName: 'errorSubtleHover', iconName: 'Danger', iconColor: 'error', bannerText: 'Test completed with errors. See below for details' },
  [TEST_RESULTS.IN_PROGRESS]: { colorName: 'infoSubtle', iconName: 'Info', iconColor: 'interactiveHover', bannerText: 'Testing...' },
  [TEST_RESULTS.INTERNAL_ERROR_SCREEN]: { colorName: 'errorSubtleHover', iconName: 'Danger', iconColor: 'error', bannerText: 'We’ve encountered an internal error. We are handling the issue. Please try again later' }
}

TestConnection.InProgressBanner = () => {
  return (<AlertBanner
    colorName={bannerConfigurations[TEST_RESULTS.IN_PROGRESS].colorName}
    iconName={bannerConfigurations[TEST_RESULTS.IN_PROGRESS].iconName}
    iconColor={bannerConfigurations[TEST_RESULTS.IN_PROGRESS].iconColor}
    bannerText={bannerConfigurations[TEST_RESULTS.IN_PROGRESS].bannerText}
    showSpinner
  />)
}

TestConnection.TestResultsBanner = ({ finalResults }) => {
  const testResult = finalResults.result
  let bannerText = bannerConfigurations[testResult].bannerText
  if (testResult === TEST_RESULTS.PARTIAL_SUCCESS) {
    let failedTestsText = finalResults.failedTests.join(' and ')
    failedTestsText = capitalize(failedTestsText)
    bannerText = bannerConfigurations[testResult].bannerText.replace('{missingDataTypes}', failedTestsText)
  }
  return <AlertBanner
    colorName={bannerConfigurations[testResult].colorName}
    iconName={bannerConfigurations[testResult].iconName}
    iconColor={bannerConfigurations[testResult].iconColor}
    bannerText={bannerText}
  />
}

TestConnection.InternalErrorBanner = () => {
  return (<AlertBanner
    colorName={bannerConfigurations[TEST_RESULTS.INTERNAL_ERROR_SCREEN].colorName}
    iconName={bannerConfigurations[TEST_RESULTS.INTERNAL_ERROR_SCREEN].iconName}
    iconColor={bannerConfigurations[TEST_RESULTS.INTERNAL_ERROR_SCREEN].iconColor}
    bannerText={bannerConfigurations[TEST_RESULTS.INTERNAL_ERROR_SCREEN].bannerText}
  />)
}

const successContent = (testNames) => {
  return (
    <>
      {testNames.map(testName => {
        return <DataInfoBox
          key={testName}
          status={StatusIcon.statuses.SUCCESS}
          dataDisplayName={CAPABILITY_TO_NAME[testName]}
        />
      })}
    </>
  )
}

const partialSuccessContent = (testNames, results) => {
  return (
    <>
      {testNames.map(testName => {
        const { success, errorType, errorMessage } = results[testName]

        return <DataInfoBox
          key={testName}
          status={success ? StatusIcon.statuses.SUCCESS : StatusIcon.statuses.FAILURE}
          dataDisplayName={CAPABILITY_TO_NAME[testName]}
          additionalInfoText={[errorMessage || SYNC_ERRORS[errorType]]}
        />
      })}
    </>
  )
}

const failureContent = (testNames, results, finalResults) => {
  let showResults = true
  return (
    <>
      {testNames.map(testName => {
        const { success, errorType, errorMessage } = results[testName] || {}
        const status = success ? StatusIcon.statuses.SUCCESS : StatusIcon.statuses.FAILURE
        const isMandatoryTest = finalResults.firstMandatoryTestFailed === testName

        const dataInfoBox = (
          <DataInfoBox
            key={testName}
            status={showResults ? status : StatusIcon.statuses.UNPROCESSED}
            dataDisplayName={CAPABILITY_TO_NAME[testName]}
            additionalInfoText={showResults ? [errorMessage || SYNC_ERRORS[errorType]] : null}
            emphasizeError={isMandatoryTest}
          />
        )
        if (isMandatoryTest) {
          showResults = false
        }
        return dataInfoBox
      })}
    </>
  )
}

TestConnection.TestResultsContent = ({ finalResults, results, capabilityList }) => {
  switch (finalResults.result) {
    case TEST_RESULTS.SUCCESS:
      return successContent(capabilityList)
    case TEST_RESULTS.PARTIAL_SUCCESS:
      return partialSuccessContent(capabilityList, results)
    case TEST_RESULTS.FAILED:
      return failureContent(capabilityList, results, finalResults)
    default:
      return null
  }
}

TestConnection.UnprocessedContent = ({ capabilityList }) => {
  return (
    <>
      { capabilityList.map(capability => {
        return <DataInfoBox
          key={capability}
          status={StatusIcon.statuses.UNPROCESSED}
          dataDisplayName={CAPABILITY_TO_NAME[capability]}
        />
      })}
    </>
  )
}

export default withRouter(TestConnection)
