import React from 'react'
import * as Style from './style'
import KeyValueRow from '@components/keyValueTable/keyValueRow'
import PropTypes from 'prop-types'
import { Button, ButtonType, ButtonSize } from '@toriihq/design-system'

const initialKeyValueArray = [ { id: Date.now(), key: '', value: '' } ]

const keyValueTable = ({ keyValueArray, keyValueType = '', onChange, onBlur, ValueComponent, keyComponentProps, valueComponentProps, ValueComponentWrapper, disabled = false, disallowedCharacters }) => {
  const deleteRow = (row) => {
    const newRows = keyValueArray.filter(r => r.id !== row.id)
    onChange && onChange(newRows, false)
  }

  const addRow = () => {
    const newRows = keyValueArray.concat({ id: Date.now(), key: '', value: '' })
    onChange && onChange(newRows, false)
  }

  const editRow = (editedRow) => {
    const newRows = keyValueArray.map(row => {
      if (row.id === editedRow.id) {
        return editedRow
      }
      return row
    })
    onChange && onChange(newRows)
  }

  const saveRows = () => {
    onBlur && onBlur()
  }

  return (
    <div {...Style.Main}>
      {(keyValueArray || initialKeyValueArray).map((row) =>
        <KeyValueRow
          key={row.id}
          keyValueElement={row}
          deleteRow={deleteRow}
          editRow={editRow}
          onBlur={saveRows}
          ValueComponent={ValueComponent}
          keyComponentProps={keyComponentProps}
          valueComponentProps={valueComponentProps}
          ValueComponentWrapper={ValueComponentWrapper}
          disabled={disabled}
          disallowedCharacters={disallowedCharacters}
        />
      )}
      <Button type={ButtonType.compact} size={ButtonSize.small} onClick={addRow} disabled={disabled} icon='Plus' label={`Add ${keyValueType}`} />
    </div>

  )
}

keyValueTable.propTypes = {
  keyValueArray: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    value: PropTypes.string
  })),
  keyValueType: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  ValueComponent: PropTypes.elementType,
  keyComponentProps: PropTypes.object,
  valueComponentProps: PropTypes.object,
  ValueComponentWrapper: PropTypes.elementType,
  disabled: PropTypes.bool,
  disallowedCharacters: PropTypes.arrayOf(PropTypes.string)
}

export default keyValueTable
