import React from 'react'
import PropTypes from 'prop-types'
import ToriiPopup from '../ToriiPopupV2'
import { WORKFLOW_ACTION_TYPES } from '@root/constants'
import { css } from 'glamor'
import SendEmailPreview from '@media/send_email_preview.svg'
import FillFormEmailPreview from '@media/fill_form_email_preview.svg'
import RequestApprovalEmailPreview from '@media/request_approval_email_preview.svg'
import RemoveUserEmailPreview from '@media/remove_user_email_preview.svg'
import BrowserActivationEmailPreview from '@media/browser_activation_email_preview.svg'

const previewSrc = {
  [WORKFLOW_ACTION_TYPES.SEND_EMAIL]: SendEmailPreview,
  [WORKFLOW_ACTION_TYPES.SEND_FORM]: FillFormEmailPreview,
  [WORKFLOW_ACTION_TYPES.SEND_CONTRACT_FORM]: FillFormEmailPreview,
  [WORKFLOW_ACTION_TYPES.REQUEST_APPROVAL]: RequestApprovalEmailPreview,
  [WORKFLOW_ACTION_TYPES.REMOVE_USER]: RemoveUserEmailPreview,
  [WORKFLOW_ACTION_TYPES.SEND_BROWSER_ACTIVATION_EMAIL]: BrowserActivationEmailPreview
}

const CSS = {
  imageContainer: css({
    margin: '40px -25px -45px -25px'
  })
}

const WorkflowPreviewPopup = ({ cancel, isOpen, actionType }) =>
  (
    <ToriiPopup isOpen={isOpen} onCloseAction={cancel} width='750px'>
      <ToriiPopup.Content>
        <div {...CSS.imageContainer}>
          <img alt='show preview' src={previewSrc[actionType]} width='100%' height='100%' />
        </div>
      </ToriiPopup.Content>
    </ToriiPopup>
  )

WorkflowPreviewPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  src: PropTypes.string
}

export default WorkflowPreviewPopup
