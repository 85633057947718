import {
  TOGGLE_COPY_APP_DATA_POPUP
} from '@root/constants'
import type { CopyAppDataPopupState } from '@root/store/reducers/ui/types'

export const togglePopupCopyAppData = ({ isOpen, idApp, appName, possibleDuplicateApp }: CopyAppDataPopupState = {}) =>
  ({
    type: TOGGLE_COPY_APP_DATA_POPUP,
    payload: { isOpen, idApp, appName, possibleDuplicateApp },
    meta: {}
  })
