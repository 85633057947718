import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import moment from 'moment'
import { Field } from 'react-final-form'
import orderBy from 'lodash/orderBy'
import colors from '@shared/style/colors'
import SelectApps from '@components/selectApps'
import ToriiSelect from '@components/select'
import texts from '@shared/style/texts'
import { Body2 } from '@toriihq/design-system'
import { NEW_APP_ACCOUNT_ID } from './index'

const CSS = {
  fields: css({
    display: 'flex',
    flexDirection: 'column'
  }),
  selectAppContainer: css({
    marginBottom: '20px'
  }),
  label: css({
    marginBottom: '10px'
  }),
  selectOption: css(texts.body, {
    color: colors.darkText
  })
}

class ConnectCustomIntegration extends React.Component {
  state = {
    disconnectedAccounts: [],
    selectedAppName: ''
  }

  getBadge = idApp => {
    const { availableServices, connectedServices } = this.props
    let label
    if (connectedServices[idApp]) {
      if (connectedServices[idApp].every(service => service.source === 'custom')) {
        label = 'custom integration connected'
      } else if (connectedServices[idApp].every(service => service.source !== 'custom')) {
        label = 'native integration connected'
      } else {
        label = 'native and custom integrations connected'
      }
    } else if (availableServices[idApp]) {
      label = 'native integration available'
    }
    return label ? <Body2 color='secondary'>{label}</Body2> : undefined
  }

  transformApps = apps => {
    const specialOption = {
      value: 'customApp',
      isSpecial: true,
      render: (searchValue) => {
        return <div style={{ color: colors.blue }}>Add a custom app "{searchValue}"</div>
      }
    }
    return apps.map(app => {
      const badge = this.getBadge(app.id)
      return { ...app, badge }
    }).concat(specialOption)
  }

  onSelectApp = async (app, searchValue, input) => {
    const { toggleAddApplication, getIntegrations, idOrg } = this.props
    this.setState({ disconnectedAccounts: [], selectedAppName: '' })
    if (app.value === 'customApp') {
      toggleAddApplication({
        isAddApplicationOpen: true,
        customApp: true,
        app: { name: searchValue },
        onSuccessCB: (idApp) => {
          input.onChange(idApp)
        },
        openFrom: 'Add custom integration'
      })
    } else {
      const response = await getIntegrations({ idOrg, idApp: app.id, disconnectedOnly: true, customOnly: true })
      input.onChange(app.id)
      this.setState({ disconnectedAccounts: response.appAccounts, selectedAppName: app.name })
    }
  }
  appSelectComponent = ({ label, input, ...rest }) => {
    return (
      <div {...CSS.selectAppContainer}>
        <div {...CSS.label}>{label}</div>
        <SelectApps
          {...input}
          disableHiddenApps
          openOnFocus
          autoFocus
          transformApps={this.transformApps}
          value={input.value}
          onChange={(app, searchValue) => this.onSelectApp(app, searchValue, input)}
          {...rest}
        />
      </div>
    )
  }

  renderOption = ({ data: option }) => {
    if (option.value === NEW_APP_ACCOUNT_ID) {
      return <div {...CSS.selectOption}>{option.label}</div>
    }

    return <div key={option.value}>
      <div {...CSS.selectOption}>Re-add account {option.label}</div>
      <div {...texts.body}>(created on {moment(option.creationTime).utc().format('MMM DD, YYYY')}; {option.lastSync ? `last synced on ${option.lastSync}` : 'never synced'})</div>
    </div>
  }

  renderValue = (option) => <div>{option.value === NEW_APP_ACCOUNT_ID ? option.label : `Re-add account ${option.label}`}</div>

  idAppAccountSelectComponent = ({ input }) => {
    const { disconnectedAccounts, selectedAppName } = this.state
    if (!disconnectedAccounts.length) {
      return null
    }

    const options = disconnectedAccounts.map(account => ({ label: account.appAccountName, value: account.id, lastSync: account.lastSyncTime, creationTime: account.creationTime }))
    const sortedOptions = orderBy(options, ['lastSync'], ['desc'])
    if (!input.value) {
      input.onChange(sortedOptions[0].value)
    }
    return <>
      <div {...CSS.label}>You had custom integration with {selectedAppName} in the past. What do you want to do?</div>
      <ToriiSelect
        options={sortedOptions.concat({ label: 'Add integration with a new account', value: NEW_APP_ACCOUNT_ID })}
        optionRenderer={this.renderOption}
        valueRenderer={this.renderValue}
        onChange={option => input.onChange(option.value)}
        value={input.value || sortedOptions[0]}
        clearable={false}
      />
    </>
  }

  render () {
    const { formProps: { handleSubmit, submitError } } = this.props

    return <form onSubmit={handleSubmit}>
      <div {...CSS.fields}>
        <Field
          name='idApp'
          render={this.appSelectComponent}
          label='Application'
          validate={value => value ? undefined : 'Required'}
        />
        <Field
          name='idAppAccount'
          render={this.idAppAccountSelectComponent}
        />
      </div>
      <div
        {...CSS.errorText}
        style={{ visibility: submitError ? 'visible' : 'hidden' }}>
        {submitError}
      </div>
    </form>
  }
}

ConnectCustomIntegration.propTypes = {
  idOrg: PropTypes.number.isRequired,
  apps: PropTypes.array,
  appsById: PropTypes.object,
  availableServices: PropTypes.object,
  connectedServices: PropTypes.object
}

export default ConnectCustomIntegration
