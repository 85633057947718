import styled from 'styled-components'

export const defaultModalWidth = '500px'
export const smallDeviceWidth = '610px'

type ContentAreaProps = {
  headerAndFooterHeight?: number;
  maxHeight?: string;
}

export const Modal = styled.div<{ maxHeight?: string }>`
  z-index: 5;
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  border-radius: 4px;
  outline: none;
  box-shadow: 0 16px 23px 0 rgba(0,0,0,0.24);
  background: ${({ theme }) => theme.palette.background.primary};
  padding: 0px 0px;
  max-width: 1200px;
  max-height: ${props => props.maxHeight || '700px'};
  width: ${defaultModalWidth};
`

export const SmallScreenModal = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  border-radius: 4px;
  outline: none;
  box-shadow: 0 16px 23px 0 rgba(0,0,0,0.24);
  background: ${({ theme }) => theme.palette.background.primary};
  padding: 0px 0px;
  max-width: 90vw;
  max-height: 700px;
  width: ${defaultModalWidth};
`

export const HeaderContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
  padding: 60px 30px 20px;
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  display: flex;
  font: ${({ theme }) => theme.typography.font.header04};
  color: ${({ theme }) => theme.palette.text.primary};
`

export const SubHeader = styled.div`
  padding: 4px 0px 0px;
  font: ${({ theme }) => theme.typography.font.body02};
  color: ${({ theme }) => theme.palette.text.secondary};
  display: flex;
`

export const CustomHeader = styled.div`
  display: flex;
  font: ${({ theme }) => theme.typography.font.body02};
  color: ${({ theme }) => theme.palette.text.primary};
`

export const ContentArea = styled.section<ContentAreaProps>`
  padding: 30px 30px;
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
  color: ${({ theme }) => theme.palette.text.primary};
  
  @media(max-height: ${props => props.maxHeight ?? '700px'}) {
    max-height: calc(100vh - ${props => (props.headerAndFooterHeight || 0) + 26}px);
  }
  
  @media(min-height: ${props => props.maxHeight ?? '700px'}) {
    max-height: calc(${props => props.maxHeight ?? '700px'} - ${props => props.headerAndFooterHeight || 0}px);
  }
`

export const FormArea = styled(ContentArea)`
  display: flex;
  flex-direction: column;
  gap: 30px;
`

export const Footer = styled.footer`
  border-top: 1px solid ${({ theme }) => theme.palette.border.primary};
  min-height: 75px;
  max-height: 75px;
  padding: 0 30px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  
  @media(max-width: ${smallDeviceWidth}) {
    justify-content: center;
    min-height: 90px;
    max-height: 90px;
  }
`

export const EmptyFooter = styled.footer`
  height: 30px;
`

export const BackButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  
  @media(max-width: ${smallDeviceWidth}) {
    margin-top: 10px;
    width: 100%;
  }
`

export const FooterCheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  
  @media(max-width: ${smallDeviceWidth}) {
    margin-top: 10px;
    width: 100%;
  }
`

export const BackButton = styled.div`
  padding: 0px 6px;
  justify-self: flex-start;
`

export const PageIndex = styled.div`
  font: ${({ theme }) => theme.typography.font.body02};
  color: ${({ theme }) => theme.palette.text.secondary};
  justify-self: flex-start;
  margin-left: auto;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
  gap: 10px;
  
  @media(max-width: ${smallDeviceWidth}) {
    margin: 10px 0;
    width: 100%;
  }
`
