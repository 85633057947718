import React from 'react'
export namespace Styles {
    export const placeholderStyle = {
      maxWidth: '100%',
      width: '787px',
      display: 'flex',
      flexDirection: 'columm' as React.CSSProperties['flexDirection'],
      alignItems: 'center',
      margin: '24px auto'
    }
}
