import React from 'react'
import { Col, ExpandableSection, Grid, Stack, Subtitle1, Subtitle2 } from '@toriihq/design-system'
import Analytics from '../../analytics'
import { FullWidth, RowWithMargin } from '@pages/renewalForecast/recommendation/style'
import AppAccountLicenseUsers from '@pages/renewalForecast/appAccountLicenseUsers'
import LicenseBarsAndTableCard from '../licenseBarsAndTableCard'
import LicenseToPurchaseCard from '@pages/renewalForecast/recommendation/licenseToPurchaseCard'
import { LicenseBreakdownProps } from '@pages/renewalForecast/recommendation/types'

const LicenseBreakdown = ({
  appName,
  licensesDetails,
  idApp,
  idOrg,
  idAppAccount,
  bufferValue,
  onSelectedColumnsChange,
  onSelectedViewChange,
  inactivityPeriod
}: LicenseBreakdownProps): JSX.Element => {
  return (<FullWidth>
    <Stack gap={'space-300'} direction={'column'}>
      <Subtitle1>License breakdown</Subtitle1>
      <Stack direction='column' gap={'space-800'}>
        {licensesDetails.licenses.map(license => {
          const { activeAmount } = license
          const bufferAmount = Math.floor(activeAmount * bufferValue / 100)
          return (
            <Stack direction={'column'} gap={'space-300'}>
              <Subtitle2>
                {license.name}
              </Subtitle2>
              <FullWidth>
                <Grid>
                  <RowWithMargin marginBottom='0px'>
                    <Col sm={8}>
                      <LicenseBarsAndTableCard idOrg={idOrg} idApp={idApp} licensesDetails={licensesDetails} license={license} bufferAmount={bufferAmount} bufferValue={bufferValue} />
                    </Col>
                    <Col sm={4}>
                      <LicenseToPurchaseCard activeAmount={activeAmount} bufferAmount={bufferAmount} bufferValue={bufferValue} />
                    </Col>
                  </RowWithMargin>
                </Grid>
              </FullWidth>
              <ExpandableSection title={`${license.name} detailed breakdown`} key={license.id} onToggle={(isOpen) => { isOpen ? Analytics.expandLicenseBreakdown({ appName, licenseName: license.name }) : Analytics.collapseLicenseBreakdown({ appName, licenseName: license.name }) }}>
                <AppAccountLicenseUsers
                  idApp={idApp}
                  idLicense={license.id}
                  idAppAccount={idAppAccount!}
                  idOrg={idOrg}
                  license={license}
                  onSelectedColumnsChange={onSelectedColumnsChange}
                  onSelectedViewChange={onSelectedViewChange}
                  inactivityPeriod={inactivityPeriod?.value}
                />
              </ExpandableSection>
            </Stack>
          )
        })}
      </Stack>
    </Stack>
  </FullWidth>
  )
}

export default LicenseBreakdown
