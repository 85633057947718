import {
  GET_DASHBOARDS,
  GET_DATA_FOR_WIDGET,
  GET_DRILL_DOWN_DATA_FOR_WIDGET,
  UPDATE_DASHBOARD
} from '@root/constants'
import { Action, ActionTypeStatus } from '@reducers/types'
import { IdDashboard } from '@store/types'
import { Filter } from '@shared/filters/types'

export enum ENTITY_TYPES {
    USERS= 'users',
    APPS='apps',
    CONTRACTS= 'contracts',
    TRANSACTIONS = 'transactions',
    APP_USERS = 'appUsers'
}

export enum WIDGET_TYPES {
  METRIC_WIDGET = 'metricWidget',
  PIE_CHART_WIDGET = 'pieChartWidget',
  BAR_CHART_WIDGET = 'barChartWidget',
  TABLE_WIDGET = 'tableWidget',
  FIXED_WIDGET = 'fixedWidget'
}

export enum FIXED_WIDGET_TYPES {
  RENEWAL_YEARLY_SUMMARY = 'renewalYearlySummary',
  USERS_MAP = 'usersMap'
}

export enum AGGREGATION_TYPES {
    METRIC = 'metric',
    GROUP_BY = 'groupBy',
    DATE_HISTOGRAM = 'date_histogram'
}

export enum METRIC_FUNC {
    TOTAL= 'total',
    MIN= 'min',
    MAX ='max',
    AVG = 'avg',
    SUM = 'sum'
}

export type Field = {
    systemKey: string
    type: string
    label: string
}

export enum BAR_ORIENTATION {
    VERTICAL = 'vertical',
    HORIZONTAL = 'horizontal'
}

export enum DATE_PERIOD {
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
    QUARTERLY = 'quarterly',
    YEARLY = 'yearly'
}

export enum AGGREGATION_SORT_ORDERS {
    DESC = 'desc',
    ASC = 'asc'
}

export type Metric = {
    func: METRIC_FUNC,
    field?: Field
}

export type WidgetGroupBySort = {
    field?: string
    aggFunc?: METRIC_FUNC
    order: AGGREGATION_SORT_ORDERS
}

export type DynamicWidget = {
    id: number
    entityType: ENTITY_TYPES
    type: WIDGET_TYPES
    dataConfig: {
      filters: Filter[]
    }
    drillDownConfig: {
      fields: string[]
      sort: string[]
    }
    displayConfig: {
      label: string
      groupBy?: {
        field: Field
        size: number
        metric: Metric
        datePeriod?: DATE_PERIOD
        sort: WidgetGroupBySort
        groupBy?: DynamicWidget['displayConfig']['groupBy']
      }
      metric?: Metric,
      barOrientation?: BAR_ORIENTATION,
      fields?: string[]
      sort?: string[]
    }
}

export type FixedWidget = {
  id: number
  type: WIDGET_TYPES.FIXED_WIDGET
  fixedWidgetType: FIXED_WIDGET_TYPES,
  displayConfig: {
    label: string
  }
}

export type Widget = DynamicWidget | FixedWidget

type LayoutConfig = {
  i: number
  x: number
  y: number
  w: number
  h: number
}

export type Dashboard = {
  systemKey: string
  title: string
  description: string
  id: IdDashboard
  widgets: Widget[]
  layoutConfig: LayoutConfig[]
}

type WidgetDataAggregation = {
  key: string | number,
  value: number,
  aggregations?: WidgetDataAggregation[] | number
}

type WidgetDataAggregations = WidgetDataAggregation[] | number

export type WidgetData = {
    data: {
        loading: boolean,
        rows: object[]
        total: number
        resources: { users?: object[], apps?: object[], contracts?: object[], fields?: object[] }
        aggregations: WidgetDataAggregations
        displayConfig?: object
    }
    drillDownData?: {
        loading: boolean
        total?: number
        rows?: object[]
        resources?: {
            fields?: object[],
            apps?: Record<number, object>,
            users?: Record<number, object>,
            contracts?: Record<number, object>
        }
    }
}

export type DashboardData = {
    widgetsData: Record<Widget['id'], WidgetData>
}

export type DashboardsState = {
    isLoading: boolean,
    dashboardsConfig: Dashboard[],
    dashboardsData: Record<Dashboard['id'], DashboardData>
}

type GetDashboards = Action<
    `${typeof GET_DASHBOARDS}_${ActionTypeStatus}`,
    { dashboards: DashboardsState['dashboardsConfig'] }
>

type UpdateDashboard = Action<
    `${typeof UPDATE_DASHBOARD}_${ActionTypeStatus}`,
    { dashboard: Dashboard },
    { idDashboard: IdDashboard, dashboard: Dashboard }
>

type GetDataForWidget = Action<
    `${typeof GET_DATA_FOR_WIDGET}_${ActionTypeStatus}`,
    { total: number, aggregations: any, resources: any },
    { idDashboard: IdDashboard, idWidget: number, entityType: string, displayConfig: object, reset: boolean }
>

type GetDrillDownDataForWidget = Action<
    `${typeof GET_DRILL_DOWN_DATA_FOR_WIDGET}_${ActionTypeStatus}`,
    { rows: any, total: number, resources: any },
    { idDashboard: IdDashboard, idWidget: number, entityType: string, reset?: boolean }
>

export type WidgetsActions =
    | GetDataForWidget
    | GetDashboards
    | UpdateDashboard
    | GetDrillDownDataForWidget
