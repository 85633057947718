import { isUndefined, omitBy } from 'lodash'

/*
 this function utilizes the Fetch API to send an HTTP request,
 while communicating its state to the Redux store (START -> SUCCESS/FAIL).
 it expects to receive a JSON formatted response.
 _transform_ may be a function which modifies the JSON response
 before dispatching it to the store.

 All dispatched actions comply with the Flux Standard Action (https://github.com/acdlite/flux-standard-action)
 */

/*
 reduxFetch default values
 */
type RequestInit = NonNullable<Parameters<(typeof window.fetch)>[1]>
let configuration = {
  startSuffix: '_PENDING',
  successSuffix: '_RESPONSE',
  failSuffix: '_FAILED',
  baseUrl: 'http://localhost',
  defaultHeaders: {},
  credentials: 'include' as RequestInit['credentials']
}

/*
 reduxFetch default values can be configured
 */
export const configure = config => {
  configuration = {
    ...configuration,
    ...config
  }
}

// headers can either be values, or functions which would be evaluated at run time
const evaluateHeaders = async headers => {
  const evaluatedHeaders = {}
  await Promise.all(
    Object.keys(headers).map(key => {
      const value = headers[key]
      return Promise.resolve(
        typeof value === 'function' ? value() : value)
        .then(resolution => {
          evaluatedHeaders[key] = resolution
        })
    }))
  return omitBy(evaluatedHeaders, isUndefined)
}

class ReduxFetchError extends Error {
  responseHeaders: unknown
  url: string
  sentryExtraInfo: { responseHeaders: unknown; url: string }

  constructor (message, responseHeaders, url) {
    super(message)
    this.name = 'ReduxFetchError'
    this.responseHeaders = responseHeaders
    this.url = url
    this.sentryExtraInfo = { responseHeaders, url }
  }
}

const reduxFetch = (type: string, {
  url,
  meta,
  method = 'GET',
  query,
  body,
  headers,
  transform,
  scopes = []
}: {
  url: string;
  meta?: Record<string, unknown>;
  method?: string;
  query?: Record<string, string | number | boolean>;
  body?: RequestInit['body'];
  headers?: RequestInit['headers'];
  transform?: (data: unknown) => unknown;
  scopes?: string[];
}) => async dispatch => {
  const { startSuffix, failSuffix, successSuffix, baseUrl, defaultHeaders, credentials } = configuration
  let queryString: typeof query | string = query
  if (typeof query === 'object') {
    queryString = Object.keys(query).map(key => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`
    }).join('&')
  }

  let urlWithProtocol
  if (url.includes('://')) {
    urlWithProtocol = url
  } else {
    urlWithProtocol = `${baseUrl}${url}`
  }

  const parsedUrl = queryString
    ? `${urlWithProtocol}?${queryString}`
    : urlWithProtocol
  const isAPIAccessible = dispatch(
    {
      type: `${type}${startSuffix}`,
      url: parsedUrl,
      meta,
      payload: query ? { query } : {},
      scopes
    })

  if (isAPIAccessible === false) {
    return
  }

  const evaluatedHeaders = await evaluateHeaders({
    ...defaultHeaders,
    ...headers || {}
  })
  if (body) {
    body = Object.prototype.toString.call(body) !== '[object FormData]' ? JSON.stringify(body) : body
  }
  let res

  return window.fetch(parsedUrl, {
    method,
    body,
    headers: evaluatedHeaders,
    credentials
  })
    .then(response => {
      res = response
      return response.text()
    })
    .then(data => {
      if (!res.ok) {
        throw new ReduxFetchError(data, [...res.headers.entries()], res.url)
      }
      if (!data && res.status === 204) {
        dispatch({ type: `${type}${successSuffix}`, meta, payload: {} })
        return {}
      }
      try {
        data = JSON.parse(data)
      } catch (e) {
        throw new Error(data)
      }
      if (typeof data === 'string') {
        throw new Error(data)
      }
      const payload = transform ? transform(data) : data
      dispatch({ type: `${type}${successSuffix}`, meta, payload })
      return payload
    })
    .catch(e => {
      let payload
      try {
        payload = JSON.parse(e.message)
      } catch (_) {
        payload = e.message
      }
      dispatch({
        type: `${type}${failSuffix}`,
        meta,
        payload,
        error: true
      })
      throw e
    })
}

export default reduxFetch
