import styled, { css } from 'styled-components'
import { fontWeight } from '@shared/style/sizes'

const SIDE_PADDING = '21px'

export const Main = styled.div<{ $showHeaderSection: boolean }>`
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  border-radius: 8px;
  height: ${props => props.$showHeaderSection ? '354px' : '300px'};
`

export const Header = css`
  padding: 7px ${SIDE_PADDING} 0 ${SIDE_PADDING};
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
  font-weight: ${fontWeight.bold};
`

export const ChartContainer = {
  height: '243px'
}

export const Chart = {
  paddingTop: '32px',
  paddingBottom: '26px'
}
