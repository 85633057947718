import { createSelector } from 'reselect'
import identity from 'lodash/identity'
import get from 'lodash/get'
import { SCOPES_PERMISSIONS } from '@root/constants'
import sortBy from 'lodash/sortBy'

const HIDDEN_SCOPES = ['dashboards']

export const getRoles = createSelector(
  state => get(state, ['roles', 'roles'], []),
  identity
)

export const getAdminRoles = createSelector(
  [getRoles],
  roles => roles.filter(role => Boolean(role.isAdmin))
)

export const getAdminRoleOptionsForSelect = createSelector(
  [getAdminRoles],
  roles => roles.map((role) => ({ value: role.id, label: role.name, description: role.description }))
)

export const getSuperAdminRole = createSelector(
  [getRoles],
  roles => (roles.find(role => role.systemKey === 'admin' && !role.idOrg)) || {}
)

export const getEmployeeRole = createSelector(
  [getRoles],
  roles => (roles.find(role => role.systemKey === 'employee' && !role.idOrg)) || {}
)

export const getReadonlyRole = createSelector(
  [getRoles],
  roles => (roles.find(role => role.systemKey === 'readonly' && !role.idOrg)) || {}
)

export const getAPIRole = createSelector(
  [getRoles],
  roles => (roles.find(role => role.systemKey === 'api' && !role.idOrg)) || {}
)

export const isLoadingRoles = createSelector(state => get(state, ['roles', 'loading'], false),
  identity
)

export const getScopesInfo = createSelector(
  state => get(state, ['roles', 'scopesInfo', 'scopes'], []),
  identity
)

export const getRoleInfo = createSelector(
  state => get(state, ['roles', 'roleInfo', 'roleInfo'], {}),
  identity
)

export const getUserRoleScopes = createSelector(
  state => get(state, ['roles', 'roleInfo', 'roleInfo', 'scopes'], []),
  identity
)

export const getUserScopesPermissions = createSelector(
  [getUserRoleScopes],
  userScopes => userScopes.reduce((userScopesPermissions, userScope) => {
    const userScopeArray = userScope.split(':')
    userScopesPermissions[userScopeArray[0]] = userScopeArray[1]
    return userScopesPermissions
  }, {})
)

export const isLoadingRoleInfo = createSelector(
  state => get(state, ['roles', 'roleInfo', 'loading'], false),
  identity
)

export const getAccessScopesInfo = createSelector(
  [getScopesInfo],
  scopesInfo => {
    const scopesKeys = {}
    scopesInfo.forEach(scope => {
      const [name, permission] = scope.systemKey.split(':')
      if (permission === SCOPES_PERMISSIONS.WRITE) {
        scopesKeys[name] = scope
      } else if (permission === SCOPES_PERMISSIONS.READ && !scopesKeys[name]) {
        scopesKeys[name] = scope
      }
    })

    return sortBy(Object.values(scopesKeys), 'position')
  }
)

export const getVisibleAccessScopesInfo = createSelector(
  [getAccessScopesInfo],
  scopesInfo => {
    return scopesInfo.filter(scope => {
      const [name] = scope.systemKey.split(':')
      return !HIDDEN_SCOPES.includes(name)
    })
  }
)

export const getFormattedScopesInfo = createSelector(
  [getVisibleAccessScopesInfo, getUserScopesPermissions],
  (scopesInfo, scopesPermissions) => scopesInfo.map(scope => {
    const id = scope.systemKey.split(':')[0]
    return {
      id,
      name: scope.name,
      description: scope.description,
      permission: scopesPermissions[id]
    }
  })
)

export const getFormattedReadScopesInfo = createSelector(
  [getVisibleAccessScopesInfo],
  scopesInfo => scopesInfo.map(scope => ({
    id: scope.systemKey.split(':')[0],
    name: scope.name,
    description: scope.description,
    permission: SCOPES_PERMISSIONS.READ
  }))
)

export const isLoadingScopes = createSelector(
  state => get(state, ['roles', 'scopesInfo', 'loading'], false),
  identity
)
