import React from 'react'
import PropTypes from 'prop-types'
import { formFieldLabelActionTypes, formFieldTypes, SCOPES, USER_FIELD_SEARCH_TYPES, WORKFLOW_ACTION_TYPES, WORKFLOW_TRIGGER_TYPES } from '@root/constants'
import ToriiSelect from '../../select'
import Input from '../../form/input'
import FormGroup from '../../form/formGroup'
import DatePicker from '../../datePicker'
import moment from 'moment'
import SelectState from '../../selectState'
import SelectAccount from '../selectAccount'
import SingleUser from '../singleUser'
import MultipleUsers from '../multipleUsers'
import MentionsTextarea from '../../form/mentionsTextarea'
import { StyledControlNestedInput, StyledControlNestedTextArea } from '../../form/formStyle'
import Threshold from '../threshold'
import AdvancedFilters from '../../advancedFilters'
import UserMeetsCriteriaFilters from '../../userMeetsCriteriaFilters'
import AppAndAccountSelect from '../../appAndAccountSelect'
import compact from 'lodash/compact'
import FormFieldSelectUser from '../../formFieldSelectUser'
import UpdateField from '../updateField'
import EmailSetupBox from '../emailSetup/emailSetupBox'
import EmailPreviewButton from '../emailSetup/emailPreviewButton'
import SelectFormFields from '../selectFormFields'
import LicensesSelect from '../licensesSelect'
import SelectApps from '../selectApps'
import SendFormPreviewButton from '../sendFormPreviewButton'
import OptionsRenderers from '@shared/optionRenderers'
import LicenseInfoTooltip from '@root/components/licenses/licenseInfoTooltip'
import get from 'lodash/get'
import KeyValueTable from '../../keyValueTable'
import lowerCase from 'lodash/lowerCase'
import pluralize from 'pluralize'
import EnableFor from '@components/enableFor'
import { formatDateAsUTC } from '@shared/utils'
import InfoTooltip from '@components/infoTooltip'
import ContractImg from '@media/contract.svg'
import { OverridableDropdown } from './components/dropdowns/overridableDropdown'
import { CurrencyInput } from '@components/form/currencyInput'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'
import { TextArea } from '@toriihq/design-system'
import { ReadonlyCopyField } from '@components/readonlyCopyField'
import { Dropdown } from './components/dropdowns/dropdown'
import { CSS } from './styles'

const inputComponent = ({ input, field }) => <Input {...input} placeholder={field.placeholder} onChange={(e) => input.onChange(e, true)} />
const numberInputComponent = ({ input }) => <Input {...input} type='number' onChange={(e) => input.onChange(e, true)} />
const textareaComponent = ({ input }) => <TextArea {...input} onChange={(e) => input.onChange(e, true)} />
const allNonNumbersRegex = /([^0-9]*)/g

const shouldDisableUserToRemove = ({ field, triggerType }) => {
  const isUserToRemoveField = field.id === 'user'
  return triggerType === WORKFLOW_TRIGGER_TYPES.USER_OFFBOARDING &&
  field.autoSelectTriggerUser &&
  isUserToRemoveField
}

const adjustLabel = (word) => {
  return word ? lowerCase(pluralize.singular(word)) : ''
}

const getKeyValueStylesAndProps = ({ className, personalization, disabledPlaceholders, valuePlaceholder }) => {
  const ValueComponentWrapper = StyledControlNestedInput

  const valueProps = omitBy({
    singleLine: true,
    placeholder: valuePlaceholder || (disabledPlaceholders ? '' : 'Use @ to customize'),
    className,
    mentions: personalization,
    overrideStyle: {
      border: 'none',
      '&singleLine': {
        input: {
          width: '100%',
          padding: '6px 12px'
        }
      }
    }
  }, isUndefined

  )
  return { ValueComponentWrapper, valueProps }
}

const emailValidator = value =>
  value && !/^\S+@\S+\.\S+/i.test(value)
    ? 'Invalid email address' : undefined

const dateValidator = value => {
  const isValidDate = moment(value).isValid()
  return value && !isValidDate
    ? 'Invalid date format' : undefined
}

const numberValidator = value => {
  return isNaN(value)
    ? 'Invalid number' : undefined
}

const mapping = {
  [formFieldTypes.number]: {
    component: numberInputComponent
  },
  [formFieldTypes.singleLine]: {
    component: inputComponent
  },
  [formFieldTypes.multiLine]: {
    component: textareaComponent
  },
  [formFieldTypes.email]: {
    component: inputComponent,
    validators: emailValidator
  },
  [formFieldTypes.currency]: {
    component: ({ input }) => <CurrencyInput
      {...input}
      onChange={value => input.onChange(value, true)}
      onBlur={() => input.onBlur(input.value)}
    />,
    validators: numberValidator
  },
  [formFieldTypes.cardNumber]: {
    component: inputComponent,
    format: value => 'XXXX-XXXX-XXXX-' + (value || '').replace(allNonNumbersRegex, '').substr(0, 4),
    parse: value => (value || '').replace(allNonNumbersRegex, '')
  },
  [formFieldTypes.datePicker]: {
    component: ({ input }) => (
      <DatePicker
        value={input.value ? new Date(formatDateAsUTC(input.value)) : input.value}
        onDayChange={(date) => {
          input.onChange(date && moment.utc(date, 'YYYY-MM-DD').toDate())
        }}
        dayPickerProps={{
          onBlur: input.onBlur,
          onDayMouseDown: (date) => {
            input.onChange(date && moment.utc(date, 'YYYY-MM-DD').toDate())
          }
        }}
        disabled={input.disabled}
      />
    ),
    validators: dateValidator
  },
  [formFieldTypes.dropdownMulti]: {
    component: ({ field, input, overrideStyle }) => {
      const updatedSelectedOption = input.value
        ? input.value.map(inputValue => (field.options && field.options.find(option => option.value === inputValue.value)) || inputValue)
        : input.value
      const additionalProps = {
        optionRenderer: field.optionsRenderer ? OptionsRenderers[field.optionsRenderer] : undefined
      }
      return (
        <ToriiSelect
          options={field.options}
          clearable={Boolean(field.clearable)}
          autosize={false}
          value={updatedSelectedOption}
          onBlur={() => input.onBlur(input.value)}
          onChange={(selectedOptions) => {
            selectedOptions.length === 0 ? input.onChange(null) : input.onChange(selectedOptions)
          }}
          isLoadingOptions={!input.disabled && field.loading && (!field.options || !field.options.length)}
          multi
          disabled={input.disabled}
          {...additionalProps}
        />
      )
    }
  },
  [formFieldTypes.dropdown]: {
    component: Dropdown
  },
  [formFieldTypes.overridableDropdownMulti]: {
    component: props => <OverridableDropdown {...props} dropdownType='multi' />
  },
  [formFieldTypes.overridableDropdown]: {
    component: props => <OverridableDropdown {...props} dropdownType='single' />
  },
  [formFieldTypes.usersDropdown]: {
    component: ({ input, idActionExe, id, secret }) =>
      <FormFieldSelectUser
        idActionExe={idActionExe}
        id={id}
        secret={secret}
        onChange={(selectedOption) => {
          input.onChange(selectedOption)
        }}
        value={input.value}
      />
  },
  [formFieldTypes.usersDropdownMulti]: {
    component: ({ input, idActionExe, id, secret }) => {
      return <FormFieldSelectUser
        idActionExe={idActionExe}
        id={id}
        secret={secret}
        multi
        onChange={(users) => {
          input.onChange(users)
        }}
        value={input.value}
      />
    }
  },
  [formFieldTypes.account]: {
    component: ({ field, input, action, refreshDynamicFieldsOptions, fieldParams }) => {
      const options = (field.options || []).map(option => ({ ...option, value: option.id }))
      const idApp = field.idApp || (action || {}).idApp || (fieldParams || {}).idApp

      return <SelectAccount
        options={options}
        selectedOptionValue={input.value}
        onChange={(selectedOption, locallyOnly) => input.onChange(selectedOption, locallyOnly)}
        idApp={idApp}
        disabled={input.disabled}
        refreshDynamicFieldsOptions={refreshDynamicFieldsOptions}
      />
    }
  },
  [formFieldTypes.user]: {
    component: ({ field, input, triggerType, action }) => {
      const userSearchType = field.userSearchType
      const isExternal = userSearchType === USER_FIELD_SEARCH_TYPES.INTERNAL_USERS ? false : undefined
      const disabled = input.disabled || shouldDisableUserToRemove({ field, triggerType })

      return <SingleUser
        includeCustomUserFields
        value={input.value}
        triggerType={triggerType}
        onChange={input.onChange}
        adminsOnly={Boolean(field.adminsOnly)}
        disabled={disabled}
        isExternal={isExternal}
      />
    }
  },
  [formFieldTypes.users]: {
    component: ({ field, input, triggerType }) => {
      return <MultipleUsers
        includeCustomUserFields={Boolean(!field.excludeCustomUserFields)}
        value={input.value}
        triggerType={triggerType}
        onChange={input.onChange}
        adminsOnly={Boolean(field.adminsOnly)}
        disabled={input.disabled}
        allowedScopes={[SCOPES.AUTOMATION_WRITE]}
        creatable={Boolean(field.showAddNewUserOption)}
        field={field}
      />
    }
  },
  [formFieldTypes.mentionsSingleLine]: {
    component: ({ input, personalization, field }) => {
      const style = {
        border: 'none',
        '&singleLine': {
          input: {
            width: '100%',
            padding: '6px 12px'
          }
        }
      }

      return <StyledControlNestedInput>
        <MentionsTextarea
          disabled={input.disabled}
          singleLine
          value={input.value}
          placeholder={field.placeholder || 'Use @ to customize'}
          mentions={personalization}
          overrideStyle={style}
          onChange={(e) => input.onChange(e, true)}
          onBlur={(e, clickedSuggestion) => {
            if (clickedSuggestion) {
              return
            }
            input.onChange(input.value)
          }}
        />
      </StyledControlNestedInput>
    }
  },
  [formFieldTypes.mentionsMultiLine]: {
    component: ({ input, personalization, field, className }) => {
      const style = {
        '&multiLine': {
          input: {
            padding: '9px 12px',
            outline: 0,
            width: '100%',
            height: '100%',
            minHeight: 120
          }
        }
      }

      return <StyledControlNestedTextArea>
        <MentionsTextarea
          className={className}
          disabled={input.disabled}
          value={input.value}
          placeholder={field.placeholder || 'Use @ to customize'}
          mentions={personalization}
          overrideStyle={style}
          onChange={(e) => input.onChange(e, true)}
          onBlur={(e, clickedSuggestion) => {
            if (clickedSuggestion) {
              return
            }
            input.onChange(input.value)
          }}
        />
      </StyledControlNestedTextArea>
    }
  },
  [formFieldTypes.threshold]: {
    component: ({ field, input }) => {
      return <Threshold
        options={field.options}
        threshold={input.value}
        onChange={(selectedOption) => input.onChange(selectedOption)}
        disabled={input.disabled}
      />
    }
  },
  [formFieldTypes.filters]: {
    component: ({ field, input, fetchTriggerValues, getTriggerPreview }) => {
      return <div>
        <AdvancedFilters
          optionsKey={field.options}
          optionsValuesPerKey={field.values}
          filters={input.value}
          onChange={value => {
            input.onChange(value)
            fetchTriggerValues({ filters: value })
          }}
          getTriggerPreview={getTriggerPreview}
          disabled={input.disabled}
        />
      </div>
    }
  },
  [formFieldTypes.userMeetsCriteriaFilters]: {
    component: ({ field, input, fetchTriggerValues, getTriggerPreview }) => {
      return <div>
        <UserMeetsCriteriaFilters
          getTriggerPreview={getTriggerPreview}
          optionsKey={field.options}
          optionsValuesPerKey={field.values}
          criteria={input.value || []}
          onChange={value => {
            input.onChange(value)
            const filters = compact((value || []).map(criterion => criterion.filters))
            fetchTriggerValues({ filters })
          }}
          disabled={input.disabled}
        />
      </div>
    }
  },
  [formFieldTypes.appAndAccount]: {
    component: ({ input, field, triggerType, action }) => {
      const disabled = triggerType === WORKFLOW_TRIGGER_TYPES.USER_OFFBOARDING &&
      action?.type === WORKFLOW_ACTION_TYPES.REMOVE_USER_TASK

      return <AppAndAccountSelect
        selectedApp={input.value}
        options={field.options}
        onChange={value => {
          input.onChange(value)
        }}
        allowedScopes={[SCOPES.AUTOMATION_WRITE]}
        disabled={disabled}
      />
    }
  },
  [formFieldTypes.appState]: {
    component: ({ input, field }) => {
      return <div>
        <SelectState
          onChange={input.onChange}
          options={field.options || []}
          selectedValue={input.value}
          allowHideApp={false}
          allowedScopes={[SCOPES.AUTOMATION_WRITE]}
        />
      </div>
    }
  },
  [formFieldTypes.app]: {
    component: ({ input, field }) => {
      return <AppAndAccountSelect
        selectedApp={input.value}
        options={field.options}
        onChange={value => {
          input.onChange(value)
        }}
        allowedScopes={[SCOPES.AUTOMATION_WRITE]}
      />
    }
  },
  [formFieldTypes.setFieldValue]: {
    component: ({ input, field }) => {
      return <UpdateField input={input} field={field} />
    }
  },
  [formFieldTypes.bool]: {
    component: ({ input, field }) => {
      const options = [
        { value: true, label: 'Yes' },
        { value: false, label: 'No' }
      ]
      return <ToriiSelect
        options={options}
        value={input.value}
        clearable={Boolean(field.clearable)}
        autosize={false}
        onBlur={() => input.onBlur(input.value)}
        onChange={(selectedOption) => selectedOption || field.clearable ? input.onChange(selectedOption) : null}
        disabled={input.disabled}
      />
    }
  },
  [formFieldTypes.emailSetup]: {
    component: ({ input, field, triggerType, personalization, action }) => {
      const fields = field.fields.map(subField => ({ ...subField, value: input.value[subField.id] || subField.value }))
      const onChange = (subField) => {
        return input.onChange({ ...input.value, [subField.fieldId]: subField.selectedValue })
      }

      return <div {...CSS.emailSetupContainer}>
        <EmailPreviewButton overrideStyle={CSS.emailSetupPreview} actionType={action.type} />
        <EmailSetupBox fields={fields} onChange={onChange} triggerType={triggerType} personalization={personalization} action={action} allowedScopes={[SCOPES.AUTOMATION_WRITE]} />
      </div>
    }
  },
  [formFieldTypes.selectFormFields]: {
    component: ({ input, action }) => {
      return <SelectFormFields value={input.value} onChange={input.onChange} action={action} />
    }
  },
  [formFieldTypes.selectLicenses]: {
    component: ({ input, field }) => {
      return <LicensesSelect value={input.value} onChange={input.onChange} field={field} />
    }
  },
  [formFieldTypes.includeAndExcludeApps]: {
    component: ({ input, field }) => {
      return <SelectApps value={input.value} onChange={input.onChange} field={field} />
    }
  },
  [formFieldTypes.contract]: {
    component: ({ field, input, overrideStyle }) => {
      const renderOption = option => {
        return <div {...CSS.contractOptionContainer}>
          <img alt='contract' src={ContractImg} width={24} height={24} />
          {option.label}
        </div>
      }
      const isLoading = !input.disabled && field.loading && (!field.options || !field.options.length)
      return <ToriiSelect
        options={field.options}
        value={input.value}
        autosize={false}
        onBlur={() => input.onBlur(input.value)}
        onChange={(selectedOption) => selectedOption || field.clearable ? input.onChange(selectedOption) : null}
        clearable={Boolean(field.clearable)}
        disabled={input.disabled}
        isLoadingOptions={isLoading}
        arrowRenderer={isLoading ? null : undefined}
        optionRenderer={renderOption}
      />
    }
  },
  [formFieldTypes.mentionsKeyValueTable]: {
    component: ({ input, personalization, field, className }) => {
      const valuePlaceholder = field?.valuePlaceholder
      const { valueContainerName, valueProps } = getKeyValueStylesAndProps({ className, personalization, disabledPlaceholders: false, valuePlaceholder })

      const keyProps = {
        placeholder: get(field, 'keyPlaceholder')
      }

      const allowedScopes = [SCOPES.AUTOMATION_WRITE]

      return (
        <EnableFor scopes={allowedScopes}>
          <KeyValueTable
            keyValueArray={input.value}
            keyValueType={adjustLabel(field.label)}
            ValueComponent={MentionsTextarea}
            keyComponentProps={keyProps}
            valueComponentProps={valueProps}
            valueContainerName={valueContainerName}
            onBlur={() => input.onBlur(input.value)}
            onChange={(e, saveLocallyOnly = true) => {
              input.onChange(e, saveLocallyOnly)
            }}
            disallowedCharacters={field.disallowedCharacters}
          />
        </EnableFor>
      )
    }
  },
  [formFieldTypes.keyValueTable]: {
    component: ({ input, field, className }) => {
      const valuePlaceholder = field?.valuePlaceholder
      const { valueContainerName, valueProps } = getKeyValueStylesAndProps({ className, disabledPlaceholders: true, valuePlaceholder })

      const keyProps = {
        placeholder: get(field, 'keyPlaceholder')
      }

      const allowedScopes = [SCOPES.AUTOMATION_WRITE]

      return (
        <EnableFor scopes={allowedScopes}>
          <KeyValueTable
            keyValueArray={input.value}
            keyValueType={adjustLabel(field.label)}
            keyComponentProps={keyProps}
            valueComponentProps={valueProps}
            valueContainerName={valueContainerName}
            onBlur={() => input.onBlur(input.value)}
            onChange={(e, saveLocallyOnly = true) => {
              input.onChange(e, saveLocallyOnly)
            }}
            disallowedCharacters={field.disallowedCharacters}
          />
        </EnableFor>
      )
    }
  },
  [formFieldTypes.readonlyCopy]: {
    component: ({ input, field }) => {
      return <ReadonlyCopyField
        value={input.value}
        sensitive={field.sensitive}
      />
    }
  }
}

const labelActionMapping = {
  [formFieldLabelActionTypes.previewSendForm]: {
    component: ({ action }) => {
      return { component: <SendFormPreviewButton action={action} />, isJustifyToEnd: true }
    }
  },
  [formFieldLabelActionTypes.licenseInfoTooltip]: {
    component: ({ field }) => {
      const idApp = get(field, ['value', 'idApp'], null)
      return { component: <LicenseInfoTooltip idApp={idApp} /> }
    }
  }
}

class FormField extends React.Component {
  render () {
    const { className, field, input, index, meta: { touched, error }, addFieldIndex = true, action, triggerType, personalization, refreshDynamicFieldsOptions, fetchTriggerValues, getTriggerPreview, idActionExe, id, secret, overrideLabel, overrideStyleComponent, fieldParams } = this.props
    const labelPrefix = addFieldIndex ? `${index + 1}. ` : ''
    const hasLabel = overrideLabel || field.formQuestion || field.name || field.label
    const label = hasLabel ? overrideLabel || `${labelPrefix}${field.formQuestion || field.name || field.label}` : null
    const details = field.details
    const component = mapping[field.type] && mapping[field.type].component({ field, input, action, triggerType, personalization, refreshDynamicFieldsOptions, fetchTriggerValues, getTriggerPreview, idActionExe, id, secret, className, overrideStyle: overrideStyleComponent, fieldParams })
    const labelAction = field.labelAction ? labelActionMapping[field.labelAction].component({ action, field }) : null
    const tooltip = field.tooltipText ? <InfoTooltip tooltipText={field.tooltipText} /> : null

    return (
      <FormGroup label={label || null} labelAction={labelAction} tooltip={tooltip} details={details} error={touched && error} isRequired={field.isRequired || false} learnMoreUrl={field.learnMoreUrl}>
        {component}
      </FormGroup>
    )
  }
}

FormField.propTypes = {
  field: PropTypes.shape({
    type: PropTypes.oneOf(Object.keys(formFieldTypes)).isRequired,
    name: PropTypes.string,
    label: PropTypes.string,
    formQuestion: PropTypes.string,
    options: PropTypes.array,
    isRequired: PropTypes.bool,
    excludeCustomUserFields: PropTypes.bool,
    showAddNewUserOption: PropTypes.bool
  }).isRequired,
  input: PropTypes.object,
  index: PropTypes.number.isRequired,
  overrideStyleComponent: PropTypes.object,
  className: PropTypes.string
}

FormField.defaultProps = {
  className: ''
}

FormField.mapping = mapping

export default FormField
