import React from 'react'
import {
  ComposableMap,
  Geographies,
  Geography
} from 'react-simple-maps'
import ReactTooltip from 'react-tooltip'
import map from './world-topojson-110m'
import keyBy from 'lodash/keyBy'
import colors from '../../../../shared/style/colors'
import PropTypes from 'prop-types'
import { scaleQuantile } from 'd3-scale'
import { pluralizeWithFormat } from '@root/shared/utils'

const projectionConfig = {
  rotation: [-10, 0, 0],
  yOffset: 50
}

const mapStyle = {
  width: 450,
  height: 250
}

class MapChart extends React.Component {
  componentDidUpdate () {
    ReactTooltip.rebuild()
  }

  countryStyle = (countryData, loading, colorScale, disabled) => ({
    default: {
      fill: countryData && !loading ? colorScale(countryData.count) : colors.grey3,
      outline: 'none',
      transition: 'all 200ms'
    },
    hover: {
      fill: countryData && !loading ? colors.darkBlue3 : colors.grey3,
      outline: 'none',
      transition: 'all 200ms',
      cursor: (countryData && !loading && !disabled) && 'pointer'
    },
    pressed: {
      fill: countryData && !loading ? colors.darkBlue3 : colors.grey3,
      outline: 'none'
    }
  })

  render () {
    const { data, loading, onCountryClick, disabled } = this.props
    const colorScale = scaleQuantile().domain(data.map(d => d.count)).range(['rgba(0, 84, 255, 0.2)', 'rgba(0, 84, 255, 0.4)', 'rgba(0, 84, 255, 0.6)', 'rgba(0, 84, 255, 0.8)', 'rgba(0, 84, 255, 1)'])
    const dataByCountryCode = !loading ? keyBy(data, 'country') : {}
    return (
      <div style={loading ? { animation: 'react-placeholder-pulse 1.5s infinite' } : {}}>
        <ComposableMap style={mapStyle} projectionConfig={projectionConfig}>
          <Geographies geography={map} disableOptimization>
            {(geographies, projection) => geographies.map((country, index) => {
              const countryData = dataByCountryCode[country.properties['Alpha-2']]
              return (
                <Geography
                  data-tip={countryData && `${country.properties.name} - ${pluralizeWithFormat('user', countryData.count, true)} `}
                  key={index}
                  geography={country}
                  projection={projection}
                  style={this.countryStyle(countryData, loading, colorScale, disabled)}
                  onClick={() => !disabled && countryData && onCountryClick && onCountryClick({ countryName: country.properties.name, countryCode: country.properties['Alpha-2'] })}
                />
              )
            })}
          </Geographies>
        </ComposableMap>
        <ReactTooltip />
      </div>
    )
  }
}

MapChart.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  onCountryClick: PropTypes.func
}

export default MapChart
