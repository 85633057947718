import { useState, JSX } from 'react'
import ToriiPopup from '../ToriiPopupV2'
import { Props } from './types'
import Analytics from './analytics'
import styled from 'styled-components'

export const FullHeight = styled.div`
  height: 100%;
`

export default function Wizard<TSharedState, TSharedProps, TStepNames, TSubmitParams = undefined> ({
  isPopup,
  name,
  isOpen,
  width,
  stepsConfig,
  sharedProps,
  initialSharedState,
  onCancel,
  onClose,
  onSubmitSuccess,
  onNavigateToStep,
  startOnStep
}: Props<TSharedState, TSharedProps, TStepNames, TSubmitParams>): JSX.Element {
  const [sharedState, setSharedState] = useState<TSharedState>(initialSharedState)
  const stepIndex = startOnStep ? stepsConfig.findIndex(step => step.stepName === startOnStep) : 0
  const [currentStepIndex, setCurrentStepIndex] = useState<number>(stepIndex)
  const currentStepName = stepsConfig[currentStepIndex].stepName
  const analytics = Analytics<TStepNames>(name)

  const navigateToStep = (stepName: TStepNames) => {
    const stepIndex = stepsConfig.findIndex(step => step.stepName === stepName)
    if (stepIndex !== -1) {
      analytics.onMovingToStep(currentStepName, stepName)
      setCurrentStepIndex(stepIndex)
      onNavigateToStep?.()
    }
  }

  const onCloseAction = () => {
    analytics.onClose(currentStepName)
    onClose?.()
  }

  const onCancelAction = () => {
    analytics.onCancel(currentStepName)
    onCancel?.()
  }

  const { stepRenderer: StepRenderer, stepWidth, stepModalOverrideStyle } = stepsConfig[currentStepIndex]

  if (isPopup) {
    return (
      <ToriiPopup isOpen={isOpen} onCloseAction={onCloseAction} width={stepWidth || width} modalOverrideStyle={stepModalOverrideStyle ?? {}}>
        <StepRenderer
          sharedState={sharedState}
          sharedProps={sharedProps}
          setState={setSharedState}
          navigateToStep={navigateToStep}
          onSubmitSuccess={onSubmitSuccess}
          onCancel={onCancelAction}
        />
      </ToriiPopup>
    )
  } else {
    return (
      <FullHeight>
        <StepRenderer
          sharedState={sharedState}
          sharedProps={sharedProps}
          setState={setSharedState}
          navigateToStep={navigateToStep}
          onCancel={onCancelAction}
        />
      </FullHeight>
    )
  }
}
