import styled from 'styled-components'
import { fontWeight } from '../../shared/style/sizes'

export const Container = styled.div<{ overrideStyle?: any }>`
  flex: 1;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  font-weight: ${fontWeight.semiBold};
  color: ${props => props.theme.palette.text.secondary};
  {${props => props.overrideStyle}
`

export const Header = styled.div<{
  hoverClass: string
  forceHoverStyle?: boolean
  overrideHeaderStyle?: any
}>`
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: ${props => props.theme.palette.text.primary};
  text-transform: uppercase;
  transition: color .2s, transform .2s;
  transform: translateY(10px);
  margin-bottom: 3px;
  
  .${props => props.hoverClass}:hover & {
    transform: translateY(0);
  }
  
  ${props => props.forceHoverStyle && `
    transform: translateY(0);
  `}
  
  ${props => props.overrideHeaderStyle}
`

export const SubHeader = styled.div<{
  hoverClass: string
  forceHoverStyle?: boolean
  overrideSubHeaderStyle?: any
}>`
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-bottom: 3px;
  font-weight: ${fontWeight.normal};
  opacity: 0;
  transition: opacity .2s;
  
  .${props => props.hoverClass}:hover & {
    opacity: 1;
  }
  
  ${props => props.forceHoverStyle && `
    opacity: 1;
  `}
  
  ${props => props.overrideSubHeaderStyle}
`

export const RightContent = styled.div<{
  hoverClass: string
  overrideRightContentStyle?: any
}>`
  opacity: 0;
  transition: opacity .2s;
  line-height: 28px;
  
  .${props => props.hoverClass}:hover & {
    opacity: 1;
  }
  
  ${props => props.overrideRightContentStyle}
`
