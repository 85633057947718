import React from 'react'
import TabsPage from '../tabs'
import Overview from './overview'
import Users from './users'
import Expenses from './expenses'
import Info from './info'
import AppPageHeader from '../../components/appPageHeader'
import Contracts from './contracts'
import ChargebackTab from './chargeback'
import { SCOPES, TABLES } from '@root/constants'
import { IMPORT_APP_USERS_STATUS } from '@reducers/appUsers/types'
import LicensesRecommendations from '@root/components/licensesRecommendations'
import BackLink from '@components/backLink'
import { getScopeByIdOrgAndIdApp } from '@root/lenses/scopes'
import PropTypes from 'prop-types'
import ConnectToriiBotPopup from '@components/popups/connectToriiBotPopup'
import ConnectDirectIntegrationPopup from '@components/popups/connectDirectIntegrationPopup'
import ConnectSCIMIntegrationPopup from '@components/popups/connectSCIMIntegrationPopup'
import { FEATURES } from '@shared/features'
import DiscoveredAppsTable from '@components/discoveredAppsTable'
import AppIntegrationsAlert from '@pages/app/appIntegrationsAlert'
import { validTestConnectionInProgress } from '@components/testConnection/utils'

const appTabs = (idOrg, idApp, source) => {
  return [
    { name: 'overview', header: 'Overview', content: <Overview />, feature: FEATURES.APPLICATION.TABS.OVERVIEW },
    { name: 'info', header: 'Info', content: <Info />, feature: FEATURES.APPLICATION.TABS.INFO },
    { name: 'users', header: 'Users', content: <Users />, minimalRequiredScopes: [SCOPES.USERS_READ, getScopeByIdOrgAndIdApp(SCOPES.USERS_READ, idOrg, idApp)], feature: FEATURES.APPLICATION.TABS.USERS },
    { name: 'contracts', header: 'Contracts', content: <Contracts />, minimalRequiredScopes: [SCOPES.CONTRACTS_READ, getScopeByIdOrgAndIdApp(SCOPES.CONTRACTS_READ, idOrg, idApp)], feature: FEATURES.APPLICATION.TABS.CONTRACTS },
    { name: 'expenses', header: 'Expenses', content: <Expenses />, minimalRequiredScopes: [SCOPES.EXPENSE_READ], feature: FEATURES.APPLICATION.TABS.EXPENSES },
    { name: 'chargeback', header: 'Chargeback', content: <ChargebackTab />, minimalRequiredScopes: [SCOPES.LICENSE_AND_CHARGEBACK_READ, SCOPES.EXPENSE_READ, SCOPES.CONTRACTS_READ], mustHaveAllScopes: true, feature: FEATURES.APPLICATION.TABS.CHARGEBACK },
    { name: 'discoveredApps', header: 'Discovered Apps', content: <DiscoveredAppsTable source={source} idOrg={idOrg} tableKey={TABLES.appDiscoveredAppsTable.key} />, feature: FEATURES.APPLICATION.TABS.DISCOVERED_APPS },
    { name: 'recommendations', header: 'Recommendations', content: <LicensesRecommendations fromPage={'Applications'} />, feature: FEATURES.APPLICATION.TABS.RECOMMENDATIONS }
  ]
}

const notInUseAppTabsInfo = {
  tabsName: ['overview'],
  tabsHeader: [{ header: 'Overview' }],
  tabsContent: [<Overview />],
  tabsFeature: [FEATURES.APPLICATION.TABS.OVERVIEW]
}

class AppPage extends React.Component {
  async componentDidMount () {
    this.fetchData()

    if (this.props.afterRedirect) {
      await this.handleTestConnectionForOAuth()
    }
  }

  async handleTestConnectionForOAuth () {
    const { toggleConnectSource, getTestConnectionInfo, idOrg } = this.props
    const urlParams = new URLSearchParams(window.location.search)
    const idTestConnection = urlParams.get('idTestConnection')
    const idApp = urlParams.get('idApp')
    const testConnectionInfo = await getTestConnectionInfo({ idTestConnection, idOrg, idApp })
    const validTestInProgress = validTestConnectionInProgress(idOrg, parseInt(idApp), testConnectionInfo)
    validTestInProgress && toggleConnectSource({ isConnectSourceOpen: true, sourceIdApp: parseInt(idApp), validTestInProgress })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.idApp !== this.props.idApp || prevProps.idOrg !== this.props.idOrg) {
      this.fetchData()
    }

    const { importAppUsersStatus, getServicesSyncData } = this.props
    if (importAppUsersStatus !== prevProps.importAppUsersStatus &&
      importAppUsersStatus === IMPORT_APP_USERS_STATUS.SYNC_PRESUMABLY_ENDED) {
      const { deprecatedGetApps, idOrg, getAppV2, idApp, getConnectedAppUsersStatusesBreakdown } = this.props
      getAppV2({ idOrg, idApp })
      getConnectedAppUsersStatusesBreakdown({ idOrg, idApp })
      deprecatedGetApps({ idOrg })
      getServicesSyncData({ idOrg })
    }
  }

  fetchData () {
    const {
      idOrg,
      idApp,
      deprecatedGetApps,
      getSingleApp,
      getAppDetailsValues,
      getSimilarApps,
      getUsageTrends,
      getActiveUsersTrends,
      getStateDetails,
      getAppV2,
      getAppsV2,
      getServicesSyncData,
      getServicesConfig,
      getAppCatalogPolicies,
      getAppContracts,
      getContractsFields,
      getContractsGroups,
      getAppRecommendations,
      getConnectedAppUsersStatusesBreakdown,
      getAppUsersStats,
      getLast12MonthsTransactionsAggregations,
      getLastYearTotalExpenses
    } = this.props

    getAppDetailsValues({ idOrg, idApps: idApp })
    getSimilarApps({ idApp, idOrg })
    getSingleApp({ idOrg, idApp })
    getUsageTrends({ idOrg, idApp })
    getActiveUsersTrends({ idOrg, idApp })
    deprecatedGetApps({ idOrg })
    getStateDetails({ idOrg })
    getAppsV2({ idOrg })
    getAppV2({ idOrg, idApp })
    getServicesSyncData({ idOrg })
    getServicesConfig({ idOrg })
    getAppCatalogPolicies({ idOrg })
    getAppContracts({ idOrg, idApp })
    getContractsFields({ idOrg, idApp })
    getContractsGroups({ idOrg, idApp })
    getAppRecommendations({ idOrg, idApp })
    getConnectedAppUsersStatusesBreakdown({ idOrg, idApp })
    getAppUsersStats({ idOrg, idApp })
    getLast12MonthsTransactionsAggregations({ idOrg, idApp })
    getLastYearTotalExpenses(idOrg)
  }

  generateTabHeader = (visibleTabs, totalRecommendations) => {
    return visibleTabs.map(tabDetails => {
      const tabHeader = { header: tabDetails.header }
      if (tabDetails.name === 'recommendations') {
        tabHeader.counter = totalRecommendations
      }
      return tabHeader
    })
  }

  getConnectIntegrationPopups () {
    return (<>
      <ConnectToriiBotPopup />
      <ConnectDirectIntegrationPopup />
      <ConnectSCIMIntegrationPopup />
    </>)
  }

  render () {
    const {
      idApp,
      name,
      idOrg,
      isInUse,
      loading,
      isCostSavingFeatureSupported,
      isTabEnabled,
      doesAppSupportRecommendations,
      discoveredSourceAppName,
      totalRecommendations,
      isAppOwnerActive
    } = this.props

    const inUseAppTabsInfo = {}
    let visibleTabs = appTabs(idOrg, idApp, discoveredSourceAppName).filter(tabDetails => !tabDetails.minimalRequiredScopes || isTabEnabled(tabDetails.minimalRequiredScopes || isCostSavingFeatureSupported, tabDetails.mustHaveAllScopes))

    if (!doesAppSupportRecommendations) {
      visibleTabs = visibleTabs.filter(tab => tab.name !== 'recommendations')
    }

    if (!discoveredSourceAppName) {
      visibleTabs = visibleTabs.filter(tab => tab.name !== 'discoveredApps')
    }

    inUseAppTabsInfo.tabsName = visibleTabs.map(tabDetails => tabDetails.name)
    inUseAppTabsInfo.tabsHeader = this.generateTabHeader(visibleTabs, totalRecommendations)
    inUseAppTabsInfo.tabsContent = visibleTabs.map(tabDetails => tabDetails.content)
    inUseAppTabsInfo.tabsFeature = visibleTabs.map(tabDetails => tabDetails.feature)

    const tabsInfo = (loading || isInUse) ? inUseAppTabsInfo : notInUseAppTabsInfo
    const title = `${name && name + ' | '}Usage, Integrations and Similar products`

    return (<>
      <TabsPage
        path={`/team/${idOrg}/app/${idApp}`}
        pageHeader={<div><AppPageHeader idOrg={idOrg} idApp={idApp} /></div>}
        pageTitle={title}
        pageName='App'
        tabsName={tabsInfo.tabsName}
        tabsHeader={tabsInfo.tabsHeader}
        contentBelow={!loading ? <AppIntegrationsAlert idApp={idApp} appName={name} /> : null}
        tabsContent={tabsInfo.tabsContent}
        tabsFeature={tabsInfo.tabsFeature}
        backLink={isAppOwnerActive && <BackLink navigateTo={'/applications'} linkText={'Back to Applications'} overrideStyle={{ margin: '20px 0 15px 0' }} />}
      />
      {this.getConnectIntegrationPopups()}
    </>)
  }
}

AppPage.propTypes = {
  idOrg: PropTypes.number,
  idApp: PropTypes.number,
  importAppUsersStatus: PropTypes.oneOf(Object.values(IMPORT_APP_USERS_STATUS))
}

export default AppPage
