import analytics from '@root/helpers/analytics'

export enum ENTITY_TYPE {
  DRAWER = 'Drawer',
  POPUP = 'Popup'
}

type DashboardAnalyticsParams = {
  dashboardName?: string
  dashboardSystemKey?: string
}

type WidgetAnalyticsParams = DashboardAnalyticsParams & {
  widgetTitle?: string
  fieldName?: string
  entityType?: ENTITY_TYPE
}

const Analytics = {
  clickOnEditButton: ({ dashboardName, dashboardSystemKey }: DashboardAnalyticsParams) => analytics.track('Click on edit dashboard', { 'Dashboard name': dashboardName, 'System key': dashboardSystemKey }),
  clickOnCancelDashboardChanges: ({ dashboardName, dashboardSystemKey }: DashboardAnalyticsParams) => analytics.track('Click on cancel dashboard changes', { 'Dashboard name': dashboardName, 'System key': dashboardSystemKey }),
  clickOnSaveDashboardChanges: ({ dashboardName, dashboardSystemKey }: DashboardAnalyticsParams) => analytics.track('Save dashboard changes', { 'Dashboard name': dashboardName, 'System key': dashboardSystemKey }),
  clickOnDrillDown: ({ dashboardName, dashboardSystemKey, widgetTitle }: WidgetAnalyticsParams) => analytics.track('Click on widget drill down', { 'Dashboard name': dashboardName, 'System key': dashboardSystemKey, 'Widget title': widgetTitle }),
  resizeWidget: ({ dashboardName, dashboardSystemKey, widgetTitle }: WidgetAnalyticsParams) => analytics.track('Resize widget', { 'Dashboard name': dashboardName, 'System key': dashboardSystemKey, 'Widget title': widgetTitle }),
  dragWidget: ({ dashboardName, dashboardSystemKey, widgetTitle }: WidgetAnalyticsParams) => analytics.track('Drag widget', { 'Dashboard name': dashboardName, 'System key': dashboardSystemKey, 'Widget title': widgetTitle }),
  updateWidgetConfiguration: ({ dashboardName, dashboardSystemKey, widgetTitle, fieldName, entityType }: WidgetAnalyticsParams) => analytics.track('Update widget configuration', { 'Dashboard name': dashboardName, 'System key': dashboardSystemKey, 'Widget title': widgetTitle, 'Field name': fieldName, 'Entity type': entityType }),
  clickOnExportCSV: ({ dashboardName, dashboardSystemKey, widgetTitle }: WidgetAnalyticsParams) => analytics.track('Click on CSV download', { 'Dashboard name': dashboardName, 'System key': dashboardSystemKey, 'Widget title': widgetTitle }),
  viewDashboard: ({ dashboardName, dashboardSystemKey }: DashboardAnalyticsParams) => analytics.track('View dashboard', { 'Dashboard name': dashboardName, 'System key': dashboardSystemKey }),
  switchDashboard: ({ dashboardName, dashboardSystemKey }: DashboardAnalyticsParams) => analytics.track('Switch dashboard', { 'Dashboard name': dashboardName, 'System key': dashboardSystemKey })
}

export default Analytics
