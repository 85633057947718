import React, { JSX, useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '@components/table'
import { getColumns } from '@components/discoveredAppsTable/columns'
import {
  getDiscoveredAppsResourcesWithPendingChangesResources,
  getDiscoveredAppsWithPendingChanges,
  getIsLoadingDiscoveredApps,
  getIsLoadingMoreDiscoveredApps, getPendingChanges,
  getTotalDiscoveredApps
} from '@selectors/discoveredApps'
import {
  generateUpdateDiscoveredAppsPreview,
  getOrgDiscoveredApps,
  modifyDiscoveredApps,
  modifyDiscoveredAppsResources,
  resetDiscoveredApps,
  resetModifyDiscoveredApps,
  updateDiscoveredApps
} from '@actions/discoveredApps'
import { ALL_SOURCES_CUSTOM_SELECT_OPTION, DISCOVERED_APP_MAPPING_LOGIC, DISCOVERED_APPS_CUSTOM_SELECT_OPTIONS, EMPTY_OBJECT, ITEMS_PER_PAGE } from '@root/constants'
import { debounce, isEmpty, map, omitBy, isUndefined, get } from 'lodash'
import { getUserPreferences } from '@selectors/ui'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { getDiscoveredAppsTablesInfo, getUserTablesInfo } from '@selectors/tables'
import { getSupportedDiscoveredAppsServicesOptions } from '@selectors/org'
import { DiscoveredAppsTableProps } from '@components/discoveredAppsTable/discoveredApp.types'
import { H4, Body2, Spacer, AlertBox, AlertBoxType, toast, ToastType } from '@toriihq/design-system'
import { toggleAddApplication, toggleDiscoveredAppsPreviewPopup } from '@actions/'
import config from '@root/config'
import { HeaderText, UnsavedChangesWrapper } from './styles'
import { getPendingChangesPreviewData } from '@components/popups/discoveredAppsPreviewPopup/utils'
import { PreviewDataRaw } from '@components/popups/discoveredAppsPreviewPopup/types'
import CancelChangesPopup from '@components/popups/unsavedChangesPopup'
import useUnsavedChanges from '@shared/hooks/useUnsavedChanges'

const DiscoveredAppsTable = (props: DiscoveredAppsTableProps): JSX.Element => {
  const { idOrg, source, tableKey } = props

  const [columns, setColumns] = useState<any[]>([])
  const [initialLoading, setInitialLoading] = useState(true)
  const [query, setQuery] = useState('')

  const dispatch = useDispatch()

  const discoveredApps = useSelector(getDiscoveredAppsWithPendingChanges)
  const pendingChanges = useSelector(getPendingChanges)
  const totalDiscoveredApps = useSelector(getTotalDiscoveredApps)
  const isLoading = useSelector(getIsLoadingDiscoveredApps)
  const isLoadingMore = useSelector(getIsLoadingMoreDiscoveredApps)
  const appsById = useSelector(getDiscoveredAppsResourcesWithPendingChangesResources)
  const selectOptions = useSelector(getSupportedDiscoveredAppsServicesOptions)

  const { shouldShowUnsavedChangesPopup, handleCancel, confirmNavigation, cancelNavigation } = useUnsavedChanges(!isEmpty(pendingChanges))

  const { defaultSort = [], defaultCustomSelectOption, defaultSecondaryCustomSelectOption } = useSelector(getUserPreferences)[tableKey] || {}
  const { preDefinedColumnsMapping } = useSelector(getDiscoveredAppsTablesInfo)[tableKey]
  const tableInfo = useSelector(getUserTablesInfo)[tableKey] || EMPTY_OBJECT

  const customSelectSelectedValue = defaultCustomSelectOption ?? ALL_SOURCES_CUSTOM_SELECT_OPTION
  const secondaryCustomSelectSelectedValue = defaultSecondaryCustomSelectOption ?? DISCOVERED_APPS_CUSTOM_SELECT_OPTIONS.all

  const isInRefineTab = (source === undefined)
  const sourceForApi = !isInRefineTab ? source : customSelectSelectedValue.value === ALL_SOURCES_CUSTOM_SELECT_OPTION.value ? undefined : customSelectSelectedValue.value
  const mappingStatus = secondaryCustomSelectSelectedValue.value

  const sortStringFromArray = sortArray => sortArray.length > 0 ? sortArray.map(s => {
    const fieldId = s.id === 'mappedIdApp' ? 'mappedAppName' : s.id
    return (`${fieldId}:${s.desc ? 'desc' : 'asc'}`)
  }).join(',') : undefined

  const columnsOptions = map(preDefinedColumnsMapping, (value, key) => {
    return { value: key, label: value }
  })

  const exportToCsv = () => {
    const sortForApi = sortStringFromArray(defaultSort)
    const sortParam = sortForApi ? '&sort=' + encodeURIComponent(sortForApi) : ''
    const queryParam = `q=${encodeURIComponent(query)}`
    const fieldsParam = `fields=${encodeURIComponent(columns.filter(col => ((col.show === undefined || col.show) && !col.hideFromCSV)).map(col => col.accessor).map(field => field === 'mappedIdApp' ? 'mappedAppName' : field).join(','))}`
    const mappingStatusParam = `mappingStatus=${encodeURIComponent(mappingStatus)}`
    const sourceParam = sourceForApi ? `&source=${encodeURIComponent(sourceForApi)}` : ''
    const statusParam = `&status=active`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/externalApps/csv?${fieldsParam}&${queryParam}&${mappingStatusParam}${sourceParam}${statusParam}${sortParam}`
    const newWindow = window.open(url, '_blank') || { opener: null }
    newWindow.opener = null
  }

  const fetchOrgDiscoveredAppsData = debounce((reset = false) => {
    fetchOrgDiscoveredApps({ offset: reset ? 0 : discoveredApps.length, reset })
  }, 500, { leading: true, trailing: false })

  const fetchOrgDiscoveredApps = async ({ offset = 0, q = query, sort = defaultSort, reset = false }) => {
    dispatch(getOrgDiscoveredApps({ idOrg, source: sourceForApi, mappingStatus, reset, limit: ITEMS_PER_PAGE, offset, fields: undefined, q, sort: sortStringFromArray(sort) }))
    setInitialLoading(false)
  }

  useDeepCompareEffect(() => {
    idOrg && fetchOrgDiscoveredApps({ reset: true })
  }, [idOrg, defaultSort, sourceForApi, mappingStatus])

  const onMappingLogicChange = useCallback((id, mappingLogicValue) => {
    dispatch(modifyDiscoveredApps({
      id,
      changes: omitBy({
        mappingLogic: mappingLogicValue,
        mappedIdApp: mappingLogicValue === DISCOVERED_APP_MAPPING_LOGIC.IGNORED ? null : undefined
      }, isUndefined)
    }))
  }, [dispatch])

  const onMappedIdAppChange = useCallback((id, app, searchValue) => {
    if (get(app, 'value') === 'customApp') {
      dispatch(toggleAddApplication({
        isAddApplicationOpen: true,
        customApp: true,
        app: { name: searchValue },
        onSuccessCB: (idApp) => {
          dispatch(modifyDiscoveredApps({
            id,
            changes: { mappingLogic: DISCOVERED_APP_MAPPING_LOGIC.MANUALLY, mappedIdApp: idApp }
          }))
          return null
        },
        openFrom: 'Discovered apps'
      }))
    } else {
      dispatch(modifyDiscoveredApps({
        id,
        changes: { mappingLogic: DISCOVERED_APP_MAPPING_LOGIC.MANUALLY, mappedIdApp: app.id }
      }))
    }
    dispatch(modifyDiscoveredAppsResources({
      app: {
        [app.id]: app
      }
    }))
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(resetModifyDiscoveredApps({ isCanceledByUser: false }))
    }
  }, [dispatch])

  useEffect(() => {
    if (!tableInfo || tableInfo === EMPTY_OBJECT) {
      return
    }
    const { columnsConfiguration } = tableInfo
    idOrg && setColumns(getColumns({ displayAppColumn: !source, onMappingLogicChange, onMappedIdAppChange, appsById, columnsConfiguration }))
  }, [idOrg, source, onMappingLogicChange, onMappedIdAppChange, appsById, tableInfo])

  useEffect(() => {
    initialLoading && dispatch(resetDiscoveredApps())
  }, [initialLoading, dispatch])

  const onSearch = debounce((q) => {
    setQuery(q)
    fetchOrgDiscoveredApps({ reset: true, q })
  }, 500)

  const onCancelClick = () => {
    handleCancel()
  }

  const discardChanges = () => {
    dispatch(resetModifyDiscoveredApps({ isCanceledByUser: true }))
  }

  const getExternalAppsToUpdate = () => {
    return Object
      .keys(pendingChanges)
      .map(key => {
        const item = pendingChanges[key]
        return omitBy({
          id: Number(key),
          mappingLogic: item.mappingLogic,
          mappedIdApp: item.mappingLogic === DISCOVERED_APP_MAPPING_LOGIC.IGNORED ? undefined : item.mappedIdApp
        }, isUndefined)
      })
      .filter(app => !(app.mappingLogic === DISCOVERED_APP_MAPPING_LOGIC.MANUALLY && !app.mappedIdApp))
  }

  const updateExternalAppsChanges = async (externalApps) => {
    const updatingResult: { success: boolean } = await dispatch(updateDiscoveredApps({ idOrg, externalApps }))
    if (updatingResult?.success) {
      toast({
        message: 'Your changes were saved successfully. Please allow a few minutes for matching updates.',
        type: ToastType.SUCCESS
      })
    }

    return updatingResult
  }

  const openExternalAppsChangesPreview = (externalApps) => dispatch(toggleDiscoveredAppsPreviewPopup(true, () => updateExternalAppsChanges(externalApps), discardChanges))

  const onApplyClick = async () => {
    const externalApps = getExternalAppsToUpdate()
    if (externalApps.length === 0) {
      return dispatch(resetModifyDiscoveredApps({ isCanceledByUser: false }))
    }

    const previewDataRaw = await dispatch(generateUpdateDiscoveredAppsPreview({ idOrg, externalApps }))
    const previewData: PreviewDataRaw[] = getPendingChangesPreviewData(previewDataRaw)

    if (isEmpty(previewData)) {
      return updateExternalAppsChanges(externalApps)
    }

    openExternalAppsChangesPreview(externalApps)
  }

  const onCloseCancelChangesPopup = () => {
    cancelNavigation()
  }

  const onDiscardChanges = async () => {
    onCloseCancelChangesPopup()
    await discardChanges()
    confirmNavigation()
  }

  return (
    <>
      {isInRefineTab && <div>
        <Spacer bottom={'space-250'}>
          <div>
            <Spacer bottom={'space-100'}>
              <H4>Refine Discovered Apps</H4>
            </Spacer>
            <HeaderText>
              <Body2>
                View all third-party apps discovered by Torii via app sources and refine application matching to fit your organization’s specific needs.
                <br />
                Apps discovered by the browser extension or from expense data are not included in this table.</Body2>
            </HeaderText>
          </div>
        </Spacer>
      </div>}
      <CancelChangesPopup
        isOpen={shouldShowUnsavedChangesPopup}
        onKeepEditing={onCloseCancelChangesPopup}
        onCancelChanges={onDiscardChanges}
        content='You have unsaved changes. If you discard now, all changes will be lost.'
      />
      <Table
        tableKey={tableKey}
        data={discoveredApps}
        columns={columns}
        fetchData={fetchOrgDiscoveredAppsData}
        totalCount={totalDiscoveredApps}
        loading={isLoading}
        loadingMore={isLoadingMore}
        draggable={false}
        manual
        searchable
        exportable
        exportFunction={exportToCsv}
        forceShowSearch
        forceShowNumberOfResults
        fixedColumnOrder
        onSearch={onSearch}
        configurableColumns
        configurableColumnsOptions={columnsOptions}
        tableHeaderStyle={{ paddingTop: 12, paddingBottom: 12, marginBottom: 12 }}
        alternativeHeader={isEmpty(pendingChanges) ? null : (
          <UnsavedChangesWrapper>
            <AlertBox
              type={AlertBoxType.INFORMATIVE}
              title='You have unsaved changes.'
              primaryButton={{ label: 'Apply Changes', onClick: onApplyClick }}
              secondaryButton={{ label: 'Cancel', onClick: onCancelClick }}
            />
          </UnsavedChangesWrapper>
        )}
        customSelect={isInRefineTab}
        customSelectOptions={selectOptions}
        customSelectSelectedValue={customSelectSelectedValue}
        customSelectOnChange={() => null}
        secondaryCustomSelect
        secondaryCustomSelectOptions={Object.values(DISCOVERED_APPS_CUSTOM_SELECT_OPTIONS)}
        secondaryCustomSelectSelectedValue={secondaryCustomSelectSelectedValue}
        secondaryCustomSelectOnChange={() => null}
      />
    </>
  )
}

export default DiscoveredAppsTable
