import { useState, useEffect, useRef } from 'react'

const useIsScrolling = ({ scrollContainerId, delay = 200 }: { scrollContainerId?: string, delay?: number } = {}) => {
  const [isScrolling, setIsScrolling] = useState(false)
  const scrollTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    const element: Element | null = scrollContainerId
      ? document.getElementById(scrollContainerId)
      : document.getElementsByClassName('mainWrapper')[0]

    if (!element) return

    const handleScroll = () => {
      setIsScrolling(true)

      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current)
      }

      scrollTimeout.current = setTimeout(() => {
        setIsScrolling(false)
      }, delay)
    }

    element.addEventListener('scroll', handleScroll)

    return () => {
      element.removeEventListener('scroll', handleScroll)
      if (scrollTimeout.current) {
        clearTimeout(scrollTimeout.current)
      }
    }
  }, [scrollContainerId, delay])

  return isScrolling
}

export default useIsScrolling
