import React from 'react'
import { Stack, Button, ButtonState } from '@toriihq/design-system'
import styled from 'styled-components'
import { FooterProps } from './types'
import BackLink from '@root/components/backLink'

const Container = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.palette.border.primary};
  padding: 24px 0px;
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 10;
`

const Footer = ({ isNextButtonDisabled, onNextClick, shouldShowBackButton, onBackClick, onCloseClick, shouldShowNextButton, isNextButtonLoading }: FooterProps): JSX.Element => {
  return <Container>
    <Stack gap={'space-050'} direction={'row'} justifyContent={'space-between'} >
      <div>
        {
          !shouldShowBackButton ? <></> : <BackLink linkText={'Back'} onClick={onBackClick} />
        }
      </div>
      <Stack gap={'space-100'} direction={'row'} >
        <Button onClick={onCloseClick} label={'Close'} size='medium' type='secondary' />
        {shouldShowNextButton && <Button buttonState={isNextButtonLoading ? ButtonState.loading : ButtonState.neutral} size='medium' label={'Next'} onClick={() => onNextClick()} disabled={isNextButtonDisabled} />}
      </Stack>
    </Stack>
  </Container>
}

export default Footer
