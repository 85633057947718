import { AlertBox, AlertBoxType, Body2 } from '@toriihq/design-system'
import { AlertBoxWrapper } from './styles'
import pluralize from 'pluralize'

type Props = {
  appCount: number
}

export const CustomAppAlert = ({ appCount }: Props) => {
  return (
    <AlertBoxWrapper>
      <AlertBox
        type={AlertBoxType.NOTICE}
        title='Native apps with identical URL found'
        description={
          <Body2>{`Torii has ${appCount} native ${pluralize('app', appCount)} with ${appCount === 1 ? 'an' : ''} identical ${pluralize('URLs', appCount)} to the custom apps below.`}</Body2>
        }
      />
    </AlertBoxWrapper>
  )
}
