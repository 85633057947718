import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Stack,
  Body2,
  H4,
  Button,
  ButtonType,
  Tooltip
} from '@toriihq/design-system'
import LicenseCostAndTotalAmountTable from '@components/configureAppChargeback/licenseCostAndTotalAmountTable'
import { getCurrencySymbols } from '@selectors/ui'
import { RendererProps } from '@root/components/popups/wizardPopup/types'
import { SharedState, SharedProps, WIZARD_STEPS } from '../wizardSteps/types'
import Header from '../wizardSteps/header'
import Footer from '../wizardSteps/footer'
import { isEqual, flatten, differenceWith, some, isNumber } from 'lodash'
import { bulkUpdateLicensesTypes, getLicensesTypes } from '@root/store/actions'
import { FullHeight, LicensesTableWrapper } from './styles'
import ContractDrawer, { ContractDrawerAttachment } from './contractDrawer'
import { getContractsByIdApp, getContractAttachments as getContractAttachmentsSelector, isLoadingAppContracts } from '@root/store/selectors/contracts'
import { getContractAttachments } from '@root/store/actions/contracts'
import Analytics from '../analytics'
import differenceBy from 'lodash/differenceBy'

const LicenseReview = ({
  sharedState, sharedProps, setState, navigateToStep, onCancel
}: RendererProps<SharedState, SharedProps, WIZARD_STEPS>): JSX.Element => {
  const dispatch = useDispatch()
  const { idOrg, idApp, idAppAccount, appName, appAccount, app, licenses } = sharedProps
  const { initialAppAccountLicenses, appAccountLicenses } = sharedState
  const currencySymbols = useSelector(getCurrencySymbols)
  const contracts = useSelector(getContractsByIdApp)[idApp]
  const isLoadingContracts = useSelector(isLoadingAppContracts)
  const licenseCurrencySymbol = currencySymbols[appAccountLicenses?.[0]?.currency]
  const [isNextButtonLoading, setIsNextButtonLoading] = useState(false)
  const [isContractDrawerOpen, setIsContractDrawerOpen] = useState(false)
  const { loading: isLoadingContractAttachments, data: contractAttachmentsData } = useSelector(getContractAttachmentsSelector) ?? {}
  const isNextButtonDisabled = some(appAccountLicenses, license => isNumber(license.totalAmount) && license.isTotalAmountEnteredByUser && (license.totalAmount < license.assignedAmount))

  const onLicensesChange = (licenses) => {
    setState({ ...sharedState, appAccountLicenses: licenses })
  }

  useEffect(() => {
    dispatch(getLicensesTypes({ idOrg, idApp }))
  }, [dispatch, idOrg, idApp])

  useEffect(() => {
    setState(prevState => ({ ...prevState, appAccountLicenses: licenses }))
  }, [licenses, setState])

  const onContinueLicenseReview = async ({ idOrg, idApp, updatedLicenses, appAccountLicenses }): Promise<void> => {
    const updatedLicensesProps = updatedLicenses.map(license => ({
      idLicense: license.id,
      pricePerUser: license.pricePerUser,
      totalAmountEnteredByUser: license.totalAmountEnteredByUser
    }))

    const shouldUpdateLicensesTypes = differenceWith(updatedLicenses, appAccountLicenses, isEqual).length > 0
    if (shouldUpdateLicensesTypes) {
      await dispatch(bulkUpdateLicensesTypes({
        idOrg,
        idApp,
        licenses: updatedLicensesProps,
        LicensesFullDetails: updatedLicenses,
        waitForES: false
      }))
      const isLicenseCostChanged = differenceBy(updatedLicenses, licenses, 'pricePerUser').length > 0
      const isTotalAmountChanged = differenceBy(updatedLicenses, licenses, 'totalAmountEnteredByUser').length > 0

      const analyticsChanges: string[] = []
      isLicenseCostChanged && analyticsChanges.push('License cost')
      isTotalAmountChanged && analyticsChanges.push('Total licenses')
      Analytics.updateLicenses({ appName, updatedFields: analyticsChanges })
    }

    await dispatch(getLicensesTypes({ idOrg, idApp }))
  }

  const onNextClick = async () => {
    Analytics.clickOnNextButton({ appName, stepName: WIZARD_STEPS.LICENSES_REVIEW })
    setIsNextButtonLoading(true)
    await onContinueLicenseReview({ idOrg, idApp, updatedLicenses: appAccountLicenses, appAccountLicenses: initialAppAccountLicenses })
    setIsNextButtonLoading(false)
    navigateToStep(WIZARD_STEPS.RECOMMENDATIONS)
    window.history.pushState({}, '', `/team/${idOrg}/app/${idApp}/account/${idAppAccount}/renewalForecast/${WIZARD_STEPS.RECOMMENDATIONS}`)
  }

  const onBackClick = () => {
    Analytics.clickOnBackButton({ appName, stepName: WIZARD_STEPS.LICENSES_REVIEW })
    navigateToStep(WIZARD_STEPS.USER_REVIEW)
    window.history.pushState({}, '', `/team/${idOrg}/app/${idApp}/account/${idAppAccount}/renewalForecast/${WIZARD_STEPS.USER_REVIEW}`)
  }

  const onClose = () => {
    Analytics.clickOnCloseButton({ appName, stepName: WIZARD_STEPS.LICENSES_REVIEW })
    onCancel()
  }

  const activeContracts = useMemo(() => contracts?.filter(contract => contract.status === 'active'), [contracts])

  useEffect(() => {
    if (activeContracts?.length > 0) {
      const documentIds = flatten<number>(activeContracts.map(contract => contract.documents?.map(d => d.idUpload)))
      if (documentIds.length > 0) {
        dispatch(getContractAttachments({ idOrg, idUploads: documentIds }))
      }
    }
  }, [activeContracts, dispatch, idOrg])

  const attachments = useMemo<ContractDrawerAttachment>(() => {
    const shouldFetchAttachments = activeContracts && !isLoadingContracts && contractAttachmentsData?.files && !isLoadingContractAttachments
    if (shouldFetchAttachments) {
      return activeContracts.reduce((acc, contract) => {
        const contractAttachmentIds = contract.documents?.map(d => d.idUpload)
        if (!contractAttachmentIds) return acc

        const contractAttachments = contractAttachmentsData.files.filter(attachment => contractAttachmentIds.includes(attachment.idUpload))
        const contractWithAttachments = {
          ...acc,
          [contract.id]: { contractName: contract.name, attachments: contractAttachments }
        }
        return contractWithAttachments
      }, {})
    } else {
      return {}
    }
  }, [activeContracts, contractAttachmentsData, isLoadingContractAttachments, isLoadingContracts])

  const ViewRelatedContractsButton = () => {
    const areThereAnyAttachments = activeContracts?.some(contract => contract.documents?.length)
    const button = <Button
      disabled={!areThereAnyAttachments}
      type={ButtonType.secondary}
      icon='File'
      label={'View related contracts'}
      onClick={() => {
        Analytics.clickOnViewRelatedContracts({ appName })
        setIsContractDrawerOpen(true)
      }}
    />
    if (areThereAnyAttachments) {
      return button
    } else {
      const label = !activeContracts?.length ? 'No active contracts available.' : 'No documents available in active contracts.'
      return (<Tooltip label={label}>
        {button}
      </Tooltip>)
    }
  }

  const onCloseDrawer = () => {
    setIsContractDrawerOpen(false)
  }

  return <FullHeight>
    <Stack direction={'column'} gap='space-400' alignItems='space-between'>
      <Header app={app} accountName={appAccount.appAccountName} currentStep={WIZARD_STEPS.LICENSES_REVIEW} onBackClick={onCancel} />
      <Stack direction={'column'} gap={'space-400'} alignItems={'start'}>
        <div style={{ width: '100%' }}>
          <Stack direction={'row'} justifyContent='space-between' gap='space-0' alignItems='flex-start'>
            <Stack direction={'column'} gap={'space-100'} justifyContent={'start'}>
              <H4>Review {appName} licenses</H4>
              <Body2>Review all licenses, the purchased amount and their annual cost and ensure they match what’s in your contract.</Body2>
            </Stack>
            {ViewRelatedContractsButton()}
          </Stack>
        </div>
        <LicensesTableWrapper>
          <LicenseCostAndTotalAmountTable idOrg={idOrg} idApp={idApp} licenses={appAccountLicenses} onChange={onLicensesChange} currencySymbol={licenseCurrencySymbol} appName={appName} />
        </LicensesTableWrapper>
      </Stack>
    </Stack>
    <ContractDrawer isOpen={isContractDrawerOpen} onClose={onCloseDrawer} attachments={attachments} appName={appName} />
    <Footer
      shouldShowNextButton
      isNextButtonDisabled={isNextButtonDisabled}
      onNextClick={onNextClick}
      isNextButtonLoading={isNextButtonLoading}
      shouldShowBackButton
      onBackClick={onBackClick}
      onCloseClick={onClose}
    />
  </FullHeight>
}

export default LicenseReview
