import React, { ReactElement } from 'react'
import { FIXED_WIDGET_TYPES } from '../../../../store/reducers/dashboards/types'
import RenewalYearlySummaryWidget from './renewalYearlySummaryWidget'
import { FixedWidgetProps } from '../types'
import UsersMapWidget from './usersMapWidget'

const FixedWidgetRenderer = (properties: FixedWidgetProps): ReactElement | null => {
  const { widget } = properties

  switch (widget.fixedWidgetType) {
    case FIXED_WIDGET_TYPES.RENEWAL_YEARLY_SUMMARY:
      return <RenewalYearlySummaryWidget {...properties} />
    case FIXED_WIDGET_TYPES.USERS_MAP:
      return <UsersMapWidget {...properties} />
    default: {
      console.error('Unknown fixed widget type', widget.fixedWidgetType)
      return null
    }
  }
}

export default FixedWidgetRenderer
