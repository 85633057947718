import React from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import TabsPage from '@pages/tabs'
import { FEATURES } from '@shared/features'
import { getIdOrg } from '@selectors/org'
import ThirdPartyReport from '@components/thirdPartyReport'
import AccessReviews from './securityTabs/accessReviews'

const SecurityPage = ({ match }) => {
  const idOrg = useSelector(getIdOrg)

  const tabsName = [
    'riskAnalysis',
    'accessReviews'
  ]

  const tabsHeader = [
    { header: 'Risk Analysis' },
    { header: 'Access Reviews' }
  ]

  const tabsContent = [
    <ThirdPartyReport />,
    <AccessReviews />
  ]

  const tabsFeature = [
    FEATURES.SECURITY.TABS.RISK_ANALYSIS,
    FEATURES.SECURITY.TABS.ACCESS_REVIEWS
  ]

  return (
    <TabsPage
      pageHeader='Security'
      pageTitle='Security'
      pageName='Security'
      path={`/team/${idOrg}/security`}
      tabsName={tabsName}
      tabsHeader={tabsHeader}
      tabsContent={tabsContent}
      tabsFeature={tabsFeature}
    />
  )
}

export default withRouter(SecurityPage)
