import TaskStatus from '../taskStatus'
import { assigneesColumn, taskNameColumn, taskTypeColumn, serialNumberColumn } from '../shared/columns'

export const openTasksColumns = [
  serialNumberColumn,
  taskNameColumn,
  assigneesColumn,
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value: status, row: { statusLabel } }) => <TaskStatus status={status} statusLabel={statusLabel} />,
    width: 200
  },
  {
    Header: 'Due In',
    accessor: 'dueDate',
    Cell: ({ value: dueDate }) => {
      // TODO-OM mock implementation - Full implementation TBD
      // https://airtable.com/appHUTvmPMcNANJ9s/tbly1K7egpJuMHmpB/viw4qlhKJnRF1vLpp/recwhjHCDzob5qr1X?blocks=hide
      if (!dueDate) {
        return ''
      }

      const dueDateObj = new Date(dueDate)
      const currentDate = new Date()
      const diff = dueDateObj.getTime() - currentDate.getTime()
      const days = Math.floor(diff / (1000 * 60 * 60 * 24))

      if (days === 0) {
        return 'Today'
      }

      return days > 0 ? `${days} days` : `${-days} days ago`
    }
  },
  taskTypeColumn,
  {
    accessor: 'statusLabel',
    show: false
  }
]
