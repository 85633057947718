import styled from 'styled-components'

export const ContentAreaStyle = {
  overflowY: 'visible' as const
}

export const CreatableSelectOption = styled.span`
  margin-right: 8px;
  height: 16px;
`
