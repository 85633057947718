import { BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts'
import BasicTooltip from '@components/barsChartBox/tooltip'
import { LayoutType } from 'recharts/types/util/types'
import { AXIS, CustomAxisProps, DashboardBarChartProps } from './types'
import { useTheme } from 'styled-components'
import { AxisLabel, ChartContainer } from './styles'
import maxBy from 'lodash/maxBy'
import get from 'lodash/get'
import React from 'react'

const DashboardBarChart = (props: DashboardBarChartProps) => {
  const {
    fill,
    data,
    formatter,
    barSize,
    isAnimationActive = true,
    isVertical = true,
    customTooltip,
    onBarClick
  } = props

  const theme = useTheme()
  const AXIS_MAX_WIDTH = 150

  if (!data?.length) {
    return null
  }

  const getValueAxisTicksAndDomain = () => {
    const NUMBER_OF_TICKS = 5

    const values = data.map(d => d.value).concat(0)

    const minValue = Math.min(...values)
    const maxValue = Math.max(...values)
    const range = maxValue - minValue

    if (range === 0) {
      return { ticks: undefined, domain: undefined }
    }

    const step = Math.ceil(range / (NUMBER_OF_TICKS - 1))
    const ticks = Array.from({ length: Math.min(NUMBER_OF_TICKS, Math.ceil(range + 1)) }, (_, i) => minValue + i * step)
    const domain = [Math.floor(minValue), ticks[ticks.length - 1]]

    return { ticks, domain }
  }

  const CustomAxis = ({ x, y, payload, formatter, axis, width = 80 }: CustomAxisProps) => {
    const formattedValue = formatter ? formatter(payload?.value) : payload?.value
    return (
      <g transform={`translate(${x},${y})`}>
        <foreignObject
          x={axis === AXIS.X ? -width / 2 : -width}
          y={axis === AXIS.X ? 0 : -15}
          width={width}
          height='100'>
          <AxisLabel isCenter={axis === AXIS.X} isTruncated={(getLabelWidth(formattedValue) / 2) > width}>
            <span>{formattedValue}</span>
          </AxisLabel>
        </foreignObject>
      </g>
    )
  }

  const getLabelWidth = (label: string) => {
    return label.length * 8 + 16
  }

  const getLabelTextContent = (value: string | React.ReactNode): string => {
    if (typeof value === 'string') {
      return value
    }
    if (React.isValidElement(value)) {
      return String(get(value, 'props.value', value))
    }
    return String(value)
  }

  const calculateYAxisWidth = () => {
    const yAxisLabels = data.map(d => isVertical
      ? (formatter ? formatter(d.value) : String(d.value))
      : d.name)

    const longestLabel = maxBy(yAxisLabels, label => getLabelTextContent(label).length) || ''
    const longestLabelWidth = getLabelWidth(getLabelTextContent(longestLabel))

    return Math.min(AXIS_MAX_WIDTH, longestLabelWidth)
  }

  const getCalculatedProps = (): {
    xAxisType: 'category' | 'number',
    yAxisType: 'category' | 'number',
    xAxisDataKey?: string,
    yAxisDataKey?: string,
    layout: LayoutType,
    showVerticalGrid: boolean,
    showHorizontalGrid: boolean,
    barRadius: [number, number, number, number],
  } => ({
    xAxisType: isVertical ? 'category' : 'number',
    yAxisType: isVertical ? 'number' : 'category',
    xAxisDataKey: isVertical ? 'name' : undefined,
    yAxisDataKey: isVertical ? undefined : 'name',
    layout: isVertical ? 'horizontal' : 'vertical',
    showVerticalGrid: !isVertical,
    showHorizontalGrid: isVertical,
    barRadius: isVertical ? [4, 4, 0, 0] : [0, 4, 4, 0]
  })

  const { xAxisType, yAxisType, xAxisDataKey, yAxisDataKey, layout, showVerticalGrid, showHorizontalGrid, barRadius } = getCalculatedProps()
  const yAxisWidth = calculateYAxisWidth()
  const { ticks, domain } = getValueAxisTicksAndDomain()

  const handleBarClick = (data: any, index: number, event: any) => {
    if (onBarClick) {
      const shouldStopPropagation = event && event.nativeEvent && event.nativeEvent.stopPropagation
      if (shouldStopPropagation) {
        event.nativeEvent.stopPropagation()
      }
      onBarClick(data)
    }
  }

  return (
    <ChartContainer>
      <ResponsiveContainer>
        <BarChart data={data} layout={layout} margin={{ top: 32, right: 32, left: 32, bottom: 32 }}>
          <CartesianGrid
            stroke={theme.palette.border.primary}
            vertical={showVerticalGrid}
            horizontal={showHorizontalGrid}
          />
          <Bar
            isAnimationActive={isAnimationActive}
            dataKey='value'
            fill={fill}
            barSize={barSize}
            onClick={(data, index, event) => handleBarClick(data, index, event)}
            radius={barRadius}
            cursor={onBarClick ? 'pointer' : 'default'}
          />
          <Tooltip
            content={customTooltip || <BasicTooltip hideOnZeroValue formatter={formatter} payload={undefined} active={undefined} />}
            cursor={false}
          />
          <XAxis
            type={xAxisType}
            axisLine={false}
            tickLine={false}
            dataKey={xAxisDataKey}
            domain={!isVertical ? domain : undefined}
            ticks={!isVertical ? ticks : undefined}
            tick={<CustomAxis axis={AXIS.X} formatter={!isVertical ? formatter : undefined} />}
          />
          <YAxis
            type={yAxisType}
            axisLine={false}
            tickLine={false}
            dataKey={yAxisDataKey}
            width={yAxisWidth}
            domain={isVertical ? domain : undefined}
            ticks={isVertical ? ticks : undefined}
            tick={<CustomAxis axis={AXIS.Y} formatter={isVertical ? formatter : undefined} width={yAxisWidth} />}
          />
        </BarChart>
      </ResponsiveContainer>
    </ChartContainer>
  )
}

export default DashboardBarChart
