import React, { ReactElement, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '@components/table'
import { TABLES, fieldTypes } from '@root/constants'
import { canceledTasksColumns } from './columns'
import { IdOrg } from '@store/types'
import { getIdOrg } from '@store/selectors/org'
import { getUserPreferences } from '@store/selectors/ui'
import { sortStringFromArray } from '@shared/utils'
import { CanceledTasksState } from '@store/reducers/tasks/types'
import { getCanceledTasks as getCanceledTasksAction, getCanceledTasksFilterOptions as getCanceledTasksFilterOptionsAction, getCanceledTasksFieldValues as getCanceledTasksFieldValuesAction } from '@store/actions/tasks'
import { getCanceledTasks as getCanceledTasksSelector, getCanceledTasksFilterOptions as getCanceledTasksFilterOptionsSelector, getCanceledTasksFieldValues as getCanceledTasksFieldValuesSelector } from '@selectors/tasks'
import { GetTasksProps } from '../../types'
import useEffectOnce from '@shared/hooks/useEffectOnce'
import { useTaskSearch } from '../../useTaskSearch'
import { Filter } from '@store/actions/types'

const ITEMS_PER_PAGE = 5 // TODO-OM implement

const CanceledTasksTab = (): ReactElement => {
  const idOrg: IdOrg = useSelector(getIdOrg)
  const dispatch = useDispatch()

  const userPreferences = useSelector(getUserPreferences)
  const { defaultSort = [], filters: tableFilters = [] } = userPreferences[TABLES.canceledTasksTable.key] || {}
  const defaultSortString = sortStringFromArray(defaultSort)
  const filterOptions = useSelector(getCanceledTasksFilterOptionsSelector)
  const filterValues = useSelector(getCanceledTasksFieldValuesSelector)

  const { tasks, loading, loadingMore, total }: CanceledTasksState = useSelector(getCanceledTasksSelector)

  const getTasks = useCallback(async ({
    limit = ITEMS_PER_PAGE,
    offset = 0,
    q,
    reset = false,
    filters = tableFilters
  }: GetTasksProps) => {
    await dispatch(getCanceledTasksAction({ idOrg, limit, offset, sort: defaultSortString, q, reset, filters }))
  }, [defaultSortString, dispatch, idOrg, tableFilters])

  const { onSearch, searchText } = useTaskSearch({ getTasks })

  useEffectOnce(() => {
    dispatch(getCanceledTasksFilterOptionsAction({ idOrg }))
    getTasks({ reset: true, q: searchText })
  })

  const fetchCanceledTasksData = (reset = false): void => {
    getTasks({ offset: reset ? 0 : tasks.length, reset, q: searchText })
  }

  const handleFilterChange = (updatedFilters: Filter[]) => {
    getTasks({ filters: updatedFilters, q: searchText, reset: true })
  }

  const fetchFieldValues = (field: string) => {
    const { type } = filterOptions.find(f => f.value === field) || {}
    const isFieldSupportValues = [fieldTypes.text, fieldTypes.user, fieldTypes.dropdown, fieldTypes.dropdownMulti, fieldTypes.userMulti].includes(type)

    if (isFieldSupportValues) {
      dispatch(getCanceledTasksFieldValuesAction({ idOrg, fields: [field] }))
    }
  }

  return <Table
    tableKey={TABLES.canceledTasksTable.key}
    data={tasks}
    columns={canceledTasksColumns}
    loading={loading}
    loadingMore={loadingMore}
    totalCount={total}
    fetchData={fetchCanceledTasksData}
    searchable
    forceShowSearch
    onSearch={onSearch}
    emptyStateMessage='No tasks to display' // TODO-OM define microcopy
    manual
    filterable
    filtersOptions={filterOptions}
    filterOptionsValuesPerKey={filterValues}
    fetchFieldValues={fetchFieldValues}
    onFilterChange={handleFilterChange}
  />
}

export default CanceledTasksTab
