import React, { ReactElement, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '@components/table'
import { fieldTypes, TABLES } from '@root/constants'
import { openTasksColumns } from './columns'
import { getIdOrg } from '@selectors/org'
import { getUserPreferences } from '@store/selectors/ui'
import { sortStringFromArray } from '@shared/utils'
import {
  getOpenTasks as getOpenTasksAction,
  getOpenTasksFilterOptions as getOpenTasksFilterOptionsAction,
  getOpenTasksFieldValues as getOpenTasksFieldValuesAction
} from '@actions/tasks'
import { IdOrg } from '@store/types'
import {
  getOpenTasks as getOpenTasksSelector,
  getOpenTasksFilterOptions as getOpenTasksFilterOptionsSelector,
  getOpenTasksFieldValues as getOpenTasksFieldValuesSelector
} from '@selectors/tasks'
import { OpenTasksState } from '@store/reducers/tasks/types'
import useEffectOnce from '@shared/hooks/useEffectOnce'
import { GetTasksProps, TASK_STATUS } from '../../types'
import NeedsAttentionAlertBox from './needsAttentionAlertBox'
import { useTaskSearch } from '../../useTaskSearch'
import { TableContainer } from './styles'
import { Filter } from '@store/actions/types'

const ITEMS_PER_PAGE = 5 // TODO-OM implement

const OpenTasksTab = (): ReactElement => {
  const idOrg: IdOrg = useSelector(getIdOrg)
  const dispatch = useDispatch()

  const userPreferences = useSelector(getUserPreferences)
  const { defaultSort = [], filters: tableFilters = [] } = userPreferences[TABLES.openTasksTable.key] || {}
  const defaultSortString = sortStringFromArray(defaultSort)
  const filterOptions = useSelector(getOpenTasksFilterOptionsSelector)
  const filterValues = useSelector(getOpenTasksFieldValuesSelector)

  const { tasks, loading, loadingMore, total }: OpenTasksState = useSelector(getOpenTasksSelector)

  const getTasks = useCallback(async ({
    limit = ITEMS_PER_PAGE,
    offset = 0,
    q,
    reset = false,
    filters = tableFilters
  }: GetTasksProps) => {
    await dispatch(getOpenTasksAction({ idOrg, limit, offset, sort: defaultSortString, q, reset, filters }))
  }, [defaultSortString, dispatch, idOrg, tableFilters])

  const { onSearch, searchText } = useTaskSearch({ getTasks })

  useEffectOnce(() => {
    dispatch(getOpenTasksFilterOptionsAction({ idOrg }))
    getTasks({ reset: true, q: searchText })
  })

  const fetchOpenTasksData = (reset = false): void => {
    getTasks({ offset: reset ? 0 : tasks.length, reset, q: searchText })
  }

  const handleFilterChange = (updatedFilters: Filter[]) => {
    getTasks({ filters: updatedFilters, q: searchText, reset: true })
  }

  const numberOfNeedAttentionTasks = tasks.filter(task => task.status === TASK_STATUS.NEEDS_ATTENTION).length

  const fetchFieldValues = (field: string) => {
    const { type } = filterOptions.find(f => f.value === field) || {}
    const isFieldSupportValues = [fieldTypes.text, fieldTypes.user, fieldTypes.dropdown, fieldTypes.dropdownMulti, fieldTypes.userMulti].includes(type)

    if (isFieldSupportValues) {
      dispatch(getOpenTasksFieldValuesAction({ idOrg, fields: [field] }))
    }
  }

  return <>
    <NeedsAttentionAlertBox numberOfTasks={numberOfNeedAttentionTasks} />
    <TableContainer>
      <Table
        tableKey={TABLES.openTasksTable.key}
        data={tasks}
        columns={openTasksColumns}
        loading={loading}
        loadingMore={loadingMore}
        totalCount={total}
        fetchData={fetchOpenTasksData}
        searchable
        forceShowSearch
        onSearch={onSearch}
        emptyStateMessage='No tasks to display' // TODO-OM define microcopy
        manual
        filterable
        filtersOptions={filterOptions}
        filterOptionsValuesPerKey={filterValues}
        fetchFieldValues={fetchFieldValues}
        onFilterChange={handleFilterChange}
      />
    </TableContainer>
  </>
}

export default OpenTasksTab
