import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  background-color: white;
  position: sticky;
  top: 0;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
  z-index: 100;
  padding: 24px 0px;
`
