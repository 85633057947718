import { useEffect, useRef, useState } from 'react'
import Select from '@components/select'
import { useParamSelector } from '@shared/utils'
import { getAppDetailsField } from '@selectors/apps'
import { type SelectProps } from '@toriihq/design-system'
import { IdApp, IdOrg } from '@store/types'
import { useDispatch, useSelector } from 'react-redux'
import { setAppsDetails } from '@actions/apps'
import Analytics from '@helpers/analytics'
import { getCurrentOrg } from '@selectors/org'
import EnableFor from '@components/enableFor'

interface Props extends SelectProps {
  idApp?: IdApp
  idOrg?: IdOrg
  onChange?: (option: unknown) => void
  page?: string
  value?: string
  scopes: string[]
}

const SelectAppCategory = ({ idApp, idOrg, onChange, page, scopes, ...selectProps }: Props) => {
  const [value, setValue] = useState<string | null>(selectProps.value ?? null)
  const dispatch = useDispatch()
  const org = useSelector(getCurrentOrg)
  const categoryField = useParamSelector(getAppDetailsField, { systemKey: 'category' })
  const prevCategory = useRef(value)

  useEffect(() => {
    if (selectProps.value) {
      setValue(selectProps.value)
    }
  }, [selectProps.value])

  if (!categoryField) {
    return null
  }

  const onSelectCategory = async (option) => {
    Analytics.track('Update App Category', {
      'Plan tier': org?.plan.name,
      'Page': page ?? 'N/A',
      'Previous selection': selectProps.value ?? 'None',
      'New selection': option.value ?? 'None'
    })

    setValue(option.value)
    if (onChange) {
      return onChange(option)
    }

    if (idApp && idOrg) {
      try {
        await dispatch(setAppsDetails({ idOrg, idApps: [idApp], fieldsValues: [{ idField: option.idField, values: [option.value] }], isBulk: false, user: null }))
        prevCategory.current = option.value
      } catch (e) {
        setValue(prevCategory.current)
      }
    }
  }

  return (
    <EnableFor scopes={scopes}>
      <Select
        options={categoryField.options}
        {...selectProps}
        value={value}
        onChange={onSelectCategory}
      />
    </EnableFor>
  )
}

export default SelectAppCategory
