import React from 'react'
import PropTypes from 'prop-types'
import DraggableOption from './draggableOption'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import { css } from 'glamor'
import { fontSize } from '../../shared/style/sizes'
import { colors } from '../../shared/style/colors'
import { withTheme } from 'styled-components'

const generateCSS = ({ theme }) => ({
  itemContainer: css({
    fontSize: fontSize.small
  }),
  droppableContainer: css({
    ' > div:nth-child(n)': {
      border: `1px solid ${theme.palette.border.primary} !important`
    }
  })
})

let instances = 0

class DraggableOptions extends React.Component {
  constructor (props) {
    super(props)
    const { theme } = props
    this.state = {
      CSS: generateCSS({ theme })
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.theme !== this.props.theme) {
      this.setState({
        CSS: generateCSS({ theme: this.props.theme })
      })
    }
  }

  droppableId = `droppable-${instances++}`

  componentDidMount () {
    DraggableOptions.registerDropabble(this.droppableId, this.onDragEnd)
  }

  componentWillUnmount () {
    DraggableOptions.unregisterDropabble(this.droppableId, this.onDragEnd)
  }

  onDragEnd = (result) => {
    const isDroppedOutside = !result.destination
    if (isDroppedOutside) {
      return
    }

    const value = this.props.value
    const [removed] = value.splice(result.source.index, 1)
    value.splice(result.destination.index, 0, removed)

    this.props.onChange(value)
  }

  renderOption = (option, index, isDragging) => {
    const { value, onRemove, optionRenderer, optionProps, valueKey, disabled, showIndex, showDots, overrideStyle } = this.props
    const id = option[valueKey]
    return (
      <DraggableOption
        overrideStyle={overrideStyle}
        showIndex={showIndex}
        showDots={showDots}
        disabled={disabled}
        optionComponent={optionRenderer(option, { ...optionProps, disabled, index, isDragging })}
        id={id}
        option={option}
        index={index}
        onRemove={onRemove}
        value={value}
      />
    )
  }

  render () {
    const { options, value, valueKey, disabled, noBackgroundOnDrag, itemContainerOverrideStyle } = this.props
    const { CSS } = this.state

    if (options.length === 0 || !value) {
      return null
    }

    if (disabled) {
      return (
        <div {...css(CSS.droppableContainer)}>
          {value.map((v, index) => {
            const option = options.find(o => o[valueKey] === v)
            if (!option) {
              return null
            }

            const id = option[valueKey]

            return (
              <div key={id}>
                <div {...css(CSS.itemContainer, itemContainerOverrideStyle)}>
                  {this.renderOption(option, index)}
                </div>
              </div>
            )
          })}
        </div>
      )
    }

    return (
      <Droppable droppableId={this.droppableId}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            {...css(CSS.droppableContainer)}
          >
            {value.map((v, index) => {
              const option = options.find(o => o[valueKey] === v)
              if (!option) {
                return null
              }

              const id = option[valueKey]

              return (
                <Draggable key={id} draggableId={`item-${id}`} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      {...css(CSS.itemContainer, itemContainerOverrideStyle)}
                      style={{
                        backgroundColor: noBackgroundOnDrag || !snapshot.isDragging ? colors.white : colors.background,
                        border: `${snapshot.isDragging ? `1px solid ${colors.blue}` : 0}`,
                        ...provided.draggableProps.style
                      }}
                    >
                      {this.renderOption(option, index, snapshot.isDragging)}
                    </div>
                  )}
                </Draggable>
              )
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    )
  }
}

DraggableOptions._registeredDropabbles = {}

DraggableOptions.registerDropabble = (droppableId, onDragEnd) => {
  DraggableOptions._registeredDropabbles[droppableId] = onDragEnd
}

DraggableOptions.unregisterDropabble = (droppableId) => {
  DraggableOptions._registeredDropabbles[droppableId] = null
}

DraggableOptions.onDragEnd = (result) => {
  const { droppableId } = result.destination || {}
  const onDragEnd = DraggableOptions._registeredDropabbles[droppableId]
  onDragEnd && onDragEnd(result)
}

const optionValue = PropTypes.oneOfType([PropTypes.string, PropTypes.number])

DraggableOptions.propTypes = {
  disabled: PropTypes.bool,
  valueKey: PropTypes.string,
  value: PropTypes.arrayOf(optionValue),
  options: PropTypes.arrayOf(PropTypes.shape({
    value: optionValue
  })),
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  optionRenderer: PropTypes.func,
  optionProps: PropTypes.object,
  overrideStyle: PropTypes.object,
  showIndex: PropTypes.bool
}

DraggableOptions.defaultProps = {
  valueKey: 'value',
  disabled: false,
  showIndex: true,
  showDots: true
}

export default withTheme(DraggableOptions)
