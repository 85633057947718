import React from 'react'
import InviteUserPopup from '../inviteUserPopup'
import AssignUserPopup from '../assignUserPopup'
import UploadExpensesPopup from '../uploadExpensesPopup'
import UploadAppDetailsAttachmentsPopup from '../uploadAppDetailsAttachmentsPopup'
import SelectFieldTypePopup from '../selectFieldTypePopup'
import ConfigureAppForOffboardingPopup from '../configureAppForOffboardingPopup'
import ConfigureExecuteActionOnUsersPopup from '../configureExecuteActionOnUsersPopup'
import HideAppPopup from '../hideAppPopup'
import OffboardingPreviewPopup from '../offboardingPreviewPopup'
import OffboardingStartedPopup from '../offboardingStartedPopup'
import TriggerPreviewPopup from '../triggerPreviewPopup'
import ManualActionStartedPopup from '../manualActionStartedPopup'
import AppDetailHistoryPopup from '../appDetailHistoryPopup'
import AddApplicationPopup from '../addApplicationPopup'
import TransactionMatchingRulesPopup from '../transactionsMatchingRulesPopup'
import AddOrEditContractPopup from '../addOrEditContractPopup'
import AskToUpdateLicenseCostPopup from '../askToUpdateLicenseCostPopup'
import AppAccountSelectPopup from '../appAccountSelectPopup'
import RenewalForecastNotSupportedPopup from '@components/popups/renewalForecastNotSupportedPopup'
import ContractDetailHistoryPopup from '../contractDetailHistoryPopup'
import ConfigureAppChargebackPopup from '../configureAppChargebackPopup'
import WorkflowPreviewPopup from '../workflowPreviewPopup'
import CsvExportPopup from '../csvExportPopup'
import ConfigureBrowserExtensionActivationEmailPopup from '../configureBrowserExtensionActivationEmailPopup'
import ShareReportPopup from '../shareReportPopup'
import SentEmailsPopup from '../sentEmailsPopup'
import ManuallyRunWorkflowPopup from '../manuallyRunWorkflowPopup'
import RolePopup from '../rolePopup'
import ImportContractsPopup from '../ImportContractsPopup'
import UserLifecycleSettingsPopup from '@components/popups/userLifecycleSettingsPopup'
import CustomActionResponsePopup from '@components/popups/customActionResponsePopup'
import UsersOverlapsPopup from '@pages/appsComparison/components/usersOverlaps/components/usersOverlapsPopup'
import ActivateWorkflowPopup from '@components/popups/activateWorkflowPopup'
import WorkflowStartedPopup from '@components/popups/workflowStartedPopup'
import AddAppCatalogPolicyPopup from '@components/popups/addAppCatalogPolicyPopup'
import ImportUsersPopup from '@components/popups/importUsersPopup'
import ImportUsersDeletionPopup from '@components/popups/importUsersDeletionPopup'
import WorkflowAuditLogsPopup from '@components/popups/workflowAuditLogsPopup'
import SyncStatusPopup from '@components/popups/syncStatusPopup'
import DiscoveredAppsPreviewPopup from '@components/popups/discoveredAppsPreviewPopup'
import LicenseEditPopup from '@components/popups/licenseEditPopup'
import EditWorkflowTagsPopup from '../editWorkflowTagsPopup'
import CreateWorkflowTagsPopup from '../createWorkflowTagsPopup'
import EditWorkflowTagPopup from '../editWorkflowTagPopup'
import UserDetailHistoryPopup from '@components/popups/userDetailsHistoryPopup'
import ReviewPendingExecutionsPopup from '@components/popups/reviewPendingExecutionsPopup'
import LaunchAccessReviewsPopup from '@components/popups/launchAccessReviewsPopup'
import CopyAppDataPopup from '@components/popups/copyAppDataPopup'

class PopupsContainer extends React.Component {
  render () {
    const {
      configureAppForOffboardingPopup,
      offboardingPreviewPopup,
      triggerPreviewPopup,
      configureExecuteActionOnUsersPopup,
      manualActionStartedPopup,
      appDetailHistoryPopup,
      userDetailHistoryPopup,
      contractDetailHistoryPopup,
      configureBrowserExtensionActivationEmailPopup,
      shareReportPopup,
      sentEmailsPopup,
      csvExportPopup,
      manuallyRunWorkflowPopup,
      rolePopup,
      isUserLifecycleSettingsOpen,
      customActionResponsePopup,
      usersOverlapPopup,
      activateWorkflowPopup,
      workflowStartedPopup,
      addAppCatalogPolicyPopup,
      importUsersPopup,
      syncStatusPopup,
      deleteImportedUsersPopup,
      workflowAuditLogs,
      addApplication,
      contractPopup,
      askToUpdateLicenseCostPopup,
      appAccountSelectPopup,
      renewalForecastNotSupportedPopup,
      licenseEditPopup,
      discoveredAppsPreviewPopup,
      launchAccessReviewsPopup,
      editWorkflowTagsPopup,
      createWorkflowTagsPopup,
      editWorkflowTagPopup,
      pendingExecutionsPopup,
      copyAppDataPopup
    } = this.props

    return <div data-component='PopupsContainer'>
      <AssignUserPopup />
      <InviteUserPopup />
      {isUserLifecycleSettingsOpen && <UserLifecycleSettingsPopup />}
      <UploadExpensesPopup />
      <ImportContractsPopup />
      <UploadAppDetailsAttachmentsPopup />
      <SelectFieldTypePopup />
      {configureAppForOffboardingPopup?.isOpen && <ConfigureAppForOffboardingPopup />}
      <HideAppPopup />
      {offboardingPreviewPopup?.isOpen && <OffboardingPreviewPopup />}
      <OffboardingStartedPopup />
      {triggerPreviewPopup?.isOpen && <TriggerPreviewPopup />}
      {pendingExecutionsPopup?.isOpen && <ReviewPendingExecutionsPopup />}
      {configureExecuteActionOnUsersPopup?.isOpen && <ConfigureExecuteActionOnUsersPopup />}
      {manualActionStartedPopup?.isOpen && <ManualActionStartedPopup />}
      {appDetailHistoryPopup?.isOpen && <AppDetailHistoryPopup />}
      {userDetailHistoryPopup?.isOpen && <UserDetailHistoryPopup />}
      {contractDetailHistoryPopup?.isOpen && <ContractDetailHistoryPopup />}
      {addApplication?.isOpen && <AddApplicationPopup />}
      <TransactionMatchingRulesPopup />
      {contractPopup?.isOpen && <AddOrEditContractPopup />}
      {askToUpdateLicenseCostPopup?.isOpen && <AskToUpdateLicenseCostPopup />}
      {appAccountSelectPopup?.isOpen && <AppAccountSelectPopup />}
      {renewalForecastNotSupportedPopup?.isOpen && <RenewalForecastNotSupportedPopup />}
      {licenseEditPopup?.isOpen && <LicenseEditPopup />}
      <ConfigureAppChargebackPopup />
      {configureBrowserExtensionActivationEmailPopup?.isOpen && <ConfigureBrowserExtensionActivationEmailPopup />}
      {shareReportPopup?.isOpen && <ShareReportPopup />}
      {sentEmailsPopup?.isOpen && <SentEmailsPopup />}
      <WorkflowPreviewPopup />
      {csvExportPopup?.isOpen && <CsvExportPopup />}
      {manuallyRunWorkflowPopup?.isOpen && <ManuallyRunWorkflowPopup />}
      {rolePopup?.isOpen && <RolePopup />}
      {customActionResponsePopup?.isOpen && <CustomActionResponsePopup />}
      {usersOverlapPopup?.isOpen && <UsersOverlapsPopup />}
      {activateWorkflowPopup?.isOpen && <ActivateWorkflowPopup />}
      {workflowStartedPopup?.isOpen && <WorkflowStartedPopup />}
      {addAppCatalogPolicyPopup?.isOpen && <AddAppCatalogPolicyPopup />}
      {importUsersPopup?.isOpen && <ImportUsersPopup />}
      {deleteImportedUsersPopup?.isOpen && <ImportUsersDeletionPopup />}
      {syncStatusPopup?.isOpen && <SyncStatusPopup />}
      {workflowAuditLogs?.isOpen && <WorkflowAuditLogsPopup />}
      {discoveredAppsPreviewPopup?.isOpen && <DiscoveredAppsPreviewPopup />}
      {editWorkflowTagsPopup?.isOpen && <EditWorkflowTagsPopup />}
      {createWorkflowTagsPopup?.isOpen && <CreateWorkflowTagsPopup />}
      {editWorkflowTagPopup?.isOpen && <EditWorkflowTagPopup />}
      {launchAccessReviewsPopup?.isOpen && <LaunchAccessReviewsPopup />}
      {copyAppDataPopup?.isOpen && <CopyAppDataPopup />}
    </div>
  }
}

export default PopupsContainer
