import React, { createRef } from 'react'
import { Button, AlertBox, AlertBoxType, Stack, H3, Body1, FormElement, Input, Link } from '@toriihq/design-system'
import { Link as ReactRouterLink } from 'react-router-dom'
import qs from 'query-string'

class ForgotPage extends React.Component {
  input = {
    email: createRef()
  }

  handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    const { forgot, location } = this.props
    const query = qs.parse(location.search)

    forgot({
      email: this.input.email.current.value,
      next: query.next
    })
  }

  render () {
    const { error, success, hasError, help, loading } = this.props
    const alerts = []

    const backToLoginInfo = {
      search: this.props.location.search,
      pathname: '/login'
    }

    if (success) {
      alerts.push(
        <Stack key='success' direction='column' gap='space-200'>
          <AlertBox type={AlertBoxType.POSITIVE} description='If an account matched that address, an email will be sent with instructions.' />
          <ReactRouterLink to={backToLoginInfo}><Link>Back to login</Link></ReactRouterLink>
        </Stack>
      )
    }

    if (error) {
      alerts.push(
        <AlertBox key='error' type={AlertBoxType.NEGATIVE} description={error} />
      )
    }

    let formElements

    if (!success) {
      formElements = (
        <fieldset>
          <Stack direction='column' gap='space-200'>
            <FormElement label='Email' errorMessage={hasError.email ? help.email : null}>
              <Input
                ref={this.input.email}
                name='email'
                placeholder="What's your email?"
                hasError={hasError.email}
                disabled={loading}
                suffix='User'
                autoFocus
              />
            </FormElement>
            <Stack direction='row' gap='space-200' justifyContent='space-between'>
              <ReactRouterLink to={backToLoginInfo}><Link>Back to login</Link></ReactRouterLink>
              <Button label='Send Link' />
            </Stack>
          </Stack>
        </fieldset>
      )
    }

    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <Stack direction='column' gap='space-300'>
          <Stack direction='column' gap='space-0'>
            <H3>Forgot your password?</H3>
            <Body1>Enter your email address and we will send you a link to reset your password</Body1>
          </Stack>
          {alerts}
          {formElements}
        </Stack>
      </form>
    )
  }
}

export default ForgotPage
