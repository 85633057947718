import { omit } from 'lodash'

const isEmptyLineItem = (lineItem) => {
  const { description, quantity, pricePerUnit, discount, idLicense, paymentTerm, tax, startDate, endDate } = lineItem
  return !description && !quantity && !pricePerUnit && !discount && !idLicense && !paymentTerm && !tax && !startDate && !endDate
}

export const aggregateLineItemsData = ({ lineItems, currency, idUser }) => {
  const aggregatedLineItems = lineItems.map((lineItem) => {
    if (isEmptyLineItem(lineItem)) {
      return null
    }
    const { quantity, pricePerUnit, discount, idLicense: selectedIdLicense, paymentTerm, tax } = lineItem

    const quantityNum = quantity || undefined
    const pricePerUnitNum = pricePerUnit && (pricePerUnit.value ?? Number(pricePerUnit))
    const discountNum = discount ? Number(discount) : undefined
    const taxNum = isCurrencyValue(tax) ? tax.value : (tax ? Number(tax) : undefined)

    const totalCost = calculateTotalCost({
      quantity: quantityNum,
      pricePerUnit: pricePerUnitNum,
      discount: discountNum ?? 0,
      tax: taxNum ?? 0
    })

    const idLicense = selectedIdLicense?.value
    const paymentTermValue = paymentTerm?.value ?? paymentTerm

    return omit({
      ...lineItem,
      idLicenseByTorii: null,
      idLicenseByUser: idLicense,
      mappedByIdUser: lineItem.idLicenseByUser !== idLicense ? idUser : lineItem.mappedByIdUser,
      mappingLogic: 'manually',
      totalCost,
      currency,
      paymentTerm: paymentTermValue,
      pricePerUnit: pricePerUnitNum,
      quantity: quantityNum,
      discount: discountNum,
      tax: taxNum
    }, 'idLicense')
  })
  return aggregatedLineItems
}

interface CurrencyValue {
  currency?: string
  convertedValue?: number
  value: number
}

interface CalculationInput {
  pricePerUnit?: CurrencyValue | number
  quantity?: number | string | null
  discount?: number | null
  tax?: CurrencyValue | number | null
}

interface CalculationResult {
  totalCost: number
  isValid: boolean
}

const isCurrencyValue = (value: any): value is CurrencyValue => {
  return typeof value === 'object' && value !== null && 'value' in value
}

export const calculateTotalCost = ({ pricePerUnit, quantity, discount, tax }: CalculationInput): number | undefined => {
  if (!pricePerUnit || !quantity) {
    return undefined
  }

  const quantityNum = Number(quantity)
  if (quantityNum < 0) {
    return undefined
  }

  const pricePerUnitNum = isCurrencyValue(pricePerUnit) ? pricePerUnit.value : Number(pricePerUnit)

  const discountNum = Number(discount ?? 0)
  const taxNum = isCurrencyValue(tax) ? tax.value : Number(tax ?? 0)

  const totalBeforeDiscount = quantityNum * pricePerUnitNum
  const totalAfterDiscount = totalBeforeDiscount * (1 - discountNum / 100)
  const totalWithTax = totalAfterDiscount + taxNum

  return Math.round(totalWithTax)
}

export const calculateTotalCostWithValidation = (input: CalculationInput): CalculationResult => {
  const totalCost = calculateTotalCost(input)
  return {
    totalCost: totalCost ?? 0,
    isValid: totalCost !== undefined
  }
}
