import { createSelector } from 'reselect'
import {
  getAppsV2TablePreferences, getUserPreferences, getMonthlyReportTablePreferences, getUserTablesPreferences
} from './ui'
import { formFieldTypesToFieldTypes, TABLES } from '@root/constants'
import { getUserDetailsFieldsSelector } from './userDetails'
import pick from 'lodash/pick'
import { getAppDetailsFields, getAppsMetadata, getExpenseReportsAppsDetailsFields } from './apps'
import { getTableInfo } from '@lenses/table'
import { removeFiltersWithNoOptions, removeRestrictedFilterOptions } from '@shared/filters'
import { getSelf } from '@selectors/me'
import { getCurrentOrg } from '@selectors/org'

const USERS_TABLES_WITH_INFO = [TABLES.inactiveUsersListPopup, TABLES.usersTable, TABLES.appUsersTable, TABLES.appAccountUsersTable, TABLES.appAccountLicenseUsersTable, TABLES.appActiveUsersListPopup, TABLES.deletedUsersTable, TABLES.externalUsersTable, TABLES.licenseRecommendationsTable, TABLES.allDiscoveredAppsTable, TABLES.appDiscoveredAppsTable, TABLES.usersOverlapsPopup].map(table => table.key)
export const getUserTablesInfo = createSelector(
  [getUserTablesPreferences, getUserDetailsFieldsSelector],
  (usersTablesPreferences, dynamicColumns) => {
    const info = {}
    USERS_TABLES_WITH_INFO.forEach(tableKey => {
      const { columnsConfiguration = [], filters, defaultSort } = usersTablesPreferences[TABLES[tableKey].key] || {}
      info[tableKey] = getTableInfo(tableKey, columnsConfiguration, filters, dynamicColumns, defaultSort)
    })

    return info
  }
)

export const getMonthlyExpensesReportTableInfo = createSelector(
  [getMonthlyReportTablePreferences, getExpenseReportsAppsDetailsFields],
  (userPreferences, dynamicColumns) => {
    const tableKey = TABLES.monthlyExpensesReport.key
    const { columnsConfiguration = [], filters } = userPreferences[tableKey] || {}
    const columnsConfigurationByAccessor = columnsConfiguration.reduce((result, column) => {
      result[column] = true
      return result
    }, {})

    dynamicColumns = dynamicColumns
      .filter(detail => detail.category === 'app_details' && detail.isShown)
    const dynamicFilterOptions = dynamicColumns.map(detail => ({
      label: detail.name,
      value: detail.systemKey,
      type: formFieldTypesToFieldTypes[detail.type],
      options: detail.options
    }))

    return {
      ...pick(TABLES[tableKey], ['key', 'preDefinedColumnsMapping']),
      columnsConfiguration: columnsConfigurationByAccessor,
      filtersOptions: (TABLES[tableKey].filtersOptions || []).concat(dynamicFilterOptions),
      filters
    }
  }
)

export const getAppsV2TablesInfo = createSelector(
  [getAppsV2TablePreferences, getCurrentOrg, getSelf, getAppsMetadata],
  (userPreferences, org, me, metadata) => {
    const { predefinedFields, customFields } = metadata
    const tableKey = TABLES.appsV2Table.key
    const { columnsConfiguration = [], filters } = userPreferences[tableKey] || {}
    const columnsConfigurationByAccessor = columnsConfiguration.reduce((result, column) => {
      result[column] = true
      return result
    }, {})

    const stateInfo = customFields.find(detail => detail.systemKey === 'state') ?? {}
    const dynamicColumns = customFields.filter(detail => detail.systemKey !== 'state' && detail.isShown)
    const displayedDynamicColumnsInfo = dynamicColumns.filter(detail => columnsConfiguration.includes(detail.systemKey))

    const filtersOptions = predefinedFields.concat(customFields).map(field => ({ ...field, label: field.name, value: field.systemKey, type: formFieldTypesToFieldTypes[field.type] }))
    const filterOptionsWithRestrictions = removeRestrictedFilterOptions(filtersOptions, { me: me, org: org })
    const filtersWithOptionValues = removeFiltersWithNoOptions(filterOptionsWithRestrictions, filters)

    const preDefinedColumnsMapping = predefinedFields.reduce((acc, field) => {
      acc[field.systemKey] = field.name
      return acc
    }, {})

    return {
      key: tableKey,
      preDefinedColumnsMapping,
      columnsConfiguration: columnsConfigurationByAccessor,
      dynamicColumnsInfo: displayedDynamicColumnsInfo,
      filtersOptions: filterOptionsWithRestrictions,
      filters: filtersWithOptionValues,
      stateInfo
    }
  }
)

export const getExpensesTablesInfo = createSelector(
  [getUserPreferences],
  (userPreferences) => {
    const expenseTablesKeys = [TABLES.allExpensesTable, TABLES.appExpensesTable].map(table => table.key)
    const info = {}
    expenseTablesKeys.forEach(tableKey => {
      const { columnsConfiguration = [], filters } = userPreferences[tableKey] || {}
      const columnsConfigurationByAccessor = columnsConfiguration.reduce((result, column) => {
        result[column] = true
        return result
      }, {})

      const filtersOptions = TABLES[tableKey].filtersOptions || []
      const filtersWithOptionValues = removeFiltersWithNoOptions(filtersOptions, filters)

      info[tableKey] = {
        ...pick(TABLES[tableKey], ['key', 'preDefinedColumnsMapping']),
        columnsConfiguration: columnsConfigurationByAccessor,
        filtersOptions: filtersOptions,
        filters: filtersWithOptionValues || []
      }
    })
    return info
  }
)

export const getDiscoveredAppsTablesInfo = createSelector(
  [getUserPreferences],
  (userPreferences) => {
    const expenseTablesKeys = [TABLES.allDiscoveredAppsTable, TABLES.appDiscoveredAppsTable].map(table => table.key)
    const info = {}
    expenseTablesKeys.forEach(tableKey => {
      const { columnsConfiguration = [], filters } = userPreferences[tableKey] || {}
      const columnsConfigurationByAccessor = columnsConfiguration.reduce((result, column) => {
        result[column] = true
        return result
      }, {})

      const filtersOptions = TABLES[tableKey].filtersOptions || []
      const filtersWithOptionValues = removeFiltersWithNoOptions(filtersOptions, filters)

      info[tableKey] = {
        ...pick(TABLES[tableKey], ['key', 'preDefinedColumnsMapping']),
        columnsConfiguration: columnsConfigurationByAccessor,
        filtersOptions: filtersOptions,
        filters: filtersWithOptionValues || []
      }
    })
    return info
  }
)

export const getScheduledReportsTablesInfo = createSelector(
  [getUserPreferences],
  (userPreferences) => {
    const scheduledReportsTablesKeys = [TABLES.scheduledReportsTable].map(table => table.key)
    const info = {}
    scheduledReportsTablesKeys.forEach(tableKey => {
      const { columnsConfiguration = [] } = userPreferences[tableKey] || {}
      const columnsConfigurationByAccessor = columnsConfiguration.reduce((result, column) => {
        result[column] = true
        return result
      }, {})

      info[tableKey] = {
        ...pick(TABLES[tableKey], ['key', 'preDefinedColumnsMapping']),
        columnsConfiguration: columnsConfigurationByAccessor
      }
    })

    return info
  }
)

export const getCatalogFiltersOptions = createSelector(
  [getAppDetailsFields],
  (dynamicColumns) => {
    dynamicColumns = dynamicColumns
      .filter(detail => detail.isShown)
    const dynamicFilterOptions = dynamicColumns.map(detail => ({
      label: detail.name,
      value: detail.systemKey,
      type: formFieldTypesToFieldTypes[detail.type],
      options: detail.options
    }))

    return { filtersOptions: (TABLES.catalogAppsPopup.filtersOptions || []).concat(dynamicFilterOptions) }
  }
)
