import React from 'react'
import colors from '../../../shared/style/colors'
import { css as glamorCSS } from 'glamor'
import PieChart from '../../pieChartBox/pieChart'
import HoverableHeader from '../../hoverableHeader'
import CustomTooltip from '../../expensesSummary/customTooltip'
import AllExpenseCategoriesPopup from '../../allExpenseCategoriesPopup'
import { Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import { withTheme, css } from 'styled-components'
import pluralize from 'pluralize'
import { fontSize } from '../../../shared/style/sizes'
import PropTypes from 'prop-types'
import Currency from '@root/components/currency'

const HEADER_HEIGHT = '50px'
const BOX_PADDING = '20px'

const Header = css`
  min-height: ${HEADER_HEIGHT};
  padding: 7px ${BOX_PADDING} 0 ${BOX_PADDING};
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
`

const generateCSS = ({ theme }) => ({
  main: glamorCSS({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: '8px'
  }),
  chartsContainer: glamorCSS({
    height: `calc(100% - ${HEADER_HEIGHT})`
  }),
  tooltipAppCount: glamorCSS({
    color: colors.grey2,
    fontSize: fontSize.small,
    textTransform: 'none'
  })
})

const className = 'annualRenewalsByCategory'

class AnnualRenewalsByCategory extends React.Component {
  constructor (props) {
    super(props)
    const { theme } = props
    this.state = {
      CSS: generateCSS({ theme })
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.theme !== this.props.theme) {
      this.setState({
        CSS: generateCSS({ theme: this.props.theme })
      })
    }
  }

  tooltipValueDisplayFunction = (value, payload) => {
    return <div>
      <Currency value={value} />
      <div {...this.state.CSS.tooltipAppCount}>{`${payload.count} ${pluralize('Contract', payload.count)}`}</div>
    </div>
  }

  render () {
    const { loading, yearlyRenewalsByCategoryForPie, yearlyRenewalsByCategory, toggleAllExpenseCategories, total } = this.props
    const seeAllButton = <Button type={ButtonType.compact} size={ButtonSize.small} onClick={() => toggleAllExpenseCategories(true)} label='See all' />

    return (
      <div className={className} {...this.state.CSS.main}>
        <HoverableHeader hoverClass={className} overrideStyle={Header} header='renewals by category' subHeader='Next 12 months. Contracts with renewal costs' rightContent={seeAllButton} />
        <div {...this.state.CSS.chartsContainer}>
          <PieChart
            data={yearlyRenewalsByCategoryForPie}
            loading={loading}
            hasData={yearlyRenewalsByCategoryForPie.length > 0}
            valueDisplayFunction={(value) => <div style={{ whiteSpace: 'nowrap' }}><Currency value={value} /></div>}
            customToolTip={<CustomTooltip valueDisplayFunction={this.tooltipValueDisplayFunction} />}
          />

          {!loading && <AllExpenseCategoriesPopup title='All categories' categories={yearlyRenewalsByCategory} total={total} />}
        </div>
      </div>
    )
  }
}

AnnualRenewalsByCategory.propTypes = {
  loadingRenewals: PropTypes.bool
}

export default withTheme(AnnualRenewalsByCategory)
