import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getDashboardsData } from '@selectors/dashboards'
import { getCurrentOrg } from '@selectors/org'
import { generateMetricParams, getMetricValue } from '@components/dashboard/widgets/dynamicWidgets/metricWidget/utils'
import { Body2, Card, Stack } from '@toriihq/design-system'
import Placeholder from '@components/placeholder'
import Currency from '@components/currency'
import numeral from 'numeral/numeral'
import { DynamicWidgetProps } from '@components/dashboard/widgets/types'
import * as Style from './style'
import { EMPTY_OBJECT, fieldTypes } from '@root/constants'
import { getEntityDataForWidget } from '@actions/dashboards'
import useDeepCompareEffect from 'use-deep-compare-effect'
import OverflowTooltip from '@components/overflowTooltip'

const MetricWidget = (props: DynamicWidgetProps) => {
  const { idDashboard, widget, isSelected, primaryButton, isWidgetMenuOpen } = props

  const dispatch = useDispatch()
  const { id: idOrg } = useSelector(getCurrentOrg)

  const dashboardsData = useSelector(getDashboardsData)
  const dashboardData = dashboardsData[idDashboard]
  const widgetsDataById = dashboardData?.widgetsData || EMPTY_OBJECT
  const widgetData = widgetsDataById[widget.id]

  const value = getMetricValue({ metricFunc: widget.displayConfig?.metric?.func, data: widgetData?.data })

  const fieldType = widgetData?.data?.displayConfig?.metric?.field?.type
  const title = fieldType === fieldTypes.currency ? <Currency value={value ?? 0} /> : numeral(value).format('0,0')

  useDeepCompareEffect(() => {
    if (idOrg) {
      const params = generateMetricParams({ metric: widget.displayConfig.metric })
      dispatch(getEntityDataForWidget({
        entityType: widget.entityType,
        idOrg,
        idDashboard,
        idWidget: widget.id,
        filters: widget.dataConfig.filters,
        displayConfig: widget.displayConfig,
        ...params
      }))
    }
  }, [dispatch, idOrg, widget, idDashboard])

  const isLoading = widgetData?.data?.loading && widgetData?.data?.total === undefined && widgetData?.data?.aggregations === undefined

  return <Card hoverable selected={isSelected} padding={'small'}>
    <Style.CardContent isSelected={isSelected} showPrimaryButton={isWidgetMenuOpen}>
      <Stack direction='column' gap={isLoading ? 'space-150' : 'space-050'}>
        <Placeholder loading={isLoading} type='rect' style={{ width: '130px', height: '40px' }}>
          <Stack direction='row' gap='space-100' justifyContent='space-between' alignItems='flex-start'>
            <Style.TitleWrapper>
              <OverflowTooltip label={title}>
                {title}
              </OverflowTooltip>
            </Style.TitleWrapper>
            {primaryButton}
          </Stack>
        </Placeholder>
        <Placeholder loading={isLoading} type='rect' style={{ height: '20px', maxWidth: '234px', width: '100%' }} >
          <Body2 color='secondary'>{widget?.displayConfig?.label}</Body2>
        </Placeholder>
      </Stack>
    </Style.CardContent>
  </Card>
}

export default MetricWidget
