import { css } from 'glamor'
import colors from '@shared/style/colors'
import styled from 'styled-components'

export const MainPopupArea = css({
  width: '100%',
  height: '476px'
})

export const OnHover = css({
  ':hover': {
    textDecoration: 'underline'
  }
})
export const ColorIndication = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-right: 10px;
  min-width: 10px;
  background-color: ${({ color }) => color};
  `

export const StateContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  padding: 8px 10px;
  `

export const StateLabel = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: 'nowrap';
`

export const Table = {
  minHeight: '391px'
}

export const NameAndIcon = css({
  ':hover': {
    color: colors.blue
  }
})
