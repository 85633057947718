import { formFieldTypes } from '@root/constants'
import { ops } from '@root/lenses/filters'
import { DropdownOptionsSource, WidgetConfig } from '@components/dashboard/widgets/types'
import { filterNumericOptions } from '../../shared/widgetConfig/filterOptions'
import { widgetInfoSection } from '../../shared/widgetConfig/constants'
import { METRIC_FUNC } from '@root/store/reducers/dashboards/types'

const config: WidgetConfig = {
  editableSections: [
    widgetInfoSection,
    {
      key: 'dataConfig',
      label: 'Data',
      fields: [
        {
          key: 'metricFunc',
          label: 'Aggregation',
          type: formFieldTypes.dropdown,
          valuePathInWidget: 'displayConfig.metric.func',
          dropdownOptionsSource: DropdownOptionsSource.CLOSED_LIST,
          options: [
            { label: 'Count', value: METRIC_FUNC.TOTAL },
            { label: 'Summation', value: METRIC_FUNC.SUM },
            { label: 'Average', value: METRIC_FUNC.AVG },
            { label: 'Maximum', value: METRIC_FUNC.MAX },
            { label: 'Minimum', value: METRIC_FUNC.MIN }
          ]
        },
        {
          key: 'metricField',
          label: 'Field',
          type: formFieldTypes.dropdown,
          dropdownOptionsSource: DropdownOptionsSource.ENTITY_FIELDS,
          filterOptions: filterNumericOptions,
          valuePathInWidget: 'displayConfig.metric.field.systemKey',
          showOnFieldSpecificCondition: { fieldKey: 'metricFunc', comparisonType: 'value', value: 'total', op: ops.notEquals }
        },
        {
          key: 'filters',
          label: 'Filter by',
          type: formFieldTypes.filters,
          valuePathInWidget: 'dataConfig.filters'
        }
      ]
    }
  ]
}

export default config
