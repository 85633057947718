import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import keyBy from 'lodash/keyBy'
import { Tooltip, Checkbox } from '@toriihq/design-system'

import { getLifecycleApps as lifecycleApps, isLoadingUserLifecycleApps } from '@selectors/org'
import Placeholder from '@components/placeholder'
import AppDetails from '@components/appDetails'
import sortBy from 'lodash/sortBy'
import { UserLifecycleSettingsContext } from '@components/popups/userLifecycleSettingsPopup/UserLifecycleSettingsContext'
import * as Style from './style'

export const INTEGRATION_CATEGORY = {
  IDENTITY_PROVIDER: 'identityProvider',
  HUMAN_RESOURCE: 'humanResource'
}

export const UserLifecycleSelect = ({ userLifecycleConfig }) => {
  const isLoading = useSelector(isLoadingUserLifecycleApps)
  const apps = useSelector(lifecycleApps)

  const { updateLifecycleSettings, selectedLifecycleApps } = useContext(UserLifecycleSettingsContext)

  const [selectedRows, setSelectedRows] = useState(() => selectedLifecycleApps.reduce((acc, app) => {
    acc[app.idApp] = true
    return acc
  }, {}))

  const rows = useMemo(() => sortBy(apps, app => app.name), [apps])
  const rowsByIdApp = useMemo(() => keyBy(apps, 'idApp'), [apps])

  const toggleSelection = (idApp) => {
    const selected = { ...selectedRows }
    if (selected[idApp]) {
      delete selected[idApp]
    } else {
      selected[idApp] = true
    }
    setSelectedRows(selected)
  }

  useEffect(() => {
    const selectedLifecycleApps = rows.filter(row => selectedRows[row.idApp]).map(row => ({ idApp: row.idApp, source: row.source }))
    updateLifecycleSettings({ lifecycleApps: selectedLifecycleApps })
  }, [rows, selectedRows, updateLifecycleSettings])

  const AppRow = ({ idApp, loading }) => {
    const app = rowsByIdApp[idApp] || {}
    return (
      <Placeholder loading={loading} type='textRow' style={{ height: '10px', marginTop: '15px', marginBottom: '15px' }} rows={1}>
        {!loading &&
        <div {...Style.CheckButton}>
          <Checkbox checked={selectedRows[app.idApp]} onChange={(e) => toggleSelection(idApp)} />
        </div>
        }
        <div {...Style.AppDetails}>
          <AppDetails
            name={app.name}
            category={app.category}
            imageUrl={app.imageUrl}
            component='User Lifecycle'
            loading={loading}
            overrideStyle={{ width: '190px' }}
          />
        </div>
        {(!loading && !app.isConnected) && (
          <Tooltip
            label='Connect account at the Integrations page'
          >
            <span {...Style.Info}>The account is not connected</span>
          </Tooltip>
        )}
      </Placeholder>
    )
  }

  return (
    <div>
      <div {...Style.Label}>identity providers</div>
      {rows.filter(row => row.integrationCategory === INTEGRATION_CATEGORY.IDENTITY_PROVIDER).map((row) => (
        <div key={row.idApp} {...(selectedRows[row.idApp] ? Style.SelectedRow : Style.Row)}>
          <AppRow idApp={row.idApp} loading={isLoading} />
        </div>
      ))}
      <div {...Style.Divider} />
      <div {...Style.Label}>Human Resource Management Systems</div>
      {rows.filter(row => row.integrationCategory === INTEGRATION_CATEGORY.HUMAN_RESOURCE).map((row) => (
        <div key={row.idApp} {...(selectedRows[row.idApp] ? Style.SelectedRow : Style.Row)}>
          <AppRow idApp={row.idApp} loading={isLoading} />
        </div>
      ))}
    </div>
  )
}
