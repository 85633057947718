import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { AppIcon, Spacer } from '@toriihq/design-system'
import { getOverlapsUsersAppsComparison, getUserDetailsFields, toggleUsersOverlapPopup } from '@actions/'
import Table from '@components/table'
import colors from '@shared/style/colors'
import { getCurrentOrg } from '@selectors/org'
import { ITEMS_PER_PAGE, TABLES } from '@root/constants'
import * as Style from '../style'
import { AppWrapper, HeaderWrapper, AppName, Separator } from './styles'
import { customJoin } from '@lenses/utils'
import config from '@root/config'
import { withRouter } from 'react-router-dom'
import useEffectOnce from '@shared/hooks/useEffectOnce'
import { getUsersOverlapsConfigurableColumnsOptions } from '@selectors/userDetails'
import { getColumns } from '@pages/appsComparison/components/usersOverlaps/components/columns'
import { getUserTablesInfo } from '@selectors/tables'
import keyBy from 'lodash/keyBy'

const UsersOverlapsPopup = withRouter(({ match, cancel, close, ...rest }) => {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingMore, setLoadingMore] = useState(false)

  const query = useRef('')

  const configurableColumnsOptions = useSelector(getUsersOverlapsConfigurableColumnsOptions)
  const tableInfo = useSelector(getUserTablesInfo)[TABLES.usersOverlapsPopup.key]

  const { isOpen, apps, idUsers, withLicenses } = useSelector(state => state.ui.usersOverlapPopup)
  const { id: idOrg = parseInt(match.params.idOrg, 10) } = useSelector(getCurrentOrg)

  const dispatch = useDispatch()

  const getUsers = ({ limit = ITEMS_PER_PAGE, offset = 0, reset = false, q = query.current }) => {
    const idApps = apps.map(app => app.id)
    setLoadingMore(offset !== 0)
    setLoading(offset === 0)
    dispatch(getOverlapsUsersAppsComparison({
      idOrg,
      idApps,
      limit,
      offset,
      q,
      reset,
      withLicenses
    }))
      .then(result => {
        setUsers(reset ? result : users.concat(result))
        setLoading(false)
        setLoadingMore(false)
      })
    dispatch(getUserDetailsFields({ idOrg }))
  }

  useEffectOnce(() => {
    getUsers({ reset: true })
  })

  const onClose = () => {
    dispatch(toggleUsersOverlapPopup({ apps: [], idUsers: [] }))
  }

  const getHeader = () => {
    return (
      <div>
        <div {...Style.TableHeaderTitle}>{withLicenses ? 'users with licenses' : 'overlapping users'} in {customJoin(apps.map(app => app.name))} ({idUsers})</div>
      </div>
    )
  }

  const onSearch = debounce((q) => {
    query.current = q
    getUsers({ reset: true, q })
  }, 250)

  const fetchUsersData = () => {
    getUsers({ offset: users.length, reset: false })
  }

  const renderPopupHeader = () => {
    return (
      <HeaderWrapper>
        {apps.map((app, i) => (
          <>
            <AppWrapper key={app.name}>
              <Spacer right={'space-150'}>
                <AppIcon appName={app.name} appImageUrl={app.imageUrl} />
              </Spacer>
              <AppName>{app.name}</AppName>
            </AppWrapper>
            {i < apps.length - 1 && <Spacer right={'space-400'} left={'space-400'}>
              <Separator>+</Separator>
            </Spacer>}
          </>
        ))}
      </HeaderWrapper>
    )
  }

  const exportToCsv = ({ query }) => {
    const idApps = `idApps=${JSON.stringify(apps.map(app => app.id))}`
    const withLicensesParam = `withLicenses=${withLicenses}`
    const queryParam = `q=${query}`
    const fieldsParam = `fields=${JSON.stringify(tableInfo.dynamicColumnsInfo.map(field => field.key))}`
    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/apps/compare/overlaps/users/csv?${idApps}&${withLicensesParam}&${queryParam}&${fieldsParam}`
    const newWindow = window.open(url, '_blank')
    newWindow.opener = null
  }

  const columns = getColumns({ onClose, apps, dynamicColumnsInfo: tableInfo.dynamicColumnsInfo, usersById: keyBy(users, 'idUser') })
  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={onClose} width='1165px' height='700px' contentAreaStyle={Style.MainTableArea}>
      <ToriiPopup.Header header={renderPopupHeader()} />
      <ToriiPopup.Content>
        <Table
          tableKey={TABLES.usersOverlapsPopup.key}
          columns={columns}
          data={users}
          fetchData={fetchUsersData}
          searchable
          onSearch={onSearch}
          forceShowSearch
          manual
          sortable={false}
          configurableColumns
          configurableColumnsOptions={configurableColumnsOptions}
          header={getHeader}
          exportable
          exportFunction={exportToCsv}
          loading={loading}
          loadingMore={loadingMore}
          groupedHeaders
          totalCount={query.current ? users.length : idUsers}
          scrollObjectId='contentArea'
          overrideThGroupStyle={{ borderBottom: `1px solid ${colors.border}`, padding: '10px 0' }}
          overrideTheadGroupThStyle={{ textAlign: 'center' }}
          overrideTheadThStyle={{ padding: '14px 10px' }}
          tableHeaderStyle={{ paddingRight: 10 }}
          overrideResizerStyle={{ right: '-6px' }}
        />
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        showCancelButton={false}
        mainButtonAction={onClose}
        mainButtonText={'Close'}
      />
    </ToriiPopup>
  )
})

UsersOverlapsPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func
}

export default UsersOverlapsPopup
