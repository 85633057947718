import { ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { getHistoricalRenewalsSummary } from '@selectors/contracts'
import { getUserPreferences } from '@selectors/ui'
import { renewalsSummaryBaseYearChange } from '@actions/ui'
import { bucketSummarySortOrder } from '@components/renewalsSummary/utils/constants'
import { RENEWALS_SUMMARY_CUSTOM_SELECT_OPTIONS } from '@root/constants'
import RenewalsListTable from '@components/renewalsSummary/renewalsListTable'
import { getLastAvailableYear, getSortedBaseYearRenewals } from '@components/renewalsSummary/utils/utils'

const RenewalYearlySummaryDataPopup = (): ReactElement => {
  const dispatch = useDispatch()

  const renewalsSummary = useSelector(getHistoricalRenewalsSummary)
  const userPreferences = useSelector(getUserPreferences)
  const baseYearPreference = userPreferences?.renewalsSummary?.baseYear

  const [baseYear, setBaseYear] = useState(baseYearPreference || { value: 0, label: '' })
  const [bucketOption, setBucketOption] = useState(RENEWALS_SUMMARY_CUSTOM_SELECT_OPTIONS.all)

  useDeepCompareEffect(() => {
    getLastAvailableYear(renewalsSummary, baseYear, onYearChange)
  }, [renewalsSummary])

  const baseYearRenewals = getSortedBaseYearRenewals(renewalsSummary, baseYear, bucketSummarySortOrder)

  const onYearChange = (year) => {
    setBaseYear(year)
    dispatch(renewalsSummaryBaseYearChange({ baseYear: year }))
  }

  const onBucketChange = (bucket) => {
    setBucketOption(bucket)
  }

  return (
    <RenewalsListTable
      baseYearRenewals={baseYearRenewals}
      baseYear={baseYear}
      bucketOption={bucketOption}
      onBucketChange={onBucketChange}
    />
  )
}

export default RenewalYearlySummaryDataPopup
