import { AGGREGATION_TYPES, DATE_PERIOD, DynamicWidget, METRIC_FUNC, WidgetData, WidgetGroupBySort } from '@reducers/dashboards/types'
import { DATE_FORMAT, fieldTypes, formFieldTypes, scoreSpecialValues } from '@root/constants'
import capitalize from 'lodash/capitalize'
import isNumber from 'lodash/isNumber'
import moment from 'moment'
import { DATE_FIELD_TYPES } from './shared'
import { Filter } from '@shared/filters/types'
import { FILTER_OPERATORS } from '@root/lenses/filters.t'
import { WidgetFormattedData } from './types'

const LABELS = {
  OTHER: 'Other',
  NOT_ENOUGH_DATA: 'Not enough data',
  USAGE_DATA_NOT_PROVIDED: 'Usage data not provided'
}

export const getFormattedData = ({ displayConfig, aggregations }: { displayConfig: DynamicWidget['displayConfig'], aggregations: WidgetData['data']['aggregations'] }): WidgetFormattedData[] => {
  if (isNumber(aggregations)) {
    return []
  }

  const shouldDisplayAggregationValue = aggregations.length > 0 && aggregations[0]?.aggregations !== undefined
  const valueProperty = shouldDisplayAggregationValue ? 'aggregations' : 'value'

  const { datePeriod, field, metric } = displayConfig?.groupBy || {}

  const getLabel = (label) => {
    if (label === LABELS.OTHER) {
      return label
    }

    if (field?.type === formFieldTypes.bool) {
      if (label === 0) {
        return 'No'
      } else if (label === 1) {
        return 'Yes'
      }
    }

    if (DATE_FIELD_TYPES.includes(field?.type || '')) {
      if (datePeriod) {
        switch (datePeriod) {
          case DATE_PERIOD.WEEKLY:
            return moment.utc(label).startOf('isoWeek').format(DATE_FORMAT)
          case DATE_PERIOD.MONTHLY:
            return moment.utc(label).format('MMM YY')
          case DATE_PERIOD.QUARTERLY:
            return `Q${moment.utc(label).quarter()} ${moment.utc(label).format('YYYY')}`
          case DATE_PERIOD.YEARLY:
            return moment.utc(label).format('YYYY')
          default:
            return moment.utc(label).format(DATE_FORMAT)
        }
      }

      return moment.utc(label).format(DATE_FORMAT)
    }

    if (field?.systemKey === 'score') {
      if (label === scoreSpecialValues.onlyExtensionLowUsage) {
        return LABELS.NOT_ENOUGH_DATA
      }
      if (label === scoreSpecialValues.notCollectingUsage) {
        return LABELS.USAGE_DATA_NOT_PROVIDED
      }
    }

    return capitalize(label)
  }

  const getValue = (value) => {
    if (metric?.field?.systemKey === 'score' && value <= -1) {
      return 0
    }

    return value
  }

  const result = aggregations.map((entry, i) => ({
    ...entry,
    value: getValue(entry[valueProperty]),
    name: getLabel(entry.key),
    key: entry.key
  }))

  return result.sort((a, b) => {
    if (a.name === LABELS.OTHER) return 1
    if (b.name === LABELS.OTHER) return -1

    if (a.name === LABELS.NOT_ENOUGH_DATA) return 1
    if (b.name === LABELS.NOT_ENOUGH_DATA) return -1

    if (a.name === LABELS.USAGE_DATA_NOT_PROVIDED) return 1
    if (b.name === LABELS.USAGE_DATA_NOT_PROVIDED) return -1
    return 0
  })
}

type getAggsForGroupByResponse = { field: string, aggregationType: AGGREGATION_TYPES, options: { sort: WidgetGroupBySort, size: number, dateHistogramOptions: { datePeriod?: DATE_PERIOD } }, aggs?: any }

export const getAggsForGroupBy = ({ widget }: { widget: DynamicWidget }) : getAggsForGroupByResponse | undefined => {
  if (!widget.displayConfig.groupBy?.field) {
    return undefined
  }

  const aggregationType = DATE_FIELD_TYPES.includes(widget.displayConfig.groupBy.field.type)
    ? AGGREGATION_TYPES.DATE_HISTOGRAM
    : AGGREGATION_TYPES.GROUP_BY

  const result: getAggsForGroupByResponse = {
    field: widget.displayConfig.groupBy.field.systemKey,
    aggregationType,
    options: {
      dateHistogramOptions: {
        datePeriod: widget.displayConfig.groupBy.datePeriod
      },
      sort: widget.displayConfig.groupBy.sort,
      size: widget.displayConfig.groupBy.size
    }
  }

  if (widget.displayConfig.groupBy.metric?.func && widget.displayConfig.groupBy.metric?.func !== METRIC_FUNC.TOTAL) {
    result.aggs = {
      field: widget.displayConfig.groupBy.metric?.field?.systemKey,
      aggregationType: AGGREGATION_TYPES.METRIC,
      options: {
        metricFunction: widget.displayConfig.groupBy.metric?.func
      }
    }
  }

  return result
}

export const createExtraFilters = (widget: DynamicWidget, allData?: WidgetFormattedData[], selectedData?: WidgetFormattedData): Filter[] => {
  if (!selectedData || (!selectedData.name && !selectedData.key)) {
    return []
  }

  const key = widget.displayConfig.groupBy?.field
  if (!key) {
    return []
  }

  switch (key.type) {
    case fieldTypes.date:
    case formFieldTypes.datePicker: {
      const startOfPeriod = selectedData.key
      if (!startOfPeriod) {
        return []
      }

      const datePeriod = widget.displayConfig.groupBy?.datePeriod || DATE_PERIOD.MONTHLY

      const startDate = moment.utc(startOfPeriod).toDate()
      let endDate = moment.utc(startOfPeriod).toDate()

      switch (datePeriod) {
        case DATE_PERIOD.WEEKLY:
          endDate = moment.utc(startDate).add(1, 'week').toDate()
          break
        case DATE_PERIOD.MONTHLY:
          endDate = moment.utc(startDate).add(1, 'month').toDate()
          break
        case DATE_PERIOD.QUARTERLY:
          endDate = moment.utc(startDate).add(3, 'months').toDate()
          break
        case DATE_PERIOD.YEARLY:
          endDate = moment.utc(startDate).add(1, 'year').toDate()
          break
      }

      return [
        {
          id: Date.now(),
          key: {
            type: key.type,
            label: key.label,
            value: key.systemKey
          },
          op: {
            type: key.type,
            label: 'day on or after',
            value: FILTER_OPERATORS.dayOnOrAfter
          },
          value: {
            value: startDate.toISOString(),
            label: selectedData.name
          }
        },
        {
          id: Date.now() + 1,
          key: {
            type: key.type,
            label: key.label,
            value: key.systemKey
          },
          op: {
            type: key.type,
            label: 'day before',
            value: FILTER_OPERATORS.dayBefore
          },
          value: {
            value: endDate.toISOString(),
            label: selectedData.name
          }
        }
      ]
    }

    default: {
      let value: string | number | boolean | null = selectedData.name

      if (key.type === formFieldTypes.bool) {
        value = (selectedData.name === 'Yes')
      } else if (key.systemKey === 'score') {
        if (selectedData.name === LABELS.NOT_ENOUGH_DATA) {
          value = scoreSpecialValues.onlyExtensionLowUsage
        } else if (selectedData.name === LABELS.USAGE_DATA_NOT_PROVIDED) {
          value = scoreSpecialValues.notCollectingUsage
        }
      }

      return [{
        id: Date.now(),
        key: {
          type: key.type,
          label: key.label,
          value: key.systemKey
        },
        op: {
          type: key.type,
          label: 'is',
          value: FILTER_OPERATORS.equals
        },
        value: { value, label: selectedData.name }
      }]
    }
  }
}
