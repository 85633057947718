import View from './view'
import { connect } from 'react-redux'
import {
  toggleConfigureAppForOffboarding,
  getWorkflowsActionsConfig,
  toggleConnectSource,
  getWorkflowsTriggersConfig,
  getServicesSyncData,
  getServicesConfig,
  validateWorkflow,
  getWorkflowsPersonalizationsConfig
} from '@actions/'
import {
  createWorkflow,
  updateWorkflow
} from '@actions/workflows'
import { getCurrentOrg } from '@selectors/org'
import { getFieldsDefaultValues, getActionsConfigByType, getFlattenActionsConfig, getSelectOptionsOfActionsByAppFor } from '@selectors/workflows'
import {
  EMPTY_OBJECT,
  OFFBOARDING_METHOD_TYPE,
  WORKFLOW_ACTION_TYPES,
  WORKFLOW_TRIGGER_TYPES,
  WORKFLOW_TYPES
} from '@root/constants'
import { getSourceByType } from '@root/sourcesConfig'
import {
  getPersonalizationByTriggerAndAction,
  createAction,
  getAutomaticActionList,
  getCreateTicketActionsByOffboardingType,
  getDelegateActionsList
} from '@lenses/workflows'
import {
  getActionableServices,
  getConstantServices,
  getSyncStatus
} from '@selectors/services'
import { deprecatedGetAppsByIds } from '@selectors/apps'
import omit from 'lodash/omit'
import { createActionsWithASingleAction } from '@root/shared/workflows/actions/utils/createActionsWithASingleAction'

const TRIGGER_TYPE = WORKFLOW_TRIGGER_TYPES.USER_OFFBOARDING

const mapStateToProps = state => {
  const { isOpen, idApp, idAppAccount, appAndAccountName, actions, onConfig, onClose, pendingOffboardingAmount, showAlertBox } = state.ui.configureAppForOffboardingPopup
  const { id: idOrg } = getCurrentOrg(state)
  const action = actions && actions.length > 0 ? actions[0] : EMPTY_OBJECT
  const actionsConfig = getFlattenActionsConfig(state)
  const fieldsDefaultValues = getFieldsDefaultValues(state)
  const actionsByType = getActionsConfigByType(state)
  const personalization = getPersonalizationByTriggerAndAction(state, TRIGGER_TYPE, WORKFLOW_ACTION_TYPES.REMOVE_USER)

  const config = getConstantServices(state)
  const isConfigLoaded = config.length > 0
  const isServicesLoaded = !!getSyncStatus(state)
  const isAllDataLoaded = (isServicesLoaded && isConfigLoaded)
  const services = isAllDataLoaded ? getActionableServices(state) : []
  const service = services.find(service => service.idApp === idApp && service.isConnected) || services.find(service => service.idApp === idApp) || {}
  const { name: appName } = deprecatedGetAppsByIds(state)[idApp] || EMPTY_OBJECT

  const actionsByApp = getSelectOptionsOfActionsByAppFor(state) || {}
  const createTicketActionsByOffboardingType = getCreateTicketActionsByOffboardingType(state)

  const automaticOffboaringActionSelectOptions = getAutomaticActionList({
    idApp,
    idAppAccount,
    actionsByApp,
    createTicketActions: createTicketActionsByOffboardingType[OFFBOARDING_METHOD_TYPE.AUTOMATIC]
  })
  const autoSelectSingleAutomaticAction = createAction(TRIGGER_TYPE, (automaticOffboaringActionSelectOptions.length === 1 ? automaticOffboaringActionSelectOptions[0] : EMPTY_OBJECT).value, { actionsConfig, fieldsDefaultValues, actionsByType }) || EMPTY_OBJECT

  const delegateOffboaringActionSelectOptions = getDelegateActionsList({ actionsByApp, createTicketActions: createTicketActionsByOffboardingType[OFFBOARDING_METHOD_TYPE.DELEGATE] })

  const app = {
    idApp,
    idAppAccount,
    name: service.name || appName,
    appAndAccountName,
    isConnected: Boolean(service.isConnected),
    source: service.source || ''
  }

  return {
    triggerType: TRIGGER_TYPE,
    action,
    autoSelectSingleAutomaticAction,
    automaticOffboaringActionSelectOptions,
    delegateOffboaringActionSelectOptions,
    fieldsDefaultValues,
    actionsConfig,
    actionsByType,
    idOrg,
    isOpen,
    app,
    isConnectSourceOpen: state.ui.isConnectSourceOpen,
    onConfig,
    personalization,
    onClose,
    pendingOffboardingAmount,
    showAlertBox
  }
}

const mapDispatchToProps = {
  toggleConfigureAppForOffboarding,
  getWorkflowsActionsConfig,
  getWorkflowsTriggersConfig,
  createWorkflow,
  updateWorkflow,
  validateWorkflow,
  toggleConnectSource,
  getServicesConfig,
  getServicesSyncData,
  getWorkflowsPersonalizationsConfig
}

const WORKFLOW_ID_APP = getSourceByType('torii').idApp

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...ownProps,
  ...omit(stateProps, ['fieldsDefaultValues']),
  ...dispatchProps,
  cancel () {
    stateProps.onClose()
    dispatchProps.toggleConfigureAppForOffboarding({ isOpen: false })
  },
  close () {
    stateProps.onClose()
    dispatchProps.toggleConfigureAppForOffboarding({ isOpen: false, isByUser: false })
  },
  async setupActionWorkflow ({ action, executeWorkflow }) {
    const { app, idOrg, triggerType } = stateProps
    const { workflow } = await dispatchProps.createWorkflow({ idOrg, type: WORKFLOW_TYPES.offboarding, triggerIdApp: app.idApp, triggerIdAppAccount: app.idAppAccount })
    workflow.triggerType = triggerType
    workflow.isActive = true
    workflow.name = `${app.name} offboarding`
    workflow.triggerConfiguration = []
    workflow.triggerIdApp = app.idApp
    workflow.triggerIdAppAccount = app.idAppAccount
    workflow.idApp = WORKFLOW_ID_APP
    workflow.actions = createActionsWithASingleAction(action)
    return dispatchProps.updateWorkflow({ idOrg, idWorkflow: workflow.id, workflow, executeWorkflow })
  },
  async validateWorkflow ({ action }) {
    const { idOrg, triggerType } = stateProps
    const workflow = {
      triggerType,
      idApp: WORKFLOW_ID_APP,
      actions: [action]
    }
    return dispatchProps.validateWorkflow({ idOrg, workflow })
  },
  createAction (actionType) {
    return createAction(TRIGGER_TYPE, actionType, stateProps)
  }
})

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(View)
