import React from 'react'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { toggleRenewalForecastNotSupportedPopup } from '@actions/'
import { useDispatch, useSelector } from 'react-redux'
import { getRenewalForecastNotSupportedPopup } from '@selectors/ui'
import Analytics from '@helpers/analytics'
import { Body2 } from '@toriihq/design-system'

const RenewalForecastNotSupportedPopup = (): JSX.Element => {
  const dispatch = useDispatch()

  const { isOpen, appName } = useSelector(getRenewalForecastNotSupportedPopup)
  const closeButtonText = 'Close'

  const closePopup = () => {
    dispatch(toggleRenewalForecastNotSupportedPopup({ isOpen: false }))
  }

  const trackButtonClick = (buttonLabel: string) => {
    Analytics.track('Click on button', {
      'Dialog name': 'RenewalForecastNotSupportedPopup',
      'Button label': buttonLabel,
      'App name': appName
    })
  }
  const onClose = () => {
    trackButtonClick(closeButtonText)
    closePopup()
  }

  return (
    <ToriiPopup
      isOpen={isOpen}
      onCloseAction={onClose}
      width='600px'
    >
      <ToriiPopup.Header
        header={`Renewal Forecast not supported for this setup`}
      />
      <ToriiPopup.Content>
        <Body2>
          It looks like {appName} users are coming from multiple accounts.<br />
          Unfortunately, Torii doesn't yet support renewal forecasts for this setup.<br />
          We're actively exploring ways to enhance this functionality.
        </Body2>
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        showCancelButton
        cancelButtonAction={onClose}
        cancelButtonText={closeButtonText}
        showMainButton={false}
      />
    </ToriiPopup>
  )
}

export default RenewalForecastNotSupportedPopup
