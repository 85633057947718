import styled from 'styled-components'

export const EmptyStateContainer = styled.div`
      display: flex;
      justify-content: center;
      align-items: center;
`

export const EmptyStateDescription = styled.div`
      width: 1084px;
      height: 40px;
`
