import styled, { css, keyframes } from 'styled-components'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const AnimatedDashboardTitle = styled.div`
  animation: ${fadeIn} 0.4s ease-in;
`

export const DashboardChangesText = styled.div`
  color: ${({ theme }) => theme.palette.text.tertiary};
`

export const DashboardChangesContainer = styled.div<{ isEditMode: boolean }>`
  ${({ isEditMode }) => isEditMode && css`
    border: 2px dashed ${({ theme }) => theme.palette.border.focus};
    padding: ${({ theme }) => theme.spacing('space-200')};
    border-radius: ${({ theme }) => theme.corner('radius-150')};
  `}`

export const ButtonsContainer = styled.div<{ scrolledDown: boolean }>`
  position: sticky;
  top: 0;
  z-index: 4;
  background-color: ${({ theme }) => theme.palette.background.primary};
  border-bottom: ${({ theme, scrolledDown }) => (scrolledDown ? `1px solid ${theme.palette.border.secondary}` : 'none')};
  padding: ${({ theme }) => theme.spacing('space-400')};
  padding-right: 0;
`

export const DashboardContentElement = styled.div`
  height: 100%;
`

export const ReminderWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing('space-125', 'space-0')};
  margin-bottom: ${({ theme }) => theme.spacing('space-200')};
`
