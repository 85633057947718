import { css } from 'glamor'
import { hoverActions } from '@shared/style/mixins'
import { fontSize } from '@shared/style/sizes'

const style = (theme, noBorder = false) => ({
  workflowActions: css(hoverActions, {
    opacity: noBorder ? undefined : 1,
    display: 'flex',
    gap: noBorder ? '0px' : theme.spacing(['space-100']),
    justifyContent: 'flex-end',
    fontSize: fontSize.small
  })
})

export default style
