import View from './view'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import withTracker from '../../helpers/withTracker'
import { isFeatureEnabled } from '@lenses/features'

const mapStateToProps = (state) => ({
  isAccessReviewsEnabled: isFeatureEnabled(state, 'accessReviews')
})

export default withRouter(withTracker(connect(mapStateToProps)(View)))
