import { withRouter } from 'react-router-dom'
import View from './view'
import { connect } from 'react-redux'
import { getSelf, getIsOnlyAppOwner, getUserScopes } from '@selectors/me'

const mapStateToProps = (state) => {
  const me = getSelf(state)
  const isOnlyAppOwner = getIsOnlyAppOwner(state)
  const userScopes = getUserScopes(state)

  return {
    me,
    isOnlyAppOwner,
    userScopes
  }
}

export default withRouter(connect(mapStateToProps)(View))
