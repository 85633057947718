import React from 'react'
import { Menu, Tooltip, Stack, Icon, Side } from '@toriihq/design-system'
import AccessControl from '@lenses/accessControl'
import { getPlanForFeatureTooltipLabel } from '@shared/features'
import { MenuItemWithTooltipContainer } from './styles'

type MenuItemProps = React.ComponentProps<typeof Menu.Item>

interface Props extends MenuItemProps {
  feature: string
  tooltipPlacement?: Side
}

const MenuItemOfFeature = ({
  feature,
  tooltipPlacement,
  ...menuItemProps
}: Props): JSX.Element => {
  const isFeatureEnabledInPlan = AccessControl.useIsFeatureEnabledInPlan({ feature })
  const tooltip = getPlanForFeatureTooltipLabel(feature)

  return (
    <Tooltip
      placement={tooltipPlacement}
      label={tooltip}
      hide={isFeatureEnabledInPlan || !tooltip}>
      <MenuItemWithTooltipContainer>
        <Menu.Item
          {...menuItemProps}
          disabled={menuItemProps.disabled || !isFeatureEnabledInPlan}
        >
          <Stack direction='row' gap='space-050' justifyContent='space-between'>
            <div>{menuItemProps.children}</div>
            {!isFeatureEnabledInPlan && <Icon color='disabled' name='Lock' />}
          </Stack>
        </Menu.Item>
      </MenuItemWithTooltipContainer>
    </Tooltip>
  )
}

export default MenuItemOfFeature
