import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const useUnsavedChanges = (hasUnsavedChanges: boolean) => {
  const history = useHistory()

  const [navigationInfo, setNavigationInfo] = useState<{ navigateTo: string | null; shouldBeNavigated: boolean }>(
    { navigateTo: null, shouldBeNavigated: false }
  )
  const [shouldShowUnsavedChangesPopup, setShouldShowUnsavedChangesPopup] = useState(false)

  useEffect(() => {
    const unblock = history.block((location) => {
      if (hasUnsavedChanges) {
        setShouldShowUnsavedChangesPopup(true)
        setNavigationInfo({ navigateTo: location.pathname, shouldBeNavigated: false })
        return false
      }
      return true
    })

    if (navigationInfo.shouldBeNavigated && navigationInfo.navigateTo) {
      history.push(navigationInfo.navigateTo)
      setNavigationInfo({ navigateTo: null, shouldBeNavigated: false })
    }

    return () => unblock()
  }, [history, hasUnsavedChanges, navigationInfo, setShouldShowUnsavedChangesPopup])

  const confirmNavigation = () => {
    if (navigationInfo.navigateTo) {
      setNavigationInfo((prevState) => ({ ...prevState, shouldBeNavigated: true }))
    }
    setShouldShowUnsavedChangesPopup(false)
  }

  const cancelNavigation = () => {
    setShouldShowUnsavedChangesPopup(false)
  }

  const handleCancel = () => {
    setNavigationInfo(prevState => ({ ...prevState, navigateTo: null }))
    setShouldShowUnsavedChangesPopup(true)
  }

  return { shouldShowUnsavedChangesPopup, handleCancel, confirmNavigation, cancelNavigation }
}

export default useUnsavedChanges
