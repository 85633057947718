import React, { Fragment, ReactElement } from 'react'
import { Form } from 'react-final-form'
import { Divider, Spacer, Subtitle1 } from '@toriihq/design-system'
import { ops } from '@root/lenses/filters'
import { FormFieldWrapper } from './styles'
import { noop } from 'lodash'
import { renderField } from './mapping/fieldMapping'

const EditWidgetForm = React.memo((props: any): ReactElement => {
  const { widget, sections, initialValues, onFieldChange, filtersOptions, fieldOptionsValuesPerKey, fetchFieldValues } = props

  const shouldShowField = (field, formValues) => {
    const condition = field.showOnFieldSpecificCondition

    if (!condition) {
      return true
    }

    const { comparisonType, fieldKey, op, value } = condition
    const dependsOnFieldValue = formValues[fieldKey]

    if (comparisonType === 'type') {
      if (dependsOnFieldValue?.type) {
        return op === ops.anyOf
          ? value.includes(dependsOnFieldValue.type)
          : dependsOnFieldValue.type === value
      }
      return true
    }

    return op === ops.equals
      ? dependsOnFieldValue === value
      : dependsOnFieldValue !== value
  }

  return (
    <Form
      onSubmit={noop}
      initialValues={initialValues}
      render={(formProps) => {
        const { handleSubmit, values } = formProps

        return (
          <Fragment>
            <form onSubmit={handleSubmit}>
              {sections.map((section, index) => {
                const isLastSection = index === sections.length - 1

                return <Fragment key={section.key}>
                  {section.label && <Spacer bottom='space-100'>
                    <Subtitle1>{section.label}</Subtitle1>
                  </Spacer>}

                  {section.fields.map((field) => {
                    if (!shouldShowField(field, values)) {
                      return null
                    }

                    return (
                      <FormFieldWrapper key={field.key}>
                        {renderField({ widget, field, filtersOptions, fieldOptionsValuesPerKey, fetchFieldValues, onFieldChange })}
                      </FormFieldWrapper>
                    )
                  })}

                  {!isLastSection && (
                    <FormFieldWrapper>
                      <Divider orientation='Vertical' />
                    </FormFieldWrapper>
                  )}
                </Fragment>
              }) }
            </form>
          </Fragment>
        )
      }}
    />

  )
})

export default EditWidgetForm
