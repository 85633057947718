export enum CONTENT_TOOLTIP_TYPES {
  CATEGORIES = 'categories',
  KEY_VALUE = 'keyValue',
  BASIC = 'basic'
}

export interface DashboardBarChartProps {
  fill: string;
  formatter?: (value: any) => string;
  barSize?: number;
  isAnimationActive?: boolean;
  data?: Array<{
    value: number;
    name: string;
  }>;
  tooltipType?: CONTENT_TOOLTIP_TYPES;
  keyValueFormatter?: (value: any) => string;
  isVertical: boolean;
  customTooltip?: any;
  onBarClick?: (data: any) => void;
}

export enum AXIS {
  X = 'X',
  Y = 'Y'
}
export interface CustomAxisProps {
  x?: number
  y?: number
  payload?: { value: any }
  formatter?: (value: any) => string
  axis: AXIS
  width?: number
}
