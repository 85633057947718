import { ReactElement, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AdvancedCard } from '@toriihq/design-system'
import { FixedWidgetProps } from '@components/dashboard/widgets/types'
import * as Style from './style'
import Placeholder from '@components/placeholder'
import { getUsersDistributionTrends, isLoadingUsersDistributionTrends } from '@root/store/selectors/trends'
import MapChart from '@root/components/charts/usersMap/mapChart'
import { getCurrentOrg } from '@root/store/selectors/org'
import { getUsersDistribution } from '@root/store/actions/actions'

const UsersMapWidget = (props: FixedWidgetProps): ReactElement => {
  const { isSelected, widget, primaryButton, isWidgetMenuOpen, onFixedWidgetFilteredDrillDown } = props
  const data = useSelector(getUsersDistributionTrends)
  const isLoading = useSelector(isLoadingUsersDistributionTrends)

  const { id: idOrg } = useSelector(getCurrentOrg)
  const dispatch = useDispatch()

  useEffect(() => {
    if (idOrg) {
      dispatch(getUsersDistribution({ idOrg }))
    }
  }, [dispatch, idOrg])

  const onCountryClick = ({ countryName, countryCode }) => {
    onFixedWidgetFilteredDrillDown && onFixedWidgetFilteredDrillDown({ widget, countryName, countryCode })
  }

  return (
    <AdvancedCard
      title={widget?.displayConfig?.label}
      selected={isSelected}
      primaryButton={primaryButton}
      showPrimaryButton={isWidgetMenuOpen}
    >
      <Placeholder loading={isLoading} type='rect' style={Style.Placeholder}>
        <Style.Container>
          <MapChart data={data} loading={isLoading} onCountryClick={onCountryClick} />
        </Style.Container>
      </Placeholder>
    </AdvancedCard>
  )
}

export default UsersMapWidget
