import React, { createRef } from 'react'
import { withRouter } from 'react-router-dom'
import { Field } from 'react-final-form'
import { Body1, FormElement, Input, Stack, H3 } from '@toriihq/design-system'

const validatePassword = (password) => {
  const MIN_LENGTH = 12
  const MAX_LENGTH = 50
  if (!password) {
    return 'Please choose a password'
  }

  if (password.toString().length < MIN_LENGTH) {
    return `Try something a bit longer (at least ${MIN_LENGTH} characters)`
  }

  if (password.toString().length > MAX_LENGTH) {
    return `Try something a bit shorter (up to ${MAX_LENGTH} characters)`
  }

  const hasUppercase = /[A-Z]/.test(password)
  if (!hasUppercase) {
    return 'Password must contain at least one uppercase letter'
  }

  const hasLowercase = /[a-z]/.test(password)
  if (!hasLowercase) {
    return 'Password must contain at least one lowercase letter'
  }

  const hasNumber = /[0-9]/.test(password)
  if (!hasNumber) {
    return 'Password must contain at least one number'
  }

  const hasSpecialChar = /[^A-Za-z0-9]/.test(password)
  if (!hasSpecialChar) {
    return 'Password must contain at least one special character'
  }
}

class SetPasswordSignupStep extends React.Component {
  input = {
    password: createRef()
  }

  renderField = (props) => {
    const { meta, input, ...rest } = props
    return (
      <FormElement
        label={rest.placeholder}
        errorMessage={meta.touched && Boolean(meta.error) ? meta.error : null}
      >
        <Input
          ref={this.input[input.name]}
          hasError={meta.touched && Boolean(meta.error)}
          suffix='Lock'
          autoFocus
          {...input}
          {...rest}
        />
      </FormElement>
    )
  }

  render () {
    const { isSAML } = this.props

    return (
      <Stack direction='column' gap='space-500'>
        {isSAML && (
          <Stack direction='column' gap='space-0'>
            <H3>Complete Sign Up</H3>
            <Body1>You will be able to connect using your company's Single Sign On solution</Body1>
          </Stack>
        )}

        {!isSAML && (
          <Stack direction='column' gap='space-0'>
            <H3>Please choose your password</H3>
            <Body1>
              - At least 12 characters long<br />
              - One uppercase letter<br />
              - One lowercase letter<br />
              - One number<br />
              - One special character (@, #, $, etc.)
            </Body1>
          </Stack>
        )}

        <fieldset>
          {!isSAML && (
            <Field
              name='password'
              type='password'
              placeholder='Choose Password'
              component={this.renderField}
              validate={validatePassword}
            />
          )}
        </fieldset>
      </Stack>
    )
  }
}

export default withRouter(SetPasswordSignupStep)
