export const KeyOpValueVerticalFilterCSS = {
  selectKey: {
    width: '100%'
  },
  propertyInput: {
    width: '100%'
  },
  values: {
    width: '100%'
  },
  relativeDayValue: {
    width: '100%'
  }
}
