import styled, { css } from 'styled-components'
import NumberFormat from 'react-number-format'
import colors from '../../../../shared/style/colors'
import { fontSize } from '../../../../shared/style/sizes'

export const StyledNumberFormat = styled(NumberFormat)<{ $isRowHovered?: boolean }>`
  border: none;
  transition: outline .2s, box-shadow .2s, background-color .2s;
  border-radius: ${({ theme }) => theme.corner('radius-100')};
  outline: 1px solid ${({ theme }) => theme.palette.border.primary} !important;
  
  &:hover:not([disabled]) {
    outline: 1px solid ${({ theme }) => theme.palette.border.primaryHover} !important;
  }
  
  &:focus-visible:not([disabled]) {
    outline: 1px solid ${({ theme }) => theme.palette.border.active} !important;
    box-shadow: 0 0 0 3px ${({ theme }) => theme.palette.border.focus} !important;
  }
  
  &[disabled] {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.palette.background.disabledLight} !important;
    color: ${({ theme }) => theme.palette.text.textOnDisabled};
    &::placeholder {
      color: ${({ theme }) => theme.palette.text.textOnDisabled};
    }
  }
  
  text-align: right;
  font-size: ${fontSize.small};
  margin-right: -12px;
  height: 28px;
  padding-right: 10px;
  background-color: ${props => props.$isRowHovered ? colors.white : colors.transparent};
  padding-left: 10px;
  border: 1px solid ${colors.grey2};
  
  &:focus {
    background-color: ${colors.white};
  }
  
  &::placeholder {
    text-align: left;
  }
  
  ${props => !props.$isRowHovered && css`
    background-color: ${colors.transparent};
    border-color: ${colors.transparent};
  `}
`
