import colors from '@root/shared/style/colors'
import texts from '@root/shared/style/texts'
import { css } from 'glamor'

export const CSS = {
  mainArea: css({
    width: '100%',
    height: '700px',
    gap: 0,
    '& .tether-out-of-bounds-top': {
      display: 'none'
    }
  }),
  errorMessage: css(texts.body, {
    color: colors.red,
    marginTop: '5px'
  }),
  AiAlertBoxSpace: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    paddingBottom: '24px'
  })
}
