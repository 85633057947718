import { css } from 'glamor'
import colors from '@shared/style/colors'
import texts from '@shared/style/texts'
import { fontSize } from '@shared/style/sizes'
import styled from 'styled-components'

export const Main = css({
  position: 'relative',
  width: '197px',
  height: '207px',
  border: `1px solid ${colors.border}`,
  borderRadius: '4px',
  backgroundColor: colors.white,
  transition: 'box-shadow .2s',
  ':hover': {
    boxShadow: '0 9px 12px 0 rgba(0,0,0,0.05)'
  }
})

export const Container = css({
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '4px',
  height: '100%'
})

const Logo = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '195px',
  height: '150px',
  borderBottom: `1px solid ${colors.border}`
})

export const NativeIntegrationLogo = css(Logo, {
  backgroundColor: colors.white
})

export const CustomIntegrationLogo = css(Logo, {
  backgroundColor: colors.lightBlue5
})

export const CustomIntegrationAppImage = css({
  border: `1px solid #E6EBF4`,
  borderRadius: '4px',
  padding: 20,
  backgroundColor: colors.white
})

export const Card = css({
  height: '100%',
  width: '100%',
  padding: '0 20px',
  background: colors.white
})

export const CardTop = css(texts.subheading, {
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  height: '55px',
  alignItems: 'center',
  color: colors.darkText,
  '&:after': {
    content: `''`,
    position: 'absolute',
    height: '1px',
    width: '155px',
    bottom: '-1px',
    left: 0,
    right: 0,
    backgroundColor: colors.border
  }
})

export const CardContent = css({
  height: '150px'
})

export const NotConnected = css({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

export const Tile = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  padding: '10px 0'
})

export const StatusContainer = css({
  display: 'flex',
  maxHeight: '75%',
  paddingBottom: '5%',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'stretch'
})

export const Status = css(texts.body, {
  overflow: 'auto'
})

export const InfoIcon = css({
  display: 'none',
  [`.${Main}:hover &, [data-${Main}]:hover &`]: {
    display: 'block'
  }
})

export const AppAccountIDContainer = css({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 5,
  height: '32px'
})

export const Actions = css({
  display: 'flex',
  height: '25%',
  flexDirection: 'row',
  gap: '8px',
  justifyContent: 'center',
  width: 'max-content',
  alignSelf: 'center'
})

export const Spinner = css({
  display: 'flex',
  alignItems: 'center'
})

export const StatusIcon = css({
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  top: '-15px',
  left: '-15px',
  width: '30px',
  height: '30px',
  borderRadius: '100%',
  backgroundColor: colors.background
})

export const SyncButton = css({
  minWidth: '75px',
  minHeight: '25px',
  padding: 0,
  marginLeft: '3px'
})

export const AccountName = styled.span`
  color: ${props => props.theme.palette.text.tertiary};
`

export const AccountNameContainer = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
})

export const MoreDetailsButton = styled.div`
  color: ${props => props.theme.palette.text.interactive};
  width: 105px;
  margin-top: 4px;
  &:hover {
    cursor: pointer;
  }
`

export const Name = (isConnected) => css({
  display: 'block',
  width: '100%',
  lineHeight: '19px',
  ...(isConnected
    ? { [`.${Main}:hover &, [data-${Main}]:hover &`]: {
      width: '90%'
    } } : {})
})

export const NameAndLock = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
})

export const LockIcon = css({
  position: 'relative',
  top: '-1px'
})

export const CardPosition = css({
  position: 'absolute',
  top: '0',
  transform: 'translateY(150px)',
  transition: 'transform .2s',
  [`.${Main}:hover &, [data-${Main}]:hover &`]: {
    transform: 'translateY(0px)'
  }
})

export const DropDownMenuOpen = css({
  transform: 'translateY(0px)'
})

export const menu = css({
  flexDirection: 'row-reverse',
  height: '25px',
  alignItems: 'center',
  justifyContent: 'space-between',
  display: 'inline',
  '>div': {
    display: 'flex',
    justifyContent: 'center'
  }
})

export const nativeIntegrationUl = ({
  width: '165px',
  marginRight: '20px',
  borderRadius: '4px'
})

export const customIntegrationUl = ({
  width: '125px',
  marginRight: '61px',
  borderRadius: '4px'
})

export const menuItem = css({
  display: 'flex',
  alignItems: 'center'
})

export const menuItemSpan = css({
  marginLeft: '10px',
  fontSize: '14px'
})

export const Tooltip = css({
  display: 'none',
  margin: 0,
  transform: 'translateY(13%)',
  fontSize: fontSize.medium,
  color: colors.darkText,
  [`.${Main}:hover &, [data-${Main}]:hover &`]: {
    display: 'block'
  }
})

export const CopyText = css(texts.body, {
  padding: '3.5px 0px 3.5px 14px'
})

export const BetaBadge = css({ position: 'absolute', right: '10px', top: '-2px', width: 60 })
