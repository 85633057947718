import React, { ReactElement } from 'react'
import ToriiPopup from '../ToriiPopupV2'
import { useSelector, useDispatch } from 'react-redux'
import * as Style from './style'
import { toggleSyncStatusPopup } from '@actions/'
import { syncStatusByIdAppAccount } from '@selectors/services'
import { SYNC_STATUS } from '@root/constants'
import { CAPABILITY_TO_NAME } from '@components/testConnection/constants'
import { SyncStatusResult } from './types'
import { AppIcon } from '@toriihq/design-system'
import {
  statusToBanner,
  renderInfoBox,
  renderInfoBoxForResourcesCapability,
  renderInfoBoxForRealTimeEvents
} from './utils'

const SyncStatusPopup = (): JSX.Element => {
  const { isOpen, idAppAccount, connectByMessage, connectedAt }: { isOpen: boolean, idAppAccount: number, connectByMessage: ReactElement, connectedAt: string } = useSelector((state: { ui: { syncStatusPopup: { isOpen: boolean, idAppAccount: number, connectByMessage: ReactElement, connectedAt: string } } }) => state.ui.syncStatusPopup)

  const syncStatus = useSelector(syncStatusByIdAppAccount(idAppAccount, connectedAt)) as SyncStatusResult

  const { breakdown, name, imageUrl, licensesManagedManually, lastUsersAndLicensesFileLastSyncTime } = syncStatus

  const dispatch = useDispatch()

  const closePopup = () => dispatch(toggleSyncStatusPopup({ isOpen: false }))

  const renderAppNameAndIcon = () => (
    <Style.AppNameAndIconContainer>
      <AppIcon appName={name} appImageUrl={imageUrl} size='medium' />
      <Style.AppName>{name}</Style.AppName>
    </Style.AppNameAndIconContainer>
  )

  const { users, licenses, thirdPartyApps, usage, expenses, contracts, realTimeEvents } = breakdown

  const isUsersFailed = (users?.status.statusToShow === SYNC_STATUS.FINISHED_FAILED)
  const isExpensesFailed = (expenses?.status.statusToShow === SYNC_STATUS.FINISHED_FAILED)
  const isMandatoryDataFailed = isUsersFailed || isExpensesFailed

  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={closePopup} closeOnOverlayClick width='690px'>
      <ToriiPopup.Header header={renderAppNameAndIcon()} subHeader={connectByMessage} />
      <ToriiPopup.Content contentAreaStyle={{ padding: '0px 30px 25px' }}>
        {statusToBanner[breakdown.overallStatus]}
        {expenses &&
            renderInfoBox({
              capability: CAPABILITY_TO_NAME.expenses,
              status: expenses.status,
              isMandatoryDataFailed,
              lastSyncTime: expenses.status.lastSyncTime
            })
        }
        {contracts &&
            renderInfoBox({
              capability: CAPABILITY_TO_NAME.contracts,
              status: contracts.status,
              isMandatoryDataFailed,
              lastSyncTime: contracts.status.lastSyncTime
            })
        }
        {users &&
            renderInfoBox({
              capability: CAPABILITY_TO_NAME.users,
              status: users.status,
              isMandatoryDataFailed,
              lastSyncTime: users.status.lastSyncTime
            })
        }
        {licenses &&
            renderInfoBox({
              capability: CAPABILITY_TO_NAME.licenses,
              status: licenses.status,
              isMandatoryDataFailed,
              lastSyncTime: licenses.status.lastSyncTime,
              licensesManagedManually,
              lastUsersAndLicensesFileLastSyncTime
            })
        }
        {thirdPartyApps &&
            renderInfoBoxForResourcesCapability({
              capability: CAPABILITY_TO_NAME.thirdPartyApps,
              statusByResource: thirdPartyApps.statusByResource,
              usageOverallStatus: thirdPartyApps.overallStatus,
              isMandatoryDataFailed,
              lastSyncTime: thirdPartyApps.lastSyncTime,
              connectedAt
            })
        }
        {usage &&
            renderInfoBoxForResourcesCapability({
              capability: CAPABILITY_TO_NAME.usage,
              statusByResource: usage.statusByResource,
              usageOverallStatus: usage.overallStatus,
              isMandatoryDataFailed,
              lastSyncTime: usage.lastSyncTime,
              connectedAt
            })
        }
        {realTimeEvents &&
            renderInfoBoxForRealTimeEvents({
              status: realTimeEvents.status,
              isMandatoryDataFailed
            })
        }
      </ToriiPopup.Content>
    </ToriiPopup>
  )
}

export default SyncStatusPopup
