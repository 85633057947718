import React, { ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import AppDetails from '@components/appDetails'
import EnableFor from '@components/enableFor'
import { SCOPES } from '@root/constants'
import { Container, Card, RadioButtonsContainer, RadioButtonContainer, RadioText } from './styles'
import { getAppsLicenses } from '@actions/'
import { useSelector, useDispatch } from 'react-redux'
import { getAppsLicensesSelector, getAppsLicensesLoadingSelector } from '@selectors/apps'
import Placeholder from '@components/placeholder'
import { AppWithLicenses, IdLicense } from './types'
import { useDebouncedCallback } from '@shared/hooks/useDebouncedCallback'
import { Checkbox } from '@toriihq/design-system'

interface Props {
  value: IdLicense[]
  onChange: (value: IdLicense[], locally?: boolean) => void
}

const LicensesSelectV2 = ({
  value,
  onChange
}: Props): ReactElement => {
  const dispatch = useDispatch()
  const { idOrg } = useParams()
  const appsLicenses: AppWithLicenses[] = useSelector(getAppsLicensesSelector)
  const loadingAppsLicenses: boolean = useSelector(getAppsLicensesLoadingSelector)

  useEffect(() => {
    if (idOrg) {
      dispatch(getAppsLicenses({ idOrg }))
    }
  }, [idOrg, dispatch])

  const debouncedOnChange = useDebouncedCallback(onChange, 2000, { cancelOnDestroy: false })

  const onSelectionChange = (idLicense: IdLicense, selected: boolean) => {
    let newValue: IdLicense[]
    if (selected) {
      newValue = [...value, idLicense]
    } else {
      newValue = value.filter(id => id !== idLicense)
    }

    onChange(newValue, true)
    debouncedOnChange(newValue, false)
  }

  const getLicenses = () => {
    return appsLicenses.map(app => {
      const anyLicenseSelected = app.licenses.some(license => value.includes(license.idLicense))

      const updatedLicenses = app.licenses.map(license => ({
        ...license,
        isSelected: value.includes(license.idLicense)
      }))

      return {
        ...app,
        anyLicenseSelected,
        licenses: updatedLicenses
      }
    })
  }

  const licensesPerApp = getLicenses()
  const loading = loadingAppsLicenses && licensesPerApp.length === 0

  return (
    <Placeholder loading={loading} rows={20}>
      <Container>
        {licensesPerApp.map(({ id, name, category, imageUrl, licenses }) =>
          <Card key={`app-license-${id}`}>
            <div>
              <AppDetails
                id={id}
                name={name}
                category={category}
                imageUrl={imageUrl}
                component='Workflow user stopped using license' />
            </div>
            <RadioButtonsContainer>
              {licenses.map(license =>
                <RadioButtonContainer key={license.idLicense}>
                  <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
                    <Checkbox checked={license.isSelected} onChange={(e) => onSelectionChange(license.idLicense, e.target.checked)} />
                  </EnableFor>
                  <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
                    <RadioText onClick={() => onSelectionChange(license.idLicense, !license.isSelected)}>{license.name}</RadioText>
                  </EnableFor>
                </RadioButtonContainer>
              )}
            </RadioButtonsContainer>
          </Card>
        )}
      </Container>
    </Placeholder>

  )
}

export default LicensesSelectV2
