import type { UiAction, UiState } from './types'
import { GET_TRANSACTIONS_METADATA } from '@root/constants.t'

const uiReducerTransactionsExpander = (state: UiState, action: UiAction): UiState => {
  switch (action.type) {
    case `${GET_TRANSACTIONS_METADATA}_PENDING`: {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          loading: true
        }
      }
    }
    case `${GET_TRANSACTIONS_METADATA}_FAILED`: {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          loading: false
        }
      }
    }
    case `${GET_TRANSACTIONS_METADATA}_RESPONSE`: {
      const { predefinedFields, customFields } = action.payload
      return {
        ...state,
        metadata: {
          loading: false,
          predefinedFields,
          customFields
        }
      }
    }

    default: {
      return state
    }
  }
}

export default uiReducerTransactionsExpander
