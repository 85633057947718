import styled from 'styled-components'

export const Main = styled.div`
  border-radius: 4px;
`

export const BottomActions = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`
