import { createSelector } from 'reselect'
import get from 'lodash/get'
import identity from 'lodash/identity'
import { Dashboard, DashboardData, ENTITY_TYPES } from '@reducers/dashboards/types'
import { State } from '@reducers/types'

export const getDashboards = createSelector<State, Dashboard[], Dashboard[]>(
  state => get(state, ['dashboards', 'dashboardsConfig']),
  identity
)

export const getIsLoadingDashboards = createSelector<State, Dashboard[], Dashboard[]>(
  state => get(state, ['dashboards', 'isLoading']),
  identity
)

export const getDashboardsData = createSelector<State, DashboardData, DashboardData>(
  state => get(state, ['dashboards', 'dashboardsData']),
  identity
)

const entityTypes = Object.values(ENTITY_TYPES)
export const getEntitiesMetadataByEntityType = createSelector<State, any, any>(
  state => entityTypes.map(entityType => get(state, [entityType, 'metadata'])),
  (entitiesMetadata) => {
    return entityTypes.reduce((acc, entity, i) => {
      acc[entity] = entitiesMetadata[i]
      return acc
    }, {})
  }
)
