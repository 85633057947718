import Analytics from '@helpers/analytics'

export const ANALYTICS_EVENTS = {
  ADD_LINE_ITEM: 'Add contract-line-item',
  REMOVE_LINE_ITEM: 'Remove contract-line-item'
} as const

export const analytics = {
  onAddLineItem: (appName: string) => {
    Analytics.track(ANALYTICS_EVENTS.ADD_LINE_ITEM, {
      'App name': appName
    })
  },
  onRemoveLineItem: (appName: string) => {
    Analytics.track(ANALYTICS_EVENTS.REMOVE_LINE_ITEM, {
      'App name': appName
    })
  }
}
