import React from 'react'
import Input from '../../form/input'
import { css } from 'glamor'
import * as Style from './style'
import PropTypes from 'prop-types'
import { Button, ButtonType, ButtonSize } from '@toriihq/design-system'

const ActionButton = ({ iconName, onClick, disabled }) => (
  <Button type={ButtonType.compact} size={ButtonSize.small} onClick={onClick} icon={iconName} disabled={disabled} />
)

const KeyValueRow = ({ keyValueElement = {}, deleteRow, editRow, onBlur, ValueComponent = Input, keyComponentProps, valueComponentProps, ValueComponentWrapper = 'div', disabled = false, disallowedCharacters }) => {
  const onRemove = () => {
    deleteRow(keyValueElement)
  }
  const onChangeKey = (e) => {
    const key = e.target.value
    const regex = new RegExp(`[${(disallowedCharacters || []).join('')}]`, 'g')

    const sanitizedKey = disallowedCharacters ? (key || '').replace(regex, '-') : key
    editRow({ ...keyValueElement, key: sanitizedKey })
  }

  const onChangeValue = (e) => {
    const target = e.target || {}
    const value = target.value

    editRow({ ...keyValueElement, value })
  }

  const renderKeyInput = () => {
    return (
      <Input
        {...keyComponentProps}
        type='text'
        value={keyValueElement.key}
        onChange={onChangeKey}
        onBlur={onBlur}
        disabled={disabled}
      />
    )
  }

  const valueInputOnBlur = (e, clickedSuggestion) => {
    if (clickedSuggestion) {
      return
    }
    onBlur(e)
  }

  const renderValueInput = () => {
    return (
      <ValueComponentWrapper style={{ width: '100%' }}>
        <ValueComponent
          {...valueComponentProps}
          type='text'
          value={keyValueElement.value}
          onChange={onChangeValue}
          onBlur={valueInputOnBlur}
          disabled={disabled}
        />
      </ValueComponentWrapper>
    )
  }

  return (
    <div {...css(Style.Main)}>
      {renderKeyInput()}
      {renderValueInput()}
      <ActionButton onClick={onRemove} iconName='Trash' disabled={disabled} />
    </div>
  )
}

KeyValueRow.propTypes = {
  keyValueElement: PropTypes.object.isRequired,
  deleteRow: PropTypes.func.isRequired,
  editRow: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  ValueComponent: PropTypes.elementType,
  keyComponentProps: PropTypes.object,
  valueComponentProps: PropTypes.object,
  ValueComponentWrapper: PropTypes.elementType,
  disabled: PropTypes.bool,
  disallowedCharacters: PropTypes.arrayOf(PropTypes.string)
}

export default KeyValueRow
