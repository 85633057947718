import { NO_APP_ACCOUNT_ID, TORII_APP_ID } from '@root/constants'
import { APP_ACCOUNT_TYPE } from '@root/constants.t'
import { OffboardingApp } from './offboarding.types'

// PLEASE NOTICE: Updating this function requires updating the "shouldOffboardingAppWithAccountBeVisible" function in "torii-monorepo/projects/etl/functions/sync_appOrg_es/functions/offboarding/offboardingUtils.ts"
export const shouldOffboardingAppWithAccountBeVisible = ({
  id: idApp,
  type: accountType,
  idAppAccount,
  idAppToken,
  hasTokenWithoutAccount
}: OffboardingApp) => {
  const hasAppToken = Boolean(idAppToken)
  const isDefaultAppAccount = idAppAccount === NO_APP_ACCOUNT_ID

  return (
    (idApp === TORII_APP_ID && accountType !== APP_ACCOUNT_TYPE.MANUAL) ||
    hasAppToken ||
    isDefaultAppAccount ||
    hasTokenWithoutAccount
  )
}
