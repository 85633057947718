import keyBy from 'lodash/keyBy'
import isUndefined from 'lodash/isUndefined'
import omitBy from 'lodash/omitBy'
import {
  PARSE_EXPENSE_FILE,
  GET_PARSING_STATUS,
  PARSING_STATUS,
  GET_TRANSACTIONS,
  RESET_TRANSACTIONS,
  GET_SEARCHED_TRANSACTIONS,
  RESET_SEARCH_TRANSACTIONS,
  GET_TRANSACTIONS_BY_SOURCE,
  ARCHIVE_EXPENSES_TRANSACTION,
  UNARCHIVE_EXPENSES_TRANSACTION,
  EDIT_EXPENSES_TRANSACTION,
  GET_ORG_MATCHING_RULES,
  UPDATE_ORG_MATCHING_RULES,
  UPDATE_ORG_MATCHING_RULES_LOCALLY,
  GET_TRANSACTIONS_FIELD_VALUES,
  TOGGLE_MATCHING_RULES_POPUP,
  GET_LAST_12_MONTHS_TRANSACTIONS_AGGS,
  GET_TRANSACTIONS_AGGS,
  GET_LAST_YEAR_TOTAL_EXPENSES,
  GET_TRANSACTIONS_AGGS_BY_CATEGORY
} from '@root/constants'
import sumBy from 'lodash/sumBy'
import moment from 'moment'
import uiReducerTransactionsExpander from './expander'

const initialState = {
  loading: true,
  loadingMore: false,
  loadingWithoutDeduplication: false,
  uploadCounter: 0,
  transactions: [],
  resources: { users: {}, apps: {} },
  transactionsWithoutDeduplication: {},
  idUpload: undefined,
  matchingRules: {
    loading: false,
    rules: [],
    localRules: []
  },
  fieldValuesData: {
    fieldValues: {},
    resources: { users: {}, apps: {} }
  },
  aggs: {
    perApp: {},
    last12MonthsExpensesAggsPerApp: {},
    byCategory: [],
    global: [],
    lastYearTotalExpenses: 0
  },
  metadata: {
    loading: true,
    predefinedFields: [],
    customFields: []
  }
}

const transactionsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case RESET_TRANSACTIONS: {
      return {
        ...state,
        transactions: [],
        totalTransactions: 0
      }
    }
    case `${GET_TRANSACTIONS}_PENDING`: {
      const { reset } = action.meta
      return {
        ...state,
        loading: true,
        loadingMore: !reset
      }
    }
    case `${GET_TRANSACTIONS_FIELD_VALUES}_PENDING`: {
      return {
        ...state,
        loadingFieldValues: true
      }
    }
    case `${GET_SEARCHED_TRANSACTIONS}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case RESET_SEARCH_TRANSACTIONS: {
      return {
        ...state,
        searchedTransactions: {
          total: 0,
          transactions: []
        }
      }
    }
    case `${EDIT_EXPENSES_TRANSACTION}_PENDING`: {
      const { idTransaction, idApp, mappingStatus, mappingLogic, modifiedAmount, transactionDate, amount } = action.meta
      const updates = omitBy({ idApp, mappingStatus, mappingLogic, modifiedAmount, transactionDate, amount }, isUndefined)

      const modifiedTransactions = state.transactions.slice()
      const transactionIndex = modifiedTransactions.findIndex(t => t.id === idTransaction)
      modifiedTransactions[transactionIndex] = {
        ...modifiedTransactions[transactionIndex],
        ...updates
      }

      return {
        ...state,
        loading: true,
        transactions: modifiedTransactions
      }
    }
    case `${EDIT_EXPENSES_TRANSACTION}_RESPONSE`: {
      const { resources } = action.payload
      return {
        ...state,
        resources: {
          ...state.resources,
          apps: {
            ...state.resources.apps,
            ...resources.apps
          }
        }
      }
    }
    case `${PARSE_EXPENSE_FILE}_PENDING`: {
      return {
        ...state,
        parseData: {
          parsingStatus: PARSING_STATUS.parsingInProgress
        },
        loading: true
      }
    }

    case `${GET_TRANSACTIONS_AGGS}_PENDING`: {
      const { idApp, toDate, fromDate, viewName } = action.meta
      return {
        ...state,
        aggs: {
          ...state.aggs,
          perApp: {
            ...state.aggs.perApp,
            [idApp]: {
              ...state.aggs.perApp[idApp],
              toDate,
              fromDate,
              viewName,
              loading: true
            }
          }
        }
      }
    }
    case `${GET_TRANSACTIONS_AGGS}_RESPONSE`: {
      const { aggregations } = action.payload
      const { idApp, timestamp: newTimestamp } = action.meta
      const { timestamp: lastTimestamp } = state.aggs.perApp[idApp]
      const gotNewerSearchResult = lastTimestamp ? lastTimestamp < newTimestamp : true

      return {
        ...state,
        aggs: {
          ...state.aggs,
          perApp: {
            ...state.aggs.perApp,
            [idApp]: {
              ...state.aggs.perApp[idApp],
              ...(gotNewerSearchResult && {
                timestamp: newTimestamp,
                data: aggregations?.map(transformCountAggregation)
              }),
              loading: false
            }
          }
        }
      }
    }
    case `${GET_LAST_12_MONTHS_TRANSACTIONS_AGGS}_PENDING`: {
      const { idApp } = action.meta
      return {
        ...state,
        aggs: {
          ...state.aggs,
          last12MonthsExpensesAggsPerApp: {
            ...state.aggs.last12MonthsExpensesAggsPerApp,
            [idApp]: {
              data: state.aggs.last12MonthsExpensesAggsPerApp[idApp]?.data,
              loading: true
            }
          }
        }
      }
    }
    case `${GET_LAST_12_MONTHS_TRANSACTIONS_AGGS}_RESPONSE`: {
      const { idApp } = action.meta
      const { aggregations } = action.payload
      return {
        ...state,
        aggs: {
          ...state.aggs,
          last12MonthsExpensesAggsPerApp: {
            ...state.aggs.last12MonthsExpensesAggsPerApp,
            [idApp]: {
              data: aggregations?.map(transformCountAggregation),
              loading: false
            }
          }
        }
      }
    }

    case `${GET_TRANSACTIONS_AGGS_BY_CATEGORY}_PENDING`: {
      return {
        ...state,
        aggs: {
          ...state.aggs,
          byCategory: {
            ...state.aggs.byCategory,
            loading: true
          }
        }
      }
    }
    case `${GET_TRANSACTIONS_AGGS_BY_CATEGORY}_RESPONSE`: {
      const { aggregations } = action.payload
      return {
        ...state,
        aggs: {
          ...state.aggs,
          byCategory: {
            ...state.aggs.byCategory,
            data: aggregations?.map(transformByCategoryAggregation),
            loading: false
          }
        }
      }
    }

    case `${GET_LAST_YEAR_TOTAL_EXPENSES}_RESPONSE`: {
      const { aggregations } = action.payload
      return {
        ...state,
        aggs: {
          ...state.aggs,
          lastYearTotalExpenses: sumBy(aggregations?.map(transformCountAggregation), 'total')
        }
      }
    }

    case `${PARSE_EXPENSE_FILE}_RESPONSE`: {
      const { idParsing } = action.payload
      return {
        ...state,
        parseData: {
          ...state.parseData,
          idParsing
        },
        loading: false
      }
    }
    case `${GET_PARSING_STATUS}_RESPONSE`: {
      let uploadCounter = state.uploadCounter
      const parseData = action.payload
      if (parseData.parsingStatus === PARSING_STATUS.parsedSuccessfully) {
        uploadCounter++
      }
      return {
        ...state,
        uploadCounter,
        parseData,
        loading: false
      }
    }
    case `${GET_TRANSACTIONS}_RESPONSE`: {
      const { transactions, resources, total } = action.payload
      const { reset = true, timestamp: newTimestamp } = action.meta
      const { transactions: oldTransactions, transactionsTimestamp: lastTimestamp } = state
      const gotNewerSearchResult = lastTimestamp ? lastTimestamp < newTimestamp : true

      return {
        ...state,
        ...(gotNewerSearchResult && {
          transactionsTimestamp: newTimestamp,
          transactions: reset ? transactions : oldTransactions.concat(transactions),
          resources: reset ? resources : {
            apps: { ...state.resources.apps, ...resources.apps },
            users: { ...state.resources.users, ...resources.users }
          },
          totalTransactions: total
        }),
        loading: false,
        loadingMore: false
      }
    }
    case `${GET_TRANSACTIONS_FIELD_VALUES}_RESPONSE`: {
      const { fieldValues, resources } = action.payload
      return {
        ...state,
        fieldValuesData: {
          fieldValues,
          resources
        },
        loadingFieldValues: false
      }
    }
    case `${GET_SEARCHED_TRANSACTIONS}_RESPONSE`: {
      const response = action.payload
      const { reset = true, timestamp: newSearchTimeStamp } = action.meta
      const oldTransactions = state.searchedTransactions ? state.searchedTransactions.transactions : []
      const lastSearchTimeStamp = state.searchTimeStamp
      const gotNewerSearchResult = lastSearchTimeStamp ? lastSearchTimeStamp < newSearchTimeStamp : true

      return {
        ...state,
        searchedTransactions: {
          ...response,
          transactions: gotNewerSearchResult ? reset ? response.transactions : [...oldTransactions, ...response.transactions] : oldTransactions
        },
        searchTimeStamp: gotNewerSearchResult ? newSearchTimeStamp : lastSearchTimeStamp,
        loading: false
      }
    }
    case `${ARCHIVE_EXPENSES_TRANSACTION}_RESPONSE`: {
      const { transaction } = action.payload
      const modifiedTransactions = state.transactions.slice()
      const transactionIndex = modifiedTransactions.findIndex(t => t.id === transaction.id)
      modifiedTransactions[transactionIndex] = {
        ...modifiedTransactions[transactionIndex],
        ...transaction
      }

      return {
        ...state,
        transactions: modifiedTransactions
      }
    }
    case `${UNARCHIVE_EXPENSES_TRANSACTION}_RESPONSE`: {
      const { transaction } = action.payload
      const modifiedTransactions = state.transactions.slice()
      const transactionIndex = modifiedTransactions.findIndex(t => t.id === transaction.id)
      modifiedTransactions[transactionIndex] = {
        ...modifiedTransactions[transactionIndex],
        ...transaction
      }

      return {
        ...state,
        transactions: modifiedTransactions
      }
    }
    case `${GET_TRANSACTIONS_BY_SOURCE}_PENDING`: {
      return {
        ...state,
        loadingWithoutDeduplication: true
      }
    }
    case `${GET_TRANSACTIONS_BY_SOURCE}_RESPONSE`: {
      const { transactions } = action.payload
      const transactionsById = keyBy(transactions, 'id')
      return {
        ...state,
        transactionsWithoutDeduplication: {
          ...state.transactionsWithoutDeduplication,
          ...transactionsById
        },
        loadingWithoutDeduplication: false
      }
    }
    case `${GET_ORG_MATCHING_RULES}_PENDING`: {
      return {
        ...state,
        matchingRules: {
          ...state.matchingRules,
          loading: true
        }
      }
    }
    case `${GET_ORG_MATCHING_RULES}_RESPONSE`: {
      const { rules } = action.payload
      return {
        ...state,
        matchingRules: {
          ...state.matchingRules,
          rules,
          localRules: rules,
          loading: false
        }
      }
    }
    case TOGGLE_MATCHING_RULES_POPUP: {
      const { isOpen } = action.payload
      const { rules } = action.meta
      const localRules = isOpen ? { localRules: rules } : {}
      return {
        ...state,
        matchingRules: {
          ...state.matchingRules,
          ...localRules
        }
      }
    }
    case UPDATE_ORG_MATCHING_RULES_LOCALLY: {
      const { rules } = action.meta
      return {
        ...state,
        matchingRules: {
          ...state.matchingRules,
          localRules: rules
        }
      }
    }
    case `${UPDATE_ORG_MATCHING_RULES}_RESPONSE`: {
      const { rules } = action.payload
      return {
        ...state,
        matchingRules: {
          ...state.matchingRules,
          rules
        }
      }
    }
    default: {
      return uiReducerTransactionsExpander(state, action)
    }
  }
}

const transformByCategoryAggregation = (agg) => {
  const { key, value, aggregations } = agg
  const date = moment.utc(key).startOf('month').format('YYYY-MM-DD')
  return {
    date,
    count: value,
    total: aggregations.length > 0 ? sumBy(aggregations, 'aggregations') : 0,
    appCategory: aggregations.map(({ key, value, aggregations }) => ({
      appCategory: key,
      count: value,
      total: aggregations || 0
    }))
  }
}

const transformCountAggregation = (agg) => {
  const { key, value, aggregations } = agg
  const date = moment.utc(key).startOf('month').format('YYYY-MM-DD')
  return {
    date,
    count: value,
    total: aggregations || 0
  }
}

export default transactionsReducer
