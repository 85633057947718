import keyBy from 'lodash/keyBy'
import unionBy from 'lodash/unionBy'
import sortBy from 'lodash/sortBy'
import partition from 'lodash/partition'
import {
  GET_APPS,
  GET_APPS_PERMISSIONS,
  GET_SINGLE_APP,
  GET_SUGGESTED_OWNER,
  CHANGE_APP_HIDDEN_STATE,
  GET_APP_LICENSES,
  GET_MOST_USED_APPS,
  GET_USER_DATA_ACCESS_BY_CATEGORY,
  GET_USER_DATA_ACCESS_OF_CATEGORY,
  GET_APP_CHARGEBACK,
  GET_CATALOG_APPS,
  GET_APPS_V2,
  GET_APPS_FIELD_VALUES,
  GET_APPS_V2_AMOUNT,
  APP_COMPARISON_UPDATE_ID_APPS,
  GET_COMPARISON_APPS,
  APP_COMPARISON_GET_OVERLAPS,
  APP_COMPARISON_GET_USAGE,
  APP_COMPARISON_GET_ACTIVE_USERS,
  APP_COMPARISON_GET_RECOMMENDATIONS,
  APP_COMPARISON_GET_SSO_SOURCES_ACCOUNTS,
  GET_SPECIFIC_APP_V2,
  GET_APP_OWNERS,
  GET_EXPENSE_REPORT_APPS,
  GET_EXPENSE_REPORT_APPS_FIELD_VALUES,
  EMPTY_OBJECT,
  GET_APP_COMPLIANCE, LICENSE_AUDIT_DATA, GET_APPS_METADATA, GET_ORG_APP_LICENSES,
  GET_APPS_V2_TABLE
} from '@root/constants'
import { mergeByProperty } from '@shared/utils'
import moment from 'moment'
import { getSourceByType } from '@root/sourcesConfig'
import get from 'lodash/get'
import compact from 'lodash/compact'

const initialState = {
  loading: true,
  isLoaded: false,
  apps: [],
  appsResources: { users: {}, apps: {} },
  core: [],
  singleAppLoading: false,
  loadingSuggestedOwners: false,
  suggestedOwners: {},
  loadingPermissions: false,
  permissions: {},
  isPermissionsLoaded: false,
  appsLicenses: [],
  loadingAppsLicenses: false,
  mostUsedApps: {
    apps: [],
    loading: false,
    isLoaded: false
  },
  userDataAccess: {
    categories: [],
    loading: false,
    isLoaded: false
  },
  userDataAccessOfCategory: {
    apps: [],
    loading: false
  },
  chargeback: {},
  catalog: {
    apps: [],
    appsAggregations: {
      idAppsWithCustomPolicy: [],
      idAppsWithDefaultPolicy: []
    },
    requestNewAppPoliciesAmount: 0,
    loading: false
  },
  appsV2: {
    apps: [],
    total: 0,
    totalOrgApps: 0,
    resources: {},
    fieldsValues: {},
    loading: false,
    loadingMore: false,
    isLoaded: false
  },
  appsV2Table: {
    apps: [],
    total: 0,
    resources: {},
    loading: false,
    loadingMore: false,
    isLoaded: false
  },
  expenseReportApps: {
    apps: [],
    total: 0,
    totalOrgApps: 0,
    resources: {},
    fieldsValues: {},
    loading: false,
    loadingMore: false
  },
  comparisonApps: {
    showAppsCompareTab: false,
    idApps: [],
    extensionOnlyApps: [],
    comparisonRecommendations: {
      comparisons: [],
      resources: {},
      isLoaded: false
    },
    loadingComparisonApps: false,
    loadingComparisonActiveUsers: false,
    loadingComparisonUsage: false,
    loadingComparisonUsersOverlaps: false,
    loadingComparisonUsersOverlapsWithLicenses: false,
    appOwners: {
      loading: false,
      owners: []
    }
  },
  renewalForecasttData: {
    idApp: null,
    idAppAccount: null,
    data: null
  },
  metadata: {
    loading: false,
    predefinedFields: [],
    customFields: []
  },
  currentAppLicenses: {
    appLicenses: [],
    isLoading: false
  }
}

const appsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_APPS}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_APPS}_FAILED`: {
      return {
        ...state,
        loading: false
      }
    }
    case `${GET_APPS}_RESPONSE`: {
      const oldApps = state.apps
      const prevAppsByIdApp = keyBy(oldApps, 'id')

      const newApps = action.payload.apps.map(app => {
        const oldApp = prevAppsByIdApp[app.id] || {}
        return {
          ...oldApp,
          ...app
        }
      })

      const allApps = unionBy(newApps, oldApps, 'id')

      return {
        ...state,
        loading: false,
        apps: allApps,
        isLoaded: true,
        appsResources: action.payload.resources
      }
    }
    case `${GET_APPS_V2}_PENDING`: {
      const { reset = true } = action.meta

      return {
        ...state,
        appsV2: {
          ...state.appsV2,
          loading: true,
          loadingMore: !reset
        }
      }
    }
    case `${GET_APPS_V2}_FAILED`: {
      return {
        ...state,
        appsV2: {
          ...state.appsV2,
          loading: false,
          loadingMore: false
        }
      }
    }
    case `${GET_APPS_V2}_RESPONSE`: {
      const { apps, resources, total } = action.payload
      const { reset = true } = action.meta

      return {
        ...state,
        appsV2: {
          ...state.appsV2,
          isLoaded: true,
          loading: false,
          loadingMore: false,
          apps: reset ? apps : state.appsV2.apps.concat(apps),
          total,
          resources
        }
      }
    }
    case `${GET_EXPENSE_REPORT_APPS}_PENDING`: {
      const { reset = true } = action.meta

      return {
        ...state,
        expenseReportApps: {
          ...state.expenseReportApps,
          loading: true,
          loadingMore: !reset
        }
      }
    }
    case `${GET_EXPENSE_REPORT_APPS}_FAILED`: {
      return {
        ...state,
        expenseReportApps: {
          ...state.expenseReportApps,
          loading: false,
          loadingMore: false
        }
      }
    }
    case `${GET_EXPENSE_REPORT_APPS}_RESPONSE`: {
      const { apps, resources, total } = action.payload
      const { reset = true } = action.meta

      return {
        ...state,
        expenseReportApps: {
          ...state.expenseReportApps,
          isLoaded: true,
          loading: false,
          loadingMore: false,
          apps: reset ? apps : state.expenseReportApps.apps.concat(apps),
          total,
          resources
        }
      }
    }
    case `${GET_SINGLE_APP}_PENDING`: {
      return {
        ...state,
        singleAppLoading: true
      }
    }
    case `${GET_SINGLE_APP}_FAILED`: {
      return {
        ...state,
        singleAppLoading: false
      }
    }
    case `${GET_SINGLE_APP}_RESPONSE`: {
      const { app } = action.payload

      const modifiedApps = state.apps.slice()
      const appIndex = modifiedApps.findIndex(oldApp => oldApp.id === app.id)
      if (appIndex === -1) {
        modifiedApps.push(app)
      } else {
        modifiedApps[appIndex] = {
          ...modifiedApps[appIndex],
          ...app
        }
      }

      return {
        ...state,
        singleAppLoading: false,
        apps: modifiedApps
      }
    }
    case `${CHANGE_APP_HIDDEN_STATE}_RESPONSE`: {
      const { isHidden, idApp } = action.meta

      let modifiedApps = state.apps
      if (isHidden) {
        modifiedApps = state.apps.filter(app => app.id !== idApp)
      }

      return {
        ...state,
        apps: modifiedApps
      }
    }
    case `${GET_SUGGESTED_OWNER}_PENDING`: {
      return {
        ...state,
        loadingSuggestedOwners: true
      }
    }
    case `${GET_SUGGESTED_OWNER}_FAILED`: {
      return {
        ...state,
        loadingSuggestedOwners: false
      }
    }
    case `${GET_SUGGESTED_OWNER}_RESPONSE`: {
      const { idApp } = action.meta
      const suggestedOwnersArray = action.payload.suggestedOwners.map(user => ({ ...user, isSuggestedOwner: true }))
      const suggestedOwners = keyBy(suggestedOwnersArray, 'id')
      return {
        ...state,
        loadingSuggestedOwners: false,
        suggestedOwners: {
          ...state.suggestedOwners,
          [idApp]: suggestedOwners
        }
      }
    }
    case `${GET_APPS_PERMISSIONS}_PENDING`: {
      return {
        ...state,
        loadingPermissions: true
      }
    }
    case `${GET_APPS_PERMISSIONS}_FAILED`: {
      return {
        ...state,
        loadingPermissions: false
      }
    }
    case `${GET_APPS_PERMISSIONS}_RESPONSE`: {
      const { appsPermissionsBySource } = action.payload
      const { sources } = action.meta

      const combined = {
        google: [],
        azure_ad: [],
        slack: [],
        ...state.permissions
      }

      sources.forEach(source => {
        mergeByProperty(combined[source], appsPermissionsBySource[source] || [], 'idApp')
      })

      return {
        ...state,
        loadingPermissions: false,
        permissions: {
          ...combined
        },
        isPermissionsLoaded: true
      }
    }

    case `${GET_APP_LICENSES}_PENDING`: {
      return {
        ...state,
        loadingAppsLicenses: true
      }
    }
    case `${GET_APP_LICENSES}_FAILED`: {
      return {
        ...state,
        loadingAppsLicenses: false
      }
    }
    case `${GET_APP_LICENSES}_RESPONSE`: {
      const { apps } = action.payload

      return {
        ...state,
        appsLicenses: apps,
        loadingAppsLicenses: false
      }
    }
    case `${GET_MOST_USED_APPS}_PENDING`: {
      return {
        ...state,
        mostUsedApps: {
          ...state.mostUsedApps,
          loading: true
        }
      }
    }
    case `${GET_MOST_USED_APPS}_FAILED`: {
      return {
        ...state,
        mostUsedApps: {
          ...state.mostUsedApps,
          loading: false
        }
      }
    }
    case `${GET_MOST_USED_APPS}_RESPONSE`: {
      const { apps } = action.payload

      return {
        ...state,
        mostUsedApps: {
          apps,
          loading: false,
          isLoaded: true
        }
      }
    }
    case `${GET_USER_DATA_ACCESS_BY_CATEGORY}_PENDING`: {
      return {
        ...state,
        userDataAccess: {
          ...state.userDataAccess,
          loading: true
        }
      }
    }
    case `${GET_USER_DATA_ACCESS_BY_CATEGORY}_FAILED`: {
      return {
        ...state,
        userDataAccess: {
          ...state.userDataAccess,
          loading: false
        }
      }
    }
    case `${GET_USER_DATA_ACCESS_BY_CATEGORY}_RESPONSE`: {
      const { categories } = action.payload

      return {
        ...state,
        userDataAccess: {
          categories,
          loading: false,
          isLoaded: true
        }
      }
    }

    case `${GET_USER_DATA_ACCESS_OF_CATEGORY}_PENDING`: {
      return {
        ...state,
        userDataAccessOfCategory: {
          ...state.userDataAccessOfCategory,
          loading: true
        }
      }
    }
    case `${GET_USER_DATA_ACCESS_OF_CATEGORY}_FAILED`: {
      return {
        ...state,
        userDataAccessOfCategory: {
          ...state.userDataAccessOfCategory,
          loading: false
        }
      }
    }
    case `${GET_USER_DATA_ACCESS_OF_CATEGORY}_RESPONSE`: {
      const { apps } = action.payload

      return {
        ...state,
        userDataAccessOfCategory: {
          apps,
          loading: false
        }
      }
    }

    case `${GET_APP_CHARGEBACK}_PENDING`: {
      const { idApp } = action.meta

      return {
        ...state,
        chargeback: {
          ...state.chargeback,
          [idApp]: {
            ...(state.chargeback[idApp] || {}),
            loading: true
          }
        }
      }
    }
    case `${GET_APP_CHARGEBACK}_FAILED`: {
      const { idApp } = action.meta

      return {
        ...state,
        chargeback: {
          ...state.chargeback,
          [idApp]: {
            ...(state.chargeback[idApp] || {}),
            loading: false
          }
        }
      }
    }
    case `${GET_APP_CHARGEBACK}_RESPONSE`: {
      const { idApp } = action.meta

      return {
        ...state,
        chargeback: {
          ...state.chargeback,
          [idApp]: {
            data: action.payload,
            loading: false,
            isLoaded: true
          }
        }
      }
    }

    case `${GET_CATALOG_APPS}_PENDING`: {
      return {
        ...state,
        catalog: {
          ...state.catalog,
          loading: true
        }
      }
    }

    case `${GET_CATALOG_APPS}_RESPONSE`: {
      const { apps, resources } = action.payload

      return {
        ...state,
        catalog: {
          apps,
          appsAggregations: {
            idAppsWithDefaultPolicy: get(resources, ['appsAggregations', 'idAppsWithDefaultPolicy'], []),
            idAppsWithCustomPolicy: get(resources, ['appsAggregations', 'idAppsWithCustomPolicy'], [])
          },
          requestNewAppPoliciesAmount: get(resources, 'requestNewAppPoliciesAmount', 0),
          loading: false
        }
      }
    }

    case `${GET_APPS_FIELD_VALUES}_RESPONSE`: {
      return {
        ...state,
        appsV2: {
          ...state.appsV2,
          fieldsValues: {
            ...state.appsV2.fieldsValues,
            ...action.payload
          }
        }
      }
    }

    case `${GET_EXPENSE_REPORT_APPS_FIELD_VALUES}_RESPONSE`: {
      return {
        ...state,
        expenseReportApps: {
          ...state.expenseReportApps,
          fieldsValues: {
            ...state.expenseReportApps.fieldsValues,
            ...action.payload
          }
        }
      }
    }

    case `${GET_APPS_METADATA}_PENDING`: {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          loading: true
        }
      }
    }

    case `${GET_APPS_METADATA}_FAILED`: {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          loading: false
        }
      }
    }

    case `${GET_APPS_METADATA}_RESPONSE`: {
      const { predefinedFields, customFields } = action.payload

      return {
        ...state,
        metadata: {
          loading: false,
          predefinedFields,
          customFields
        }
      }
    }

    case `${GET_APPS_V2_AMOUNT}_RESPONSE`: {
      const { total } = action.payload

      return {
        ...state,
        appsV2: {
          ...state.appsV2,
          totalOrgApps: total
        }
      }
    }

    case APP_COMPARISON_UPDATE_ID_APPS: {
      const { idApps } = action.payload

      return {
        ...state,
        comparisonApps: {
          ...state.comparisonApps,
          idApps
        }
      }
    }

    case LICENSE_AUDIT_DATA: {
      const { idApp, idAppAccount, data } = action.payload
      return {
        ...state,
        renewalForecastData: {
          idApp,
          idAppAccount,
          data
        }
      }
    }

    case `${GET_COMPARISON_APPS}_PENDING`: {
      return {
        ...state,
        comparisonApps: {
          ...state.comparisonApps,
          loadingComparisonApps: true
        }
      }
    }

    case `${GET_COMPARISON_APPS}_RESPONSE`: {
      const { apps } = action.payload
      const { idApps } = action.meta

      const sortedApps = idApps.length === 0 ? apps : apps.sort((a, b) => (idApps.indexOf(a.id) - idApps.indexOf(b.id)))
      const extensionOnlyApps = sortedApps.filter(app => app.usageSources && app.usageSources.length === 1 && app.usageSources[0] === 'extension').map(app => ({ name: app.name, id: app.id }))

      return {
        ...state,
        comparisonApps: {
          ...state.comparisonApps,
          extensionOnlyApps,
          loadingComparisonApps: false
        }
      }
    }

    case `${APP_COMPARISON_GET_ACTIVE_USERS}_PENDING`: {
      return {
        ...state,
        comparisonApps: {
          ...state.comparisonApps,
          loadingComparisonActiveUsers: true
        }
      }
    }

    case `${APP_COMPARISON_GET_ACTIVE_USERS}_RESPONSE`: {
      return {
        ...state,
        comparisonApps: {
          ...state.comparisonApps,
          loadingComparisonActiveUsers: false
        }
      }
    }

    case `${APP_COMPARISON_GET_USAGE}_PENDING`: {
      return {
        ...state,
        comparisonApps: {
          ...state.comparisonApps,
          loadingComparisonUsage: true
        }
      }
    }

    case `${APP_COMPARISON_GET_USAGE}_RESPONSE`: {
      return {
        ...state,
        comparisonApps: {
          ...state.comparisonApps,
          loadingComparisonUsage: false
        }
      }
    }

    case `${APP_COMPARISON_GET_OVERLAPS}_PENDING`: {
      const { withLicenses } = action.meta

      return {
        ...state,
        comparisonApps: {
          ...state.comparisonApps,
          loadingComparisonUsersOverlaps: withLicenses ? state.comparisonApps.loadingComparisonUsersOverlaps : true,
          loadingComparisonUsersOverlapsWithLicenses: withLicenses ? true : state.comparisonApps.loadingComparisonUsersOverlapsWithLicenses
        }
      }
    }

    case `${APP_COMPARISON_GET_OVERLAPS}_RESPONSE`: {
      const { withLicenses } = action.meta

      return {
        ...state,
        comparisonApps: {
          ...state.comparisonApps,
          loadingComparisonUsersOverlaps: withLicenses ? state.comparisonApps.loadingComparisonUsersOverlaps : false,
          loadingComparisonUsersOverlapsWithLicenses: withLicenses ? false : state.comparisonApps.loadingComparisonUsersOverlapsWithLicenses
        }
      }
    }

    case `${APP_COMPARISON_GET_RECOMMENDATIONS}_RESPONSE`: {
      const { comparisons, resources } = action.payload
      const { apps } = resources

      return {
        ...state,
        comparisonApps: {
          ...state.comparisonApps,
          comparisonRecommendations: {
            comparisons: comparisons.map(comparison => ({ ...comparison, idApps: sortBy(comparison.idApps, idApp => apps[idApp] && apps[idApp].name) })),
            resources,
            isLoaded: true
          }
        }
      }
    }

    case `${APP_COMPARISON_GET_SSO_SOURCES_ACCOUNTS}_RESPONSE`: {
      const { appAccounts } = action.payload

      const googleIdApp = getSourceByType('google').idApp
      const [googleAppAccounts, nonGoogleAppAccounts] = partition(appAccounts, app => app.idApp === googleIdApp)

      const hasGoogleUsage = googleAppAccounts.some(account => moment(account.lastSyncTime).utc().isAfter(moment('2022-02-07T00:00:00.000Z').utc()))
      const showAppsCompareTab = hasGoogleUsage || nonGoogleAppAccounts.some(account => moment(account.lastSyncTime).utc().isAfter(moment('2021-07-01T00:00:00.000Z').utc()))

      return {
        ...state,
        comparisonApps: {
          ...state.comparisonApps,
          showAppsCompareTab
        }
      }
    }

    case `${GET_SPECIFIC_APP_V2}_PENDING`: {
      return {
        ...state,
        currentAppV2: {
          ...state.currentAppV2,
          loading: true
        }
      }
    }
    case `${GET_SPECIFIC_APP_V2}_FAILED`: {
      return {
        ...state,
        currentAppV2: {
          ...state.currentAppV2,
          loading: false
        }
      }
    }
    case `${GET_SPECIFIC_APP_V2}_RESPONSE`: {
      const { apps } = action.payload

      return {
        ...state,
        currentAppV2: {
          ...state.currentAppV2,
          loading: false,
          app: (apps && apps.length > 0) ? apps[0] : EMPTY_OBJECT
        }
      }
    }
    case `${GET_APP_OWNERS}_PENDING`: {
      return {
        ...state,
        appOwners: {
          ...state.appOwners,
          loading: true
        }
      }
    }
    case `${GET_APP_OWNERS}_FAILED`: {
      return {
        ...state,
        appOwners: {
          ...state.appOwners,
          loading: false
        }
      }
    }
    case `${GET_APP_OWNERS}_RESPONSE`: {
      const { apps } = action.payload
      const owners = apps.reduce((res, app) => {
        const allOwners = compact([...(app.appOwners || [])])
        if (allOwners?.length && app.primaryOwner) {
          allOwners.forEach(owner => {
            if (res[owner.id]) {
              res[owner.id].idApps.push(app.id)
            } else {
              res[owner.id] = { ...owner, idApps: [app.id], isPrimary: owner.id === app.primaryOwner.id }
            }
          })
        }
        return res
      }, {})

      return {
        ...state,
        appOwners: {
          ...state.appOwners,
          loading: false,
          owners: Object.values(owners)
        }
      }
    }
    case `${GET_APP_COMPLIANCE}_PENDING`: {
      return {
        ...state,
        appCompliance: {
          ...state.appCompliance,
          loading: true
        }
      }
    }
    case `${GET_APP_COMPLIANCE}_FAILED`: {
      return {
        ...state,
        appCompliance: {
          ...state.appCompliance,
          loading: false
        }
      }
    }
    case `${GET_APP_COMPLIANCE}_RESPONSE`: {
      const { idApp } = action.meta
      return {
        ...state,
        appCompliance: {
          ...state.appCompliance,
          loading: false,
          [idApp]: action.payload.data
        }
      }
    }
    case `${GET_ORG_APP_LICENSES}_PENDING`: {
      return {
        ...state,
        currentAppLicenses: {
          ...state.currentAppLicenses,
          isLoading: true
        }
      }
    }
    case `${GET_ORG_APP_LICENSES}_FAILED`: {
      return {
        ...state,
        currentAppLicenses: {
          ...state.currentAppLicenses,
          isLoading: false
        }
      }
    }
    case `${GET_ORG_APP_LICENSES}_RESPONSE`: {
      const { licenses } = action.payload
      return {
        ...state,
        currentAppLicenses: {
          ...state.currentAppLicenses,
          appLicenses: licenses,
          isLoading: false
        }
      }
    }

    case `${GET_APPS_V2_TABLE}_PENDING`: {
      const { reset = true } = action.meta

      return {
        ...state,
        appsV2Table: {
          ...state.appsV2Table,
          loading: true,
          loadingMore: !reset
        }
      }
    }

    case `${GET_APPS_V2_TABLE}_FAILED`: {
      return {
        ...state,
        appsV2Table: {
          ...state.appsV2Table,
          loading: false,
          loadingMore: false
        }
      }
    }

    case `${GET_APPS_V2_TABLE}_RESPONSE`: {
      const { apps, resources, total } = action.payload
      const { reset = true } = action.meta

      return {
        ...state,
        appsV2Table: {
          ...state.appsV2Table,
          isLoaded: true,
          loading: false,
          loadingMore: false,
          apps: reset ? apps : state.appsV2Table.apps.concat(apps),
          total,
          resources
        }
      }
    }

    default: {
      return state
    }
  }
}

export default appsReducer
