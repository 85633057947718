import React from 'react'
import { useFormState } from 'react-final-form'
import { formatCurrencyForInput } from '@root/components/form/currencyInput/utils'
import { useLineItemCalculations } from '../hooks/useLineItemCalculations'
import _ from 'lodash'

interface FinalAnnualUnitCostCellProps {
  index: number
  currencySymbol: string
}

export const FinalAnnualUnitCostCell: React.FC<FinalAnnualUnitCostCellProps> = ({ index, currencySymbol }) => {
  const { values } = useFormState()
  const lineItems = values.lineItems || []
  const current = lineItems[index] || {}

  const { totalCost, isValid } = useLineItemCalculations(current)
  const quantity = _.get(current, 'quantity', null)

  if (!isValid || !quantity) {
    return <span />
  }

  const finalAnnualUnitCost = totalCost / Number(quantity)
  const formattedCost = formatCurrencyForInput(finalAnnualUnitCost)

  return <span>{`${currencySymbol}${formattedCost}`}</span>
}
