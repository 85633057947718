import send from '@shared/redux-fetch'
import {
  CREATE_CUSTOM_INTEGRATION,
  CREATE_SOURCE_TOKEN,
  DISCONNECT_SERVICE,
  GET_CONNECT_INTEGRATION_LINK,
  GET_CONNECT_INTEGRATION_LINK_INFO,
  GET_INTEGRATIONS,
  GET_SERVICES_CATEGORY,
  GET_SERVICES_CONFIG,
  GET_SERVICES_IMAGE_URL,
  GET_SYNC_DATA,
  GET_SYNC_DATA_FOR_APP_ACCOUNT,
  RUN_SERVICE_SYNC,
  UPDATE_APP_ACCOUNT_NAME
} from '@root/constants'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'

export const getServicesSyncData = ({ idOrg }) =>
  send(GET_SYNC_DATA, {
    url: `/api/sync/${idOrg}/status`
  })

export const getSyncDataForAppAccount = ({ idOrg, idAppAccount }) =>
  send(GET_SYNC_DATA_FOR_APP_ACCOUNT, {
    url: `/api/sync/${idOrg}/status/${idAppAccount}`
  })

export const getServicesConfig = ({ idOrg }) =>
  send(GET_SERVICES_CONFIG, {
    url: `/api/services/config/${idOrg}`
  })

export const getServicesImageUrl = () =>
  send(GET_SERVICES_IMAGE_URL, {
    url: `/api/services/imageUrls`
  })

export const getServicesCategory = ({ idOrg }) =>
  send(GET_SERVICES_CATEGORY, {
    url: `/api/orgs/${idOrg}/services/categories`
  })

export const createSourceToken = ({ source, idApp, formData, idTestConnection }) => {
  const body = { ...formData, source, idApp, idTestConnection }
  return send(CREATE_SOURCE_TOKEN, {
    url: '/api/auth/token',
    method: 'POST',
    body,
    meta: {
      source,
      permission: formData.permissions
    }
  })
}

export const disconnectService = ({ idOrg, source, appName, accountName, idApp, idAppToken, idAppAccount }) =>
  send(DISCONNECT_SERVICE, {
    url: `/api/auth/${idOrg}/token/${idAppToken}`,
    body: {
      idApp,
      idAppAccount,
      source
    },
    method: 'DELETE',
    meta: {
      idOrg,
      source,
      appName,
      accountName,
      idApp
    }
  })

export const runServicesSync = ({ idOrg, idAppToken }) =>
  send(RUN_SERVICE_SYNC, {
    url: `/api/sync/${idOrg}/${idAppToken}`,
    method: 'POST',
    body: {},
    meta: {
      idOrg,
      idAppToken
    }
  })

export const getConnectIntegrationLink = ({ idOrg, idApp, permission, syncType }) => {
  return send(GET_CONNECT_INTEGRATION_LINK, {
    url: `/api/orgs/${idOrg}/app/${idApp}/connectLink`,
    method: 'POST',
    body: {
      permission,
      syncType
    }
  })
}

export const getConnectIntegrationInfo = ({ idOrg, idApp, token }) => {
  return send(GET_CONNECT_INTEGRATION_LINK_INFO, {
    url: `/api/orgs/${idOrg}/app/${idApp}/connectLink`,
    query: {
      token
    }
  })
}

export const createCustomIntegration = ({ idOrg, idApp, idAppAccount }) => {
  return send(CREATE_CUSTOM_INTEGRATION, {
    url: `/api/orgs/${idOrg}/appToken/custom`,
    method: 'POST',
    body: omitBy({
      idApp,
      idAppAccount
    }, isUndefined)
  })
}

export const getIntegrations = ({ idOrg, idApp, disconnectedOnly = false, customOnly = false }) => {
  return send(GET_INTEGRATIONS, {
    url: `/api/orgs/${idOrg}/appAccounts`,
    query: {
      idApps: idApp,
      disconnectedOnly,
      customOnly
    }
  })
}

export const updateAppAccountName = ({ idOrg, idAppAccount, appAccountName }) =>
  send(UPDATE_APP_ACCOUNT_NAME, {
    url: `/api/orgs/${idOrg}/appAccounts/${idAppAccount}`,
    method: 'PUT',
    body: {
      name: appAccountName
    },
    meta: {
      idAppAccount,
      appAccountName
    }
  })
