import React from 'react'
import PropTypes from 'prop-types'
import Table from '../../table/index'
import { ITEMS_PER_PAGE, TABLES, SCOPES, DATE_FORMAT } from '@root/constants'
import { USER_LIFECYCLE_STATUS } from '@shared/types'
import { css } from 'glamor'
import texts from '../../../shared/style/texts'
import colors from '../../../shared/style/colors'
import moment from 'moment'
import { getNameColumn } from '../columns'
import EnableFor from '../../enableFor'
import emptyImage from '@media/dance.svg'
import { fontSize, fontWeight } from '@shared/style/sizes'
import debounce from 'lodash/debounce'
import { mapSortArray, objectToQueryString, sortStringFromArray } from '@shared/utils'
import config from '../../../config'
import { hoverActions } from '@shared/style/mixins'
import Analytics from '@helpers/analytics'
import { H4, Button, ButtonType, ButtonSize, EmptyState, Link } from '@toriihq/design-system'
import { getTdStyle } from '../styles'
import { isParentUserFilter } from '@actions/'
import RelativeTeamLink from '@components/relativeTeamLink'
import isNil from 'lodash/isNil'
import Currency from '@components/currency'
import { REPORT_KEY } from '@actions/reports/reports.types'

const CSS = {
  actionLink: css(hoverActions, {
    float: 'right'
  }),
  offboardingProgress: css(texts.caption, hoverActions, {
    marginLeft: '5px'
  }),
  tableHeader: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 32
  }),
  tableHeaderTitle: css({
    marginRight: '20px',
    marginLeft: '0px',
    fontSize: fontSize.medium,
    fontWeight: fontWeight.semiBold,
    color: colors.darkText,
    width: '100%'
  }),
  tableHeaderSubtitle: css({
    color: colors.grey1,
    fontSize: fontSize.small,
    marginLeft: 15
  })
}

class TodoUsers extends React.Component {
  state = {
    selectedUsers: [],
    q: ''
  }

  constructor (props) {
    super(props)

    this.columns = this.getColumns(this.props.columnsRestrictions)
  }

  componentDidMount () {
    if (this.props.idOrg) {
      this.fetchData(true)
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.idOrg && (prevProps.idOrg !== this.props.idOrg)) {
      this.fetchData(true)
    }
  }

  getHeader = () => {
    const { title, total } = this.props
    return <H4>{`${title} (${total}) `}</H4>
  }

  getColumns = (columnsRestrictions) => {
    const { idOrg } = this.props
    return [
      getNameColumn({ paddingLeft: '5px' }),
      {
        accessor: 'email',
        show: false,
        textHeader: 'Email'
      },
      {
        Header: 'Date of departure',
        id: 'identitySourcesDeletionTime',
        accessor: ({ identitySourcesDeletionTime }) => moment.utc(identitySourcesDeletionTime).valueOf(),
        Cell: ({ value }) => moment.utc(value).format(DATE_FORMAT),
        width: 150
      },
      {
        Header: 'All apps',
        id: 'totalAppAccountsCount',
        accessor: 'totalAppAccountsCount',
        width: 75
      },
      {
        Header: 'Apps used',
        id: 'activeAppsCount',
        accessor: 'activeAppsCount',
        width: 85
      },
      {
        Header: 'Annual cost',
        id: 'annualCostConverted',
        accessor: 'annualCostConverted',
        Cell: ({ value }) => {
          return isNil(value) ? '-' : <Currency value={value} />
        },
        width: 150,
        textValue: ({ value }) => isNil(value) ? '' : value,
        sortMethod: Table.sortMethods.currency,
        show: columnsRestrictions.hasLicenseCostAndChargebackAccess
      },
      {
        Header: '',
        sortable: false,
        id: 'offboardingAction',
        accessor: 'lifecycleStatus',
        minWidth: 135,
        hideFromCSV: true,
        Cell: ({ row: user }) => {
          const { selectedUsers } = this.state

          return selectedUsers.length ? '' : <div {...CSS.actionLink}>
            <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
              <Button onClick={async () => {
                Analytics.track('Click on start offboarding')
                await this.props.startOffboarding([user])
              }} label='Start offboarding' />
            </EnableFor>
          </div>
        }
      },
      {
        Header: '',
        sortable: false,
        id: 'offboardingAction',
        accessor: 'lifecycleStatus',
        width: 120,
        hideFromCSV: true,
        Cell: ({ row: { id } }) => {
          const { history } = this.props
          const { selectedUsers } = this.state

          return selectedUsers.length ? '' : <div {...CSS.actionLink}>
            <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
              <Button type={ButtonType.compact} size={ButtonSize.small}
                key={id}
                onClick={async () => {
                  Analytics.track('Click on skip offboarding')
                  await this.props.skipOffboarding([ id ])
                  history.push(`/team/${idOrg}/user/${id}`)
                }}
                label='Skip offboarding'
              />
            </EnableFor>
          </div>
        }
      },
      {
        accessor: 'firstName',
        show: false
      },
      {
        accessor: 'lastName',
        show: false
      },
      {
        accessor: 'id',
        show: false
      },
      {
        accessor: 'email',
        show: false
      },
      {
        accessor: 'isExternal',
        show: false
      },
      {
        accessor: 'photoUrl',
        show: false
      }
    ]
  }

  getUsers = ({ limit = ITEMS_PER_PAGE, offset = 0, sort = this.props.defaultSort, q = this.query, reset = false }) => {
    const { idOrg, getOffboardingTodoUsers } = this.props
    getOffboardingTodoUsers({ idOrg, limit, offset, sort: sortStringFromArray(sort), q, reset })
  }

  fetchData = debounce((reset = false) => {
    const { users } = this.props

    this.getUsers({ offset: reset ? 0 : users.length, reset })
  }, 500, { leading: true, trailing: false })

  onSortedChange = (sort) => {
    this.getUsers({ sort, reset: true })
  }

  onSearch = debounce((q) => {
    this.query = q
    this.getUsers({ reset: true, q })
  }, 250)

  prepareExportParams = ({ sort, query }) => {
    return {
      sort: mapSortArray(sort),
      q: query,
      fields: this.columns.filter(col => ((col.show === undefined || col.show) && !col.hideFromCSV)).map(col => col.id || col.accessor).flatMap(field => field === 'fullName' ? ['firstName', 'lastName', 'email'] : field),
      baseFilters: { isExternal: false, isDeletedInIdentitySources: true, lifecycleStatus: USER_LIFECYCLE_STATUS.ACTIVE },
      filters: [isParentUserFilter]
    }
  }

  onShareReport = ({ sort: rawSort, query: rawQuery }) => {
    const { toggleShareReportPopup } = this.props
    const { sort, q, fields, baseFilters, filters } = this.prepareExportParams({ sort: rawSort, query: rawQuery })

    toggleShareReportPopup({ isOpen: true, pageName: 'Offboarding - To-do', report: { config: { sort, q, fields, baseFilters, filters }, reportName: 'Offboarding - To-do', reportKey: REPORT_KEY.USERS } })
  }

  exportToCsv = ({ sort: rawSort, query: rawQuery }) => {
    const { idOrg } = this.props

    const { sort, q, fields, baseFilters, filters } = this.prepareExportParams({ sort: rawSort, query: rawQuery })

    const sortParam = `sort=${sort.join(',')}`
    const queryParam = `q=${q}`
    const fieldsParam = `fields=${fields.join(',')}`
    const baseFiltersParam = objectToQueryString(baseFilters)
    const filtersParam = `filters=${encodeURIComponent(JSON.stringify(filters))}`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/users/csv?${sortParam}&${queryParam}&${fieldsParam}&${baseFiltersParam}&${filtersParam}`
    const newWindow = window.open(url, '_blank')
    newWindow.opener = null
  }

  renderNoUsers = () => {
    return <EmptyState
      image={<img src={emptyImage} alt='No users' />}
      title='Cool, your work here is done!'
      link={<RelativeTeamLink to='/offboarding/progress'><Link>View offboarding progress</Link></RelativeTeamLink>}
    />
  }

  searchFilterMethod (row, search) {
    const values = [
      [row.firstName, row.lastName].join(' '),
      row.email
    ]
    return values.some(value => value && value.toLowerCase().includes(search))
  }

  onSelectChange = selection => this.setState({ selectedUsers: selection })

  customHeaderOnSelect = () => {
    const { selectedUsers } = this.state
    const { skipOffboarding, startOffboarding, refreshAllOffboardingData, users } = this.props
    const selectedUsersinfo = users.filter(user => selectedUsers.includes(user.id))

    return <div>
      <div {...css(CSS.tableHeader, { padding: 0 })}>
        <div {...css(CSS.actionLink, { opacity: 1, marginRight: 20 })}>
          <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
            <Button onClick={async () => {
              startOffboarding(selectedUsersinfo)
            }} label='Start offboarding' />
          </EnableFor>
        </div>
        <div {...css(CSS.actionLink, { opacity: 1 })}>
          <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
            <Button type={ButtonType.compact} size={ButtonSize.small}
              onClick={async () => {
                await skipOffboarding(selectedUsers)
                await refreshAllOffboardingData()
              }}
              label='Skip offboarding' />
          </EnableFor>
        </div>
      </div>
    </div>
  }

  render () {
    const { users, total, loading, loadingMore } = this.props
    const { selectedUsers } = this.state

    return (
      <Table
        tableKey={TABLES.offboardingTodoTable.key}
        emptyStateMessage={this.renderNoUsers()}
        data={users}
        columns={this.columns}
        header={this.getHeader}
        loading={loading}
        loadingMore={loadingMore}
        exportable
        searchable
        searchFilterMethod={this.searchFilterMethod}
        getTdProps={getTdStyle}
        selectedItems={selectedUsers}
        selectable
        onSelectChange={this.onSelectChange}
        customHeaderOnSelect={this.customHeaderOnSelect}
        manual
        fetchData={this.fetchData}
        onSortedChangeCB={this.onSortedChange}
        onSearch={this.onSearch}
        forceShowSearch
        exportFunction={this.exportToCsv}
        onShareReport={this.onShareReport}
        totalCount={total}
      />
    )
  }
}

TodoUsers.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    lifecycleStatus: PropTypes.string.isRequired,
    identitySourcesDeletionTime: PropTypes.string.isRequired
  }))
}

export default TodoUsers
