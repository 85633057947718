import React from 'react'
import moment from 'moment'
import { AppIcon, Link } from '@toriihq/design-system'
import Table from '../../../../table'
import { highlightTermInText } from '@lenses/utils'
import RelativeTeamLink from '../../../../relativeTeamLink'
import { DATE_FORMAT, EXPENSES_SOURCES } from '@root/constants'
import { css } from 'glamor'
import * as Style from './style'
import Currency from '@root/components/currency'
import MatchingStatusIndicator from '@components/matchingStatusIndicator'

export const getColumns = ({ term, appsById }) => {
  return [
    {
      Header: 'Transaction date',
      accessor: 'transactionDate',
      Cell: ({ value: date }) => <span>{moment.utc(date).format(DATE_FORMAT)}</span>,
      maxWidth: 170,
      width: 170,
      sortMethod: Table.sortMethods.date
    },
    {
      Header: 'Description',
      accessor: 'description',
      Cell: ({ value: description, row: { extraInfo } }) => {
        const descriptionText = `${description}${extraInfo ? ` - ${extraInfo}` : ''}`

        const highlitedText = highlightTermInText([term], descriptionText)
        return <div dangerouslySetInnerHTML={{ __html: `${highlitedText}` }} />
      },
      width: 350
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      Cell: ({ value: amount }) => <span> <Currency value={amount} /> </span>,
      ...Table.numericFieldProps,
      width: 100
    },
    {
      Header: 'Matching Status',
      accessor: 'mappingStatus',
      Cell: ({ value: matchingStatus, row: { idApp } }) => {
        const renderApp = (idApp) => {
          if (matchingStatus !== 'mapped') {
            return null
          }
          const app = appsById[idApp] || null

          return (
            app
              ? <div {...Style.MatchingStatus}>
                <AppIcon size='small' appImageUrl={app.imageUrl} appName={app.name} />
                <div {...Style.MatchingStatusText}>{app.name}</div>
              </div> : null)
        }

        return <div>
          <MatchingStatusIndicator mappingStatus={matchingStatus} />
          <div>{renderApp(idApp)}</div>
        </div>
      }
    },
    {
      Header: 'Source',
      accessor: 'toriiSource',
      Cell: ({ value: source, row: { idUpload, fileName } }) => {
        if (source === 'csv' && idUpload) {
          const decodedFileName = decodeURIComponent(fileName)
          return <RelativeTeamLink to={`/expenses/uploads/${idUpload}`} overrideStyle={css({ display: 'flex' })}><Link><Link.Icon name='File' />{decodedFileName}</Link></RelativeTeamLink>
        }

        return <div>{EXPENSES_SOURCES[source] || source}</div>
      },
      width: 280
    },
    {
      accessor: 'reportedBy',
      show: false
    },
    {
      accessor: 'idUpload',
      show: false
    },
    {
      accessor: 'idOrg',
      show: false
    },
    {
      accessor: 'fileName',
      show: false
    },
    {
      accessor: 'idExternalTransaction',
      show: false
    },
    {
      accessor: 'idApp',
      show: false
    },
    {
      accessor: 'id',
      show: false
    },
    {
      accessor: 'extraInfo',
      show: false
    },
    {
      accessor: 'origTransactionDate',
      show: false
    },
    {
      accessor: 'reportedBy',
      show: false
    },
    {
      accessor: 'modifiedAmount',
      show: false
    },
    {
      accessor: 'origAmount',
      show: false
    },
    {
      accessor: 'origCurrency',
      show: false
    }
  ]
}
