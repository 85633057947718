import { formFieldTypesToFieldTypes } from '@root/constants'
import { FilterOption } from '@root/constants.t'
import { PredefinedField } from '@shared/entitiesMetadataTypes'

export enum FILTER_OPERATORS {
  equals = 'equals',
  notEquals = 'notEquals',
  contains = 'contains',
  notContains = 'notContains',
  anyOf = 'anyOf',
  noneOf = 'noneOf',
  allOf = 'allOf',
  isSet = 'isSet',
  isNotSet = 'isNotSet',
  gt = 'gt',
  gte = 'gte',
  lt = 'lt',
  lte = 'lte',
  dayAfter = 'dayAfter',
  dayOnOrAfter = 'dayOnOrAfter',
  dayBefore = 'dayBefore',
  dayOnOrBefore = 'dayOnOrBefore',
  relativeDateMore = 'relativeDateMore',
  relativeDateOn = 'relativeDateOn',
  relativeDateLess = 'relativeDateLess',
  relativeDateToday = 'relativeDateToday',
  exists = 'exists',
  notExists = 'notExists',
  default = 'default'
}

export const mapPredefinedFieldToFilterOption = (predefinedField: PredefinedField): FilterOption => ({
  label: predefinedField.name,
  value: predefinedField.systemKey,
  type: formFieldTypesToFieldTypes[predefinedField.type]
})
