import React from 'react'
import { css } from 'glamor'
import { Col, Grid, Row, Button, ButtonType, EmptyState } from '@toriihq/design-system'
import { withTheme } from 'styled-components'
import ExpensesSummary from '../expensesSummary'
import ExpensesBarChart from '../expensesBarChart'
import Expenses from '../expenses'
import emptyImage from '@media/expenses.svg'
import MonthSelector from '../monthSelector'
import { SCOPES } from '../../constants'
import { getStateDetails } from '@actions/'
import Analytics from '@helpers/analytics'
import EnableFor from '@components/enableFor'

const generateCSS = ({ theme }) => ({
  row: css({
    height: '323px',
    marginBottom: '45px',
    ' > div': {
      height: '100%'
    },
    ' .col > *': {
      marginBottom: '30px'
    }
  }),
  smallRow: css({
    minHeight: '323px',
    ' .col': {
      marginBottom: '24px'
    },
    ' .col > *': {
      marginBottom: '30px'
    }
  }),
  expensesBox: css({
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: '8px',
    height: '300px',
    margin: '26px 0'
  }),
  monthSelectorRow: css({
    margin: '0px !important'
  })
})

class ExpensesOverview extends React.Component {
  constructor (props) {
    super(props)
    const { theme } = props
    this.state = {
      CSS: generateCSS({ theme })
    }
  }

  componentDidMount () {
    if (this.props.idOrg) {
      this.fetchData()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.idOrg && (prevProps.idOrg !== this.props.idOrg)) {
      this.fetchData()
    }

    if (prevProps.theme !== this.props.theme) {
      this.setState({
        CSS: generateCSS({ theme: this.props.theme })
      })
    }
  }

  fetchData () {
    const { idOrg, deprecatedGetApps, getTransactionsAggsByAppCategory } = this.props
    deprecatedGetApps({ idOrg })
    getStateDetails({ idOrg })
    getTransactionsAggsByAppCategory({ idOrg })
  }

  renderEmptyState () {
    const onClick = () => {
      Analytics.track('Click to upload expenses report', {
        from: 'Empty state expenses'
      })
      toggleUploadExpenses(true)
    }
    const { toggleUploadExpenses } = this.props

    return <EmptyState
      image={<img src={emptyImage} alt='No expenses' />}
      title='Integrate your expenses'
      buttons={[
        <EnableFor scopes={[SCOPES.EXPENSE_WRITE]} allowForToriiAdmin>
          <Button type={ButtonType.primary} onClick={onClick} label='Upload expenses report' />
        </EnableFor>
      ]}
    />
  }

  render () {
    const { isSmallScreen, year, month, showEmptyState } = this.props
    const { CSS } = this.state

    return (
      <>
        {showEmptyState ? <div {...css({ height: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' })}>{this.renderEmptyState()}</div> : (
          <Grid>
            <Row {...CSS.monthSelectorRow}>
              <MonthSelector year={year} month={month} />
            </Row>
            <Row {...css(isSmallScreen ? CSS.smallRow : CSS.row)}>
              <Col sm={6}>
                <ExpensesSummary year={year} month={month} />
              </Col>
              <Col sm={6}>
                <div {...CSS.expensesBox}>
                  <ExpensesBarChart chartHeight={180} showChartSummary chartOverrideStyle={{ marginLeft: '-10px' }} />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Expenses year={year} month={month} />
              </Col>
            </Row>
          </Grid>
        )}
      </>
    )
  }
}

export default withTheme(ExpensesOverview)
