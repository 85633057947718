import AdvancedFilters from '@root/components/advancedFilters'
import { clearEmptyFilters } from '@root/shared/filters'
import { Button, ButtonSize, ButtonType, Popover, Tooltip } from '@toriihq/design-system'
import { noop } from 'lodash'
import { ReactElement } from 'react'
import pluralize from 'pluralize'
import { PopoverContent } from './styles'
import { opsWithValuesDropdown } from '@root/lenses/filters'
import { getValue } from '@root/shared/utils'
import useEffectOnce from '@root/shared/hooks/useEffectOnce'

const Filters = (props): ReactElement => {
  const { filters, optionsKey, optionsValuesPerKey, disabled, disabledReason } = props

  const createFilter = () => {
    const { onFiltersChange, filters } = props
    if (!filters || filters.length === 0) {
      onFiltersChange([{ id: Date.now() }])
    }
  }

  const onPopoverToggle = ({ isOpen }: { isOpen?: boolean | undefined }) => {
    const { onFiltersChange, filters, onFiltersPopupClose } = props
    if (isOpen) {
      createFilter()
    } else {
      clearEmptyFilters(onFiltersChange, filters)
      onFiltersPopupClose(filters)
    }
  }

  const fetchFieldValues = (filters = props.filters) => {
    const { optionsValuesPerKey, fetchFieldValues } = props
    filters.forEach(filter => {
      if (opsWithValuesDropdown.includes(getValue(filter.op)) && !optionsValuesPerKey[getValue(filter.key)]) {
        fetchFieldValues(getValue(filter.key))
      }
    })
  }

  useEffectOnce(() => {
    fetchFieldValues()
  })

  const onFiltersChange = (filters) => {
    const { onFiltersChange } = props
    onFiltersChange(filters)
    fetchFieldValues(filters)
  }

  return (<div>
    <Tooltip placement='top' label={disabledReason || 'Filters'}>
      <Popover
        position='bottom'
        align='end'
        maxWidth={720}
        openOnClick
        onToggle={onPopoverToggle}
        content={<PopoverContent>
          <AdvancedFilters
            optionsKey={optionsKey}
            optionsValuesPerKey={optionsValuesPerKey}
            filters={filters}
            onChange={onFiltersChange}
          />
        </PopoverContent>}
      >
        <Button
          size={ButtonSize.small}
          type={ButtonType.secondary}
          icon='Filter'
          disabled={disabled}
          label={filters.length === 0 ? '' : pluralize('Condition', filters.length, true)}
          onClick={noop}
          htmlButtonType='button'
        />
      </Popover>
    </Tooltip>
  </div>
  )
}

export default Filters
