import React, { Component } from 'react'
import ToriiPopup from '../ToriiPopupV2'
import { css } from 'glamor'
import { Field } from 'react-final-form'
import InputField from '../inputPopup/input'
import EnableFor from '../../enableFor'
import { SCOPES } from '../../../constants'
import ScopesSelectionTable from '../../scopesSelectionTable'
import Analytics from '../../../helpers/analytics'
import { FORM_ERROR } from 'final-form'
import Placeholder from '../../placeholder'

const CSS = {
  nameField: css({
    width: '40%'
  })
}

class RolePopup extends Component {
  componentDidMount () {
    const { idOrg } = this.props

    if (idOrg) {
      this.fetchData()
    }
  }

  componentDidUpdate (prevProps) {
    const { idOrg, idRole } = this.props
    if ((idOrg && prevProps.idOrg !== idOrg) || (idRole && prevProps.idRole !== idRole)) {
      this.fetchData()
    }
  }

  fetchData () {
    const { idOrg, idRole, getRole, getRoles, getScopes } = this.props

    getScopes()
    getRoles({ idOrg })
    idRole && getRole({ idOrg, idRole })
  }

  onSubmit = async (form) => {
    const { createRole, updateRole, onSuccess, idRole, createRoleScopesConfig } = this.props

    const analyticsProps = idRole ? { 'Role ID': idRole } : {}
    Analytics.track(`Clicked to submit form: ${idRole ? 'edit' : 'add'} role`, analyticsProps)

    const func = idRole ? updateRole : createRole

    const formattedForm = {
      ...form,
      scopes: createRoleScopesConfig(form.scopes)
    }

    return func(formattedForm)
      .then(onSuccess).catch(e => { return { [FORM_ERROR]: 'Error, please try again' } })
  }

  validateRoleName = (roleName) => {
    const { roles, idRole } = this.props

    if (!roleName) {
      return `Required`
    }

    if (roles.some(role => role.name.toLowerCase() === roleName.toLowerCase() && role.id !== idRole)) {
      return 'Role with the same name already exists'
    }
  }

  scopesSelectionComponent = ({ input }) => {
    const { loading, isReadOnly, isSmallScreen } = this.props

    return (
      <ScopesSelectionTable
        loading={loading}
        scopes={input.value}
        onChange={input.onChange}
        isReadOnly={isReadOnly}
        isSmallScreen={isSmallScreen}
      />)
  }

  render () {
    const { isOpen, cancel, idRole, roleInfo, isReadOnly, loading } = this.props

    return (!loading || !isReadOnly) && <ToriiPopup
      isOpen={isOpen}
      onCloseAction={cancel}
      width='728px'
    >
      <ToriiPopup.Header header={<div>
        <Placeholder loading={loading && isReadOnly}>
          { isReadOnly ? roleInfo.name || '' : `${idRole ? 'Edit' : 'Add'} Role`}
        </Placeholder>
      </div>} subHeader={isReadOnly && <div >
        <Placeholder loading={loading && isReadOnly}>
          {roleInfo.description || ''}
        </Placeholder>
      </div>} />
      <ToriiPopup.Form
        onSubmit={this.onSubmit}
        initialValues={roleInfo}
        render={(formProps) => {
          const { handleSubmit } = formProps
          return <form onSubmit={handleSubmit}>
            <div>
              {(!isReadOnly) &&
              <div {...CSS.nameField}>
                <EnableFor scopes={[SCOPES.MEMBERS_AND_ROLES_WRITE]}>
                  <Field
                    name='name'
                    label='Role name'
                    component={InputField}
                    validate={this.validateRoleName}
                    disabled={loading}
                    isRequired
                  />
                </EnableFor>
              </div>}
              {(!isReadOnly) &&
              <EnableFor scopes={[SCOPES.MEMBERS_AND_ROLES_WRITE]}>
                <Field
                  name='description'
                  label='Description'
                  component={InputField}
                  disabled={loading}
                  validate={value => value ? undefined : 'Required'}
                  isRequired
                />
              </EnableFor>}
              <Field
                name='scopes'
                component={this.scopesSelectionComponent}
              />
            </div>
          </form>
        }}
        renderFooter={(formProps) => !loading && (
          <ToriiPopup.CustomFooter>
            <ToriiPopup.Footer.Buttons>
              <ToriiPopup.Footer.CancelButton label={isReadOnly ? 'Close' : 'Cancel'} />
              {
                !isReadOnly && (<ToriiPopup.Footer.SubmitButton formProps={formProps} scopes={[SCOPES.MEMBERS_AND_ROLES_WRITE]} label={`${idRole ? 'Update' : 'Create'} role`} />
                )
              }
            </ToriiPopup.Footer.Buttons>
          </ToriiPopup.CustomFooter>
        )}
      />

    </ToriiPopup>
  }
}

export default RolePopup
