import React, { useEffect, useMemo, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../table/index'
import { getLineItemColumns } from './columnConfig'
import { getAppOrgLicenses as getAppOrgLicensesSelector } from '@root/store/selectors/apps'
import { getAppOrgLicenses } from '@root/store/actions'
import { Button, ButtonSize, ButtonType, Subtitle2, EmptyState } from '@toriihq/design-system'
import { getContractsFields } from '@root/store/selectors/contracts'
import { getUserPreferences } from '@root/store/selectors/ui'
import AccessControl from '@lenses/accessControl'
import { SCOPES, SCOPES_PREFIX, TABLES, UPDATE_USER_PREFERENCES } from '@root/constants'
import VisibleFor from '@root/components/visibleFor'
import { lineItemsDataInTableFormat } from './tableDataFormat/lineItemsDataInTableFormat'
import { analytics } from './analytics'
import { useTheme } from 'styled-components'
import get from 'lodash/get'

const PAYMENT_TERM_SYSTEM_KEY = 'paymentTerm'

interface LineItemsProps {
  idOrg: string
  idApp: string
  fields: any
  currencySymbol: string
  appName: string
  idContract: number
}

export const LineItems: React.FC<LineItemsProps> = ({ idOrg, idApp, fields, currencySymbol, appName, idContract }) => {
  const dispatch = useDispatch()
  const newItemIndexRef = useRef<number | null>(null)
  const theme = useTheme()

  useEffect(() => {
    if (idApp) {
      dispatch(getAppOrgLicenses({ idOrg, idApp }))
    }
  }, [idApp, idOrg, dispatch])

  const hasLicenseCostReadAccess = AccessControl.useIsAccessible({ scopes: [SCOPES.LICENSE_AND_CHARGEBACK_READ] })
  const hasLicenseWriteAccess = AccessControl.useIsAccessible({ scopes: [SCOPES.LICENSE_AND_CHARGEBACK_WRITE] })
  const contractWriteOrAppOwnerScopes = [SCOPES.CONTRACTS_WRITE, `${SCOPES_PREFIX.APP_OWNER}-${idOrg}-app-${idApp}`]
  const hasContractsWriteAccessOrAppOwner = AccessControl.useIsAccessible({ scopes: contractWriteOrAppOwnerScopes })

  const { appLicenses = [] } = useSelector(getAppOrgLicensesSelector) || {}
  const contractFields = useSelector(getContractsFields)
  const userPreferences = useSelector(getUserPreferences)
  const tablePreferencesKey = `${TABLES.contractLineItemsTable.key}-${idContract ?? 'new'}`
  const tableInfo = get(userPreferences, tablePreferencesKey, { columnsConfiguration: TABLES.contractLineItemsTable.columnsConfiguration })
  const columnsConfiguration = tableInfo.columnsConfiguration.reduce((result, column) => {
    result[column] = true
    return result
  }, {})

  const paymentTermsOptions = useMemo(() => {
    const fields = contractFields || []
    const paymentTerm = fields.find(field => field?.systemKey === PAYMENT_TERM_SYSTEM_KEY)
    if (!paymentTerm?.options) return []
    return paymentTerm.options.map(({ label, value }) => ({ label, value }))
  }, [contractFields])

  const data = lineItemsDataInTableFormat({
    fields,
    appOrgLicenses: appLicenses,
    currencySymbol,
    paymentTermsOptions,
    hasLicenseWriteAccess,
    hasContractsWriteAccessOrAppOwner,
    newItemIndex: newItemIndexRef.current
  })

  // Reset the focus flag after render
  useEffect(() => {
    newItemIndexRef.current = null
  })

  const onLineItemDelete = useCallback((index: number) => {
    fields.remove(index)
    analytics.onRemoveLineItem(appName)
  }, [fields, appName])

  const configurableColumnsOptions = useMemo(() => {
    const { preDefinedColumnsMapping = {} } = TABLES.contractLineItemsTable || {}
    return Object.entries(preDefinedColumnsMapping).map(([key, value]) => ({
      value: key,
      label: value
    }))
  }, [])

  const columns = useMemo(() => {
    return getLineItemColumns({
      onDelete: onLineItemDelete,
      currencySymbol,
      hasLicenseCostReadAccess,
      hasContractsWriteAccessOrAppOwner,
      columnsConfiguration
    })
  }, [onLineItemDelete, currencySymbol, hasLicenseCostReadAccess, hasContractsWriteAccessOrAppOwner, columnsConfiguration])

  const onColumnSelectionChange = (fields) => {
    dispatch({
      type: UPDATE_USER_PREFERENCES,
      payload: { table: tablePreferencesKey, userPreference: { columnsConfiguration: fields } }
    })
  }
  return (
    <Table
      tableKey={tablePreferencesKey}
      tableKeyInConstants={TABLES.contractLineItemsTable.key}
      header={<Subtitle2>LINE ITEMS</Subtitle2>}
      columns={columns}
      style={{ minHeight: '70px' }}
      data={data}
      tableHeaderStyle={{ padding: '16px 20px', borderBottom: data.length ? 'none' : `1px solid ${theme.palette.border.primary}` }}
      configurableColumns={data?.length > 0}
      configurableColumnsOptions={configurableColumnsOptions}
      onColumnSelectionChange={onColumnSelectionChange}
      extraHeaderComponent={
        <VisibleFor scopes={contractWriteOrAppOwnerScopes}>
          <Button
            type={ButtonType.compact}
            size={ButtonSize.small}
            onClick={() => {
              const newIndex = fields.length
              fields.push({})
              newItemIndexRef.current = newIndex
              analytics.onAddLineItem(appName)
            }}
            label='+ Add line item'
          />
        </VisibleFor>
      }
      emptyStateStyle={{
        style: {
          position: 'relative',
          width: '100px',
          left: 0,
          transform: 'translateX(10%)',
          top: 0,
          padding: 0
        }
      }}
      emptyStateMessage={
        <EmptyState
          image={null}
          description='No line items'
        />
      }
    />
  )
}

export default LineItems
