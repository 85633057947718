import styled from 'styled-components'

export const AxisLabel = styled.div<{ isCenter?: boolean, isTruncated?: boolean }>`
  font-family: 'Nunito Sans';
  font-size: 14px;
  color: ${({ theme }) => theme.palette.text.tertiary};
  text-align: ${({ isCenter }) => isCenter ? 'center' : 'right'};
  line-height: 14px;
  height: 28px;
  overflow: hidden;
  display: flex;
  align-items: center;
  word-wrap: break-word;

  > span {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    padding-right: ${({ isTruncated }) => isTruncated ? '8px' : '0'};
  }
`

export const ChartContainer = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    height: 0;
    width: 0;
  }
`
