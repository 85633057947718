import ToriiPopupV2 from '@components/popups/ToriiPopupV2'
import { ButtonType } from '@toriihq/design-system'

interface CancelChangesPopupProps {
    isOpen: boolean
    onKeepEditing: () => void
    onCancelChanges: () => void
    content?: string
}

const CancelChangesPopup = ({ isOpen, onKeepEditing, onCancelChanges, content }: CancelChangesPopupProps) => {
  return (
    <ToriiPopupV2 isOpen={isOpen} onCloseAction={onKeepEditing} width='518px'>
      <ToriiPopupV2.Header header='Cancel changes' />
      {content && <ToriiPopupV2.Content>{content}</ToriiPopupV2.Content>}
      <ToriiPopupV2.Footer
        cancelButtonText='Keep editing'
        cancelButtonAction={onKeepEditing}
        mainButtonText='Cancel changes'
        mainButtonAction={onCancelChanges}
        mainButtonType={ButtonType.destructive}
      />
    </ToriiPopupV2>
  )
}

export default CancelChangesPopup
