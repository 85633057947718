import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Placeholder = {
  height: '60px',
  width: '300px'
}
