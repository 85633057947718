import { ReactElement, useState } from 'react'
import { getAppsFieldValues } from '@store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getIdOrg } from '@selectors/org'
import { fieldTypes, SCOPES, formFieldTypes } from '@root/constants'
import AccessControl from '@lenses/accessControl'
import getAppColumns from '@components/appsV2/columns'
import {
  getAppDetailsStateField,
  getFieldValues as getAppFieldValuesSelector
} from '@selectors/apps'
import config from '@root/config'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { DynamicWidgetInternalDataPopupProps } from '../types'
import { useGetEntityTableData } from '../shared.hooks'

const AppDataPopup = (props: DynamicWidgetInternalDataPopupProps): ReactElement => {
  const dispatch = useDispatch()
  const { renderFunc, widget, fields = [] } = props

  const [columns, setColumns] = useState([])

  const { preDefinedColumnsMapping, configurableColumnsOptions, filtersOptions, customFields, columnsConfiguration } = useGetEntityTableData({ entityType: widget.entityType, fields })

  const idOrg = useSelector(getIdOrg)
  const appFieldValues = useSelector(getAppFieldValuesSelector)
  const stateInfo = useSelector(getAppDetailsStateField)

  const hasLicenseCostAndChargebackAccess = AccessControl.useIsAccessible({ scopes: [SCOPES.LICENSE_AND_CHARGEBACK_READ, SCOPES.APP_OWNER_READ] })
  const hasExpensesAccess = AccessControl.useIsAccessible({ scopes: [SCOPES.EXPENSE_READ, SCOPES.APP_OWNER_READ] })
  const hasContractsAccess = AccessControl.useIsAccessible({ scopes: [SCOPES.CONTRACTS_READ, SCOPES.APP_OWNER_READ] })

  const fieldValues = appFieldValues

  const fetchFieldValues = (field) => {
    const selectedFilterOption = filtersOptions.find(f => f.value === field) || { type: '' }
    const isFieldSupportValues = [fieldTypes.text, fieldTypes.user, fieldTypes.dropdown, fieldTypes.dropdownMulti, fieldTypes.userMulti].includes(selectedFilterOption.type)
    if (isFieldSupportValues) {
      dispatch(getAppsFieldValues({ idOrg, fields: [field] }))
    }
  }

  const dynamicColumnsInfo = customFields
    .filter(detail => detail.category === 'app_details' && detail.isShown)
    .filter(detail => columnsConfiguration[detail.systemKey] && !preDefinedColumnsMapping[detail.systemKey])

  useDeepCompareEffect(() => {
    setColumns(getAppColumns({
      columnsConfiguration,
      dynamicColumnsInfo,
      preDefinedColumnsMapping,
      stateInfo: { ...stateInfo, readOnly: true },
      isCategoryFieldReadOnly: true,
      columnsRestrictions: { hasExpensesAccess, hasContractsAccess, hasLicenseCostAndChargebackAccess },
      idOrg,
      customFieldPropsByType: {
        [formFieldTypes.usersDropdown]: ({ field }) => ({
          disabled: true
        })
      }
    }) as any)
  }, [columnsConfiguration, dynamicColumnsInfo, preDefinedColumnsMapping, hasContractsAccess, hasExpensesAccess, hasLicenseCostAndChargebackAccess, idOrg])

  const exportToCsv = ({ sort, query, filters }) => {
    const sortParam = 'sort=' + sort.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')
    const queryParam = `q=${query}`
    const columnsOrder = fields
    const columnsOrdered = columns.sort((a: any, b: any) => columnsOrder.indexOf(a.id || a.accessor) - columnsOrder.indexOf(b.id || b.accessor))
    const fieldsParam = `fields=${columnsOrdered.filter((col: any) => ((col.show === undefined || col.show) && !col.hideFromCSV)).map((col: any) => col.id || col.accessor).join(',')}`
    const filtersParam = `filters=${encodeURIComponent(JSON.stringify(filters))}`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/${widget.entityType}/csv?${sortParam}&${queryParam}&${filtersParam}&${fieldsParam}`
    const newWindow = window.open(url, '_blank') as any
    newWindow.opener = null
  }

  return renderFunc({ columns, filtersOptions, fieldValues, configurableColumnsOptions, fetchFieldValues, exportToCsv })
}

export default AppDataPopup
