import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  Stack,
  H4,
  Spacer,
  Button
} from '@toriihq/design-system'
import { RendererProps } from '@root/components/popups/wizardPopup/types'
import { SharedProps, SharedState, WIZARD_STEPS } from '../wizardSteps/types'
import { FullHeight, FullWidth, RecommendationContainer } from './style'
import Header from '../wizardSteps/header'
import Footer from '../wizardSteps/footer'
import CopyTextPopup from '@components/copyTextPopup'
import config from '@root/config'
import { sumBy, get, orderBy } from 'lodash'
import { getCurrentOrg } from '@root/store/selectors/org'
import Analytics from '../analytics'
import { sortColumns } from '@root/components/table/view'
import { getUserPreferences } from '@root/store/selectors/ui'
import { EMPTY_OBJECT, TABLES } from '@root/constants'
import { TableColumn } from '@root/components/table/types'
import DropdownSettings from '@pages/renewalForecast/recommendation/dropdownSettings'
import WidgetCardGrid from '@pages/renewalForecast/recommendation/widgetCardGrid'
import LicenseBreakdown from '@pages/renewalForecast/recommendation/licenseBreakdown'
import { LicensesDetails } from '@pages/renewalForecast/recommendation/types'
import { INACTIVE_PERIOD_OPTIONS } from '@root/constants.t'

const Recommendation = ({
  sharedProps, navigateToStep, onCancel
}: RendererProps<SharedState, SharedProps, WIZARD_STEPS>): JSX.Element => {
  const { idOrg, app, appName, licenses, idApp, idAppAccount, appAccount, onChangeInactivityPeriod } = sharedProps
  const { appNotInUsePeriod } = useSelector(getCurrentOrg)
  const [showOnlyPaidLicenses, setShowOnlyPaidLicenses] = useState(true)
  const [bufferValue, setBufferValue] = useState(0)

  const userPreferences = useSelector(getUserPreferences)
  const userTablePreferences = userPreferences[TABLES.appAccountLicenseUsersTable.key] || EMPTY_OBJECT
  const [selectedColumns, setSelectedColumns] = React.useState<TableColumn[] | null>(null)
  const initialViewWithAllUtilizationStatuses = licenses.map(license => ({ idLicense: license.id, view: 'all' })).reduce((acc, { idLicense, view }) => ({ ...acc, [idLicense]: view }), {})
  const [selectedView, setSelectedView] = React.useState<{ [idLicense: number]: string }>(initialViewWithAllUtilizationStatuses)
  const [inactivityPeriod, setInactivityPeriod] = useState(INACTIVE_PERIOD_OPTIONS.find(option => option.value === appNotInUsePeriod))

  const licensesDetails: LicensesDetails = useMemo(() => {
    const licensesToDisplay = showOnlyPaidLicenses ? licenses.filter(license => {
      const pricePerUser = get(license, 'pricePerUser', 0)
      return pricePerUser > 0
    }) : licenses

    const totalLicenseCost = sumBy(licensesToDisplay, 'totalCostConverted')
    const forecastedLicenseCost = licensesToDisplay.reduce((acc, license) => {
      const activeAmount = license.activeAmount
      const bufferAmount = Math.floor(activeAmount * bufferValue / 100)
      acc += (activeAmount + bufferAmount) * license.pricePerUserConverted
      return acc
    }, 0)
    const savings = forecastedLicenseCost - totalLicenseCost

    const LicensesWithTotalAmount = orderBy(licensesToDisplay, ['validTotalAmount'], 'desc')
    const maxLicensesAmount = Math.max(
      ...LicensesWithTotalAmount.map(license => license.validTotalAmount),
      ...licensesToDisplay.map(license => license.activeAmount + Math.floor(license.activeAmount * bufferValue / 100))
    )

    return { licenses: licensesToDisplay, savings, totalLicenseCost, forecastedLicenseCost, maxLicensesAmount }
  }, [licenses, bufferValue, showOnlyPaidLicenses])

  useEffect(() => {
    setInactivityPeriod(INACTIVE_PERIOD_OPTIONS.find(option => option.value === appNotInUsePeriod))
  }, [appNotInUsePeriod])

  const onBackClick = () => {
    Analytics.clickOnBackButton({ appName, stepName: WIZARD_STEPS.RECOMMENDATIONS })
    navigateToStep(WIZARD_STEPS.LICENSES_REVIEW)
    window.history.pushState({}, '', `/team/${idOrg}/app/${idApp}/account/${idAppAccount}/renewalForecast/${WIZARD_STEPS.LICENSES_REVIEW}`)
  }

  const onClose = () => {
    Analytics.clickOnCloseButton({ appName, stepName: WIZARD_STEPS.RECOMMENDATIONS })
    onCancel()
  }

  const exportToCsv = () => {
    Analytics.exportCSV({ appName })
    sortColumns(selectedColumns, userTablePreferences)
    const fieldsParam = selectedColumns && `fields=${selectedColumns.filter(col => ((col.show === undefined || col.show) && !col.hideFromCSV)).map(col => col.id || col.accessor).join(',').replace('fullName', 'firstName,lastName')}`

    const licensesParam = `idLicenses=${licenses.map(license => license.id).join(',')}`

    const view = selectedView && Object.keys(selectedView).map(idLicense => ({ idLicense: Number(idLicense), view: selectedView[idLicense] }))
    const viewPerLicense = view && `viewPerLicense=${encodeURIComponent(JSON.stringify(view))}`
    const inactivityPeriodParam = inactivityPeriod && `inactivityPeriod=${inactivityPeriod.value}`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/renewalForecast/${idApp}/csv?${licensesParam}&${fieldsParam}&${viewPerLicense}&${inactivityPeriodParam}`
    const newWindow = window.open(url, '_blank')
    newWindow!.opener = null
  }

  const PageHeader = () => (
    <FullWidth>
      <Stack direction={'row'} justifyContent={'space-between'} gap={'space-200'} alignItems='flex-start'>
        <H4>Review Forecast</H4>
        <Stack direction='row' gap='space-150'>
          <CopyTextPopup
            icon='Share'
            label='Share'
            textToCopy={`${config.appBaseUrl}/team/${idOrg}/app/${idApp}/account/${idAppAccount}/renewalForecast/${WIZARD_STEPS.RECOMMENDATIONS}`}
            analyticsText='Click on copy-link-button'
            analyticsProps={{ appName }}
            analyticsButtonText='Click on share-forecast'
            analyticsButtonProps={{ appName }}
            bodyText={`Share ${appName} renewal forecast`}
          />
          <Button icon='Download' label='CSV' type='secondary' onClick={exportToCsv} />
        </Stack>
      </Stack>
    </FullWidth>
  )

  const onSelectedViewChange = (selectedView) => {
    setSelectedView(prevState => ({ ...prevState, [selectedView.idLicense]: selectedView.view }))
  }

  return (
    <FullHeight>
      <Stack direction={'column'} gap={'space-400'} alignItems={'start'} >
        <Header app={app} accountName={appAccount.appAccountName} currentStep={WIZARD_STEPS.RECOMMENDATIONS} onBackClick={onCancel} />
        <FullWidth>
          <RecommendationContainer>
            <Stack direction={'column'} gap={'space-600'} alignItems={'start'}>
              <FullWidth>
                <Stack gap={'space-300'} direction={'column'}>
                  <PageHeader />
                  <DropdownSettings showOnlyPaidLicenses={showOnlyPaidLicenses} setShowOnlyPaidLicenses={setShowOnlyPaidLicenses} bufferValue={bufferValue} setBufferValue={setBufferValue} appName={appName} inactivityPeriod={inactivityPeriod} setInactivityPeriod={setInactivityPeriod} onChangeInactivityPeriod={onChangeInactivityPeriod} />
                  <WidgetCardGrid licensesDetails={licensesDetails} />
                </Stack>
              </FullWidth>
              <LicenseBreakdown
                idOrg={idOrg}
                idApp={idApp}
                idAppAccount={idAppAccount}
                appName={appName}
                licensesDetails={licensesDetails}
                bufferValue={bufferValue}
                onSelectedColumnsChange={setSelectedColumns}
                onSelectedViewChange={onSelectedViewChange}
                inactivityPeriod={inactivityPeriod}
              />
            </Stack>
          </RecommendationContainer>
        </FullWidth>
        <Spacer bottom='space-025' ><div /></Spacer>
      </Stack>
      <Footer
        shouldShowBackButton
        onBackClick={onBackClick}
        onCloseClick={onClose}
      />
    </FullHeight>
  )
}

export default Recommendation
