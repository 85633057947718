import startCase from 'lodash/startCase'
import keyBy from 'lodash/keyBy'

const ICONS_FOLDER = 'https://res.cloudinary.com/dy38uvqxu/image/upload/Integrations%20-%20Icons/'
const GRAY_SCALE_ICON_FOLDER = `${ICONS_FOLDER}Grayscale/`
const COLOR_ICON_FOLDER = `${ICONS_FOLDER}Color/`

export const DISCOVERED_APPS_SOURCES = ['okta', 'slack', 'google', 'microsoftTeams', 'keycloak', 'azure_ad', 'office365', 'onelogin', 'salesforce', 'jumpCloud', 'jumpCloud_mdm', 'jamf', 'kandji', 'microsoftIntune', 'workspaceOneUem']
// Sources that are sources of apps (google, azure, etc'...), have to have an entry here with idApp
export const SOURCE_TYPES = {
  okta: {
    id: 'okta',
    idApp: 2554,
    name: 'Okta',
    tooltip: 'Okta',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}okta.svg`, color: `${COLOR_ICON_FOLDER}okta.svg` },
    userTooltip: ({ displayName, appName, source }) => {
      const isSource = (source === SOURCE_TYPES.okta.source && appName === SOURCE_TYPES.okta.name)
      return isSource ? `${displayName} is an Okta user` : `${displayName} signed in to ${appName} using Okta`
    },
    appsTooltip: ({ appName, source }) => {
      const isSource = (source === SOURCE_TYPES.okta.source && appName === SOURCE_TYPES.okta.name)
      return isSource ? `Okta was connected to Torii` : `${appName} is configured on Okta`
    },
    userStatus: {
      STAGED: 'Staged',
      PROVISIONED: 'Provisioned',
      ACTIVE: 'Active',
      RECOVERY: 'Recovery',
      LOCKED_OUT: 'Locked out',
      PASSWORD_EXPIRED: 'Password expired',
      SUSPENDED: 'Suspended',
      DEPROVISIONED: 'Deprovisioned'
    },
    source: 'okta',
    type: 'okta',
    isSSO: true
  },
  google: {
    id: 'google',
    idApp: 71,
    name: 'Google Workspace',
    tooltip: 'Google connected',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}google.svg`, color: `${COLOR_ICON_FOLDER}google.svg` },
    userTooltip: ({ displayName, appName, source }) => {
      const isSource = (source === SOURCE_TYPES.google.source && appName === SOURCE_TYPES.google.name)
      return isSource ? `${displayName} is a Google Workspace user` : `${displayName} connected to ${appName} using their Google account`
    },
    appsTooltip: ({ appName, source }) => {
      const isSource = (source === SOURCE_TYPES.google.source && appName === SOURCE_TYPES.google.name)
      return isSource ? `Google Workspace was connected to Torii` : `At least one team member connected ${appName} to their Google account`
    },
    userStatus: {
      ACTIVE: 'Active',
      SUSPENDED: 'Suspended'
    },
    source: 'google',
    type: 'google',
    isSSO: true,
    mergeUsersFieldName: 'emails',
    mergeUsersFieldKey: 'gsuite_emails',
    hasPermissions: true
  },
  onelogin: {
    id: 'onelogin',
    idApp: 3128,
    name: 'OneLogin',
    tooltip: 'OneLogin',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}onelogin.svg`, color: `${COLOR_ICON_FOLDER}onelogin.svg` },
    userTooltip: ({ displayName, appName, source }) => {
      const isSource = (source === SOURCE_TYPES.onelogin.source && appName === SOURCE_TYPES.onelogin.name)
      return isSource ? `${displayName} is a OneLogin user` : `${displayName} signed in to ${appName} using OneLogin`
    },
    appsTooltip: ({ appName, source }) => {
      const isSource = (source === SOURCE_TYPES.onelogin.source && appName === SOURCE_TYPES.onelogin.name)
      return isSource ? `OneLogin was connected to Torii` : `${appName} is configured on OneLogin`
    },
    userStatus: {
      UNACTIVATED: 'Unactivated',
      ACTIVE: 'Active',
      SUSPENDED: 'Suspended',
      LOCKED: 'Locked',
      PASSWORD_EXPIRED: 'Password expired',
      AWAITING_PASSWORD_RESET: 'Awaiting password reset'
    },
    userState: {
      UNAPPROVED: 'Unapproved',
      APPROVED: 'Approved',
      REJECTED: 'Rejected',
      UNLICENSED: 'Unlicensed'
    },
    source: 'onelogin',
    type: 'onelogin',
    isSSO: true
  },
  slack: {
    id: 'slack',
    idApp: 170,
    name: 'Slack',
    tooltip: 'Slack connected',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}slack.svg`, color: `${COLOR_ICON_FOLDER}slack.svg` },
    userTooltip: ({ displayName, appName, source }) => {
      const isSource = (source === SOURCE_TYPES.slack.source && appName === SOURCE_TYPES.slack.name)
      return isSource ? `${displayName} is a Slack user` : `${displayName} connected ${appName} to your Slack account`
    },
    appsTooltip: ({ appName, source }) => {
      const isSource = (source === SOURCE_TYPES.slack.source && appName === SOURCE_TYPES.slack.name)
      return isSource ? `Slack was connected to Torii` : `At least one team member connected ${appName} to Slack`
    },
    source: 'slack',
    type: 'slack',
    hasPermissions: true
  },
  microsoftTeams: {
    id: 'microsoftTeams',
    idApp: 4064,
    name: 'Microsoft Teams',
    tooltip: 'Microsoft Teams connected',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}microsoft_teams.svg`, color: `${COLOR_ICON_FOLDER}microsoft_teams.svg` },
    userTooltip: ({ displayName, appName, source }) => {
      const isSource = (source === SOURCE_TYPES.microsoftTeams.source && appName === SOURCE_TYPES.microsoftTeams.name)
      return isSource ? `${displayName} is a Microsoft Teams user` : `${displayName} connected ${appName} to your Microsoft Teams account`
    },
    appsTooltip: ({ appName, source }) => {
      const isSource = (source === SOURCE_TYPES.microsoftTeams.source && appName === SOURCE_TYPES.microsoftTeams.name)
      return isSource ? `Microsoft Teams was connected to Torii` : `At least one team member connected ${appName} to Microsoft Teams`
    },
    source: 'microsoftTeams',
    type: 'microsoftTeams'
  },
  jamf: {
    id: 'jamf',
    idApp: 3458,
    name: 'Jamf',
    tooltip: 'Jamf connected',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}jamf.svg`, color: `${COLOR_ICON_FOLDER}jamf.svg` },
    userTooltip: ({ displayName, appName, source }) => {
      const isSource = (source === SOURCE_TYPES.jamf.source && appName === SOURCE_TYPES.jamf.name)
      return isSource ? `${displayName} is a Jamf user` : `Jamf discovered ${appName} installed on ${displayName}’s device`
    },
    appsTooltip: ({ appName, source }) => {
      const isSource = (source === SOURCE_TYPES.jamf.source && appName === SOURCE_TYPES.jamf.name)
      return isSource ? `Jamf was connected to Torii` : `At least one team member was found for ${appName} via Jamf`
    },
    source: 'jamf',
    type: 'jamf'
  },
  kandji: {
    id: 'kandji',
    idApp: 8225,
    name: 'Kandji',
    tooltip: 'Kandji connected',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}kandji.svg`, color: `${COLOR_ICON_FOLDER}kandji.svg` },
    userTooltip: ({ displayName, appName, source }) => {
      const isSource = (source === SOURCE_TYPES.kandji.source && appName === SOURCE_TYPES.kandji.name)
      return isSource ? `${displayName} is a Kandji user` : `Kandji discovered ${appName} installed on ${displayName}’s device`
    },
    appsTooltip: ({ appName, source }) => {
      const isSource = (source === SOURCE_TYPES.kandji.source && appName === SOURCE_TYPES.kandji.name)
      return isSource ? `Kandji was connected to Torii` : `At least one team member was found for ${appName} via Kandji`
    },
    source: 'kandji',
    type: 'kandji'
  },
  workspaceOneUem: {
    id: 'workspaceOneUem',
    idApp: 6365,
    name: 'Workspace One UEM',
    tooltip: 'Workspace One UEM connected',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}WorkspaceOne_UEM.svg`, color: `${COLOR_ICON_FOLDER}WorkspaceOne_UEM.svg` },
    userTooltip: ({ displayName, appName, source }) => {
      const isSource = (source === SOURCE_TYPES.workspaceOneUem.source && appName === SOURCE_TYPES.workspaceOneUem.name)
      return isSource ? `${displayName} is a Workspace One UEM user` : `Workspace One UEM discovered ${appName} installed on ${displayName}’s device`
    },
    appsTooltip: ({ appName, source }) => {
      const isSource = (source === SOURCE_TYPES.workspaceOneUem.source && appName === SOURCE_TYPES.workspaceOneUem.name)
      return isSource ? `Workspace One UEM user was connected to Torii` : `At least one team member was found for ${appName} via Workspace One UEM`
    },
    source: 'workspaceOneUem',
    type: 'workspaceOneUem'
  },
  microsoftIntune: {
    id: 'microsoftIntune',
    idApp: 4551,
    name: 'Microsoft Intune',
    tooltip: 'Microsoft Intune connected',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}desktop.svg`, color: `${COLOR_ICON_FOLDER}desktop.svg` },
    userTooltip: ({ displayName, appName, source }) => {
      const isSource = (source === SOURCE_TYPES.microsoftIntune.source && appName === SOURCE_TYPES.microsoftIntune.name)
      return isSource ? `${displayName} is a Microsoft Intune user` : `Microsoft Intune discovered ${appName} installed on ${displayName}’s device`
    },
    appsTooltip: ({ appName, source }) => {
      const isSource = (source === SOURCE_TYPES.microsoftIntune.source && appName === SOURCE_TYPES.microsoftIntune.name)
      return isSource ? `Microsoft Intune was connected to Torii` : `At least one team member was found for ${appName} via Microsoft Intune`
    },
    source: 'microsoftIntune',
    type: 'microsoftIntune'
  },
  salesforce: {
    id: 'salesforce',
    idApp: 258,
    name: 'Salesforce',
    tooltip: 'Salesforce connected',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}salesforce.svg`, color: `${COLOR_ICON_FOLDER}salesforce.svg` },
    userTooltip: ({ displayName, appName, source }) => {
      const isSource = (source === SOURCE_TYPES.salesforce.source && appName === SOURCE_TYPES.salesforce.name)
      return isSource ? `${displayName} is a Salesforce user` : `${displayName} connected ${appName} to your Salesforce account`
    },
    appsTooltip: ({ appName, source }) => {
      const isSource = (source === SOURCE_TYPES.salesforce.source && appName === SOURCE_TYPES.salesforce.name)
      return isSource ? `Salesforce was connected to Torii` : `At least one team member connected ${appName} to Salesforce`
    },
    source: 'salesforce',
    type: 'salesforce'
  },
  extension: {
    id: 'extension',
    name: 'Extension',
    tooltip: 'Extension',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}extension.svg`, color: `${COLOR_ICON_FOLDER}extension.svg` },
    userTooltip: ({ appName }) => `Torii's browser extension identified a login to ${appName}`,
    appsTooltip: ({ appName }) => `Torii's browser extension identified a login to ${appName} by at least one team member`
  },
  torii: {
    id: 'torii',
    idApp: 1,
    name: 'Torii',
    tooltip: 'Torii',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}torii.svg`, color: `${COLOR_ICON_FOLDER}torii.svg` },
    userTooltip: ({ displayName, appName }) => `${displayName} is a ${appName} user`,
    appsTooltip: () => `Torii is in use`,
    source: 'torii',
    type: 'torii'
  },
  toriiBot: {
    id: 'toriiBot',
    name: 'Torii Bot integration',
    tooltip: 'Torii Bot',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}custom_integration.svg`, color: `${COLOR_ICON_FOLDER}custom_integration.svg` },
    userTooltip: ({ displayName }) => `${displayName} was detected by the Torii Bot`,
    appsTooltip: ({ appName }) => `${appName} was found by the Torii Bot`,
    source: 'toriiBot',
    type: 'toriiBot'
  },
  'expense files': {
    id: 'expense files',
    name: 'Expense files',
    tooltip: 'Expenses',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}expenses_manual.svg`, color: `${COLOR_ICON_FOLDER}expenses_manual.svg` },
    userTooltip: () => '',
    appsTooltip: ({ appName }) => `${appName} was found in your expense files`
  },
  expenses: {
    id: 'expenses',
    name: 'Expenses',
    tooltip: 'Expenses',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}expenses_manual.svg`, color: `${COLOR_ICON_FOLDER}expenses_manual.svg` },
    userTooltip: () => '',
    appsTooltip: ({ appName }) => `${appName} was found in your expense reports`
  },
  azure_ad: {
    id: 'azure_ad',
    idApp: 3530,
    name: 'Microsoft Entra ID',
    tooltip: 'Microsoft Entra ID',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}azure_ad.svg`, color: `${COLOR_ICON_FOLDER}azure_ad.svg` },
    userTooltip: ({ displayName, appName, source }) => {
      const isSource = (source === SOURCE_TYPES.azure_ad.source && appName === SOURCE_TYPES.azure_ad.name)
      return isSource ? `${displayName} is a Microsoft Entra ID user` : `${displayName} signed in to ${appName} using Microsoft Entra ID`
    },
    appsTooltip: ({ appName, source }) => {
      const isSource = (source === SOURCE_TYPES.azure_ad.source && appName === SOURCE_TYPES.azure_ad.name)
      return isSource ? `Microsoft Entra ID was connected to Torii` : `${appName} is configured on Microsoft Entra ID`
    },
    source: 'azure_ad',
    type: 'graph.microsoft.com',
    isSSO: true,
    mergeUsersFieldName: 'proxyAddresses',
    mergeUsersFieldKey: 'azure_proxyAddresses',
    hasPermissions: true
  },
  netsuite: {
    id: 'netsuite',
    idApp: 605,
    name: 'NetSuite',
    tooltip: 'NetSuite',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}expenses_integrated.svg`, color: `${COLOR_ICON_FOLDER}expenses_integrated.svg` },
    userTooltip: () => '',
    appsTooltip: ({ appName }) => `${appName} was found on NetSuite`,
    source: 'netsuite',
    type: 'restlet'
  },
  expensify: {
    id: 'expensify',
    idApp: 65,
    name: 'Expensify',
    tooltip: 'Expensify',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}expenses_integrated.svg`, color: `${COLOR_ICON_FOLDER}expenses_integrated.svg` },
    userTooltip: () => '',
    appsTooltip: ({ appName }) => `${appName} was found on Expensify`,
    source: 'expensify',
    type: 'expensify'
  },
  billcom: {
    id: 'billcom',
    idApp: 407,
    name: 'Bill.com',
    tooltip: 'Bill.com',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}expenses_integrated.svg`, color: `${COLOR_ICON_FOLDER}expenses_integrated.svg` },
    userTooltip: () => '',
    appsTooltip: ({ appName }) => `${appName} was found on Bill.com`,
    source: 'billcom',
    type: 'billcom'
  },
  quickbooks: {
    id: 'quickbooks',
    idApp: 287,
    name: 'QuickBooks',
    tooltip: 'QuickBooks',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}expenses_integrated.svg`, color: `${COLOR_ICON_FOLDER}expenses_integrated.svg` },
    userTooltip: () => '',
    appsTooltip: ({ appName }) => `${appName} was found on QuickBooks`,
    source: 'quickbooks',
    type: 'quickbooks'
  },
  jumpCloud: {
    id: 'jumpCloud',
    idApp: 2236,
    name: 'JumpCloud',
    tooltip: 'JumpCloud',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}jumpCloud.svg`, color: `${COLOR_ICON_FOLDER}jumpCloud.svg` },
    userTooltip: ({ displayName, appName, source }) => {
      const isSource = (source === SOURCE_TYPES.jumpCloud.source && appName === SOURCE_TYPES.jumpCloud.name)
      return isSource ? `${displayName} is a JumpCloud user` : `${displayName} signed in to ${appName} using JumpCloud`
    },
    appsTooltip: ({ appName, source }) => {
      const isSource = (source === SOURCE_TYPES.jumpCloud.source && appName === SOURCE_TYPES.jumpCloud.name)
      return isSource ? `JumpCloud was connected to Torii` : `${appName} is configured on JumpCloud`
    },
    source: 'jumpCloud',
    type: 'jumpCloud',
    isSSO: true
  },
  jumpCloud_mdm: {
    id: 'jumpCloud_mdm',
    idApp: 13661,
    name: 'JumpCloud MDM',
    tooltip: 'JumpCloud MDM',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}jumpCloud.svg`, color: `${COLOR_ICON_FOLDER}jumpCloud.svg` },
    userTooltip: ({ displayName, appName, source }) => {
      const isSource = (source === SOURCE_TYPES.jumpCloud_mdm.source && appName === SOURCE_TYPES.jumpCloud_mdm.name)
      return isSource ? `${displayName} is a JumpCloud MDM user` : `JumpCloud MDM discovered ${appName} installed on ${displayName}’s device`
    },
    appsTooltip: ({ appName, source }) => {
      const isSource = (source === SOURCE_TYPES.jumpCloud_mdm.source && appName === SOURCE_TYPES.jumpCloud_mdm.name)
      return isSource ? `JumpCloud MDM was connected to Torii` : `at least one team member was found for ${appName} via JumpCloud MDM`
    },
    source: 'jumpCloud_mdm',
    type: 'jumpCloud_mdm'
  },
  office365: {
    id: 'office365',
    idApp: 128,
    name: 'Office 365',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}office365.svg`, color: `${COLOR_ICON_FOLDER}office365.svg` }
  },
  dropbox: {
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}custom_integration.svg`, color: `${COLOR_ICON_FOLDER}custom_integration.svg` }
  },
  box: {
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}custom_integration.svg`, color: `${COLOR_ICON_FOLDER}custom_integration.svg` }
  },
  intercom: {
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}custom_integration.svg`, color: `${COLOR_ICON_FOLDER}custom_integration.svg` }
  },
  bambooHr: {
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}custom_integration.svg`, color: `${COLOR_ICON_FOLDER}custom_integration.svg` }
  },
  asana: {
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}custom_integration.svg`, color: `${COLOR_ICON_FOLDER}custom_integration.svg` }
  },
  hubspot: {
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}custom_integration.svg`, color: `${COLOR_ICON_FOLDER}custom_integration.svg` }
  },
  jetBrains: {
    name: 'JetBrains'
  },
  keycloak: {
    name: 'Keycloak'
  },
  docusign: {
    name: 'DocuSign'
  },
  logzio: {
    name: 'Logz.io'
  },
  newrelic: {
    name: 'New Relic'
  },
  servicenow: {
    name: 'ServiceNow'
  },
  concur: {
    id: 'concur',
    idApp: 1453,
    name: 'Concur',
    tooltip: 'Concur',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}expenses_integrated.svg`, color: `${COLOR_ICON_FOLDER}expenses_integrated.svg` },
    userTooltip: () => '',
    appsTooltip: ({ appName }) => `${appName} was found on Concur`,
    source: 'concur',
    type: 'concur'
  },
  oracleFinancialsCloud: {
    id: 'oracleFinancialsCloud',
    idApp: 8416,
    name: 'Oracle Financials Cloud',
    tooltip: 'Oracle Financials Cloud',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}expenses_integrated.svg`, color: `${COLOR_ICON_FOLDER}expenses_integrated.svg` },
    userTooltip: () => '',
    appsTooltip: ({ appName }) => `${appName} was found on Oracle Financials Cloud`,
    source: 'oracleFinancialsCloud',
    type: 'oracleFinancialsCloud'
  },
  xero: {
    id: 'xero',
    idApp: 202,
    name: 'Xero',
    tooltip: 'Xero',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}expenses_integrated.svg`, color: `${COLOR_ICON_FOLDER}expenses_integrated.svg` },
    appsTooltip: ({ appName }) => `${appName} was found on Xero`,
    source: 'xero',
    type: 'xero'
  },
  workdayFinancialManagement: {
    id: 'workdayFinancialManagement',
    idApp: 11018,
    name: 'workdayFinancialManagement',
    tooltip: 'workdayFinancialManagement',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}expenses_integrated.svg`, color: `${COLOR_ICON_FOLDER}expenses_integrated.svg` },
    appsTooltip: ({ appName }) => `${appName} was found on Workday Financial Management`,
    source: 'workdayFinancialManagement',
    type: 'workdayFinancialManagement'
  },
  custom: {
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}custom_integration.svg`, color: `${COLOR_ICON_FOLDER}custom_integration.svg` },
    name: ({ appName }) => `${appName} custom integration`,
    userTooltip: ({ displayName }) => `${displayName} was added via custom integration API`,
    appsTooltip: ({ appName }) => `${appName} custom integration was added to Torii`
  },
  manual: {
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}file_upload.svg`, color: `${COLOR_ICON_FOLDER}file_upload.svg` },
    name: () => 'File upload',
    userTooltip: ({ displayName, appName }) => `${displayName} was imported to ${appName} via file upload`
  },
  contract: {
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}contract2.svg`, color: `${COLOR_ICON_FOLDER}contract2.svg` },
    name: 'Contract',
    appsTooltip: ({ appName }) => `A contract was added for ${appName}`
  },
  zip: {
    id: 'zip',
    idApp: 8778,
    name: 'Zip',
    tooltip: 'Zip',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}contract2.svg`, color: `${COLOR_ICON_FOLDER}contract2.svg` },
    appsTooltip: ({ appName }) => `${appName} was found on Zip`,
    source: 'zip',
    type: 'zip'
  },
  ironclad: {
    id: 'ironclad',
    idApp: 5248,
    name: 'Ironclad',
    tooltip: 'Ironclad',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}contract2.svg`, color: `${COLOR_ICON_FOLDER}contract2.svg` },
    appsTooltip: ({ appName }) => `${appName} was found on Ironclad`,
    source: 'ironclad',
    type: 'ironclad'
  },
  sageIntacct: {
    id: 'sageIntacct',
    idApp: 2753,
    name: 'Sage Intacct',
    tooltip: 'Sage Intacct',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}expenses_integrated.svg`, color: `${COLOR_ICON_FOLDER}expenses_integrated.svg` },
    appsTooltip: ({ appName }) => `${appName} was found on SageIntacct`,
    source: 'sageIntacct',
    type: 'sageIntacct'
  },
  ramp: {
    id: 'ramp',
    idApp: 8824,
    name: 'Ramp',
    tooltip: 'Ramp',
    icon: { grayscale: `${GRAY_SCALE_ICON_FOLDER}expenses_integrated.svg`, color: `${COLOR_ICON_FOLDER}expenses_integrated.svg` },
    appsTooltip: ({ appName }) => `${appName} was found on Ramp`,
    source: 'ramp',
    type: 'ramp'
  }
}

export const SOURCE_TYPES_BY_ID_APP = keyBy(Object.values(SOURCE_TYPES), 'idApp')

export const ssoProviders = Object.values(SOURCE_TYPES).filter(source => source.isSSO)

export const getSourceByType = (type) => {
  const sourceName = startCase(type)

  const defaultSource = {
    id: type,
    name: sourceName,
    tooltip: sourceName,
    source: type,
    type: type
  }
  const source = { ...defaultSource, ...SOURCE_TYPES[type] }
  if (!source.icon) {
    source.icon = { grayscale: `${GRAY_SCALE_ICON_FOLDER}custom_integration.svg`, color: `${COLOR_ICON_FOLDER}custom_integration.svg` }
  }

  return (source)
}
