import { WIDGET_TYPES } from '@reducers/dashboards/types'
import metricConfig from '@components/dashboard/widgets/dynamicWidgets/metricWidget/config'
import pieChartConfig from '@components/dashboard/widgets/dynamicWidgets/pieChartWidget/config'
import barChartConfig from '@components/dashboard/widgets/dynamicWidgets/barChartWidget/config'
import tableConfig from '@components/dashboard/widgets/dynamicWidgets/tableWidget/config'

export const WIDGET_TO_CONFIG = {
  [WIDGET_TYPES.METRIC_WIDGET]: metricConfig,
  [WIDGET_TYPES.PIE_CHART_WIDGET]: pieChartConfig,
  [WIDGET_TYPES.BAR_CHART_WIDGET]: barChartConfig,
  [WIDGET_TYPES.TABLE_WIDGET]: tableConfig,
  [WIDGET_TYPES.FIXED_WIDGET]: null
}
