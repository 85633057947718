import React from 'react'
import { FormElement, Select, Stack } from '@toriihq/design-system'
import { INACTIVE_PERIOD_OPTIONS } from '@root/constants.t'
import Analytics from '../../analytics'
import { DropdownSettingsProps } from '@pages/renewalForecast/recommendation/types'
import { DropdownSettingsContainer } from '@pages/renewalForecast/recommendation/style'

const ANALYZE_LICENSES_OPTIONS = {
  PAID_ONLY: { label: 'Paid Only', value: 'paid' },
  ALL_LICENSES: { label: 'All licenses', value: 'all' }
}
const RENEWAL_BUFFER_OPTIONS = {
  '0': { label: 'No Buffer (0%)', value: 0 },
  '10': { label: 'Small Growth (10%)', value: 10 },
  '20': { label: 'Medium Growth (20%)', value: 20 },
  '30': { label: 'High Growth (30%)', value: 30 }
}

const DropdownSettings = ({
  appName,
  showOnlyPaidLicenses,
  setShowOnlyPaidLicenses,
  setInactivityPeriod,
  inactivityPeriod,
  bufferValue,
  setBufferValue,
  onChangeInactivityPeriod
}: DropdownSettingsProps): JSX.Element => {
  const onAnalyzeLicensesChange = (selectedOption) => {
    const isOnlyPaid = selectedOption.value === ANALYZE_LICENSES_OPTIONS.PAID_ONLY.value
    setShowOnlyPaidLicenses(isOnlyPaid)
    Analytics.selectLicensesToAnalyze({ appName, selectedLicenses: isOnlyPaid ? ANALYZE_LICENSES_OPTIONS.PAID_ONLY.label : ANALYZE_LICENSES_OPTIONS.ALL_LICENSES.label })
  }
  const onRenewalBufferChange = (selectedOption) => {
    setBufferValue(selectedOption.value)
    Analytics.updateRenewalBuffer({ bufferPercentage: selectedOption.label, appName })
  }
  const onInactivityPeriodChange = (selectedOption) => {
    setInactivityPeriod(selectedOption)
    onChangeInactivityPeriod(selectedOption.value)
  }

  return <DropdownSettingsContainer >
    <Stack gap='space-150' direction='row' alignItems='flex-start'>
      <FormElement label='Inactivity period' >
        <Select
          size='small'
          value={inactivityPeriod}
          options={INACTIVE_PERIOD_OPTIONS}
          onChange={onInactivityPeriodChange}
        />
      </FormElement>
      <FormElement label='Analyze licenses' >
        <Select
          size='small'
          options={Object.values(ANALYZE_LICENSES_OPTIONS)}
          value={showOnlyPaidLicenses ? ANALYZE_LICENSES_OPTIONS.PAID_ONLY : ANALYZE_LICENSES_OPTIONS.ALL_LICENSES}
          onChange={onAnalyzeLicensesChange}
        />
      </FormElement>
      <FormElement label='Renewal buffer' >
        <Select
          size={'small'}
          options={Object.values(RENEWAL_BUFFER_OPTIONS)}
          value={RENEWAL_BUFFER_OPTIONS[bufferValue]}
          onChange={onRenewalBufferChange}
        />
      </FormElement>
    </Stack>
  </DropdownSettingsContainer>
}

export default DropdownSettings
