import {
  GET_OFFBOARDING_APPS,
  GET_OFFBOARDING_APPS_OF_USERS,
  GET_OFFBOARDING_APP,
  UPDATE_WORKFLOW,
  WORKFLOW_TYPES,
  DELETE_WORKFLOW,
  OFFBOARDING_APPS_STATUS,
  GET_OFFBOARDING_STATUS_OF_USER,
  UPDATE_APP_USER_INFO,
  GET_OFFBOARDING_TODO_USERS,
  GET_OFFBOARDING_IN_PROGRESS_USERS,
  GET_OFFBOARDING_DONE_USERS,
  GET_OFFBOARDING_TODO_USERS_COUNT, GET_OFFBOARDING_IN_PROGRESS_USERS_COUNT, GET_OFFBOARDING_DONE_USERS_COUNT,
  GET_OFFBOARDING_DEFAULT
} from '@root/constants'
import { GET_PENDING_OFFBOARDING_TASKS, GET_PENDING_OFFBOARDING_TICKETS, OFFBOARDING_TASK_MARKED_AS_DONE } from '@root/constants.t'
import { getFirstAction } from '@root/shared/workflows/actions/utils/getFirstAction'
import { uniq } from 'lodash'
import partition from 'lodash/partition'

const initialState = {
  appsConfig: {
    loading: false,
    apps: [],
    resources: { users: {} }
  },
  appsOfUsersConfig: {
    loading: false,
    apps: [],
    idUsers: []
  },
  user: {
    loading: false,
    id: null,
    apps: [],
    resources: { users: {}, assignedUsersToTicketByEmail: {} }
  },
  todoUsersCount: 0,
  todoUsers: {
    loading: false,
    isLoaded: false,
    total: 0,
    reset: false,
    users: []
  },
  inProgressUsersCount: 0,
  inProgressUsers: {
    loading: false,
    isLoaded: false,
    total: 0,
    reset: false,
    users: []
  },
  doneUsersCount: 0,
  doneUsers: {
    loading: false,
    isLoaded: false,
    total: 0,
    reset: false,
    users: []
  },
  defaultOffboarding: {
    loading: false,
    workflow: {}
  },
  pendingTasks: {
    tasks: [],
    tickets: [],
    appsById: {},
    usersById: {}
  }
}

const getNewStateWithNewAppForUser = ({ state, newApp, userResources }) => ({
  ...state,
  user: {
    ...state.user,
    apps: state.user.apps.filter(app => app.id !== newApp.id || app.idAppAccount !== newApp.idAppAccount || app.idUser !== newApp.idUser).concat(newApp),
    resources: {
      ...state.user.resources,
      users: { ...state.user.resources.users, ...userResources }
    }
  }
})

const convertSingleActionGraphToArray = (actionsGraph) => {
  const firstAction = getFirstAction(actionsGraph)
  return [{ ...firstAction, id: Number(actionsGraph.idRootNode) }]
}

const updateUserApps = ({
  state,
  actionMeta,
  appUserInfoForOffboarding = [],
  userResources = {}
}) => {
  const { idApp, idAppAccount, idUser, isRemoved, triggerIdUser, removedTime } = actionMeta

  const idUsers = uniq([state.user.id, ...state.user.apps.map(app => app.idUser)]).filter(Boolean)

  if (idUsers.includes(idUser)) {
    const app = state.user.apps.find(app => app.id === idApp && app.idAppAccount === idAppAccount && app.idUser === idUser)

    if (app) {
      const appUserInfo = appUserInfoForOffboarding.find(appUserInfo =>
        appUserInfo.idApp === idApp &&
        appUserInfo.idAppAccount === idAppAccount &&
        appUserInfo.idUser === idUser) || {}

      const newApp = {
        ...app,
        ...appUserInfo,
        isUserRemovedFromApp: isRemoved,
        appRemovedBy: triggerIdUser,
        removedTime
      }

      return getNewStateWithNewAppForUser({ state, newApp, userResources })
    }
  }

  return state
}

const offboardingReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_OFFBOARDING_TODO_USERS}_PENDING`: {
      const { reset } = action.meta

      return {
        ...state,
        todoUsers: {
          ...state.todoUsers,
          loading: true,
          loadingMore: !reset
        }
      }
    }
    case `${GET_OFFBOARDING_TODO_USERS}_FAILED`: {
      return {
        ...state,
        todoUsers: {
          ...state.todoUsers,
          loading: false,
          loadingMore: false
        }
      }
    }
    case `${GET_OFFBOARDING_TODO_USERS}_RESPONSE`: {
      const { users, total } = action.payload
      const { reset } = action.meta

      return {
        ...state,
        todoUsers: {
          users: reset ? users : (state.todoUsers.users).concat(users),
          total,
          reset,
          loading: false,
          isLoaded: true,
          loadingMore: false
        }
      }
    }

    case `${GET_OFFBOARDING_TODO_USERS_COUNT}_RESPONSE`: {
      const { total } = action.payload

      return {
        ...state,
        todoUsersCount: total
      }
    }

    case `${GET_OFFBOARDING_IN_PROGRESS_USERS}_PENDING`: {
      const { reset } = action.meta

      return {
        ...state,
        inProgressUsers: {
          ...state.inProgressUsers,
          loading: true,
          loadingMore: !reset
        }
      }
    }
    case `${GET_OFFBOARDING_IN_PROGRESS_USERS}_FAILED`: {
      return {
        ...state,
        inProgressUsers: {
          ...state.inProgressUsers,
          loading: false,
          loadingMore: false
        }
      }
    }
    case `${GET_OFFBOARDING_IN_PROGRESS_USERS}_RESPONSE`: {
      const { users, total } = action.payload
      const { reset } = action.meta

      return {
        ...state,
        inProgressUsers: {
          users: reset ? users : (state.inProgressUsers.users).concat(users),
          total,
          reset,
          loading: false,
          isLoaded: true,
          loadingMore: false
        }
      }
    }

    case `${GET_OFFBOARDING_IN_PROGRESS_USERS_COUNT}_RESPONSE`: {
      const { total } = action.payload

      return {
        ...state,
        inProgressUsersCount: total
      }
    }

    case `${GET_OFFBOARDING_DONE_USERS}_PENDING`: {
      const { reset } = action.meta

      return {
        ...state,
        doneUsers: {
          ...state.doneUsers,
          loading: true,
          loadingMore: !reset
        }
      }
    }
    case `${GET_OFFBOARDING_DONE_USERS}_FAILED`: {
      return {
        ...state,
        doneUsers: {
          ...state.doneUsers,
          loading: false,
          loadingMore: false
        }
      }
    }
    case `${GET_OFFBOARDING_DONE_USERS}_RESPONSE`: {
      const { users, total } = action.payload
      const { reset } = action.meta

      return {
        ...state,
        doneUsers: {
          users: reset ? users : (state.doneUsers.users).concat(users),
          total,
          reset,
          loading: false,
          isLoaded: true,
          loadingMore: false
        }
      }
    }

    case `${GET_OFFBOARDING_DONE_USERS_COUNT}_RESPONSE`: {
      const { total } = action.payload

      return {
        ...state,
        doneUsersCount: total
      }
    }

    case `${GET_OFFBOARDING_STATUS_OF_USER}_PENDING`: {
      return {
        ...state,
        user: {
          ...state.user,
          loading: true
        }
      }
    }
    case `${GET_OFFBOARDING_STATUS_OF_USER}_FAILED`: {
      return {
        ...state,
        user: {
          ...state.user,
          loading: false
        }
      }
    }
    case `${GET_OFFBOARDING_STATUS_OF_USER}_RESPONSE`: {
      const { id, apps, resources } = action.payload

      return {
        ...state,
        user: {
          id,
          apps,
          resources,
          loading: false
        }
      }
    }

    case `${GET_PENDING_OFFBOARDING_TASKS}_RESPONSE`: {
      return {
        ...state,
        pendingTasks: {
          ...state.pendingTasks,
          tasks: action.payload.tasks,
          appsById: { ...state.pendingTasks.appsById, ...action.payload.resources.apps },
          usersById: { ...state.pendingTasks.usersById, ...action.payload.resources.users }
        }
      }
    }

    case `${GET_PENDING_OFFBOARDING_TICKETS}_RESPONSE`: {
      return {
        ...state,
        pendingTasks: {
          ...state.pendingTasks,
          tickets: action.payload.tickets,
          appsById: { ...state.pendingTasks.appsById, ...action.payload.resources.apps },
          usersById: { ...state.pendingTasks.usersById, ...action.payload.resources.users }
        }
      }
    }

    case OFFBOARDING_TASK_MARKED_AS_DONE: {
      const { idAppAccount, idApp, idUser } = action.meta
      const isTaskNotMarkedAsDone = pendingTask => (
        pendingTask.idApp !== idApp ||
        pendingTask.idAppAccount !== idAppAccount ||
        pendingTask.onUser !== idUser
      )
      return {
        ...state,
        pendingTasks: {
          ...state.pendingTasks,
          tasks: state.pendingTasks.tasks.filter(isTaskNotMarkedAsDone),
          tickets: state.pendingTasks.tickets.filter(isTaskNotMarkedAsDone)
        }
      }
    }

    case `${UPDATE_APP_USER_INFO}_PENDING`: {
      return updateUserApps({
        state,
        actionMeta: action.meta
      })
    }
    case `${UPDATE_APP_USER_INFO}_RESPONSE`: {
      const {
        appUserInfoForOffboarding,
        resources: { users: userResources }
      } = action.payload

      return updateUserApps({
        state,
        actionMeta: action.meta,
        appUserInfoForOffboarding,
        userResources
      })
    }
    case `${UPDATE_APP_USER_INFO}_FAILED`: {
      const { message } = action.payload
      const isAlreadyCompleted = message.includes('already completed')
      const { idApp, idAppAccount, idUser, isRemoved, triggerIdUser, removedTime } = action.meta

      const idUsers = uniq([state.user.id, ...state.user.apps.map(app => app.idUser)]).filter(Boolean)
      if (idUsers.includes(idUser)) {
        const app = state.user.apps.find(app => app.id === idApp && app.idAppAccount === idAppAccount && app.idUser === idUser)
        if (app) {
          let newApp = { ...app, isUserRemovedFromApp: !isRemoved }
          if (isAlreadyCompleted) {
            newApp = { ...newApp, isUserRemovedFromApp: isRemoved, appRemovedBy: triggerIdUser, removedTime }
          }

          return getNewStateWithNewAppForUser({ state, newApp })
        }
      }

      return state
    }

    case `${GET_OFFBOARDING_APPS}_PENDING`: {
      return {
        ...state,
        appsConfig: {
          ...state.appsConfig,
          loading: true
        }
      }
    }
    case `${GET_OFFBOARDING_APPS}_FAILED`: {
      return {
        ...state,
        appsConfig: {
          ...state.appsConfig,
          loading: false
        }
      }
    }
    case `${GET_OFFBOARDING_APPS}_RESPONSE`: {
      const { apps, resources } = action.payload
      return {
        ...state,
        appsConfig: {
          apps,
          loading: false,
          resources
        }
      }
    }

    case `${GET_OFFBOARDING_APPS_OF_USERS}_PENDING`: {
      return {
        ...state,
        appsOfUsersConfig: {
          ...state.appsOfUsersConfig,
          loading: true
        }
      }
    }
    case `${GET_OFFBOARDING_APPS_OF_USERS}_FAILED`: {
      return {
        ...state,
        appsOfUsersConfig: {
          ...state.appsOfUsersConfig,
          loading: false
        }
      }
    }
    case `${GET_OFFBOARDING_APPS_OF_USERS}_RESPONSE`: {
      const { apps } = action.payload
      const { idUsers } = action.meta

      return {
        ...state,
        appsOfUsersConfig: {
          apps,
          loading: false,
          idUsers
        }
      }
    }

    case `${GET_OFFBOARDING_APP}_PENDING`: {
      return {
        ...state,
        appsConfig: {
          ...state.appsConfig,
          loading: true
        }
      }
    }
    case `${GET_OFFBOARDING_APP}_FAILED`: {
      return {
        ...state,
        appsConfig: {
          ...state.appsConfig,
          loading: false
        }
      }
    }
    case `${GET_OFFBOARDING_APP}_RESPONSE`: {
      const { apps: updatedApps, resources } = action.payload

      let apps = state.appsConfig.apps
      let appsOfUsers = state.appsOfUsersConfig.apps

      updatedApps.forEach(app => {
        apps = apps.filter(existingApp => existingApp.id !== app.id || existingApp.idAppAccount !== app.idAppAccount)
        appsOfUsers = appsOfUsers.filter(existingApp => existingApp.id !== app.id || existingApp.idAppAccount !== app.idAppAccount)
        apps.push(app)
        appsOfUsers.push(app)
      })

      return {
        ...state,
        appsConfig: {
          ...state.appsConfig,
          apps,
          loading: false,
          resources: {
            users: {
              ...state.appsConfig.resources.users,
              ...resources.users
            }
          }
        },
        appsOfUsersConfig: {
          ...state.appsOfUsersConfig,
          apps: appsOfUsers
        }
      }
    }

    case `${UPDATE_WORKFLOW}_PENDING`: {
      const { workflow, executeWorkflow, isDefaultOffboarding } = action.meta

      if (workflow.type !== WORKFLOW_TYPES.offboarding || isDefaultOffboarding) {
        return state
      }

      const appStateToUpdate = [
        state.appsConfig.apps.find(app => app.id === workflow.triggerIdApp && app.idAppAccount === workflow.triggerIdAppAccount),
        state.appsOfUsersConfig.apps.find(app => app.id === workflow.triggerIdApp && app.idAppAccount === workflow.triggerIdAppAccount)
      ].filter(Boolean)

      appStateToUpdate.forEach(appToUpdate => {
        appToUpdate.previousStatus = appToUpdate.status
        appToUpdate.status = OFFBOARDING_APPS_STATUS.configured
        appToUpdate.actions = convertSingleActionGraphToArray(workflow.actions)
        appToUpdate.workflowId = workflow.id
      })

      let userApps = state.user.apps
      if (executeWorkflow) {
        const [appToUpdate, restApps] = partition(userApps, app => app.id === workflow.triggerIdApp && app.idAppAccount === workflow.triggerIdAppAccount)

        userApps = restApps.concat({ ...appToUpdate[0], isExecuteWorkflow: executeWorkflow })
      }

      return {
        ...state,
        appsConfig: {
          ...state.appsConfig,
          apps: [...state.appsConfig.apps]
        },
        appsOfUsersConfig: {
          ...state.appsOfUsersConfig,
          apps: [...state.appsOfUsersConfig.apps]
        },
        user: {
          ...state.user,
          apps: userApps
        }
      }
    }
    case `${UPDATE_WORKFLOW}_FAILED`: {
      const { workflow, isDefaultOffboarding } = action.meta

      if (workflow.type === WORKFLOW_TYPES.offboarding) {
        if (isDefaultOffboarding) {
          return state
        }

        const appStateToUpdate = [
          state.appsConfig.apps.find(app => app.id === workflow.triggerIdApp && app.idAppAccount === workflow.triggerIdAppAccount),
          state.appsOfUsersConfig.apps.find(app => app.id === workflow.triggerIdApp && app.idAppAccount === workflow.triggerIdAppAccount)
        ].filter(Boolean)
        appStateToUpdate.forEach(appToUpdate => {
          appToUpdate.status = appToUpdate.previousStatus
        })

        return {
          ...state,
          appsConfig: {
            ...state.appsConfig,
            apps: [...state.appsConfig.apps]
          },
          appsOfUsersConfig: {
            ...state.appsOfUsersConfig,
            apps: [...state.appsOfUsersConfig.apps]
          }
        }
      }

      return state
    }
    case `${UPDATE_WORKFLOW}_RESPONSE`: {
      const { workflow, isDefaultOffboarding } = action.meta

      if (workflow.type === WORKFLOW_TYPES.offboarding && isDefaultOffboarding) {
        return {
          ...state,
          defaultOffboarding: {
            ...state.defaultOffboarding,
            workflow: workflow
          }
        }
      }

      return state
    }
    case `${DELETE_WORKFLOW}_FAILED`: {
      const { idWorkflow } = action.meta

      const appStateToUpdate = [
        state.appsConfig.apps.find(app => app.workflowId === idWorkflow),
        state.appsOfUsersConfig.apps.find(app => app.workflowId === idWorkflow)
      ].filter(Boolean)

      if (appStateToUpdate.length) {
        appStateToUpdate.forEach(appToUpdate => {
          appToUpdate.status = appToUpdate.previousStatus
        })

        return {
          ...state,
          appsConfig: {
            ...state.appsConfig
          },
          appsOfUsersConfig: {
            ...state.appsOfUsersConfig
          }
        }
      }

      return state
    }

    case `${GET_OFFBOARDING_DEFAULT}_PENDING`: {
      return {
        ...state,
        defaultOffboarding: {
          ...state.defaultOffboarding,
          loading: true
        }
      }
    }
    case `${GET_OFFBOARDING_DEFAULT}_FAILED`: {
      return {
        ...state,
        defaultOffboarding: {
          ...state.defaultOffboarding,
          loading: false
        }
      }
    }
    case `${GET_OFFBOARDING_DEFAULT}_RESPONSE`: {
      const { workflows } = action.payload
      const defaultOffboardingWorkflow = workflows[0] || {}

      return {
        ...state,
        defaultOffboarding: {
          ...state.defaultOffboarding,
          workflow: defaultOffboardingWorkflow,
          loading: false
        }
      }
    }

    default: {
      return state
    }
  }
}

export default offboardingReducer
