import styled from 'styled-components'

export const ErrorSpan = styled.span`
  color: ${({ theme }) => theme.palette.text.error};
`

export const SelectWrapper = styled.div<{ width?: string }>`
  display: inline-flex;
  flex-direction: column;
  width: ${({ width }) => width || '100%'};
`

export const WidthWrapper = styled.div<{ width?: string }>`
  width: ${({ width }) => width || '100%'};
`
