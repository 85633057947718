import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { Grid, Row, Col, EmptyState, Button, ButtonType } from '@toriihq/design-system'
import isEmpty from 'lodash/isEmpty'
import SourceWithApps from './sourceWithApps'
import Analytics from '../../helpers/analytics'
import emptyImage from '@media/applications.svg'
import Placeholder from '../placeholder'
import texts from '../../shared/style/texts'
import SecurityAndCompliance from '../charts/securityAndCompliance'
import RiskLevel from '../charts/riskLevel'
import PageHeader from '../pageHeader'
import TableSearch from '../../components/table/tableSearch'
import Page from '../../components/page'
import { fontSize } from '@shared/style/sizes'
import { sourceType } from './sourceType'

const SMALL_SCREEN_WIDTH = '992px'
const CSS = {
  wrapper: css({
    marginTop: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    marginBottom: 20
  }),
  row: css({
    marginBottom: '50px',
    display: 'flex',
    flexDirection: 'row',
    [`@media(max-width: ${SMALL_SCREEN_WIDTH})`]: {
      ' .col': {
        marginBottom: '24px'
      }
    }
  }),
  sourceHeader: css(texts.headers.regular, {
    display: 'flex',
    alignItems: 'center',
    height: 60
  }),
  downloadIcon: css({
    fontSize: fontSize.small,
    marginRight: '10px'
  }),
  tablesHeader: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }),
  rightContainer: css({
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  }),
  tablesHeaderTitle: css(texts.heading, {
    textTransform: 'uppercase'
  }),
  search: css({
    width: '180px'
  }),
  emptyState: css({
    display: 'flex',
    justifyContent: 'center',
    marginTop: 30
  }),
  placeholder: {
    maxWidth: '100%',
    fontSize: '64px',
    height: '390px'
  }
}

const CSVIcon = <>CSV</>

class ThirdPartyReport extends React.Component {
  state = { search: '' }

  componentDidMount () {
    if (this.props.idOrg) {
      this.fetchData()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.idOrg && prevProps.idOrg !== this.props.idOrg) {
      this.fetchData()
    }
  }

  fetchData () {
    const { idOrg, getAppsPermissions, getAppsV2 } = this.props

    getAppsPermissions({ idOrg, sources: ['google', 'slack', 'azure_ad'] })
    getAppsV2({ idOrg })
  }

  onSearch = (e) => {
    const { value } = e.currentTarget
    this.setState({
      search: value
    })
  }

  onConnectClick = () => {
    const { idOrg, history } = this.props
    history.push(`/team/${idOrg}/services/all`)

    Analytics.track('Click to go to services', {
      from: 'Third Party Report'
    })
  }

  onExport = () => {
    const { onExportCSV, sourcesWithApps } = this.props
    return onExportCSV(sourcesWithApps)
  }

  renderNoApps () {
    const onClick = () => {
      Analytics.track('Click to connect service providers', {
        from: 'From 3rd party apps report'
      })
      this.onConnectClick()
    }
    return <div {...CSS.emptyState}>
      <EmptyState
        image={<img src={emptyImage} alt='No apps' />}
        title='Discover your apps'
        buttons={[
          <Button type={ButtonType.primary} onClick={onClick} label='Connect service providers' />
        ]}
      />
    </div>
  }

  renderApps () {
    const { loading, sourcesWithApps, stateOrder } = this.props
    const { search } = this.state

    return (
      <div id='printIndication' {...CSS.wrapper}>
        <Placeholder loading={loading} type='text' rows={3} style={CSS.placeholder} widths={[100]}>
          {sourcesWithApps.map(source => <SourceWithApps key={source.idApp} source={source} search={search} stateOrder={stateOrder} loading={loading} />)}
        </Placeholder>
      </div>
    )
  }

  render () {
    const { sourcesWithApps, isLoaded, isAccessReviewsEnabled } = this.props
    const showEmptyState = isLoaded && isEmpty(sourcesWithApps)

    return (
      <Page title='Security & Risk analysis'>
        {!isAccessReviewsEnabled && <PageHeader title='Security & Risk analysis' menuButtonText={CSVIcon} menuButtonOnClick={this.onExport} iconName='Download' />}
        {showEmptyState && this.renderNoApps()}
        {!showEmptyState && (
          <Grid>
            <Row {...CSS.row}>
              <Col sm={6}>
                <RiskLevel />
              </Col>
              <Col sm={6}>
                <SecurityAndCompliance
                  header='Resources accessed by 3rd party Apps'
                  subHeader='Applications with access to user data'
                />
              </Col>
            </Row>
            <Row {...CSS.row}>
              <Col sm={12}>
                <div {...CSS.tablesHeader}>
                  <div {...CSS.tablesHeaderTitle}>Applications</div>
                  <div {...CSS.rightContainer}>
                    <div {...CSS.search}>
                      <TableSearch
                        onSearch={this.onSearch}
                        placeholder={'Search for apps'}
                      />
                    </div>
                    {isAccessReviewsEnabled && (
                      <Button
                        icon='Download'
                        label='CSV'
                        type='secondary'
                        onClick={this.onExport}
                      />
                    )}
                  </div>
                </div>
                {this.renderApps()}
              </Col>
            </Row>
          </Grid>
        )}
      </Page>
    )
  }
}

ThirdPartyReport.propTypes = {
  sourcesWithApps: PropTypes.arrayOf(sourceType)
}

export default ThirdPartyReport
