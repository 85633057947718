import { createSelector } from 'reselect'
import { State } from '@reducers/types'
import identity from 'lodash/identity'
import mapValues from 'lodash/mapValues'
import get from 'lodash/get'
import { AppUsersMetadata } from '@reducers/appUsers/types'
import map from 'lodash/map'
import { TABLES } from '@root/constants'

export const getAppUsersCount = createSelector(
  (state: State) => state.appUsers.appUsersCount,
  appUsersCount => appUsersCount
)

export const getAppUsersMetadata = createSelector(
  (state: State): AppUsersMetadata => state.appUsers.metadata,
  identity
)

export const getAppUsersConfigurableColumnsOptions = createSelector(
  [getAppUsersMetadata],
  (appUsersMetadata: AppUsersMetadata | undefined) => {
    if (!appUsersMetadata) {
      return []
    }

    const { predefinedFields, customFields } = appUsersMetadata
    const { preDefinedColumnsMapping } = TABLES.appUsersTable

    const generateOptions = (fields: { systemKey: string, name: string }[], shouldInclude: (field: any) => boolean) =>
      fields.filter(shouldInclude).map(({ systemKey, name }) => ({ value: systemKey, label: name }))

    return [
      ...map(preDefinedColumnsMapping, (label, key) => ({ value: key, label })),
      ...generateOptions(predefinedFields, field => !preDefinedColumnsMapping[field.systemKey]),
      ...generateOptions(customFields, field => field.isShown)
    ]
  }
)

export const isAppUsersMetadataLoading = createSelector(
  (state: State) => state.appUsers.metadata.loading,
  identity
)

export const getAppUsersFieldValues = createSelector(
  (state: State) => {
    const fieldsValues = state.appUsers.fieldsValues
    return mapValues(fieldsValues, (values, field) => values.map(item => ({ value: item.key, label: get(item, ['key', 'fullName'], item.key) })))
  },
  identity
)
