import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import Placeholder from '../placeholder'
import ToriiSelect, { ToriiSelectCreatable } from '../select'
import keyBy from 'lodash/keyBy'
import noop from 'lodash/noop'
import isEqual from 'lodash/isEqual'
import { MAX_ITEMS_TO_SHOW } from '../../constants'
import { Checkbox } from '@toriihq/design-system'

const CSS = {
  colorIndication: css({
    width: '10px',
    height: '10px',
    borderRadius: '10px',
    marginRight: '10px'
  }),
  option: css({
    display: 'flex',
    alignItems: 'center'
  }),
  checkbox: css({
    marginRight: '8px',
    height: '16px'
  }),
  optionContent: css({
    flex: 1
  })
}

class MultipleCheckboxSelect extends React.Component {
  state = {
    options: [],
    selectedOptions: []
  }

  componentDidMount () {
    this.init()
  }

  componentDidUpdate (prevProps) {
    const optionsChanged = !isEqual(prevProps.options, this.props.options)
    const selectedValuesChanged = !isEqual(prevProps.selectedValues, this.props.selectedValues)
    if (optionsChanged || selectedValuesChanged) {
      this.init()
    }
  }

  init () {
    const { options, selectedValues } = this.props
    const selectedOptions = options.filter(option => selectedValues.includes(option.value))

    this.setState({
      options,
      selectedOptions
    })
  }

  onChange = (selectedOptions) => {
    const { onChange } = this.props

    this.setState({
      selectedOptions
    })

    const selectedOptionsByValues = keyBy(selectedOptions, 'value')
    onChange && onChange(Object.keys(selectedOptionsByValues))
  }

  renderOption = (props) => {
    const { renderOption, creatable } = this.props

    const option = props.data

    if ((option.created && creatable) || !creatable) {
      return (
        <div key={option.value} {...CSS.option}>
          <div {...CSS.checkbox}><Checkbox checked={props.isSelected} onChange={noop} /></div>
          <div {...CSS.optionContent}>{renderOption ? renderOption(option) : option.label}</div>
        </div>
      )
    }

    return (
      <div key={option.value}>
        <div {...CSS.optionContent}>{renderOption ? renderOption(option) : option.label}</div>
      </div>
    )
  }

  onNewOptionHandle = async (inputValue) => {
    const { onNewOptionClick } = this.props
    if (onNewOptionClick) {
      await onNewOptionClick(inputValue)
    }
  }

  shouldKeyDownEventCreateNewOption = (event) => {
    const { keyCode } = event

    switch (keyCode) {
      case 9:
      case 13:
        return true
      default:
        return false
    }
  }

  render () {
    const {
      options,
      placeholder,
      isLoading,
      showValues = false,
      searchable = false,
      onSelectResetsInput,
      disabled,
      maxItemsToShow,
      alignPopover,
      valueRenderer,
      creatable,
      isValidNewOption,
      autoFocus,
      components,
      formatCreateLabel,
      onBlur
    } = this.props
    const { selectedOptions } = this.state

    const hideValues = {
      valueRenderer: this.renderOption,
      backspaceRemoves: false,
      deleteRemoves: false
    }
    const extraProps = showValues ? {} : hideValues

    const allProps = {
      options: options,
      optionRenderer: this.renderOption,
      valueRenderer: valueRenderer,
      onChange: this.onChange,
      onBlur,
      clearable: false,
      searchable: searchable,
      autoFocus,
      openOnFocus: true,
      multi: true,
      removeSelected: false,
      closeOnSelect: false,
      tabSelectsValue: false,
      onSelectResetsInput: onSelectResetsInput,
      value: selectedOptions,
      placeholder: placeholder,
      attachment: `top ${alignPopover}`,
      targetAttachment: `bottom ${alignPopover}`,
      disabled: disabled,
      maxItemsToShow: maxItemsToShow,
      components,
      formatCreateLabel,
      ...extraProps
    }

    return <Placeholder loading={isLoading} type='rect' style={{ width: '114px', height: '20px' }}>
      {creatable ? <ToriiSelectCreatable
        {...allProps}
        ref={select => (this.selectRef = select)}
        onCreateOption={this.onNewOptionHandle}
        shouldKeyDownEventCreateNewOption={this.shouldKeyDownEventCreateNewOption}
        isValidNewOption={isValidNewOption}
      /> : <ToriiSelect
        {...allProps}
        ref={select => (this.selectRef = select)}
      />}
    </Placeholder>
  }
}

MultipleCheckboxSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
    disabled: PropTypes.bool
  })),
  selectedValues: PropTypes.array,
  onChange: PropTypes.func,
  renderOption: PropTypes.func,
  placeholder: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  maxItemsToShow: PropTypes.number,
  alignPopover: PropTypes.oneOf(['right', 'left']),
  creatable: PropTypes.bool
}

MultipleCheckboxSelect.defaultProps = {
  options: [],
  selectedValues: [],
  placeholder: '',
  maxItemsToShow: MAX_ITEMS_TO_SHOW,
  alignPopover: 'left',
  creatable: false
}

export default MultipleCheckboxSelect
