import colors from '@shared/style/colors'
import styled from 'styled-components'

export const DataInfoContainer = styled.div`
  border: 1px solid ${colors.lightBlue5};
  padding: 16px;
  margin: 16px 48px 16px 48px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
`

export const DataDisplayName = styled.div`
  margin-left: 22px;
`

export const AdditionalInfo = styled.div`
  color: ${props => props.emphasizeError ? props.theme.palette.background.error : colors.grey2};
  margin-top: 8px;
  margin-left: 47px;
  margin-right: auto;
`

export const SmallComment = styled.div`
  font: ${props => props.theme.typography.font.caption02};
  color: ${props => props.theme.palette.text.tertiary};
  margin-left: auto;
`

export const CredentialStatusLine = styled.div`
  display: flex;
  flex-direction: row;
`
