import React from 'react'
import { useSelector } from 'react-redux'
import { css } from 'glamor'
import SelectOrgs from '@components/selectOrgs'
import Search from '../search'
import Menu from './menu'
import ProductUpdatesButton from '../productUpdatesButton'
import HelpButton from '../helpButton'
import DarkModeButton from '../darkModeButton'
import { getSelf, isToriiAdmin } from '@selectors/me'
import { getIsSmallScreen } from '@selectors/ui'
import * as style from './style'
import VisibleFor from '@components/visibleFor'
import { SCOPES, FEATURE_FLAGS } from '@root/constants'
import FeedbackBoardButton from '@components/feedbackBoardButton'
import DeleteURLCookieButton from '@components/deleteURLCookieButton'
import { Stack } from '@toriihq/design-system'
import { useTheme } from 'styled-components'
import { useSupportedFeatures } from '@lenses/features'

const SearchBar = () => {
  const theme = useTheme()
  const isSmallScreen = useSelector(getIsSmallScreen)
  const connectedUser = useSelector(getSelf)
  const hasToriiAdminPermissions = useSelector(isToriiAdmin)
  const canChangeOrgs = hasToriiAdminPermissions
  const isDarkModeEnabled = useSupportedFeatures(FEATURE_FLAGS.DARK_MODE)

  const { firstName, lastName, photoUrl } = connectedUser

  const searchContainerStyle = css(
    style.SearchContainer,
    isSmallScreen && style.SearchContainerSmallScreen
  )

  return (
    <div {...css(style.Main, { background: theme.palette.background.primary })}>
      <div {...searchContainerStyle}>
        <Search />
        <Stack direction='row' gap='space-0'>
          {canChangeOrgs && <SelectOrgs />}
          {isDarkModeEnabled && <DarkModeButton />}
          <HelpButton />
          <FeedbackBoardButton />
          <VisibleFor scopes={[SCOPES.BASIC_ADMIN]}>
            <ProductUpdatesButton />
          </VisibleFor>
          <DeleteURLCookieButton />
          <Menu firstName={firstName} lastName={lastName} photoUrl={photoUrl} />
        </Stack>
      </div>
    </div>
  )
}

export default SearchBar
