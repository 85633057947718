import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboardWidgetEditConfigDrawer } from '@selectors/ui'
import { filterTable } from '@store/actions'
import { BAR_ORIENTATION, DATE_PERIOD, DynamicWidget, Metric, METRIC_FUNC, WIDGET_TYPES } from '@reducers/dashboards/types'
import { fieldTypes, formFieldTypes } from '@root/constants'
import EditWidgetForm from './editWidgetForm'
import { DATE_FIELD_TYPES, getWidgetTableKey, useGetWidgetFieldsDataByEntityType } from '../widgets/shared'
import { isNil, omitBy, get, flattenDeep } from 'lodash'
import Analytics, { ENTITY_TYPE } from '@components/dashboard/analytics'
import { DashboardEditWidgetProps } from './types'
import { updateGroupBySort } from '@components/dashboard/dashboardEditWidget/shared'
import { Filter } from '@shared/filters/types'
import { EditableSection, DropdownOptionsSource, EditableBaseFieldWithValue } from '../widgets/types'

const DashboardEditWidget = (props: DashboardEditWidgetProps): ReactElement | null => {
  const dispatch = useDispatch()

  const { dashboardName, dashboardSystemKey } = props

  const { sections, widget, onWidgetUpdate } = useSelector(getDashboardWidgetEditConfigDrawer)

  const [updatedWidget, setUpdatedWidget] = useState(widget)

  useEffect(() => {
    if (widget) {
      setUpdatedWidget(widget)
    }
  }, [widget])

  const widgetTableKey = getWidgetTableKey(widget)

  const onFieldChange = (fieldKey: string, fieldValue: any) => {
    Analytics.updateWidgetConfiguration({ dashboardName, dashboardSystemKey, widgetTitle: updatedWidget?.displayConfig.label, fieldName: fieldKey, entityType: ENTITY_TYPE.DRAWER })
    const newDisplayConfig: DynamicWidget['displayConfig'] = { ...updatedWidget.displayConfig }
    let filters = updatedWidget.dataConfig.filters

    if (fieldKey === 'filters') {
      filters = fieldValue as Filter[]
      dispatch(filterTable({ tableKey: widgetTableKey, filters: fieldValue, supportViews: false, shareViewPreferences: false }))
    }

    if (fieldKey === 'label') {
      newDisplayConfig.label = fieldValue
    }

    if (fieldKey === 'barOrientation' && fieldValue) {
      newDisplayConfig.barOrientation = fieldValue as BAR_ORIENTATION
    }

    if (fieldKey === 'fields' && fieldValue) {
      newDisplayConfig.fields = fieldValue
    }

    if (fieldKey === 'groupBy' && fieldValue) {
      const field = entityFields.find((field) => field.value === fieldValue)
      const newField = { systemKey: fieldValue, type: field.type, label: field.label }
      newDisplayConfig.groupBy = {
        ...newDisplayConfig.groupBy,
        field: newField,
        size: updatedWidget.displayConfig?.groupBy?.size,
        metric: updatedWidget.displayConfig?.groupBy?.metric,
        sort: updatedWidget?.displayConfig?.groupBy?.sort
      }

      if (DATE_FIELD_TYPES.includes(newField.type)) {
        newDisplayConfig.groupBy.datePeriod = updatedWidget.displayConfig?.groupBy?.datePeriod || fieldsDefaultValue.datePeriod
      } else {
        newDisplayConfig.groupBy.datePeriod = undefined
      }
    }

    if (fieldKey === 'datePeriod' && fieldValue) {
      if (newDisplayConfig.groupBy) {
        newDisplayConfig.groupBy = {
          ...newDisplayConfig.groupBy,
          datePeriod: fieldValue as DATE_PERIOD
        }
      }
    }

    if (fieldKey === 'metricFunc') {
      const existingMetricFunc = updatedWidget.displayConfig?.groupBy?.metric?.func ?? updatedWidget.displayConfig?.metric?.func
      const existingMetricField = updatedWidget.displayConfig?.groupBy?.metric?.field ?? updatedWidget.displayConfig?.metric?.field
      const newMetricConfig = { func: fieldValue, field: existingMetricField } as Metric

      if (fieldValue !== METRIC_FUNC.TOTAL && existingMetricFunc !== fieldValue) {
        if (!existingMetricField?.systemKey) {
          const field = entityFields.find((field) => field.value === fieldsDefaultValue?.metricField)
          newMetricConfig.field = { systemKey: fieldsDefaultValue?.metricField, type: field.type, label: field.label }
        }
      }

      if (fieldValue === METRIC_FUNC.TOTAL) {
        newMetricConfig.field = undefined
      }

      if (updatedWidget.displayConfig.groupBy) {
        newDisplayConfig.groupBy = { ...updatedWidget.displayConfig.groupBy, metric: newMetricConfig }
      } else {
        newDisplayConfig.metric = newMetricConfig
      }
    }

    if (fieldKey === 'metricField' && fieldValue) {
      const field = entityFields.find((field) => field.value === fieldValue)
      const newMetricField = { systemKey: fieldValue, type: field.type, label: field.label }

      if (updatedWidget.displayConfig.groupBy?.metric?.func && updatedWidget.displayConfig.groupBy?.metric?.func !== METRIC_FUNC.TOTAL) {
        newDisplayConfig.metric = undefined
        newDisplayConfig.groupBy = {
          field: updatedWidget.displayConfig.groupBy.field,
          datePeriod: updatedWidget.displayConfig.groupBy.datePeriod,
          size: updatedWidget.displayConfig.groupBy.size,
          sort: updatedWidget?.displayConfig?.groupBy?.sort,
          metric: {
            func: updatedWidget.displayConfig.groupBy?.metric.func,
            field: newMetricField
          }
        }
      } else if (updatedWidget.displayConfig.metric?.func && updatedWidget.displayConfig.metric?.func !== METRIC_FUNC.TOTAL) {
        newDisplayConfig.metric = {
          func: updatedWidget.displayConfig.metric.func,
          field: newMetricField
        }
      }
    }

    if (newDisplayConfig.groupBy?.sort) {
      newDisplayConfig.groupBy = {
        ...newDisplayConfig.groupBy,
        sort: updateGroupBySort({ fieldKey, fieldValue, newDisplayConfig, oldDisplayConfig: updatedWidget?.displayConfig })
      }
    }

    if (fieldKey === 'numberOfGroups' && fieldValue) {
      if (newDisplayConfig.groupBy) {
        newDisplayConfig.groupBy = {
          ...newDisplayConfig.groupBy,
          size: Number(fieldValue)
        }
      }
    }

    if (fieldKey === 'sortField' && widget.type === WIDGET_TYPES.TABLE_WIDGET) {
      const order = newDisplayConfig?.sort?.[0]?.split(':')?.[1]
      newDisplayConfig.sort = [`${fieldValue}:${order}`]
    }

    if (fieldKey === 'sortOrder' && widget.type === WIDGET_TYPES.TABLE_WIDGET) {
      const field = newDisplayConfig?.sort?.[0]?.split(':')?.[0]
      newDisplayConfig.sort = [`${field}:${fieldValue}`]
    }

    const newWidget = {
      ...updatedWidget,
      displayConfig: omitBy(newDisplayConfig, isNil),
      dataConfig: {
        ...updatedWidget.dataConfig,
        filters
      }
    }

    setUpdatedWidget(newWidget)
    onWidgetUpdate({
      updatedWidget: newWidget
    })
  }

  const { fieldsOptions: entityFields, filtersOptions, fieldOptionsValuesPerKey, fetchFieldValues } = useGetWidgetFieldsDataByEntityType(widget.entityType)

  const getFieldWithOptions = (field) => {
    if (field.type === formFieldTypes.inlineGroup) {
      const fields = field.fields.map(getFieldWithOptions)
      return { ...field, fields }
    }

    if (field.type === fieldTypes.dropdown) {
      let options = []

      switch (field.dropdownOptionsSource) {
        case DropdownOptionsSource.CLOSED_LIST:
          options = field.options
          break
        case DropdownOptionsSource.ENTITY_FIELDS:
          options = entityFields
          break
      }

      if (field.filterOptions) {
        options = field.filterOptions(options, widget)
      }

      return { ...field, options }
    }

    return field
  }

  const sectionWithFieldOptions = sections.map((section) => ({
    ...section,
    fields: section.fields.map(getFieldWithOptions)
  }))

  const fieldsDefaultValue = sectionWithFieldOptions.reduce((acc, section) => {
    section.fields.forEach((field) => {
      acc[field.key] = field.options?.[0]?.value
    })
    return acc
  }, {})

  const getFieldValue = (field: EditableBaseFieldWithValue, widget: DynamicWidget) => {
    if ('getValueFunc' in field) {
      return field.getValueFunc(widget)
    }

    if (field.valuePathInWidget) {
      const value = get(widget, field.valuePathInWidget)
      return value
    }

    return undefined
  }

  const generateInitialValues = (widget: DynamicWidget, sections: EditableSection[]) => {
    const allFormFields = flattenDeep(sections.map(section => section.fields.map(field => get(field, 'fields', [field]))))

    return allFormFields.reduce((initialValues, field) => {
      const value = getFieldValue(field, widget)
      if (value !== undefined) {
        initialValues[field.key] = value
      }
      return initialValues
    }, {})
  }

  if (!widget.displayConfig) {
    return null
  } else {
    const initialFormValues = generateInitialValues(widget, sections)

    return <EditWidgetForm
      widget={widget}
      sections={sectionWithFieldOptions}
      onFieldChange={onFieldChange}
      initialValues={initialFormValues}
      filtersOptions={filtersOptions}
      fieldOptionsValuesPerKey={fieldOptionsValuesPerKey}
      fetchFieldValues={fetchFieldValues}
    />
  }
}

export default DashboardEditWidget
