import { DEFAULT_API_PAGE_LIMIT, GET_APP_ACCOUNT_LICENSES_USERS } from '@root/constants'
import send from '@shared/redux-fetch'
import { isUndefined, omitBy } from 'lodash'

export const getAppUsersLicense = ({ idOrg, idApp, idAppAccount, calculatedStatus, idLicense, limit = DEFAULT_API_PAGE_LIMIT, offset = 0, q, sort, reset, filters = [], inactivityPeriod }) => {
  return send(GET_APP_ACCOUNT_LICENSES_USERS, {
    url: `/api/orgs/${idOrg}/renewalForecast/${idApp}/users`,
    query: omitBy({
      limit,
      offset,
      q,
      sort,
      idAppAccount,
      calculatedStatus,
      idLicense,
      filters: JSON.stringify(filters),
      inactivityPeriod
    }, isUndefined),
    meta: {
      idApp,
      idAppAccount,
      idLicense,
      reset
    }
  })
}
