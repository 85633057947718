import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 4px 0;
`

export const StatusText = styled.div`
  padding-left: 3px;
`

export const LightWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 16px;
`

export const StatusDot = styled.div<{ color: string }>`
  border-radius: 50%;
  height: 8px;
  width: 8px;
  background-color: ${props => props.color};
`
