import PropTypes from 'prop-types'
import React, { createRef } from 'react'
import qs from 'query-string'
import { AlertBox, AlertBoxType, Button, Link, Stack, FormElement, Input, H3, Body1 } from '@toriihq/design-system'

class ResetPage extends React.Component {
  input = {
    password: createRef()
  }

  async handleSubmit (event) {
    event.preventDefault()
    event.stopPropagation()

    const { reset, params } = this.props

    await reset({
      id: params.id,
      key: params.key,
      password: this.input.password.current.value
    })
  }

  render () {
    const { success, error, hasError, help, loading } = this.props
    const alerts = []

    if (success) {
      const query = qs.parse(window.location.search)
      const redirectPath = query.next || '/team'
      alerts.push(
        <div key='success'>
          <Stack direction='column' gap='space-200'>
            <AlertBox type={AlertBoxType.POSITIVE} description='Your password has been reset' />
            <Link href={redirectPath}>Continue</Link>
          </Stack>
        </div>
      )
    }

    if (error) {
      alerts.push(
        <AlertBox key='error' type={AlertBoxType.NEGATIVE} description={error} />
      )
    }

    let formElements

    if (!success) {
      formElements = (
        <fieldset>
          <Stack direction='column' gap='space-200'>
            <FormElement label='Password' errorMessage={hasError.password ? help.password : null}>
              <Input
                ref={this.input.password}
                name='password'
                type='password'
                placeholder='New password'
                hasError={hasError.password}
                disabled={loading}
                suffix='Lock'
                autoFocus
              />
            </FormElement>
            <Stack direction='row' gap='space-0' justifyContent='flex-end'>
              <Button label='Set Password' />
            </Stack>
          </Stack>
        </fieldset>
      )
    }

    return (
      <form onSubmit={this.handleSubmit.bind(this)}>
        <Stack direction='column' gap='space-300'>
          <Stack direction='column' gap='space-0'>
            <H3>Reset your password</H3>
            <Body1>Enter your new password</Body1>
          </Stack>
          {alerts}
          {formElements}
        </Stack>
      </form>
    )
  }
}

ResetPage.propTypes = {
  params: PropTypes.object
}

export default ResetPage
