import styled, { css } from 'styled-components'
import RelativeTeamUserLink from '@components/relativeTeamUserLink'

export const oneLineStyles = css`
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const Container = styled.div<{ overrideStyle?: any }>`
  padding: 4px 0;
  ${props => props.overrideStyle};
`

export const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.corner('radius-050')};
`

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
`

export const Details = styled.div`
  flex: 1;
  overflow: hidden;
`

export const Email = styled.div<{ emailColor?: string }>`
  ${oneLineStyles};
  font: ${({ theme }) => theme.typography.font.caption01};
  color: ${({ theme }) => theme.palette.text.tertiary};
  opacity: 0;
  transition: opacity .2s;
  color: ${props => props.emailColor};

  .rt-tr:hover &, .userDetailsContainer:hover & {
    opacity: 1;
  }
`

interface NameProps {
  blueNameOnHover?: boolean
  hasEmail?: boolean
}

export const Name = styled.div<NameProps>`
  ${oneLineStyles};
  color: ${({ theme }) => theme.palette.text.primary};
  ${props => props.hasEmail && css`
    &:not(:last-child) {
      transform: translateY(8px);
      transition: color .2s, transform .2s;
    }
  `}

  .rt-tr:hover &, .userDetailsContainer:hover & {
    ${props => props.blueNameOnHover && css`
      color: ${({ theme }) => theme.palette.text.interactive};
    `}
    transform: translateY(0);
  }
`

export const StyledRelativeTeamUserLink = styled(RelativeTeamUserLink)`
  width: 100%;
`
