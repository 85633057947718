import React from 'react'
import colors from '../../../shared/style/colors'
import { css as glamorCSS } from 'glamor'
import PropTypes from 'prop-types'
import HoverableHeader from '../../hoverableHeader'
import Placeholder from '../../placeholder'
import { fontSize, fontWeight } from '@shared/style/sizes'
import AppsListPopup from '../../popups/appsListPopup'
import Analytics from '@helpers/analytics'
import { ProgressBar } from '@toriihq/design-system'
import { withTheme, css } from 'styled-components'

const HEADER_HEIGHT = '50px'
const BOX_PADDING = '20px'
const MAX_CATEGORIES = 4

const Header = css`
  padding: 7px ${BOX_PADDING} 0 ${BOX_PADDING};
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
`

const generateCSS = ({ theme }) => ({
  main: glamorCSS({
    height: '100%',
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: '8px'
  }),
  container: glamorCSS({
    display: 'flex',
    flexWrap: 'wrap',
    padding: `${BOX_PADDING} ${BOX_PADDING} ${BOX_PADDING} 40px`,
    height: `calc(100% - ${HEADER_HEIGHT})`,
    alignItems: 'center'
  }),
  category: glamorCSS({
    float: 'left',
    width: '50%'
  }),
  categoryOneRow: glamorCSS({
    float: 'left',
    width: '25%'
  }),
  categoryTitle: glamorCSS({
    fontSize: fontSize.small,
    fontWeight: fontWeight.semiBold
  }),
  categoryCount: glamorCSS({
    fontSize: fontSize.extraLarge,
    fontWeight: fontWeight.semiBold,
    cursor: 'pointer',
    ':hover': {
      color: colors.blue
    }
  }),
  apps: glamorCSS({
    fontSize: fontSize.small,
    fontWeight: fontWeight.semiBold,
    color: colors.grey2
  })
})

const className = 'securityAndCompliance'

const placeholderStyle = { maxWidth: '50%', padding: '20px', height: 100 }

const loadingData = [{}, {}, {}, {}]

const Box = ({ title, count, percentage, onClick, CSS }) => {
  return <div {...CSS.category}>
    <div {...CSS.categoryTitle}>{title}</div>
    <div {...CSS.categoryCount} onClick={onClick}>{count || 0}</div>
    <div {...CSS.apps}>Apps</div>
    <div style={{ width: '100%' }}>
      <ProgressBar size='Medium' value={percentage} max={100} />
    </div>
  </div>
}

class SecurityAndCompliance extends React.Component {
  constructor (props) {
    super(props)
    const { theme } = props
    this.state = {
      isAppsListPopupOpen: false,
      selectedCategoryType: null,
      CSS: generateCSS({ theme })
    }
  }

  componentDidMount () {
    if (this.props.idOrg) {
      this.fetchData()
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.idOrg !== this.props.idOrg) {
      this.fetchData()
    }

    if (prevProps.theme !== this.props.theme) {
      this.setState({
        CSS: generateCSS({ theme: this.props.theme })
      })
    }
  }

  fetchData = () => {
    const { idOrg, getUserDataAccessByCategory } = this.props
    idOrg && getUserDataAccessByCategory({ idOrg })
  }

  onCategoryClick = ({ categoryType }) => {
    const { pageName } = this.props

    pageName && Analytics.track(`Click on Security and Compliance / ${pageName}`, {
      'Counter': categoryType
    })
    this.setState({ isAppsListPopupOpen: true, selectedCategoryType: categoryType })
  }

  closeAppsListPopup = () => this.setState({ isAppsListPopupOpen: false })

  render () {
    const { loading, categories, header, subHeader, showTwoRows } = this.props
    const { isAppsListPopupOpen, selectedCategoryType, CSS } = this.state
    const data = loading ? loadingData : categories
    return (
      <div className={className} {...CSS.main}>
        <HoverableHeader hoverClass={className} overrideStyle={Header} outerHoverStyle={`#${className}:hover &`} header={header} subHeader={subHeader} />
        <div {...CSS.container}>
          {data.slice(0, MAX_CATEGORIES).map((category, index) => {
            return <div key={index} {...glamorCSS(showTwoRows ? CSS.category : CSS.categoryOneRow)} data-testid='category-div'>
              <Placeholder loading={loading} type='rect' rows={1} style={placeholderStyle}>
                <Box {...category} CSS={CSS} onClick={() => this.onCategoryClick({ categoryType: category.title })} showTwoRows={showTwoRows} />
              </Placeholder>
            </div>
          })}
        </div>
        { isAppsListPopupOpen && <AppsListPopup categoryType={selectedCategoryType} isOpen={isAppsListPopupOpen} cancel={this.closeAppsListPopup} /> }
      </div>
    )
  }
}

SecurityAndCompliance.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired
  })),
  loading: PropTypes.bool,
  header: PropTypes.string,
  subHeader: PropTypes.string,
  showTwoRows: PropTypes.bool.isRequired,
  theme: PropTypes.object
}

SecurityAndCompliance.defaultProps = {
  header: 'Security and Compliance',
  subHeader: 'Applications with access to user data'
}

export default withTheme(SecurityAndCompliance)
