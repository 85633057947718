import React from 'react'
import colors from '../../../shared/style/colors'
import { css as glamorCSS } from 'glamor'
import HoverableHeader from '../../hoverableHeader'
import BarsChart from '../../barsChartBox/chart'
import { oneLiner } from '../../../shared/style/mixins'
import pluralize from 'pluralize'
import ResizeDetector from 'react-resize-detector'
import PropTypes from 'prop-types'
import { CONTENT_TOOLTIP_TYPES } from '@components/barsChartBox/chart/view'
import Currency from '@root/components/currency'
import { withTheme, css } from 'styled-components'

const HEADER_HEIGHT = '50px'
const BOX_PADDING = '20px'

const Header = css`
  min-height: ${HEADER_HEIGHT};
  padding: 7px ${BOX_PADDING} 0 ${BOX_PADDING};
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
`

const generateCSS = ({ theme }) => ({
  main: glamorCSS({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: '8px'
  }),
  chartsContainer: glamorCSS({
    height: `calc(100% - ${HEADER_HEIGHT})`
  }),
  chart: glamorCSS({
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  }),
  totalAnnualPrice: glamorCSS(oneLiner, {
    color: colors.black,
    lineHeight: '35px',
    display: 'flex',
    flexDirection: 'row'
  }),
  totalContractsCount: glamorCSS({
    paddingRight: '3px'
  })
})

const className = 'annualRenewals'

class AnnualRenewals extends React.Component {
  constructor (props) {
    super(props)
    const { theme } = props
    this.state = {
      rotateXAxis: false,
      CSS: generateCSS({ theme })
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.theme !== this.props.theme) {
      this.setState({
        CSS: generateCSS({ theme: this.props.theme })
      })
    }
  }

  onResize = (width) => {
    this.setState({ rotateXAxis: width < 560 })
  }

  render () {
    const { loading, renewalsBarChartData, totalAnnualPrice, totalContractsCount } = this.props
    const { rotateXAxis, CSS } = this.state
    const totalAnnualPriceDiv = <div {...CSS.totalAnnualPrice} >
      {totalContractsCount ? <div {...CSS.totalContractsCount}>{`${totalContractsCount} ${pluralize('Contract', totalContractsCount)},`}</div> : null}
      <Currency value={totalAnnualPrice} />
    </div>

    return (
      <div className={className} {...CSS.main}>
        <HoverableHeader hoverClass={className} overrideStyle={Header} header='renewals' subHeader='Next 12 months. Contracts with renewal costs' rightContent={totalAnnualPriceDiv} overrideRightContentStyle={{ opacity: 1 }} />
        <div {...CSS.chartsContainer}>
          <ResizeDetector handleWidth onResize={this.onResize} />
          <div {...CSS.chart}>
            <BarsChart
              tooltipOverrideStyle={{ position: { y: 0 } }}
              loading={loading}
              data={renewalsBarChartData}
              fill={colors.purple}
              formatter={value => <Currency value={value} format={'$0,0'} />}
              barSize={10}
              chartHeight={220}
              tooltipType={CONTENT_TOOLTIP_TYPES.CATEGORIES}
              interval={0}
              rotateXAxis={rotateXAxis}
              overrideStyle={{ width: '100%', padding: '10px 24px', marginLeft: '-20px' }}
              labelDataKey='count'
              alwaysShowLabels
              isAnimationActive={false}
            />
          </div>
        </div>
      </div>
    )
  }
}

AnnualRenewals.propTypes = {
  loadingRenewals: PropTypes.bool
}

export default withTheme(AnnualRenewals)
