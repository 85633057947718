import { calculateTotalCostWithValidation } from '../utils/aggregateLineItemsData'

interface CurrencyValue {
  value: number
}

interface LineItem {
  quantity?: number | string
  pricePerUnit?: CurrencyValue | number
  discount?: number
  tax?: CurrencyValue | number
}

interface CalculationResult {
  totalCost: number
  isValid: boolean
}

export const useLineItemCalculations = (lineItem: LineItem): CalculationResult => {
  return calculateTotalCostWithValidation({
    quantity: lineItem.quantity,
    pricePerUnit: lineItem.pricePerUnit,
    discount: lineItem.discount,
    tax: lineItem.tax
  })
}
