import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.background.transparent};
  padding: 0;
  gap: 16px;
`

export const FiltersContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  font: ${({ theme }) => theme.typography.font.body02};
`

export const DeleteButtonContainer = styled.div`
  align-self: flex-start;
  margin-left: -8px;
`

export const KeyOpValueFilterStyles = {
  selectKey: {
    width: 220
  },
  propertyInput: {
    width: 246
  },
  values: {
    width: 246
  },
  relativeDayValue: {
    width: 235,
    gap: 16
  }
} as const
