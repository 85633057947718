import {
  GET_TABLE_VIEWS,
  ADD_TABLE_VIEW,
  EDIT_TABLE_VIEW,
  DELETE_TABLE_VIEW,
  CONFIGURE_TABLE_COLUMNS,
  SORT_TABLE,
  FILTER_TABLE,
  TABLE_CUSTOM_SELECT_CHANGE,
  TOGGLE_FILTER_TABLE,
  UPDATE_USER_PREFERENCES
} from '@root/constants'
import { getTableSelectedView, getTableSelectedViewID } from '@lenses/table'
import send from '@shared/redux-fetch'
import { getTablesViews } from '@selectors/ui'

const handleSharedPreferences = (state, dispatch, tableKey, preferencesKey, updatedValue) => {
  const tableViews = getTablesViews(state)[tableKey] || {}
  const selectedViewId = getTableSelectedViewID(state, tableKey)
  Object.values(tableViews).forEach(view => {
    view.preferences[preferencesKey] = updatedValue
    dispatch(editTableView(tableKey, view, selectedViewId === view.id))
  })
}

export const tableCustomSelectChange = ({ tableKey, selectedOption, appName, isSecondaryCustomSelect }) => async (dispatch) => {
  dispatch({
    type: TABLE_CUSTOM_SELECT_CHANGE,
    payload: { table: tableKey, selectedOption, appName, isSecondaryCustomSelect },
    meta: { tableKey, selectedOption, isSecondaryCustomSelect }
  })
}

export const applyCustomFilterOnTable = ({ tableKey, customFilterKey, customFilterSelectdValue: customFilterSelectdOption, userPreferences, supportViews, shareViewPreferences }) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_USER_PREFERENCES,
    payload: { table: tableKey, userPreference: { ...userPreferences, [customFilterKey]: customFilterSelectdOption } }
  })
  const state = getState()
  if (supportViews) {
    if (shareViewPreferences) {
      handleSharedPreferences(state, dispatch, tableKey, customFilterKey, customFilterSelectdOption)
    } else {
      const selectedView = getTableSelectedView(getState(), tableKey)
      if (selectedView) {
        selectedView.preferences[customFilterKey] = customFilterSelectdOption
        dispatch(editTableView(tableKey, selectedView))
      }
    }
  }
}

export const sortTable = ({ tableKey, sortingInfo, supportViews, shareViewPreferences }) => async (dispatch, getState) => {
  dispatch({
    type: SORT_TABLE,
    payload: { table: tableKey, sortingInfo },
    meta: {}
  })

  const state = getState()

  if (supportViews) {
    if (shareViewPreferences) {
      handleSharedPreferences(state, dispatch, tableKey, 'defaultSort', sortingInfo)
    } else {
      const selectedView = getTableSelectedView(getState(), tableKey)
      if (selectedView) {
        selectedView.preferences.defaultSort = sortingInfo
        dispatch(editTableView(tableKey, selectedView))
      }
    }
  }
}

export const filterTable = ({ tableKey, filters, supportViews, shareViewPreferences }) => (dispatch, getState) => {
  dispatch({
    type: FILTER_TABLE,
    payload: { table: tableKey, filters },
    meta: {}
  })

  const state = getState()

  if (supportViews) {
    if (shareViewPreferences) {
      handleSharedPreferences(state, dispatch, tableKey, 'filters', filters)
    } else {
      const selectedView = getTableSelectedView(state, tableKey)
      if (selectedView) {
        selectedView.preferences.filters = filters
        dispatch(editTableView(tableKey, selectedView))
      }
    }
  }
}

export const configureTableColumns = (tableKey, columns, options, supportViews, shareViewPreferences) => async (dispatch, getState) => {
  dispatch({
    type: CONFIGURE_TABLE_COLUMNS,
    payload: { table: tableKey, columns },
    meta: { table: tableKey, selectedColumns: columns, options }
  })

  const state = getState()

  if (supportViews) {
    if (shareViewPreferences) {
      const tableViews = getTablesViews(state)[tableKey] || {}
      const selectedViewId = getTableSelectedViewID(state, tableKey)
      Object.values(tableViews).forEach(view => {
        view.preferences.columnsConfiguration = columns
        view.preferences.sortedOnce = true
        dispatch(editTableView(tableKey, view, selectedViewId === view.id))
      })
    } else {
      const selectedView = getTableSelectedView(state, tableKey)
      if (selectedView) {
        selectedView.preferences.columnsConfiguration = columns
        selectedView.preferences.sortedOnce = true
        dispatch(editTableView(tableKey, selectedView))
      }
    }
  }
}

export const toggleFilterTable = (table, isOpen) =>
  ({
    type: TOGGLE_FILTER_TABLE,
    payload: { table, isOpen },
    meta: {}
  })

export const updateUserPreferences = (table, userPreference) =>
  ({
    type: UPDATE_USER_PREFERENCES,
    payload: { table, userPreference },
    meta: {}
  })

export const getTableViews = ({ tableKey }) =>
  send(GET_TABLE_VIEWS, {
    url: `/api/tableViews/${tableKey}`,
    meta: { tableKey }
  })

export const editTableView = (tableKey, view, isSelectedView = true) =>
  send(EDIT_TABLE_VIEW, {
    url: `/api/tableViews/${view.id}`,
    method: 'PUT',
    body: {
      preferences: view.preferences,
      viewName: view.viewName,
      shareStatus: view.shareStatus
    },
    meta: {
      tableKey,
      isSelectedView
    }
  })

export const addTableView = (tableKey, view, templateName, shareStatus) =>
  send(ADD_TABLE_VIEW, {
    url: `/api/tableViews`,
    method: 'POST',
    body: {
      tableKey,
      preferences: view.preferences,
      viewName: view.viewName,
      shareStatus
    },
    meta: {
      tableKey,
      view,
      viewName: view.viewName,
      templateName
    }
  })

export const deleteTableView = (tableKey, view) =>
  send(DELETE_TABLE_VIEW, {
    url: `/api/tableViews/${view.id}`,
    method: 'DELETE',
    meta: {
      tableKey,
      view,
      viewName: view.viewName
    }
  })
