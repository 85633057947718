import React, { Component } from 'react'
import { SCOPES } from '@root/constants'
import AdvancedFilters from '@components/advancedFilters'
import { getFilterOptionsValues, getValidFilters } from '@shared/filters'
import { css } from 'glamor'
import { fontWeight } from '@shared/style/sizes'
import pluralize from 'pluralize'
import Analytics from '@helpers/analytics'
import CatalogAppsPopup from '@components/popups/catalogAppsPopup'
import isEqual from 'lodash/isEqual'
import EnableFor from '@components/enableFor'
import { AlertBox, AlertBoxType, Stack } from '@toriihq/design-system'

const CSS = {
  description: css({
    paddingBottom: '5px'
  }),
  matchingAppsCount: css({
    fontWeight: fontWeight.bold
  })
}

class CatalogAppsFilters extends Component {
  state = { isPopupOpen: false }

  componentDidMount () {
    const { idOrg, getCatalogApps, getStateDetails } = this.props
    getCatalogApps({ idOrg })
    getStateDetails({ idOrg })
  }

  togglePopup = () => {
    const nextPopUpState = !this.state.isPopupOpen

    this.setState({ isPopupOpen: nextPopUpState })
    if (nextPopUpState) {
      Analytics.track('Click on View applications link/Apps catalog settings')
    }
  }

  onChange = async (updatedFilters) => {
    const { idOrg, onFiltersChange, filters, getCatalogApps } = this.props

    await onFiltersChange(updatedFilters)

    if (!isEqual(getValidFilters(updatedFilters), getValidFilters(filters))) {
      getCatalogApps({ idOrg })
    }
  }

  render () {
    const { filters, apps, stateOptions, stateOrder, tableInfo, usersById, catalogApps, isLoadingCatalogApps } = this.props
    const { isPopupOpen } = this.state

    const optionsValuesPerKey = getFilterOptionsValues({ apps, stateOptions, tableInfo, usersById })

    return <div>
      <Stack direction='column' gap='space-100'>
        <span {...CSS.description}>Show in the catalog apps that match the following filters:</span>
        <EnableFor scopes={[SCOPES.APPLICATION_CATALOG_WRITE]}>
          <AdvancedFilters
            onChange={this.onChange}
            optionsKey={tableInfo.filtersOptions}
            optionsValuesPerKey={optionsValuesPerKey}
            filters={filters}
            supportClearAllFilters={false}
            fieldsAutoFocus
          />
        </EnableFor>
        <AlertBox
          type={AlertBoxType.INFORMATIVE}
          description={filters.length === 0 ? <span>All <span {...CSS.matchingAppsCount}>{pluralize('application', catalogApps.length, true)}</span> were added to the catalog. Consider showing less applications by adding filters.</span>
            : (catalogApps.length === 0 ? <span>There are no applications matching the filters. Your catalog is empty. Consider editing the filters.</span>
              : <span><span {...CSS.matchingAppsCount}>{pluralize('application', catalogApps.length, true)}</span> matching the filters were added to the catalog.</span>)}
          secondaryButton={{
            label: isLoadingCatalogApps ? 'Calculating' : 'View applications',
            onClick: this.togglePopup,
            disabled: isLoadingCatalogApps || catalogApps.length === 0
          }}
        />
      </Stack>
      <CatalogAppsPopup isOpen={isPopupOpen} cancel={() => this.togglePopup()} apps={catalogApps} stateOptions={stateOptions} stateOrder={stateOrder} />
    </div>
  }
}

CatalogAppsFilters.defaultProps = {
  catalogApps: []
}

export default CatalogAppsFilters
