import texts from '../../shared/style/texts'
import { CURRENCY_BADGE } from '../table/editableTableColumn/types/currency'
import { fontSize, fontWeight } from '@shared/style/sizes'
import { insideTableCellsClass, insideTableRowsClass } from '@shared/style/mixins'
import { css } from 'glamor'
import colors from '../../shared/style/colors'
import styled from 'styled-components'

export const CSS = {
  regular: css(texts.body, {
    color: colors.black
  }),
  tableHeader: css({
    display: 'flex'
  }),
  icon: css({
    padding: '10px 5px',
    width: '25px'
  }),
  appNameColumnValue: css({
    whiteSpace: 'normal'
  }),
  editButton: css({
    padding: '10px 0px 10px 24px !important',
    opacity: 0,
    transition: 'opacity .2s',
    [insideTableCellsClass]: {
      textOverflow: 'unset !important'
    },
    [insideTableRowsClass]: {
      opacity: 1
    }
  }),
  yearlyLicenseCost: css({
    paddingRight: '8px',
    display: 'flex',
    flexDirection: 'row-reverse'
  }),
  badge: css(CURRENCY_BADGE, {
    lineHeight: '14px',
    height: '17px'
  }),
  benchmarkContainer: css({
    display: 'flex',
    marginLeft: '16px'
  }),
  benchmarkBadge: css({
    lineHeight: '14px',
    height: '17px',
    borderRadius: '2px',
    padding: '1px 3px',
    color: colors.grey2,
    fontSize: fontSize.extraSmall,
    fontWeight: fontWeight.bold,
    textTransform: 'uppercase',
    backgroundColor: colors.transparent,
    border: `1px solid ${colors.grey2}`
  }),
  benchmark: css({
    paddingRight: '32px',
    fontSize: '18px'
  }),
  headerIcon: css({
    marginLeft: '6px',
    marginRight: '6px'
  }),
  headerWithIcon: css({
    alignItems: 'center',
    display: 'flex'
  }),
  spinner: css({
    height: '16px',
    width: '16px',
    marginRight: '8px',
    marginBottom: '4px'
  }),
  infoText: css({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px'
  }),
  licenseRowIconAndLabel: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }),
  totalLicensesNumberAndIconWithTooltip: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '4px'
  }),
  hidden: css({
    display: 'none'
  }),
  licenseAmount: css({
    float: 'right'
  }),
  licenseTooltipIcon: css({
    marginTop: '5px'
  }),
  columnMarginLeft: css({
    marginLeft: '16px'
  }),
  totalLicensesContainer: css({
    paddingRight: '8px',
    display: 'flex',
    flexDirection: 'row-reverse'
  }),
  totalAmountWithLabel: css({
    flex: 'none',
    order: 0,
    flexGrow: 0,
    textAlign: 'right'
  }),
  estimatedLabel: css({
    fontSize: '12px'
  }),
  alertBoxContainer: css({
    marginBottom: 12
  })
}

export const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`

export const PaddingText = styled.div`
  padding: 0 8px;
`

export const TotalAmountEditedLabel = styled.div`
  font-size: 10px;
  margin-top: -4px;
  font-weight: ${fontWeight.normal};
  color: ${({ theme }) => theme.palette.text.disabled};
`

export const ExpanderChevron = styled.span`
  display: inline-flex;
  transform: ${({ isExpanded }) => isExpanded && 'rotate(-180deg)'};
  transition: transform 0.2s ease-in-out;
`

export const UnavailableOrLoadingBenchmark = styled.div`
  display: flex;
  justify-content: center;
  color: ${({ theme }) => theme.palette.text.disabled};
  ${props => props.$hidden && 'display: none;'}
`

export const LicenseStatusLabel = styled.span`
  color: ${({ theme }) => theme.palette.text.tertiary};
`

export const InactiveMoreThanZero = styled.div`
  color: ${({ theme }) => theme.palette.link.primary};
  &:hover {
    > .inactiveAmount {
      text-decoration: underline;
    }
  }
  &[disabled] {
    color: ${colors.black};
    cursor: text;
    &:hover {
      > .inactiveAmount {
        text-decoration: none;
      }
    }
  }
`
