import UsersList from '@root/components/popups/usersMapPopup/usersList'
import { ReactElement } from 'react'
import { UsersMapDataPopupProps } from './types'

const UsersMapDataPopup = (props: UsersMapDataPopupProps): ReactElement => {
  const { additionalDataPopupOptions } = props
  return <UsersList country={additionalDataPopupOptions} />
}

export default UsersMapDataPopup
