import React from 'react'
import Table from '../../../table/index'
import colors from '../../../../shared/style/colors'
import { getDisplayName } from '../../../../lenses/users'
import UserDetails from '../../../userDetails'
import SourceIcon from '../../../sourceIcon'
import { getSourceByType } from '../../../../sourcesConfig'
import { TABLES } from '../../../../constants'
import { getFormattedDate } from '@lenses/utils'
import debounce from 'lodash/debounce'
import { EMPTY_ARRAY, ITEMS_PER_PAGE } from '@root/constants'
import config from '../../../../config'
import PropTypes from 'prop-types'
import { sortStringFromArray } from '@shared/utils/utils'

const getNameColumn = () => ({
  Header: 'User',
  id: 'fullName',
  accessor: ({ firstName, lastName, email }) => {
    const array = [firstName, lastName, email]
    return array.join(' ').trim().toLowerCase()
  },
  minWidth: 220,
  maxWidth: 250,
  Cell: ({ row: { firstName, lastName, idUser, email, isExternal, photoUrl } }) => {
    return <UserDetails
      firstName={firstName}
      lastName={lastName}
      email={email}
      isExternal={isExternal}
      idUser={idUser}
      photoUrl={photoUrl}
    />
  },
  textValue: ({ user: { firstName, lastName, email } }) => getDisplayName({ firstName, lastName, email })
})

const columns =
  [
    getNameColumn(),
    {
      accessor: 'email',
      show: false,
      textHeader: 'Email'
    },
    {
      Header: 'Country',
      show: true,
      accessor: 'countryName'
    },
    {
      Header: 'Last seen',
      accessor: 'usageDate',
      Cell: ({ value: usageDate }) => {
        return getFormattedDate({ date: usageDate })
      },
      sortMethod: Table.sortMethods.date,
      minWidth: 80,
      show: true
    },
    {
      Header: 'Source',
      show: true,
      accessor: 'source',
      Cell: ({ value: source }) => (
        <div>
          <SourceIcon
            key={source}
            sourceType={source}
            tooltipText={getSourceByType(source).name}
          />
        </div>
      )
    },
    {
      accessor: 'firstName',
      show: false
    },
    {
      accessor: 'lastName',
      show: false
    },
    {
      accessor: 'idUser',
      show: false
    },
    {
      accessor: 'isExternal',
      show: false
    },
    {
      accessor: 'photoUrl',
      show: false
    }
  ]

class UsersList extends React.Component {
  state = { sort: EMPTY_ARRAY }

  componentDidMount () {
    this.fetchData(true)
  }

  fetchData = debounce((reset = false) => {
    const { users } = this.props

    this.fetchUsers({ offset: reset ? 0 : users.length })
  }, 500, { leading: true, trailing: false })

  exportToCsv = ({ query }) => {
    const { idOrg, country } = this.props
    const { sort } = this.state

    const sortParam = `sort=${encodeURIComponent(sortStringFromArray(sort))}`
    const queryParam = `q=${encodeURIComponent(query)}`
    const params = `${country && country.countryCode ? `country=${encodeURIComponent(country.countryCode)}` : ''}`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/trends/usersByCountry/csv?${sortParam}&${queryParam}&${params}`
    const newWindow = window.open(url, '_blank')
    newWindow.opener = null
  }

  fetchUsers ({ limit = ITEMS_PER_PAGE, offset = 0, props = this.props }) {
    const { getUsersByCountry, idOrg, country } = props
    const { sort } = this.state

    getUsersByCountry({ idOrg, limit, offset, country: country && country.countryCode, sort: sortStringFromArray(sort), q: this.query })
  }

  onSearch = debounce((q) => {
    this.query = q
    this.fetchData(true)
  }, 250)

  onSortedChange = (sort) => {
    this.setState({ sort }, () => this.fetchData(true))
  }

  render () {
    const { users, loading, loadingMore, totalUsers, scrollObjectId = 'contentArea' } = this.props

    return (
      <Table
        tableKey={TABLES.mapUsersTable.key}
        data={users}
        columns={columns}
        loading={loading}
        loadingMore={loadingMore}
        getTdProps={setTdStyle}
        forceShowSearch
        manual
        scrollObjectId={scrollObjectId}
        searchable
        onSearch={this.onSearch}
        fetchData={this.fetchData}
        exportable
        exportFunction={this.exportToCsv}
        onSortedChangeCB={this.onSortedChange}
        totalCount={totalUsers}
        pageSize={25}
        forceShowNumberOfResults
      />
    )
  }
}

UsersList.propTypes = {
  users: PropTypes.array,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  totalUsers: PropTypes.number,
  scrollObjectId: PropTypes.string,
  idOrg: PropTypes.string.isRequired,
  country: PropTypes.object
}

const setTdStyle = () => {
  return {
    style: {
      border: 0,
      padding: '3px 10px',
      color: colors.black
    }
  }
}

export default UsersList
