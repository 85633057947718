import RelativeTeamUserLink from '@components/relativeTeamUserLink'
import { getDisplayName } from '@lenses/users'
import { AddButton, Avatar, Badge, Tooltip } from '@toriihq/design-system'
import capitalize from 'lodash/capitalize'
import PropTypes from 'prop-types'
import React from 'react'
import EnableFor from '../../components/enableFor'
import { Label } from './styles'

class SelectUserButton extends React.Component {
  render () {
    const { toggleAssignUser, user, idApp, onSelectUser, actionDescription, suggestOwner, allowedScopes = [], assignUserCB, componentPage, actionNotes, disabled } = this.props
    const { email, id, firstName, lastName, photoUrl } = user || {}
    if (user) {
      const showDeletedUserIndication = Boolean(user.isDeletedInIdentitySources)
      const deletedUserTooltip = showDeletedUserIndication && <strong>Left the organization</strong>
      const badge = showDeletedUserIndication && <Badge name='Warning' size='medium' />
      const tooltipLabel =
        <Label>
          <div>{getDisplayName(user)}</div>
          <div>{email}</div>
          {deletedUserTooltip}
        </Label>

      return (
        <Tooltip label={tooltipLabel}>
          <RelativeTeamUserLink idUser={id}>
            <Avatar firstName={firstName} lastName={lastName} imageUrl={photoUrl} badge={badge} />
          </RelativeTeamUserLink>
        </Tooltip>
      )
    }
    return (
      <Tooltip label={capitalize(actionDescription)}>
        <EnableFor scopes={allowedScopes}>
          <AddButton
            onClick={() => !disabled && toggleAssignUser(true, idApp, onSelectUser, suggestOwner, actionDescription, assignUserCB, componentPage, actionNotes)}
            disabled={disabled}
          />
        </EnableFor>
      </Tooltip>
    )
  }
}

SelectUserButton.propTypes = {
  size: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }),
  toggleAssignUser: PropTypes.func.isRequired,
  idApp: PropTypes.number.isRequired,
  actionDescription: PropTypes.string.isRequired,
  actionNotes: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.object
  ]),
  onSelectUser: PropTypes.func.isRequired,
  componentPage: PropTypes.string,
  disabled: PropTypes.bool
}

SelectUserButton.defaultProps = {
  disabled: false
}

export default SelectUserButton
