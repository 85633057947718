import * as Style from './style'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { isFeatureEnabled } from '@lenses/features'
import { FEATURE_FLAGS } from '@root/constants'
import Eko from '@media/eko.svg'
import { Icon } from '@toriihq/design-system'

const ToriiChat = () => {
  const featureEnabled = useSelector(state => isFeatureEnabled(state, FEATURE_FLAGS.AIChat))
  const [isOpen, setIsOpen] = useState(false)
  const iframeOpened = useRef(false)

  const toggleWidget = () => {
    setIsOpen(prev => !prev)
    if (!iframeOpened.current) {
      iframeOpened.current = true
    }
  }

  if (!featureEnabled) return null

  return (
    <Style.WidgetContainer>
      <Style.WidgetButton onClick={toggleWidget}>
        <Style.IconContainer className={!isOpen ? 'active' : ''}>
          <img src={Eko} alt='Eko' width={50} height={50} style={{ position: 'absolute', bottom: 0 }} />
        </Style.IconContainer>
        <Style.IconContainer className={isOpen ? 'active' : ''}>
          <Icon name='Close' color='interactive' size='mobile' />
        </Style.IconContainer>
      </Style.WidgetButton>
      {iframeOpened.current && (
        <Style.ChatWindow $isOpen={isOpen}>
          <Style.Iframe src='https://chat.toriihq.com' title='Torii Chat' loading='lazy' />
        </Style.ChatWindow>
      )}
    </Style.WidgetContainer>
  )
}

export default ToriiChat
