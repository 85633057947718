import { Filter } from '@store/actions/types'

export enum TASK_STATUS {
  IN_PROGRESS = 'inProgress',
  NEEDS_ATTENTION = 'needsAttention',
  CANCELED = 'canceled',
  COMPLETED = 'completed',
}

export enum TASK_TYPE {
  REMOVE_USER_FROM_APP = 'removeUserFromApp'
}

export interface GetTasksProps {
  limit?: number,
  offset?: number,
  reset?: boolean,
  filters?: Filter[],
  q?: string
}
