import { Grid, RowWithoutMargin, Col, Stack, Subtitle2, Icon, Select, Tooltip, Spinner, SpinnerColor, EmptyState } from '@toriihq/design-system'
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getContractAttachments } from '@root/store/actions/contracts'
import { getContractAttachments as getContractAttachmentsSelector } from '@selectors/contracts'
import { NoDocumentsPreview } from './style'
import { useWindowDimensions } from '@root/shared/hooks'
import { last } from 'lodash'
import brokenContractImage from 'src/components/contracts/images/brokenContract.svg'

const PdfPreview = ({ documents, idOrg, idApp }) => {
  const dispatch = useDispatch()
  const { height: windowHeight } = useWindowDimensions()

  const contractIdUploads = useMemo(() => {
    const idUploads = documents.map(doc => doc.idUpload) ?? []
    return idUploads
  }, [documents])

  useEffect(() => {
    if (contractIdUploads.length > 0) {
      dispatch(getContractAttachments({ idOrg, idUploads: contractIdUploads, idApp }))
    }
  }, [contractIdUploads, dispatch, idOrg, idApp])

  const { loading: isLoadingContractAttachments, data: contractAttachmentsData } = useSelector(getContractAttachmentsSelector)

  const [selectedAttachment, setSelectedAttachment] = React.useState<{ label: string, value: string } | null | undefined>(null)

  const contractAttachments = useMemo(() => {
    if (documents.length === 0) return []
    if (!isLoadingContractAttachments && contractAttachmentsData?.files?.length > 0) {
      const onlyPDF = contractAttachmentsData.files.filter(attachment => attachment.name.includes('pdf'))
      const pdfOptions = onlyPDF.map(attachment => ({ label: attachment.name, value: attachment.url }))
      setSelectedAttachment(last(pdfOptions))
      return pdfOptions
    }
    return []
  }, [documents, contractAttachmentsData?.files, isLoadingContractAttachments])

  const onSelectAttachment = (attachment) => {
    setSelectedAttachment(attachment)
  }

  const Preview = <iframe
    title='contract pdf preview'
    src={selectedAttachment?.value}
    style={{
      width: '100%',
      height: '100%',
      borderTop: 0,
      borderRight: '2px solid #0E1C28',
      borderLeft: '2px solid #0E1C28',
      borderBottom: '2px solid #0E1C28',
      borderRadius: '4px'
    }}
  />

  return (
    <Grid fullWidth style={{ height: windowHeight < 1000 ? `${windowHeight - 420}px` : '580px', position: 'sticky', top: 0 }}>
      <RowWithoutMargin style={{ marginBottom: '12px' }}>
        <Col>
          <Stack gap='space-050'>
            <Stack direction='row' gap='space-050'>
              <Subtitle2>
                Documents
              </Subtitle2>
              <Tooltip maxWidth={500} label='Preview is available for PDF files only'>
                <Icon name='Info' />
              </Tooltip>
            </Stack>
            <Select
              selectWidth={'100%'}
              value={selectedAttachment}
              options={contractAttachments}
              onChange={onSelectAttachment}
              isDisabled={contractAttachments.length === 0}
            />
          </Stack>
        </Col>
      </RowWithoutMargin>
      <RowWithoutMargin style={{ height: '100%' }}>
        <Col>
          <div style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            {isLoadingContractAttachments
              ? <NoDocumentsPreview><Spinner color={SpinnerColor.active} /></NoDocumentsPreview>
              : contractAttachments.length === 0
                ? <EmptyState
                  image={<img src={brokenContractImage} alt='No documents' />}
                  size='small'
                  description={<span>No documents to preview. <br />Preview is available for PDF file only</span>}
                />
                : Preview}
          </div>
        </Col>
      </RowWithoutMargin>
    </Grid>
  )
}

export default React.memo(PdfPreview, (prevProps, nextProps) => {
  return JSON.stringify(prevProps.documents) === JSON.stringify(nextProps.documents)
})
