import React from 'react'
import PropTypes from 'prop-types'
import * as Style from './style'
import { Icon } from '@toriihq/design-system'
import spinnerMedia from '@media/spinner.svg'

const AlertBanner = (props) => {
  const { colorName, iconColor, iconName, showSpinner, bannerText } = props
  return (
    <Style.ResultBanner data-testid='alertBanner' $colorName={colorName}>
      <Icon name={iconName} color={iconColor} />
      <Style.BannerText>{bannerText}</Style.BannerText>
      { showSpinner && <Style.Spinner alt='Spinner' src={spinnerMedia} width='100%' height='auto' />}
    </Style.ResultBanner>
  )
}

AlertBanner.propTypes = {
  colorName: PropTypes.string.isRequired,
  iconColor: PropTypes.string.isRequired,
  iconName: PropTypes.string.isRequired,
  showSpinner: PropTypes.bool,
  bannerText: PropTypes.string
}

export default AlertBanner
