import React, { MouseEvent } from 'react'
import { ButtonType, Button, Tooltip } from '@toriihq/design-system'
import { useDispatch, useSelector } from 'react-redux'
import { toggleTheme } from '@root/store/actions/ui'

const DarkModeButton = () => {
  const dispatch = useDispatch()
  const theme = useSelector(state => state.ui.userPreferences.theme?.value || 'light')
  const isDarkMode: boolean = theme === 'dark'

  const onClick = (event: MouseEvent<HTMLButtonElement>): void => {
    dispatch(toggleTheme())
  }

  return (
    <Tooltip
      placement='bottom'
      label={isDarkMode ? 'Light Mode' : 'Dark Mode'}>
      <Button
        type={ButtonType.tertiary}
        icon={isDarkMode ? 'Sun' : 'Moon'}
        dataTestId='dark-mode-button'
        onClick={onClick}
      />
    </Tooltip>
  )
}

export default DarkModeButton
