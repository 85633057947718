import { removeColumnOptionsByFilterOptions, removeRestrictedFilterOptions } from '@root/shared/filters'
import { getEntitiesMetadataByEntityType } from '@root/store/selectors/dashboards'
import { useSelector } from 'react-redux'
import { formFieldTypesToFieldTypes } from '@root/constants'
import { UseGetEntityTableDataProps, UseGetEntityTableDataReturn } from './types'
import { getSelf } from '@root/store/selectors/me'
import { getCurrentOrg } from '@root/store/selectors/org'

export const useGetEntityTableData = ({ entityType, fields }: UseGetEntityTableDataProps): UseGetEntityTableDataReturn => {
  const entitiesMetadata = useSelector(getEntitiesMetadataByEntityType)
  const me = useSelector(getSelf)
  const org = useSelector(getCurrentOrg)

  const metadata = entitiesMetadata[entityType]
  const { customFields, predefinedFields } = metadata
  const configurableColumnsOptionsWithoutRestrictions = predefinedFields
    .concat(customFields.filter(field => field.isShown))
    .map(field => ({ value: field.systemKey, label: field.name }))
  const preDefinedColumnsMapping = predefinedFields.reduce((acc, field) => {
    acc[field.systemKey] = field.name
    return acc
  }, {})

  const filtersOptionsWithoutRestrictions = predefinedFields.concat(customFields).map(field => ({ ...field, label: field.name, value: field.systemKey, type: formFieldTypesToFieldTypes[field.type] }))
  const filtersOptions = removeRestrictedFilterOptions(filtersOptionsWithoutRestrictions, { me: me, org: org })
  const configurableColumnsOptions = removeColumnOptionsByFilterOptions(filtersOptions, configurableColumnsOptionsWithoutRestrictions)

  const columnsConfiguration = fields.reduce((acc, field) => {
    acc[field] = true
    return acc
  }, {})

  return { preDefinedColumnsMapping, configurableColumnsOptions, filtersOptions, customFields, columnsConfiguration }
}
