import { useEffect } from 'react'
import { AdvancedCard } from '@toriihq/design-system'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentOrg } from '@selectors/org'
import { getDashboardsData } from '@selectors/dashboards'
import { WidgetFormattedData, DynamicWidgetProps } from '@components/dashboard/widgets/types'
import { getAggsForGroupBy, getFormattedData } from '@components/dashboard/widgets/utils'
import { getEntityDataForWidget } from '@actions/dashboards'
import { EMPTY_ARRAY, EMPTY_OBJECT, fieldTypes } from '@root/constants'
import { BAR_ORIENTATION } from '@reducers/dashboards/types'
import WidgetEmptyState from '../../shared/emptyState'
import DashboardBarChart from './dashboardBarChart'
import WidgetTooltip from '../../shared/widgetTooltip'
import { useTheme } from 'styled-components'
import Currency from '@components/currency'
import numeral from 'numeral/numeral'
import BarChartLoadingState from '../../shared/barChartLoadingState'

const BarChartWidget = (props: DynamicWidgetProps) => {
  const { idDashboard, widget, isSelected, primaryButton, isWidgetMenuOpen, onFilteredDrilldown } = props

  const dispatch = useDispatch()
  const theme = useTheme()

  const { id: idOrg } = useSelector(getCurrentOrg)
  const dashboardsData = useSelector(getDashboardsData)
  const dashboardData = dashboardsData[idDashboard]
  const widgetsDataById = dashboardData?.widgetsData || EMPTY_OBJECT
  const widgetData = widgetsDataById[widget.id]

  const { loading, aggregations = EMPTY_ARRAY, displayConfig } = widgetData?.data ?? EMPTY_OBJECT

  const data = getFormattedData({ displayConfig, aggregations: aggregations.filter(aggs => aggs.key !== 'Other') })

  const loadingData = loading && data.length === 0

  const isVertical = widget?.displayConfig?.barOrientation === BAR_ORIENTATION.VERTICAL

  useEffect(() => {
    if (idOrg) {
      dispatch(getEntityDataForWidget({
        entityType: widget.entityType,
        idOrg,
        idDashboard,
        displayConfig: widget.displayConfig,
        idWidget: widget.id,
        filters: widget.dataConfig.filters,
        aggs: getAggsForGroupBy({ widget })
      }))
    }
  }, [dispatch, idOrg, widget, idDashboard])

  const valueFormatter = (value: any) => {
    if (displayConfig?.groupBy?.metric?.field?.type === fieldTypes.currency) {
      return <Currency value={value} />
    } else {
      return numeral(value).format(Number.isInteger(value) ? '0,0' : '0,0.0')
    }
  }

  const handleBarClick = (selectedData: WidgetFormattedData) => {
    onFilteredDrilldown && onFilteredDrilldown(widget, data, selectedData)
  }

  return (
    <AdvancedCard
      title={widget?.displayConfig?.label}
      hoverable
      selected={isSelected}
      primaryButton={primaryButton}
      showPrimaryButton={isWidgetMenuOpen}
    >
      {loadingData ? <BarChartLoadingState isVertical={isVertical} numberOfBars={isVertical ? 7 : 5} /> : (
        data.length ? <DashboardBarChart
          data={data}
          fill={theme.palette.background.brandPrimary}
          barSize={24}
          isVertical={isVertical}
          formatter={valueFormatter}
          customTooltip={<WidgetTooltip widget={widget} payload={undefined} active={undefined} />}
          onBarClick={handleBarClick}
        />
          : <WidgetEmptyState />
      )}
    </AdvancedCard>
  )
}

export default BarChartWidget
