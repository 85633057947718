import React from 'react'
import PropTypes from 'prop-types'
import AssignUser, { validate } from '../../assignUser'
import ToriiPopup from '../ToriiPopupV2'
import * as Style from './style'

const SelectUserPopup = (props) => {
  const { cancel, isOpen, close, actionDescription, actionNotes, isMultipleSelect, ...rest } = props

  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={cancel}>
      <ToriiPopup.Header header={actionDescription} subHeader={actionNotes} />
      <ToriiPopup.Form
        validate={validate}
        contentAreaStyle={Style.MainArea}
        render={(formProps) => {
          return (
            <AssignUser
              onSuccess={close}
              isMultipleSelect={isMultipleSelect}
              {...rest}
              {...formProps}
            />
          )
        }}
        renderFooter={(formProps) => (
          <ToriiPopup.Footer
            showCancelButton={false}
            mainButtonText={'Save'}
            isMainSubmit
            recoveryTime={1500}
            formProps={formProps}
          />
        )}
      />
    </ToriiPopup>
  )
}

SelectUserPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func
}

export default SelectUserPopup
