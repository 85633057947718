import { Tooltip, ToggleProps, Side } from '@toriihq/design-system'
import AccessControl from '@lenses/accessControl'
import { getPlanForFeatureTooltipLabel } from '@shared/features'
import ToriiToggle from '@components/toriiToggle'

interface Props extends ToggleProps {
  feature: string,
  tooltipPlacement: Side,
  onToggle: (enabled: boolean) => void,
  id: number | string,
}

const ToggleOfFeature = ({
  feature,
  tooltipPlacement = 'top',
  ...toggleProps
}: Props): JSX.Element => {
  const isFeatureEnabledInPlan = AccessControl.useIsFeatureEnabledInPlan({ feature })
  const tooltip = getPlanForFeatureTooltipLabel(feature)

  return (
    <Tooltip
      placement={tooltipPlacement}
      label={tooltip}
      hide={isFeatureEnabledInPlan || !tooltip}>
      <ToriiToggle {...toggleProps} disabled={toggleProps.disabled || !isFeatureEnabledInPlan} />
    </Tooltip>
  )
}

export default ToggleOfFeature
