import PropTypes from 'prop-types'
import React from 'react'
import config from '../../../config'
import EditableField from '@components/editableField'
import isEmpty from 'lodash/isEmpty'
import UploadFileDropzone from '../../uploadAppDetailsAttachments/uploadFileDropzone'
import { Link, Button, ButtonType, Body2 } from '@toriihq/design-system'
import { withTheme } from 'styled-components'
import * as Style from './style'
import Placeholder from '../../placeholder'

class EditableUploadFileBox extends React.Component {
  onRemoveFile (idField, idFile) {
    const { onRemove } = this.props
    onRemove({ idField, idFile })
  }

  render () {
    const { disabled, idOrg, idApp, uploadDetails, readonly, toggleUploadAppDetailsAttachments, loading, uploadViaPopup, onUpload, supportMultiOnUploadCallback } = this.props
    const onUploadFile = () => toggleUploadAppDetailsAttachments(true, true, idApp, uploadDetails.idField)
    const uploadFile = <Button type={ButtonType.compact} icon='Upload' disabled={disabled} onClick={onUploadFile} label='Upload' />
    const headerComponent = (
      <Style.HeaderContainer>
        <Style.Header>{uploadDetails.header || uploadDetails.name || 'Files'}</Style.Header>
        {uploadViaPopup && uploadFile}
      </Style.HeaderContainer>
    )
    const descriptionComponent = uploadDetails.description && <Style.Description><Body2>{uploadDetails.description}</Body2></Style.Description>

    const fileFields = uploadDetails.values && uploadDetails.values.map(file => {
      const onRemoveFile = () => this.onRemoveFile(uploadDetails.idField, file.id)
      return (
        <EditableField
          readonly={readonly}
          disabled={disabled}
          key={file.id || file.idUpload}
          label={<Style.FileName><Link href={`${config.apiBaseUrl}/api/uploads/${idOrg}/${file.idUpload}`} target='_blank' rel='noopener noreferrer'>{file.name}</Link></Style.FileName>}
          value={''}
          ignoreEditMode
          onRemove={onRemoveFile}
          showRemoveButton
          emptyValue=''
          style={Style.Field}
        />
      )
    })

    return (
      <Style.UploadFileContainer>
        <Placeholder loading={loading} rows={3} style={{ maxWidth: '95%', margin: '10px', padding: '20px' }}>
          {headerComponent}
          {descriptionComponent}
          <Style.FilesContainer>
            {isEmpty(fileFields) && <Style.Description><Body2>No files uploaded yet</Body2></Style.Description>}
            {fileFields}
            {!uploadViaPopup &&
            <Style.UploadFileBox>
              <UploadFileDropzone
                idApp={idApp}
                disabled={disabled}
                onPostUpload={onUpload}
                supportMultiOnUploadCallback={supportMultiOnUploadCallback}
              />
            </Style.UploadFileBox>}
          </Style.FilesContainer>
        </Placeholder>
      </Style.UploadFileContainer>
    )
  }
}

EditableUploadFileBox.propTypes = {
  uploadDetails: PropTypes.shape({
    idField: PropTypes.number.isRequired,
    header: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      idUpload: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    }))
  }),
  uploadViaPopup: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  idOrg: PropTypes.number,
  idApp: PropTypes.number,
  onRemove: PropTypes.func.isRequired,
  onUpload: PropTypes.func,
  supportMultiOnUploadCallback: PropTypes.bool
}

EditableUploadFileBox.defaultProps = {
  uploadViaPopup: true,
  disabled: false,
  readonly: false,
  loading: false,
  supportMultiOnUploadCallback: false
}

export default withTheme(EditableUploadFileBox)
