import React, { Component } from 'react'
import { ToriiSelectAsync } from '../select'
import PropTypes from 'prop-types'
import debouncePromise from 'debounce-promise'
import UserDetails from '../userDetails'
import { getDisplayName } from '../../lenses/users'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import { simpleEmailRegex } from '@root/constants'
import { keyBy } from 'lodash'
import CustomOptionDetails from '@components/customOptionDetails'
import { Checkbox } from '@toriihq/design-system'
import noop from 'lodash/noop'

const VALUE_KEY = 'id'

class SelectUsers extends Component {
  state = {
    selectedUser: this.props.value
  }

  componentDidUpdate (prevProps) {
    if (prevProps.value !== this.props.value) {
      if (isString(this.props.value)) {
        this.setState({ selectedUser: this.props.specialUsers[this.props.value] })
      }
    }
  }

  onChange = (selectedUser) => {
    const { onChange } = this.props
    this.setState({ selectedUser }, () => {
      onChange && onChange(selectedUser)
    })
  }

  fetchData = async ({ searchValue, idUser }) => {
    const { idOrg, searchUsers, isExternal, excludePastUsers, showSelectedChildren } = this.props
    const idUsers = idUser ? [idUser] : undefined
    const includeChildren = showSelectedChildren && idUser
    const result = await searchUsers({ idOrg, idUsers, q: searchValue, limit: 10, isExternal, excludePastUsers, includeChildren })
    return result.users
  }

  debouncedFetchData = debouncePromise(this.fetchData, 500)

  initialGetOptions = true
  getOptions = async (searchValue) => {
    const { specialUsers, value, creatable } = this.props

    if (!searchValue) {
      if (this.initialGetOptions) {
        this.initialGetOptions = false

        if (isNumber(value)) {
          const users = await this.fetchData({ searchValue, idUser: value })
          this.setState({ selectedUser: users[0] })
        } if (isString(value)) {
          this.setState({ selectedUser: specialUsers[value] })
        } else if (isString(value?.value)) {
          this.setState({ selectedUser: specialUsers[value.value] })
        }
      }

      const initialRandomUsers = await this.fetchData({})
      return { options: Object.values(specialUsers).concat(initialRandomUsers) }
    }

    const specialUsersFiltered = Object.values(specialUsers).filter(user => {
      return user.label && user.label.toUpperCase().includes(searchValue.toUpperCase())
    })

    if (creatable && simpleEmailRegex.test(searchValue)) {
      return { options: [...specialUsersFiltered, { id: searchValue, label: searchValue, customEmail: true, noCheckbox: true }].map(o => ({ ...o, value: o.value || o.id, label: o.label || o.name })) }
    }

    const options = await this.debouncedFetchData({ searchValue })
    return { options: [...specialUsersFiltered, ...options] }
  }

  renderOption = ({ data }) => {
    if (isString(data.id) && isString(data.value)) {
      return (
        <div>
          <CustomOptionDetails {...data} />
        </div>
      )
    } else {
      const { specialUsers, idAppForUsingAppBadge } = this.props

      const badges = []
      if (specialUsers[data.id] && specialUsers[data.id].isSuggestedOwner) {
        badges.push({ text: 'Suggested', color: 'navy' })
      }
      if (data.idApps && data.idApps.includes(idAppForUsingAppBadge)) {
        badges.push({ text: 'Using app', color: 'gray' })
      }

      return <UserDetails
        key={data.id}
        overrideStyle={{ padding: 0, width: '100%' }}
        badge={badges}
        idUser={data.id}
        linkToUserPage={false}
        {...data}
      />
    }
  }

  renderValue = ({ data }) => {
    return data.label || getDisplayName(data)
  }

  filterOption = ({ data: user }) => {
    const { excludedOptions = [], value, useCheckboxes } = this.props
    const { selectedUser } = this.state

    if (useCheckboxes) {
      return true
    }

    const filteredOptionsById = keyBy(excludedOptions, VALUE_KEY)
    if (filteredOptionsById[user[VALUE_KEY]]) {
      return false
    }

    if (!selectedUser && !value) {
      return true
    }

    const selectedUsers = Array.isArray(value) ? value : [value]

    return !selectedUsers.filter(Boolean).some(selected => {
      if (isString(user.value) && isString(selected.value)) {
        return selected.value === user.value
      }
      if (!isString(selected.value) && !isString(user.value)) {
        return selected[VALUE_KEY] === user[VALUE_KEY]
      }
      return false
    })
  }

  renderOptionWithCheckbox = ({ data }) => {
    const { value, valueKey = 'value' } = this.props
    const optionChecked = value && value.filter(Boolean).some(selectedOption => selectedOption && data[valueKey] === selectedOption[valueKey])

    return <div key={data.value} style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ marginRight: '8px', height: '16px' }}><Checkbox checked={Boolean(optionChecked) || false} onChange={noop} /></div>
      <div>{this.renderOption({ data })}</div>
    </div>
  }

  render () {
    const {
      placeholder,
      value,
      closeOnSelect,
      excludedOptions,
      useCheckboxes,
      ...restSelectParams
    } = this.props
    const { selectedUser } = this.state

    return (
      <ToriiSelectAsync
        ref={select => { this.select = select }}
        optionRenderer={useCheckboxes ? this.renderOptionWithCheckbox : this.renderOption}
        valueRenderer={this.renderValue}
        valueKey={VALUE_KEY}
        labelKey='name'
        placeholder={placeholder}
        backspaceRemoves={false}
        clearable={false}
        autoBlur
        filterOption={this.filterOption}
        noResultsText='No users found'
        loadOptions={this.getOptions}
        cache={false}
        loadingPlaceholder={'Loading...'}
        defaultOptions
        {...restSelectParams}
        value={selectedUser || value}
        onChange={this.onChange}
        closeOnSelect={closeOnSelect ?? !restSelectParams.multi}
        selectWidth='100%'
        removeSelected={!useCheckboxes}
      />
    )
  }
}

SelectUsers.propTypes = {
  placeholder: PropTypes.string,
  idAppForUsingAppBadge: PropTypes.number,
  creatable: PropTypes.bool,
  excludedOptions: PropTypes.array,
  useCheckboxes: PropTypes.bool
}

SelectUsers.defaultProps = {
  placeholder: 'Search User...',
  specialUsers: {},
  excludedOptions: [],
  borderless: false,
  useCheckboxes: false
}

export default SelectUsers
