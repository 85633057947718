import React, { useEffect, useState } from 'react'
import * as Style from './style'
import {
  AlertBox,
  AlertBoxType,
  ButtonType,
  H4,
  ButtonSize,
  Icon,
  Link,
  Button,
  Body2, Spacer
} from '@toriihq/design-system'
import {
  getAllPlans,
  getOrg
} from '@actions/'
import { useDispatch, useSelector } from 'react-redux'
import {
  getIdOrg,
  getIsLoadingSubscriptions,
  getOrgPlanDetails,
  getOrgSubscriptions,
  getUserTypeSources as getUserTypeSourcesSelector,
  getCurrentOrg
} from '@selectors/org'
import moment from 'moment'
import pluralize from 'pluralize/pluralize'
import UpgradePlanButton from '@components/upgradePlanButton'
import Confirmation from '@components/confirmation'
import { getUserTypeSources } from '@actions/org'
import { SUPPORT_ARTICLES } from '@root/articles'
import SubscribeButton from './subscribeButton'
import { PLANS } from '@shared/features'
import Placeholder from '@components/placeholder'
import { getSubscriptionCustomerPortalSessionUrl, getSubscriptions, cancelSubscription as cancelSubscriptionAction } from '@actions/subscriptions'
import { formatNumber, sleep } from '@shared/utils'
import {
  MINIMUM_NUMBER_OF_USERS,
  MONTHLY_COST_PER_USER,
  MONTHLY_COST_PER_USER_ANNUAL_SUBSCRIPTION
} from '@components/subscription/constants'

const Subscription = () => {
  const dispatch = useDispatch()

  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] = useState(false)
  const idOrg = useSelector(getIdOrg)
  const org = useSelector(getCurrentOrg)
  const { trialEndTime, paidPlanEndTime } = org || {}
  const orgPlan = useSelector(getOrgPlanDetails)

  const subscriptions = useSelector(getOrgSubscriptions)
  const isLoading = useSelector(getIsLoadingSubscriptions) || !orgPlan
  const subscription = subscriptions[subscriptions.length - 1]
  const userTypeSources = useSelector(getUserTypeSourcesSelector)

  useEffect(() => {
    if (idOrg) {
      dispatch(getSubscriptions({ idOrg }))
      dispatch(getAllPlans())
      dispatch(getUserTypeSources({ idOrg }))
    }
  }, [idOrg, dispatch])

  const getCustomerPortalUrl = async () => {
    const response = await dispatch(getSubscriptionCustomerPortalSessionUrl({ idOrg }))
    window.location.replace(response.redirectUrl)
  }

  const cancelSubscription = async () => {
    await dispatch(cancelSubscriptionAction({ idOrg, idStripeSubscription: subscription.id }))

    // We wait a few seconds for Stripe's webhook to be called and update the subscription data
    await sleep(3 * 1000)
    await Promise.all([
      dispatch(getSubscriptions({ idOrg })),
      dispatch(getOrg({ idOrg }))
    ])

    setIsCancelConfirmationOpen(false)
  }

  const getTrialMessage = () => {
    const trialEndTimeMoment = moment(trialEndTime).utc()
    const now = moment().utc()
    const trialEndsToday = now.isSame(trialEndTimeMoment, 'days')

    let endsAtMessage
    if (trialEndsToday) {
      endsAtMessage = 'ends today'
    } else {
      const trialRemainingDays = moment(trialEndTimeMoment).diff(now, 'days')
      const trialDateEnd = trialEndTimeMoment
        .format('MMM DD, YYYY')
        .toString()
      endsAtMessage = `ends in ${pluralize(
        'day',
        Math.max(trialRemainingDays, 1),
        true
      )} on ${trialDateEnd}`
    }

    return `You are currently on a free trial that ${endsAtMessage}`
  }

  const hasActiveSubscription = subscription && subscription.externalStatus !== 'canceled' && (!subscription.cancelAtPeriodEnd || (subscription.cancelAtPeriodEnd && (moment() < moment(subscription.currentPeriodEnd))))
  const isCanceled = subscription?.externalStatus === 'canceled' || subscription?.cancelAtPeriodEnd
  const isInTrialAndNotSubscribed = trialEndTime && moment() < moment(trialEndTime) && !hasActiveSubscription

  const isPaymentProcessFailed = subscription && ['past_due', 'incomplete_expired', ' unpaid', 'past_due'].includes(subscription.externalStatus)

  const isBasic = orgPlan?.type.startsWith(PLANS.BASIC)
  const isInPaidPlanAndSubscriptionIsCancelled = (trialEndTime || paidPlanEndTime) && subscription && isCanceled && isBasic
  const isProfessional = orgPlan?.type.startsWith(PLANS.PROFESSIONAL)
  const isEnterprise = orgPlan?.type.startsWith(PLANS.ENTERPRISE)
  const isInBasicPaidPlanWithInvoice = isBasic && !subscription && paidPlanEndTime
  const isEmployeeDefinitionConfigured = userTypeSources.length > 0
  const isMonthly = subscription?.interval === 'month'
  const employeesOrUsers = isEmployeeDefinitionConfigured ? 'employees' : 'users'
  const isInvoice = isProfessional || isEnterprise || isInBasicPaidPlanWithInvoice
  const isTrailing = subscription?.externalStatus === 'trialing'
  const price = formatNumber(isMonthly ? MINIMUM_NUMBER_OF_USERS * MONTHLY_COST_PER_USER : subscription?.quantity * MONTHLY_COST_PER_USER_ANNUAL_SUBSCRIPTION * 12)
  const learnMore = <Link href={SUPPORT_ARTICLES.BILLING} target='_blank'>Learn more about Torii billing</Link>

  return (
    <Style.Wrapper>
      <H4>Subscription</H4>
      <Style.PlanContainer>
        {isInTrialAndNotSubscribed && isBasic && <Style.AlertBoxContainer>
          <AlertBox
            type={AlertBoxType.INFORMATIVE}
            title={getTrialMessage()}
            description='To keep using Torii please subscribe'
          />
        </Style.AlertBoxContainer>}
        {isInPaidPlanAndSubscriptionIsCancelled && <Style.AlertBoxContainer>
          <AlertBox
            type={AlertBoxType.NOTICE}
            title={`Your subscription was canceled and will end on ${moment(subscription.currentPeriodEnd).format('MMM DD, YYYY')}`}
            description='All associated data will be automatically deleted if you do not subscribe again within a few days'
          />
        </Style.AlertBoxContainer>}
        {isPaymentProcessFailed && <Style.AlertBoxContainer>
          <AlertBox
            type={AlertBoxType.NOTICE}
            title='We couldn’t process your payment'
            description='The payment for your subscription failed. Please update your billing info to continue using Torii'
          />
        </Style.AlertBoxContainer>}
        <Style.PlanTitle>Plan</Style.PlanTitle>

        {!hasActiveSubscription &&
        <div>
          <Style.PlanBox gap='space-100'>
            <Style.PlanRow>
              <Placeholder loading={isLoading}>
                <span>Torii {orgPlan?.name}</span>
              </Placeholder>
              { isInTrialAndNotSubscribed && <span>Free Trial</span> }
            </Style.PlanRow>
            {!isInvoice && isBasic && learnMore}
          </Style.PlanBox>
          {isInvoice && (org.paidPlanEndTime || org.trialEndTime) && (
            <Style.PlanEndTimeContainer>
              <Placeholder loading={isLoading} style={{ width: `${Style.planBoxMaxWidthPx / 2}px` }}>
                {org.paidPlanEndTime
                  ? `Plan end date: ${moment.utc(org.paidPlanEndTime).format('MMM DD, YYYY')}`
                  : `Trial end date: ${moment.utc(org.trialEndTime).format('MMM DD, YYYY')}`
                }
              </Placeholder>
            </Style.PlanEndTimeContainer>
          )}
          {(isProfessional || isInBasicPaidPlanWithInvoice) && <UpgradePlanButton feature='subscription' buttonType={ButtonType.primary} />}
        </div>
        }

        {hasActiveSubscription && <Style.PlanBox>
          <Style.BoxHeader>
            <Style.PlanName>Torii Basic</Style.PlanName>
            {subscription && <Button type={ButtonType.secondary} onClick={getCustomerPortalUrl} label='Manage billing' />}
          </Style.BoxHeader>

          <Style.PlanBillingPeriod>{ isMonthly ? 'Billed monthly' : 'Billed yearly' }</Style.PlanBillingPeriod>
          <Style.PriceContainer>
            <Style.Price>${price}</Style.Price>
            <Style.Period> / {isMonthly ? 'month' : 'year' }</Style.Period>
          </Style.PriceContainer>
          <Style.Description>{ isMonthly ? `Flat fee for ${MINIMUM_NUMBER_OF_USERS} ${employeesOrUsers}` : `$${MONTHLY_COST_PER_USER_ANNUAL_SUBSCRIPTION.toFixed(2)} X ${subscription.quantity} ${employeesOrUsers} / month`}</Style.Description>
          <Style.Description>
            ${MONTHLY_COST_PER_USER.toFixed(2)} for any additional {employeesOrUsers}
          </Style.Description>
          <Spacer top='space-075'>
            {learnMore}
          </Spacer>
        </Style.PlanBox>}

        {isBasic && subscription?.currentPeriodEnd && !subscription?.cancelAtPeriodEnd && !isTrailing && <Spacer bottom='space-200'>
          <Body2>Plan renews on {moment(subscription.currentPeriodEnd).format('MMMM DD, YYYY').toString()}</Body2>
        </Spacer>}

        {!isEmployeeDefinitionConfigured && isBasic && !isInvoice && <Style.EmployeeDefinitionMessage >
          <Icon name='Info' />
          <Style.EmployeeDefinitionMessageText>
            You can lower your cost by configuring your “Employee definition”
            <Spacer left='space-050'><Link href={SUPPORT_ARTICLES.EMPLOYEES} target='_blank'>Learn more</Link></Spacer>
          </Style.EmployeeDefinitionMessageText>
        </Style.EmployeeDefinitionMessage>}

        {isBasic && !isInvoice && <Style.ButtonsContainer>
          {!hasActiveSubscription && <SubscribeButton idOrg={idOrg} />}
          <UpgradePlanButton
            feature='subscription'
            buttonType={hasActiveSubscription ? ButtonType.primary : ButtonType.secondary}
          />
        </Style.ButtonsContainer>}

        {subscription && !isCanceled && (
          <Style.CancelSubscriptionContainer>
            <Button type={ButtonType.compactDestructive} size={ButtonSize.small} label='Cancel subscription' onClick={() => setIsCancelConfirmationOpen(true)} />
          </Style.CancelSubscriptionContainer>
        )}
      </Style.PlanContainer>

      <Confirmation
        isOpen={isCancelConfirmationOpen}
        header='Cancel subscription?'
        confirmText='Cancel subscription'
        text={<div>Your account will remain active until the end of your billing cycle <br /> on {moment.utc(subscription?.currentPeriodEnd).format('MMM DD, YYYY')}. After that you will lose access to Torii.</div>}
        confirm={() => cancelSubscription()}
        close={() => setIsCancelConfirmationOpen(false)}
        decline={() => setIsCancelConfirmationOpen(false)}
        mainButtonType={ButtonType.destructive}
        modalWidth='518px'
      />
    </Style.Wrapper>
  )
}

export default Subscription
