import React, { ChangeEvent, ReactElement } from 'react'
import Input from '@components/form/input'
import { formatCurrencyForInput, parseCurrencyFromInput } from './utils'

type CurrencyValue = number | null | '' | undefined

interface Props {
  value: CurrencyValue
  onChange?: (value: CurrencyValue) => void
  onBlur?: (value: CurrencyValue) => void
  disabled?: boolean
}

export const CurrencyInput = ({
  value,
  onChange,
  onBlur,
  disabled,
  ...input
}: Props): ReactElement => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const parsedValue = parseCurrencyFromInput(e.target.value)

      if (parsedValue !== value) {
        onChange?.(parsedValue)
      }
    } catch (e) {}
  }

  const handleBlur = () => {
    onBlur?.(value)
  }

  return (
    <Input
      {...input}
      type='number'
      value={formatCurrencyForInput(value)}
      onChange={handleChange}
      onBlur={handleBlur}
      disabled={disabled}
    />
  )
}
