import { ReactElement } from 'react'
import ToriiPopup from '../../popups/ToriiPopupV2'
import { DynamicWidget, FixedWidget, WIDGET_TYPES } from '@reducers/dashboards/types'
import { DashboardWidgetDataPopupProps } from './types'
import DynamicWidgetDataPopupContent from './dynamicDataPopup'
import FixedWidgetDataPopupContent from './fixedDataPopup'
import { useTheme } from 'styled-components'

const DashboardWidgetDataPopup = (props: DashboardWidgetDataPopupProps): ReactElement => {
  const { isOpen: isDashboardWidgetDataPopupOpen, widget, onCloseAction, extraFiltersTitle } = props
  const theme = useTheme()

  const renderPopup = (content) => {
    const headerTitle = extraFiltersTitle
      ? `${widget.displayConfig.label} - ${extraFiltersTitle}`
      : widget.displayConfig.label

    return (
      <ToriiPopup isOpen={isDashboardWidgetDataPopupOpen} width='100%' onCloseAction={onCloseAction}>
        <ToriiPopup.Header header={headerTitle} />
        <ToriiPopup.Content contentAreaStyle={{ padding: theme.spacing('space-300') }}>
          {content}
        </ToriiPopup.Content>
      </ToriiPopup>
    )
  }

  if (widget.type === WIDGET_TYPES.FIXED_WIDGET) {
    return renderPopup(<FixedWidgetDataPopupContent {...props} widget={widget as FixedWidget} />)
  }

  return renderPopup(<DynamicWidgetDataPopupContent {...props} widget={widget as DynamicWidget} />)
}

export default DashboardWidgetDataPopup
