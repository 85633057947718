import React, { useContext, useEffect, useState, ReactNode } from 'react'
import { ToriiPopupContext } from '@components/popups/ToriiPopupV2/popupContext'
import Modal from 'react-responsive-modal'
import * as Style from '@components/popups/ToriiPopupV2/style'
import ConfirmationPopup from '@components/popups/ToriiPopupV2/confirmationPopup'
import { useMedia } from '@shared/hooks'
import { useTheme } from 'styled-components'

interface RenderModalsProps {
  children: ReactNode;
  closeOnOverlayClick?: boolean;
  modalOverrideStyle?: React.CSSProperties;
  isOpen: boolean;
  onCloseAction?: () => void;
  width?: string;
  height?: string;
  showCloseIcon?: boolean;
}

const RenderModals = ({
  children,
  closeOnOverlayClick,
  modalOverrideStyle,
  isOpen,
  onCloseAction,
  width,
  height,
  showCloseIcon = true
}: RenderModalsProps) => {
  const { onClose, isConfirmCloseOpen, setIsConfirmCloseOpen } = useContext(ToriiPopupContext)
  const SMALL_SCREEN_WIDTH = '1025px'
  const theme = useTheme()

  const [isSmallScreen, setIsSmallScreen] = useState<boolean>()

  const screenChanged = useMedia(`(max-width: ${SMALL_SCREEN_WIDTH})`)

  useEffect(() => {
    setIsSmallScreen(screenChanged)
  }, [screenChanged])

  const modalStyles = {
    modal: {
      zIndex: 5,
      border: `1px solid ${theme.palette.border.primary}`,
      borderRadius: '4px',
      outline: 'none',
      boxShadow: '0 16px 23px 0 rgba(0,0,0,0.24)',
      background: theme.palette.background.primary,
      padding: '0px 0px',
      maxWidth: isSmallScreen ? '90vw' : '1200px',
      maxHeight: isSmallScreen ? '700px' : (modalOverrideStyle?.maxHeight || '700px'),
      width: width || Style.defaultModalWidth,
      height: height,
      display: 'flex',
      flexDirection: 'column',
      ...(modalOverrideStyle || {})
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.5)'
    },
    closeButton: {
      fill: theme.palette.text.primary,
      margin: '16px 16px 0',
      zIndex: 111,
      outline: 'none !important'
    }
  }

  return (
    <>
      <Modal
        showCloseIcon={showCloseIcon}
        closeIconSize={18}
        center
        open={isOpen}
        onClose={onClose}
        closeOnOverlayClick={closeOnOverlayClick}
        styles={modalStyles}
      >
        {children}
      </Modal>
      <ConfirmationPopup isOpen={isConfirmCloseOpen} quitButtonAction={onCloseAction} stayButtonAction={() => setIsConfirmCloseOpen(false)} />
    </>
  )
}

export default RenderModals
