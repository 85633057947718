import React from 'react'
import PropTypes from 'prop-types'
import withTooltipHOC from '@shared/HOCs/withTooltip'
import ToriiSelect from '@components/select'
import FormGroup from '../../../form/formGroup'
import Analytics from '@helpers/analytics'

const SelectComponent = ({ options, onChange, value, disabled }) => <ToriiSelect
  options={options}
  placeholder='Choose group'
  onChange={onChange}
  clearable={false}
  value={value}
  disabled={disabled}
/>

const SelectGroup = ({ options, input, disabled, withTooltip }) => {
  const onChange = (selection) => {
    Analytics.track('Update option', {
      'Action name': 'Select group',
      'Previous group': options[input.value]?.label ?? 'N/A',
      'New group': selection.label
    })
    input.onChange(selection.value)
  }

  const TooltipSelect = withTooltip ? withTooltipHOC(SelectComponent, { label: 'This field is defined by Torii and cannot be edited.', fullWidth: true }) : SelectComponent

  return (
    <FormGroup label='Group' disabled={disabled}>
      <TooltipSelect options={options} onChange={onChange} value={input.value} disabled={disabled} />
    </FormGroup>
  )
}

SelectGroup.propTypes = {
  options: PropTypes.array.isRequired,
  input: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  withTooltip: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
}

SelectGroup.defaultProps = {
  disabled: false,
  withTooltip: false
}
export default SelectGroup
