import React from 'react'
import PropTypes from 'prop-types'
import AllExpenseCategories from '../allExpenseCategories'
import ToriiPopup from '../popups/ToriiPopupV2'

const AllExpenseCategoriesPopup = ({ cancel, isOpen, close, title, ...rest }) => {
  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={cancel} width='500px'>
      <ToriiPopup.Header header={title} />
      <ToriiPopup.Content>
        <AllExpenseCategories {...rest} />
      </ToriiPopup.Content>
    </ToriiPopup>
  )
}

AllExpenseCategoriesPopup.propTypes = {
  cancel: PropTypes.func,
  isOpen: PropTypes.bool,
  close: PropTypes.func
}

export default AllExpenseCategoriesPopup
