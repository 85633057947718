import React from 'react'
import 'react-placeholder/lib/reactPlaceholder.css'
import ReactPlaceholder from 'react-placeholder'
import { useTheme } from 'styled-components'
export type PlaceholderType = 'text' | 'media' | 'textRow' | 'rect' | 'round'

export interface PlaceholderProps {
  children?: React.ReactNode
  loading?: boolean
  style?: React.CSSProperties
  className?: string
  type?: PlaceholderType
  rows?: number
  widths?: number[]
}

const Placeholder = ({
  children,
  loading = false,
  style = {},
  className = '',
  type = 'text',
  rows = 1,
  widths = [100]
}: PlaceholderProps) => {
  const theme = useTheme()
  return (
    <ReactPlaceholder
      ready={!loading}
      type={type}
      showLoadingAnimation
      color={theme.palette.background.secondary}
      rows={rows}
      className={className}
      widths={widths}
      style={{
        fontSize: '15px',
        maxWidth: '50%',
        ...style
      }}>
      {children ?? null}
    </ReactPlaceholder>
  )
}

export default Placeholder
