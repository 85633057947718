import { CHANGE_APP_HIDDEN_STATE, GET_CUSTOM_APPS, ADD_CUSTOM_APP, EDIT_CUSTOM_APP, COPY_APP_DATA } from '@root/constants'

const initialState = {
  loading: false,
  apps: []
}

const customAppsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_CUSTOM_APPS}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_CUSTOM_APPS}_FAILED`: {
      return {
        ...state,
        loading: false
      }
    }
    case `${GET_CUSTOM_APPS}_RESPONSE`: {
      const { apps } = action.payload

      return {
        ...state,
        loading: false,
        apps
      }
    }
    case `${CHANGE_APP_HIDDEN_STATE}_RESPONSE`: {
      const { isHidden, idApp } = action.meta

      let modifiedApps = state.apps
      if (isHidden) {
        modifiedApps = state.apps.filter(app => app.id !== idApp)
      }

      return {
        ...state,
        apps: modifiedApps
      }
    }
    case `${ADD_CUSTOM_APP}_RESPONSE`: {
      const { app } = action.payload

      return {
        ...state,
        apps: state.apps.concat(app)
      }
    }
    case `${EDIT_CUSTOM_APP}_RESPONSE`: {
      const { app: editedAppInfo } = action.payload

      return {
        ...state,
        apps: state.apps.map(app => app.id === editedAppInfo.id ? { ...app, ...editedAppInfo } : app)
      }
    }
    case `${COPY_APP_DATA}_RESPONSE`: {
      const { hideCustomApp, idCustomApp } = action.meta

      if (hideCustomApp) {
        return {
          ...state,
          apps: state.apps.filter(app => app.id !== idCustomApp)
        }
      }

      return state
    }
    default: {
      return state
    }
  }
}

export default customAppsReducer
