import React from 'react'
import PropTypes from 'prop-types'
import { Col, RowWithoutMargin } from '@toriihq/design-system'
import { withTheme } from 'styled-components'
import MonthlyRenewalBox from '../../components/monthlyRenewalBox'
import map from 'lodash/map'
import moment from 'moment'
import { css } from 'glamor'
import texts from '../../shared/style/texts'
import pluralize from 'pluralize'
import { CopyTextPopup } from '../copyTextPopup'
import { SUPPORT_ARTICLES } from '@root/articles'
import config from '@root/config'
import { CALENDAR_TYPE } from '@reducers/calendars/types'

const generateCSS = ({ theme }) => {
  const CSS = {
    yearRow: css({
      marginBottom: '10px',
      ' >div': {
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between'
      }
    }),
    year: css(texts.headers.regular, {
      color: theme.palette.text.primary
    }),
    calendarRow: css({
      width: '100%',
      display: 'flex',
      ' >div:nth-child(1)': {
        border: `solid ${theme.palette.border.primary}`,
        borderWidth: '0 1px 1px 0'
      },
      ' >div:nth-child(2)': {
        border: `solid ${theme.palette.border.primary}`,
        borderWidth: '0 1px 1px 0'
      },
      ' >div:nth-child(3)': {
        borderBottom: `1px solid ${theme.palette.border.primary}`
      }
    }),
    smallCalendarRow: css({
      width: '100%',
      ' >div': {
        borderBottom: `1px solid ${theme.palette.border.primary}`
      }
    }),
    share: css({
      display: 'flex',
      justifyContent: 'flex-end'
    })
  }

  CSS.yearlyCalendar = css({
    border: `1px solid ${theme.palette.border.primary}`,
    borderRadius: '8px',
    [` >.${CSS.calendarRow}:nth-last-child(1) >div, >[data-${CSS.calendarRow}]:nth-last-child(1) >div`]: {
      borderWidth: '0 1px 0 0'
    },
    [` >.${CSS.smallCalendarRow}:nth-last-child(1), >[data-${CSS.smallCalendarRow}]:nth-last-child(1)`]: {
      ' >div:nth-last-child(2)': {
        borderWidth: '0 0 0 0'
      }
    }
  })

  return CSS
}

class RenewalsCalendar extends React.Component {
  constructor (props) {
    super(props)
    const { theme } = props
    this.state = {
      CSS: generateCSS({ theme })
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.theme !== this.props.theme) {
      this.setState({
        CSS: generateCSS({ theme: this.props.theme })
      })
    }
  }

  render () {
    const { idOrg, idUser, loading, isSmallScreen, rowStyle, years, appsInfo, renewals, monthsInRow, contractStatusOptionsByValue, isRenewalCalendarAllowed, createCalendar, renewalCalendar, isLoadingCalendars } = this.props
    const { CSS } = this.state
    const now = moment().startOf('month')

    const onCopyTextPopupButtonClick = () => {
      if (!isLoadingCalendars && !renewalCalendar.uuid) {
        createCalendar({ idOrg, idUser, type: CALENDAR_TYPE.RENEWAL })
      }
    }

    return (
      <>
        {isRenewalCalendarAllowed ? <div {...CSS.share}>
          <CopyTextPopup
            label='Sync to calendar'
            textToCopy={renewalCalendar?.uuid ? `${config.apiBaseUrl}/api/orgs/${idOrg}/ical/${renewalCalendar.uuid}` : ''}
            onButtonClick={onCopyTextPopupButtonClick}
            disableCopy={isLoadingCalendars}
            analyticsText='Click on Copy iCalendar link'
            bodyText='Sync the renewal calendar to Google calendar or Outlook by adding the following public URL '
            supportArticleLink={SUPPORT_ARTICLES.RENEWAL_CALENDAR_SHARE}
          />
        </div> : null}
        {
          map(years, (year, yearKey) => {
            const rowsAmount = year.length / monthsInRow
            const months = []
            for (let rowNumber = 1; rowNumber <= rowsAmount; rowNumber++) {
              const currRowMonthsIndex = year.slice((rowNumber - 1) * monthsInRow, (rowNumber * 3))

              const currRowMonths = currRowMonthsIndex.map(month => {
                const date = moment(`01/${month}/${yearKey}`, 'DD/MM/YYYY')
                const key = `${yearKey}_${month}`
                const renewalsInMonth = renewals[key] || []
                return (
                  <Col xs={isSmallScreen ? 12 : 4} key={key}>
                    <MonthlyRenewalBox
                      key={key}
                      renewals={(renewalsInMonth).sort((a, b) => a.date.diff(b.date))}
                      header={`${date.format('MMMM')}`}
                      subHeader={`${renewalsInMonth.length} ${pluralize('app', renewalsInMonth.length)}`}
                      isSelected={now.isSame(date)}
                      isPassed={date < now}
                      appsInfo={appsInfo}
                      loading={loading}
                      contractStatusOptionsByValue={contractStatusOptionsByValue}
                    />
                  </Col>
                )
              })

              const calendarRowStyle = isSmallScreen ? CSS.smallCalendarRow : CSS.calendarRow
              months.push(<RowWithoutMargin key={rowNumber} nogutter {...calendarRowStyle}>{currRowMonths}</RowWithoutMargin>)
            }

            return (
              <div key={yearKey}>
                <RowWithoutMargin nogutter {...css(rowStyle, CSS.yearRow)}>{yearKey}</RowWithoutMargin>
                <RowWithoutMargin nogutter {...css(rowStyle, CSS.yearlyCalendar)}>{months}</RowWithoutMargin>
              </div>
            )
          })
        }
      </>
    )
  }
}

RenewalsCalendar.propTypes = {
  contractStatusOptionsByValue: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  isEmptyState: PropTypes.bool.isRequired
}

export default withTheme(RenewalsCalendar)
