import { IdOrg, IdApp, IdUser, IdAccessReview } from '@root/store/types'

export enum ACCESS_REVIEW_STATUS {
    PENDING = 'pending',
    ONGOING = 'ongoing',
    COMPLETED = 'completed'
}

export type AccessReview = {
    id: IdAccessReview
    idOrg: IdOrg
    idApp: IdApp
    reviewers: IdUser[]
    creationTime: Date
    status: ACCESS_REVIEW_STATUS
    name: string
    startTime: Date | null
    submitTime: Date | null
    submittedBy: IdUser | null
    snapshotTime: Date | null
    numberOfUsers: number
    numberOfReviewedUsers: number
}

export type UserResourceMap = Record<number, {
    id: IdUser,
    firstName: string,
    lastName?: string,
    email?: string
}>

export type AppResourceMap = Record<number, {
    name: string,
    category: string | null,
    imageUrl: string | null,
    id: string
}>

export type AccessReviewsState = {
    isLoading: boolean
    accessReviews: AccessReview[]
    resources: {
        users: UserResourceMap,
        apps: AppResourceMap
    }
}
