import {
  DISCONNECT_SERVICE_CONFIRMATION,
  GET_SERVICES_CONFIG,
  GET_SYNC_DATA,
  MARK_SYNC_AS_RUN,
  SYNC_STATUS, UPDATE_APP_ACCOUNT_NAME
} from '@root/constants'
import find from 'lodash/find'
import { mergeArraysByKey } from '@lenses/utils'

const initialState = {
  loading: false,
  syncStatus: null,
  resources: { users: {} },
  config: []
}

const servicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISCONNECT_SERVICE_CONFIRMATION: {
      return {
        ...state,
        loading: false
      }
    }
    case `${GET_SERVICES_CONFIG}_RESPONSE`: {
      return {
        ...state,
        config: action.payload
      }
    }
    case `${GET_SYNC_DATA}_PENDING`: {
      return {
        ...state,
        loading: true
      }
    }
    case `${GET_SYNC_DATA}_FAILED`: {
      return {
        ...state,
        syncStatus: [],
        loading: false
      }
    }
    case `${GET_SYNC_DATA}_RESPONSE`: {
      const { syncStatus, resources } = action.payload
      return {
        ...state,
        syncStatus,
        resources,
        loading: false
      }
    }
    case `${UPDATE_APP_ACCOUNT_NAME}_RESPONSE`: {
      const { idAppAccount, appAccountName } = action.meta

      return {
        ...state,
        syncStatus: mergeArraysByKey(state.syncStatus, [{ idAppAccount, appAccountName }], 'idAppAccount')
      }
    }
    case MARK_SYNC_AS_RUN: {
      const { idAppToken } = action.payload
      const clonedSyncStatus = [...state.syncStatus]
      const service = find(clonedSyncStatus, { idAppToken })
      if (service) {
        service.syncStatus = SYNC_STATUS.IN_PROGRESS
      }
      return {
        ...state,
        syncStatus: clonedSyncStatus,
        loading: false
      }
    }
    default: {
      return state
    }
  }
}

export default servicesReducer
