import styled from 'styled-components'

export const Container = styled.div<{ isVertical: boolean }>`
  padding: 32px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: ${props => props.isVertical ? 'row' : 'column'};
  justify-content: space-between;
  align-items: ${props => props.isVertical ? 'flex-end' : 'flex-start'}
`
