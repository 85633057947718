import React from 'react'
import { useFormState } from 'react-final-form'
import { formatCurrencyForInput } from '@root/components/form/currencyInput/utils'
import { useLineItemCalculations } from '../hooks/useLineItemCalculations'

interface TotalCostCellProps {
  index: number
  currencySymbol: string
}

export const TotalCostCell: React.FC<TotalCostCellProps> = ({ index, currencySymbol }) => {
  const { values } = useFormState()
  const lineItems = values.lineItems || []
  const current = lineItems[index] || {}

  const { totalCost, isValid } = useLineItemCalculations(current)
  const total = formatCurrencyForInput(totalCost)

  if (!isValid) {
    return <span />
  }

  return <span>{`${currencySymbol}${total}`}</span>
}
