import styled, { css } from 'styled-components'
import RelativeTeamLink from '../relativeTeamLink'

export const oneLineStyles = css`
  display: block;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const Container = styled.div<{ overrideStyle?: any }>`
  padding: 2px 0;
  ${props => props.overrideStyle};
`

export const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.corner('radius-050')};
`

export const ContentWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`

export const Details = styled.div`
  flex: 1;
  overflow: hidden;
`

export const Category = styled.div`
  ${oneLineStyles};
  font: ${({ theme }) => theme.typography.font.caption01};
  color: ${({ theme }) => theme.palette.text.tertiary};
  opacity: 0;
  transition: opacity .2s;
  .rt-tr:hover & {
    opacity: 1;
  }
  .appDetailsContainer:hover & {
    opacity: 1;
  }
`

interface NameProps {
  hasCategory?: boolean
  blueNameOnHover?: boolean
}

export const Name = styled.div<NameProps>`
  ${oneLineStyles};
  color: ${({ theme }) => theme.palette.text.primary};
  ${props => props.hasCategory && css`
    transform: translateY(9px);
    transition: color .2s, transform .2s;
  `}

  .rt-tr:hover &, .appDetailsContainer:hover & {
    ${props => props.blueNameOnHover && css`
      color: ${({ theme }) => theme.palette.text.interactive};
    `}
    transform: translateY(0);
  }
`

export const StyledRelativeTeamLink = styled(RelativeTeamLink)`
  width: 100%;
`
