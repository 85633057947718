import Analytics from '@helpers/analytics'

type AnalyticsParams = {
  appName: string;
  companyName?: string;
  idApp?: string;
  targetAppName?: string;
  hideCustomApp?: boolean;
  buttonLabel?: string;
  configurationState?: string;
}

export const trackLearnMoreClick = ({ appName, companyName }: AnalyticsParams): void => {
  Analytics.track('Click on Copy app data learn more', {
    'App name': appName,
    'Company name': companyName
  })
}

export const trackModalButtonClick = ({ appName, companyName, buttonLabel, configurationState, targetAppName }: AnalyticsParams): void => {
  Analytics.track('Click on Copy app data modal button', {
    'App name': appName,
    'Company name': companyName,
    'Button label': buttonLabel,
    'Configuration state': configurationState,
    'New name': targetAppName
  })
}
