import { TooltipContainer } from './styles'
import { Body2, Stack, Subtitle2 } from '@toriihq/design-system'
import { ReactElement } from 'react'
import { capitalize } from 'lodash'
import numeral from 'numeral/numeral'
import pluralize from 'pluralize/pluralize'
import Currency from '@root/components/currency'
import { fieldTypes } from '@root/constants'
import { METRIC_FUNC, DynamicWidget } from '@root/store/reducers/dashboards/types'

type Payload = { name: string, value: number, color: string }
type Props = {
    payload: Array<{ payload: Payload }> | undefined,
    active: boolean | undefined,
    widget: DynamicWidget
}

const WidgetTooltip = ({ payload, active, widget }: Props): ReactElement | null => {
  if (!active || !payload?.length) {
    return null
  }

  const data = payload[0].payload
  const { name, value } = data

  const valueDisplayFunction = (value: any): any => {
    if (widget?.displayConfig?.groupBy?.metric?.field?.type === fieldTypes.currency) {
      return (
        <Stack gap='space-050'>
          <Currency value={value ?? 0} />
          <span>{capitalize(widget?.displayConfig?.groupBy?.metric?.field?.label)}</span>
        </Stack>
      )
    }

    const units = widget?.displayConfig?.groupBy?.metric?.func === METRIC_FUNC.TOTAL
      ? widget?.entityType
      : widget?.displayConfig?.groupBy?.metric?.field?.label

    return `${numeral(value).format('0,0')} ${units?.includes('%') ? capitalize(units) : pluralize(capitalize(units), value)}`
  }

  return (
    <TooltipContainer type={widget.type}>
      <Subtitle2 color={'primary'}>{name}</Subtitle2>
      <Body2 color={'primary'}>{!value ? 'Missing data' : valueDisplayFunction(value)}</Body2>
    </TooltipContainer>
  )
}

export default WidgetTooltip
