import Page from '@components/page'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  getAppsFieldValues,
  getAppsV2,
  toggleUploadExpenses,
  deprecatedGetApps as getExpenses,
  exportMonthlyExpensesCSV,
  getAppDetailsValues
} from '@actions/'
import { isAppsLoading as isExpensesLoading, getAppDetailsFields, isExpensesReportAppsLoading, getExpenseReportAppsFieldValues } from '@selectors/apps'
import { getMonthlyExpenses } from '@selectors/expenses'
import Table from '@components/table'
import {
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  fieldTypes,
  GET_EXPENSE_REPORT_APPS,
  GET_EXPENSE_REPORT_APPS_FIELD_VALUES,
  MONTHLY_EXPENSES_REPORT_RANGE,
  SCOPES,
  TABLES
} from '@root/constants'
import { getMonthlyExpensesReportTableInfo } from '@selectors/tables'
import { getColumns, getColumnsSortMethods } from './columns'
import emptyImage from '@media/expenses.svg'
import debounce from 'lodash/debounce'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { getValidFilters } from '@shared/filters'
import ReportHeader from '@components/reportHeader'
import map from 'lodash/map'
import get from 'lodash/get'
import range from 'lodash/range'
import compact from 'lodash/compact'
import pick from 'lodash/pick'
import numeral from 'numeral'
import { getUserPreferences } from '@selectors/ui'
import exportCSV from '@helpers/exportCSV'
import { getQuartersNames } from '@shared/expenses'
import moment from 'moment'
import EnableFor from '@components/enableFor'
import { Button, ButtonType, EmptyState } from '@toriihq/design-system'
import Analytics from '@helpers/analytics'

const MonthlyExpensesReport = ({ match }) => {
  const idOrg = parseInt(match.params.idOrg, 10)
  const dispatch = useDispatch()

  const apps = useSelector(getMonthlyExpenses)
  const loadingApps = useSelector(isExpensesReportAppsLoading)
  const loadingExpenses = useSelector(isExpensesLoading)
  const { key, filtersOptions, columnsConfiguration, preDefinedColumnsMapping, filters: tableFilters } = useSelector(getMonthlyExpensesReportTableInfo)
  const filterValues = useSelector(getExpenseReportAppsFieldValues)
  const fields = useSelector(getAppDetailsFields)
  const stateInfo = fields.find(field => field.systemKey === 'state')
  const { defaultSort = EMPTY_ARRAY } = useSelector(getUserPreferences)[TABLES.monthlyExpensesReport.key] || EMPTY_OBJECT
  const userPreferences = useSelector(getUserPreferences)['monthlyExpensesReport']
  const showOnlyThisYear = userPreferences?.customFilterSelectedValue === MONTHLY_EXPENSES_REPORT_RANGE.THIS_YEAR

  const total = apps.length
  const validFilters = getValidFilters(tableFilters || [])
  const columnsOptions = map(preDefinedColumnsMapping, (value, key) => {
    return { value: key, label: value }
  })

  const [columns, setColumns] = useState([])
  const [sortMethodByColumn, setColumnsSortMethods] = useState([])
  const [sortedApps, setSortedApps] = useState(apps)
  const [query, setQuery] = useState('')

  const loading = (loadingApps || loadingExpenses) && !sortedApps.length

  const getApps = async ({ limit, offset = 0, reset = false, q = query, filters = tableFilters }) => {
    await dispatch(getAppsV2({
      actionTarget: GET_EXPENSE_REPORT_APPS,
      idOrg,
      limit,
      offset,
      sort: [],
      q,
      reset: true,
      filters,
      fields: ['id', 'name', 'category', 'imageUrl', 'primaryOwner', 'state']
    }))
    await dispatch(getExpenses({ idOrg }))
    await dispatch(getAppDetailsValues({ idOrg }))
  }

  const onSortedChangeCB = (sort) => {
    const { id, desc } = get(sort, 0, {})
    const sortFunction = get(sortMethodByColumn, id, () => {})
    const sortFunctionWrapper = (appA, appB) => {
      const a = appA[id]
      const b = appB[id]
      return desc ? sortFunction(b, a) : sortFunction(a, b)
    }
    setSortedApps(apps.sort(sortFunctionWrapper))
  }

  useDeepCompareEffect(() => {
    setColumns(getColumns({ columnsConfiguration, showOnlyThisYear, stateInfo }))
  }, [columnsConfiguration, showOnlyThisYear, stateInfo])

  useDeepCompareEffect(() => {
    if (!idOrg) {
      return
    }
    getApps({ reset: true })
  }, [idOrg, validFilters])

  useDeepCompareEffect(() => {
    setColumnsSortMethods(getColumnsSortMethods(columns))
  }, [columns])

  useDeepCompareEffect(() => {
    onSortedChangeCB(defaultSort)
  }, [apps])

  const onSearch = debounce((q) => {
    setQuery(q)
    getApps({ q })
  }, 500)

  const renderEmptyState = () => {
    const onClick = () => {
      Analytics.track('Click to open upload expenses dialog', { from: 'Empty state monthly expenses report' })
      dispatch(toggleUploadExpenses(true, true))
    }

    return <EmptyState
      image={<img src={emptyImage} alt='No expenses' />}
      title='Integrate your expenses'
      buttons={[
        <EnableFor scopes={[SCOPES.EXPENSE_WRITE]} allowForToriiAdmin>
          <Button key='configureChargeback' type={ButtonType.primary} onClick={onClick} label='Upload expenses report' />
        </EnableFor>
      ]}
    />
  }

  const fetchFieldValues = (field) => {
    const selectedFilterOption = filtersOptions.find(f => f.value === field) || {}
    const isFieldSupportValues = [fieldTypes.text, fieldTypes.user, fieldTypes.dropdown, fieldTypes.dropdownMulti].includes(selectedFilterOption.type)
    if (isFieldSupportValues) {
      dispatch(getAppsFieldValues({ actionTarget: GET_EXPENSE_REPORT_APPS_FIELD_VALUES, idOrg, fields: [field] }))
    }
  }

  const onExportCSV = () => {
    dispatch(exportMonthlyExpensesCSV())

    const columns = getColumns({ columnsConfiguration, showOnlyThisYear, stateInfo })
    const isQuarterView = (columnsConfiguration || {})['q1']
    const quarterViewKeys = getQuartersNames(showOnlyThisYear).map((header, i) => `q${i + 1}`)

    const csvFields = compact(columns.map(column => column.textHeader || column.Header))

    const data = sortedApps.map(app => {
      const appExpensesInfo = isQuarterView ? map(pick(app, quarterViewKeys), expense => expense / 100) : range(showOnlyThisYear ? moment().month() + 1 : 12).map(month => app[month].total / 100)
      return [
        app.name,
        app.state,
        app.primaryOwner?.email,
        ...appExpensesInfo,
        numeral(app.total / 100).format('0')
      ]
    })
    return exportCSV('monthly_application_expenses.csv', data, csvFields)
  }

  const subtitle = <div>Get a comprehensive view of your software expenses by application.<br />Only applications with expenses in the last 12 months will appear in this report. You can apply additional filters with the filter button.</div>
  return (
    <Page title='Expense analysis'>
      <ReportHeader title='Expense analysis' subtitle={subtitle} />
      <Table
        tableKey={key}
        emptyStateMessage={renderEmptyState()}
        data={sortedApps}
        columns={columns}
        loading={loading}
        exportable
        exportFunction={onExportCSV}
        searchable
        filterable
        filtersOptions={filtersOptions}
        manual
        forceShowSearch
        onSearch={onSearch}
        filterOptionsValuesPerKey={filterValues}
        fetchFieldValues={fetchFieldValues}
        totalCount={total}
        configurableColumnsOptions={columnsOptions}
        draggable={false}
        onSortedChangeCB={onSortedChangeCB}
        supportViews
        shareViewPreferences
        editableViews={false}
        editable={false}
      />
    </Page>
  )
}

export default withRouter(MonthlyExpensesReport)
