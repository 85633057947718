import Placeholder from '@components/placeholder'
import { Container } from './styles'
interface BarChartLoadingStateProps {
  isVertical?: boolean
  numberOfBars?: number
}

const BarChartLoadingState = ({ isVertical = true, numberOfBars = 5 }: BarChartLoadingStateProps) => {
  const barLongSizesInPercentage = Array.from({ length: numberOfBars }, (_, i) => 100 - (i * 5))
  const barShortSize = '24px'

  return (
    <Container isVertical={isVertical}>
      {barLongSizesInPercentage.map((width, index) => (
        <Placeholder
          key={index}
          loading
          type='rect'
          style={{
            width: isVertical ? barShortSize : `${width}%`,
            height: isVertical ? `${width}%` : barShortSize,
            maxWidth: '100%'
          }}
        />
      ))}
    </Container>
  )
}

export default BarChartLoadingState
