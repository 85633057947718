import React from 'react'
import { css } from 'glamor'
import colors from '@shared/style/colors'
import { Tooltip, Button, ButtonType, ButtonSize, Link, Icon, ButtonState, Menu } from '@toriihq/design-system'
import moment from 'moment'
import { SCOPES, SYNC_STATUS, SYNC_ERRORS } from '@root/constants'
import Analytics from '@helpers/analytics'
import EnableFor from '@components/enableFor'
import BaseService from '../baseService/view'
import { getSyncedText } from '../baseService/index'
import * as Style from '../baseService/style'
import Placeholder from '@components/placeholder'
import BadgeBeta from '@media/badge_beta.svg'
import { buildFirstSyncMsg } from './index'
import Confirmation from '@components/confirmation'
import ButtonOfFeature from '@components/buttonOfFeature'
import { getMergeUsersMessage } from '@components/service/nativeIntegrationService/utils'
import capitalize from 'lodash/capitalize'
import noop from 'lodash/noop'

class NativeIntegrationService extends BaseService {
  onRunSync = () => {
    const { markSyncAsRun, id, runServicesSync, idOrg, idAppToken } = this.props
    Analytics.track(`Clicked to run sync ${id} service`)
    markSyncAsRun({ idAppToken })
    return runServicesSync({ idOrg, idAppToken })
  }

  renderNotConnected = () => {
    const { showConnectAnotherService, supportsMultipleAccounts = false, displayName, feature } = this.props
    const multipleAccountSupportNeeded = showConnectAnotherService && !supportsMultipleAccounts
    const buttonText = showConnectAnotherService ? 'Connect another' : 'Connect'
    return (
      <div {...Style.NotConnected}>
        <Tooltip label={`You can only connect one ${displayName} account`} hide={!multipleAccountSupportNeeded}>
          <EnableFor scopes={[SCOPES.INTEGRATIONS_WRITE]} >
            <ButtonOfFeature
              tooltipPlacement={multipleAccountSupportNeeded ? 'bottom' : 'top'}
              feature={feature}
              size={ButtonSize.medium}
              onClick={this.onConnect}
              disabled={multipleAccountSupportNeeded}
              label={buttonText}
            />
          </EnableFor>
        </Tooltip>
      </div>
    )
  }

  renderConnected = () => {
    const {
      syncStatus,
      lastSyncTime,
      syncError,
      errorMessage,
      isSyncDisabled,
      connectedAt,
      breakdown,
      toggleSyncStatusPopup,
      idAppAccount,
      permission,
      connectedViaLink,
      connectedBy,
      companyName,
      appName
    } = this.props

    const onClickSeeMoreDetails = () => {
      toggleSyncStatusPopup({
        isOpen: true,
        idAppAccount,
        connectedAt,
        connectByMessage: this.buildConnectByMessage({ connectedAt, permission, connectedViaLink, connectedBy, returnAsString: true })
      })
      Analytics.track('Open sync-status-popup', { 'App name': appName, 'Sync status': breakdown.overallStatus, 'Company name': companyName, 'Button label': 'See more details' })
    }

    const hasUserAttentionError = (syncError && SYNC_ERRORS[syncError]) || errorMessage
    const showFirstSyncMsg = !hasUserAttentionError && !lastSyncTime
    const showSyncError = Boolean(hasUserAttentionError)
    const lastSuccessfullySyncMessage = getSyncedText({ lastSyncTime })
    const daysFromLastSync = moment().diff(lastSyncTime, 'days')

    const isSyncInProcess = syncStatus === SYNC_STATUS.IN_PROGRESS

    const isSyncDisabledCheck = isSyncInProcess || showSyncError || showFirstSyncMsg || isSyncDisabled

    const SYNC_STATUS_TO_TILE_MESSAGE = {
      [SYNC_STATUS.FINISHED_SUCCESSFULLY]: <div>{daysFromLastSync > 10 ? <span style={{ color: colors.error }}>{lastSuccessfullySyncMessage}</span> : lastSuccessfullySyncMessage}</div>,
      [SYNC_STATUS.FINISHED_PARTIALLY]: <div>Sync partially successful</div>,
      [SYNC_STATUS.FINISHED_FAILED]: <div {...css({ color: colors.errorDark })}>Sync failed. Please review the highlighted issues in the details.</div>
    }

    return (
      <div {...Style.Tile}>
        <div {...Style.StatusContainer}>
          <div {...Style.Status}>
            {showFirstSyncMsg && <div {...css({ width: '90%' })}>{buildFirstSyncMsg(connectedAt)}</div>}
            {!showFirstSyncMsg && SYNC_STATUS_TO_TILE_MESSAGE[breakdown.overallStatus]}
            {!showFirstSyncMsg && !isSyncInProcess && <Style.MoreDetailsButton onClick={onClickSeeMoreDetails}>See more details</Style.MoreDetailsButton>}
          </div>
        </div>
        <div {...Style.Actions}>
          <Tooltip
            placement='top'
            label='Sync is disabled'
            hide={!isSyncDisabled}>
            <EnableFor scopes={[SCOPES.INTEGRATIONS_WRITE]}>
              <Button
                type={ButtonType.secondary}
                onClick={() => { this.onRunSync() }}
                disabled={isSyncDisabledCheck}
                buttonState={isSyncInProcess ? ButtonState.loading : ButtonState.neutral}
                icon='Integrations'
                label='Sync'
              />
            </EnableFor>
          </Tooltip>
          <EnableFor scopes={[SCOPES.INTEGRATIONS_WRITE]}>
            <Menu
              items={this.options('native')}
              onToggle={this.onMenuClickCallback}
            >
              <Button icon='Dots' type={ButtonType.secondary} onClick={noop} />
            </Menu>
          </EnableFor>
        </div>
      </div>
    )
  }

  renderStatusIcon = () => {
    const { lastSyncTime, breakdown } = this.props
    const isFirstSync = !lastSyncTime

    const syncingIcon = <Icon name='CircleSyncFilled' color='secondary' />

    const SYNC_STATUS_TO_ICON = {
      [SYNC_STATUS.FINISHED_SUCCESSFULLY]: isFirstSync ? syncingIcon : <Icon name='CheckCircleFill' color='success' />,
      [SYNC_STATUS.FINISHED_PARTIALLY]: isFirstSync ? syncingIcon : <Icon name='CircleAlertFilled' color='warning' />,
      [SYNC_STATUS.FINISHED_FAILED]: <Icon name='CircleXFilled' color='error' />
    }

    return (
      <div {...Style.StatusIcon}>
        {SYNC_STATUS_TO_ICON[breakdown.overallStatus]}
      </div>
    )
  }

  renderLogo = () => {
    const { displayName, logo, icon, loading, mode } = this.props
    const betaBadge = mode === 'beta' ? <img alt='beta-badge' src={BadgeBeta} {...Style.BetaBadge} /> : null
    return <div {...Style.NativeIntegrationLogo}>
      <Placeholder loading={loading} type='rect' style={{ width: '100%', height: '100%', maxWidth: '100%', margin: 0 }}>
        {logo
          ? <>
            <img alt={displayName} src={logo} title={displayName} style={{ position: 'relative' }} />
            {betaBadge}
          </>
          : <>
            <img alt={displayName} src={icon} title={displayName} width={56} height={56} style={{ borderRadius: '4px', position: 'relative' }} />
            {betaBadge}
          </>
        }
      </Placeholder>
    </div>
  }

  renderDisconnectServiceConfirmationPopup = () => {
    const { showDisconnectConfirmation } = this.state
    const { appAccountName, appName, workflowsToInvalidateOnDisconnect = [], hasMultipleConnectedAccounts, hasMergeUsersRuleConfigured } = this.props
    const accountName = appAccountName ? ` (${appAccountName})` : ''
    const workflowsToInvalidateNames = workflowsToInvalidateOnDisconnect.map(wf => wf.name)
    const moreThanOneWorkflow = workflowsToInvalidateOnDisconnect.length > 1
    const workflowsToInvalidateDiv = workflowsToInvalidateNames.length > 0 ? <><span>{moreThanOneWorkflow ? `${workflowsToInvalidateNames.length} workflows` : 'One workflow'} will be invalidated since {moreThanOneWorkflow ? 'their' : 'its'} trigger depends on the integrated account: {workflowsToInvalidateNames.join(', ')}.</span><br /></> : ''
    const mergeUsersMessage = hasMergeUsersRuleConfigured && getMergeUsersMessage({ hasMultipleConnectedAccounts })
    const mergeUsersDiv = hasMergeUsersRuleConfigured ? <><span>{ workflowsToInvalidateNames.length ? <span><br />Additionally, {mergeUsersMessage}</span> : capitalize(mergeUsersMessage)}</span><br /></> : ''
    const noticeDiv = workflowsToInvalidateNames.length > 0 || hasMergeUsersRuleConfigured
      ? <>
        <span>
          <b>Notice:<br /></b>
          {workflowsToInvalidateDiv}
          {mergeUsersDiv}
        </span>
        <br />
      </>
      : ''
    const content = (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>Real time data associated with the account will be removed and new data will no longer be synced with Torii. Workflow actions of this account will not be performed.</span>
        <br />
        {noticeDiv}
        <span>{'For more details please see: '}
          <Link href='https://support.toriihq.com/hc/en-us/articles/6755817160347#heading-6:~:text=and%20custom%20integrations.-,Disconnect%20integrated%20account,-In%20some%20cases' target='_blank'>Disconnecting an integrated account</Link>
        </span>
        <span>Note that it may take up to several hours for this action to be completed.</span>
      </div>
    )

    return <Confirmation
      isOpen={showDisconnectConfirmation}
      header={`Are you sure you want to disconnect ${appName}${accountName}?`}
      text={content}
      confirmText='Disconnect'
      confirm={this.onConfirmDisconnection}
      declineText='Cancel'
      decline={this.onDeclineDisconnection}
      close={this.onDeclineDisconnection}
      mainButtonType={ButtonType.destructive}
      modalWidth='480px'
    />
  }
}

export default NativeIntegrationService
