import React, { useCallback, useEffect, useState } from 'react'
import Table from '@components/table'
import {
  Stack
} from '@toriihq/design-system'
import {
  TABLES,
  ITEMS_PER_PAGE,
  EMPTY_OBJECT,
  EMPTY_ARRAY
} from 'src/constants'
import colors from '@shared/style/colors'
import UsersEmptyState from './usersEmptyState'
import { debounce } from 'lodash'
import { getColumns } from './columns'
import { useDispatch, useSelector } from 'react-redux'
import {
  getUserDetailsFields,
  getAppUsersLicense
} from '@actions/'
import { APP_USER_STATUS } from '@shared/types'
import pluralize from 'pluralize/pluralize'
import { deprecatedGetAppsByIds, isAppsLoading } from '@selectors/apps'
import { isLoadingLicensesTypes } from '@selectors/licenses'
import {
  getAllAppAccountLicenseUsersES
} from '@selectors/appUsers'
import { getUserPreferences } from '@selectors/ui'
import { getUserTablesInfo } from '@selectors/tables'
import { getAppAccountLicenseUserConfigurableColumnsOptions } from '@selectors/userDetails'
import { AppAccountLicenseUsersProps } from '@pages/renewalForecast/appAccountLicenseUsers/types'
import { getCurrentOrg } from '@selectors/org'
import { ops } from '@lenses/filters'
import Analytics from '@pages/renewalForecast/analytics'

const getNoDataProps = () => ({
  className: 'rt-noData',
  style: {
    pointerEvents: 'all',
    top: '40%'
  }
})

export const CustomSelectOptions = [
  {
    value: 'all',
    label: 'All utilization statuses',
    filters: []
  },
  {
    value: 'active',
    label: 'Active',
    filters: [
      { key: 'userLicense.calculatedStatus', op: ops.equals, value: 'active' }
    ]
  },
  {
    value: 'inactive',
    label: 'Inactive',
    filters: [
      { key: 'userLicense.calculatedStatus', op: ops.equals, value: 'inactive' }
    ]
  }
]

const AppAccountLicenseUsers = (props: AppAccountLicenseUsersProps) => {
  const {
    idApp,
    idAppAccount,
    idLicense,
    license,
    idOrg,
    onSelectedColumnsChange,
    onSelectedViewChange,
    inactivityPeriod
  } = props
  const dispatch = useDispatch()

  const getAllUsersWereDeletedText = (app, users) => {
    const { name, activeUsersCount } = app

    if (activeUsersCount > 0) {
      return null
    }

    const deletedUserNames = users
      .filter(user => user.status === APP_USER_STATUS.DELETED)
      .map(user => [user.firstName, user.lastName].join(' ').trim())

    if (deletedUserNames.length > 0) {
      const numberOfUsersToShow = 3
      const usersToShow = deletedUserNames.slice(0, numberOfUsersToShow).join(', ')
      const notShownUsers = (deletedUserNames.length - numberOfUsersToShow)
      const suffix = (deletedUserNames.length > numberOfUsersToShow) ? `and ${pluralize('other', notShownUsers, true)}` : ''
      return <div>{name} currently have no users.<br /> {name} had {pluralize('user', deletedUserNames.length, true)} in the past: {usersToShow} {suffix}</div>
    }
  }

  const loadingApps = useSelector(isAppsLoading)
  const loadingLicenses = useSelector(isLoadingLicensesTypes)
  const app = useSelector(deprecatedGetAppsByIds)[idApp] || EMPTY_OBJECT
  const allUsers = useSelector(getAllAppAccountLicenseUsersES)
  const licenseUsers = allUsers?.[idAppAccount]?.[idLicense] || EMPTY_OBJECT
  const { users = [], total: totalUsers = 0, loading: loadingUsers } = licenseUsers
  const { appNotInUsePeriod } = useSelector(getCurrentOrg)

  const deletedUsersMessage = getAllUsersWereDeletedText(app, users)
  const usersEmptyState = <UsersEmptyState idAppAccount={idAppAccount} idLicense={idLicense} />
  const renderNoUsers = deletedUsersMessage || usersEmptyState

  const userPreferences = useSelector(getUserPreferences)
  const userTablePreferences = userPreferences[TABLES.appAccountLicenseUsersTable.key] || EMPTY_OBJECT
  const { defaultSort = EMPTY_ARRAY } = userTablePreferences
  const tableInfo = useSelector(getUserTablesInfo)[TABLES.appAccountLicenseUsersTable.key]
  const configurableColumnsOptions = useSelector(getAppAccountLicenseUserConfigurableColumnsOptions)

  const isLoading = (loadingApps || loadingLicenses || loadingUsers)
  const appName = app.name
  const [columns, setColumns] = useState(getColumns({ tableInfo, license, inactivityPeriod }))
  const [selectedView, setSelectedView] = useState(CustomSelectOptions[0])

  const onChangeSelectedView = (changedSelectedView) => {
    setSelectedView(changedSelectedView)
    Analytics.filterUtilizationStatus({ appName, utilizationStatus: changedSelectedView.label, licenseName: license.name })
    onSelectedViewChange({ idLicense, view: changedSelectedView.value })
  }

  const fetchFields = useCallback(() => {
    dispatch(getUserDetailsFields({ idOrg }))
  }, [dispatch, idOrg])

  const getAppUsersData = useCallback(({ limit = ITEMS_PER_PAGE, offset = 0, sort = defaultSort, reset = false, filters = tableInfo.filters || [], calculatedStatus, inactivityPeriod }) => {
    if (reset) {
      fetchFields()
    }
    dispatch(getAppUsersLicense({ idOrg, idApp, idAppAccount, limit, offset, sort: sortStringFromArray(sort), reset, filters: filters.filter(Boolean), idLicense: license.id, inactivityPeriod, calculatedStatus, status: APP_USER_STATUS.ACTIVE }))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSort, tableInfo.filters, userPreferences, dispatch, idOrg, idApp, idAppAccount, license.id, fetchFields, inactivityPeriod])

  const sortStringFromArray = sortArray => {
    return sortArray.map(s => `${s.id}:${s.desc ? 'desc' : 'asc'}`).join(',')
  }

  const fetchMoreData = (reset = false) => {
    fetchUsersData(reset, reset ? 0 : users.length)
  }

  const fetchUsersData = useCallback((reset = false, offset = 0) => debounce(() => {
    getAppUsersData({ offset, reset, calculatedStatus: selectedView.value === 'all' ? undefined : selectedView.value, inactivityPeriod })
  }, 500, { leading: true, trailing: false })(), [getAppUsersData, selectedView.value, inactivityPeriod])

  const onSortedChange = (sort) => {
    getAppUsersData({ sort, reset: true, calculatedStatus: selectedView.value === 'all' ? undefined : selectedView.value })
  }

  const onFilterChange = debounce((filters) => {
    getAppUsersData({ filters, reset: true, calculatedStatus: selectedView.value === 'all' ? undefined : selectedView.value })
  }, 250)

  useEffect(() => {
    fetchUsersData(true)
  }, [fetchUsersData, selectedView, inactivityPeriod])

  useEffect(() => {
    setColumns(getColumns({ tableInfo, license, inactivityPeriod }))
  }, [appName, appNotInUsePeriod, idLicense, license, tableInfo, inactivityPeriod])

  useEffect(() => {
    onSelectedColumnsChange(columns)
  }, [columns, onSelectedColumnsChange])

  return (
    <Stack direction='column' gap='space-400'>
      <Table
        getNoDataProps={getNoDataProps}
        getTdProps={setTdStyle}
        tableKey={TABLES.appAccountLicenseUsersTable.key}
        data={users}
        columns={columns}
        emptyStateMessage={renderNoUsers}
        loading={isLoading}
        loadingMore={loadingUsers}
        manual
        configurableColumnsOptions={configurableColumnsOptions}
        configurableColumns
        fetchData={fetchMoreData}
        onSortedChangeCB={onSortedChange}
        onFilterChange={onFilterChange}
        totalCount={totalUsers}
        forceShowNumberOfResults
        appName={appName}
        style={{ minHeight: users.length ? 'none' : '200px' }}
        customSelect
        customSelectOptions={CustomSelectOptions}
        customSelectSelectedValue={selectedView}
        customSelectOnChange={onChangeSelectedView}
      />
    </Stack>
  )
}

const setTdStyle = () => ({
  style: {
    border: 0,
    padding: '3px 10px',
    color: colors.black
  }
})

export default AppAccountLicenseUsers
