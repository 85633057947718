import { Reducer } from 'redux'
import { mapPredefinedFieldToFilterOption } from '@lenses/filters.t'
import { fieldsValuesToOptions } from '@lenses/utils'
import { FilterFieldValuesByKey } from '@shared/filters/types'
import { CanceledTask, DoneTask, OpenTask, TasksActions, TasksState } from './types'

const initialState: TasksState = {
  openTasks: {
    loading: false,
    loadingMore: false,
    tasks: [],
    total: 0,
    totalUnfiltered: 0,
    filters: {
      filterOptions: [],
      fieldValues: {}
    }
  },
  doneTasks: {
    loading: false,
    loadingMore: false,
    tasks: [],
    total: 0,
    filters: {
      filterOptions: [],
      fieldValues: {}
    }
  },
  canceledTasks: {
    loading: false,
    loadingMore: false,
    tasks: [],
    total: 0,
    filters: {
      filterOptions: [],
      fieldValues: {}
    }
  }
}

const tasksReducer: Reducer<TasksState, TasksActions> = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_OPEN_TASKS_PENDING': {
      const { reset = true } = action.meta

      return {
        ...state,
        openTasks: {
          ...state.openTasks,
          loading: true,
          loadingMore: !reset
        }
      }
    }
    case 'GET_OPEN_TASKS_FAILED': {
      return {
        ...state,
        openTasks: {
          ...state.openTasks,
          loading: false,
          loadingMore: false
        }
      }
    }
    case 'GET_OPEN_TASKS_RESPONSE': {
      const { tasks, total } = action.payload
      const { reset = true } = action.meta

      const retrievedTasks = tasks as OpenTask[]

      return {
        ...state,
        openTasks: {
          ...state.openTasks,
          loading: false,
          loadingMore: false,
          tasks: reset ? retrievedTasks : state.openTasks.tasks.concat(retrievedTasks),
          total
        }
      }
    }
    case 'GET_COMPLETED_TASKS_PENDING': {
      const { reset = true } = action.meta

      return {
        ...state,
        doneTasks: {
          ...state.doneTasks,
          loading: true,
          loadingMore: !reset
        }
      }
    }
    case 'GET_COMPLETED_TASKS_FAILED': {
      return {
        ...state,
        doneTasks: {
          ...state.doneTasks,
          loading: false,
          loadingMore: false
        }
      }
    }
    case 'GET_COMPLETED_TASKS_RESPONSE': {
      const { tasks, total } = action.payload
      const { reset = true } = action.meta

      const retrievedTasks = tasks as DoneTask[]

      return {
        ...state,
        doneTasks: {
          ...state.doneTasks,
          loading: false,
          loadingMore: false,
          tasks: reset ? retrievedTasks : state.doneTasks.tasks.concat(retrievedTasks),
          total
        }
      }
    }
    case 'GET_CANCELED_TASKS_PENDING': {
      const { reset = true } = action.meta

      return {
        ...state,
        canceledTasks: {
          ...state.canceledTasks,
          loading: true,
          loadingMore: !reset
        }
      }
    }
    case 'GET_CANCELED_TASKS_FAILED': {
      return {
        ...state,
        canceledTasks: {
          ...state.canceledTasks,
          loading: false,
          loadingMore: false
        }
      }
    }
    case 'GET_CANCELED_TASKS_RESPONSE': {
      const { tasks, total } = action.payload
      const { reset = true } = action.meta

      const retrievedTasks = tasks as CanceledTask[]

      return {
        ...state,
        canceledTasks: {
          ...state.canceledTasks,
          loading: false,
          loadingMore: false,
          tasks: reset ? retrievedTasks : state.canceledTasks.tasks.concat(retrievedTasks),
          total
        }
      }
    }
    case 'GET_OPEN_TASKS_AMOUNT_RESPONSE': {
      const { total } = action.payload

      return {
        ...state,
        openTasks: {
          ...state.openTasks,
          totalUnfiltered: total
        }
      }
    }

    case 'GET_OPEN_TASKS_FILTER_OPTIONS_RESPONSE': {
      const { predefinedFields } = action.payload
      const filterOptions = predefinedFields.map(mapPredefinedFieldToFilterOption)

      return {
        ...state,
        openTasks: {
          ...state.openTasks,
          filters: {
            ...state.openTasks.filters,
            loading: false,
            filterOptions
          }
        }
      }
    }

    case 'GET_OPEN_TASKS_FIELD_VALUES_RESPONSE': {
      const { fieldValues } = action.payload
      const fieldValuesOptions = fieldsValuesToOptions(fieldValues) as FilterFieldValuesByKey

      return {
        ...state,
        openTasks: {
          ...state.openTasks,
          filters: {
            ...state.openTasks.filters,
            fieldValues: fieldValuesOptions
          }
        }
      }
    }

    case 'GET_DONE_TASKS_FILTER_OPTIONS_RESPONSE': {
      const { predefinedFields } = action.payload
      const filterOptions = predefinedFields.map(mapPredefinedFieldToFilterOption)

      return {
        ...state,
        doneTasks: {
          ...state.doneTasks,
          filters: {
            ...state.doneTasks.filters,
            loading: false,
            filterOptions
          }
        }
      }
    }

    case 'GET_DONE_TASKS_FIELD_VALUES_RESPONSE': {
      const { fieldValues } = action.payload
      const fieldValuesOptions = fieldsValuesToOptions(fieldValues) as FilterFieldValuesByKey

      return {
        ...state,
        doneTasks: {
          ...state.doneTasks,
          filters: {
            ...state.doneTasks.filters,
            fieldValues: fieldValuesOptions
          }
        }
      }
    }

    case 'GET_CANCELED_TASKS_FILTER_OPTIONS_RESPONSE': {
      const { predefinedFields } = action.payload
      const filterOptions = predefinedFields.map(mapPredefinedFieldToFilterOption)

      return {
        ...state,
        canceledTasks: {
          ...state.canceledTasks,
          filters: {
            ...state.canceledTasks.filters,
            loading: false,
            filterOptions
          }
        }
      }
    }

    case 'GET_CANCELED_TASKS_FIELD_VALUES_RESPONSE': {
      const { fieldValues } = action.payload
      const fieldValuesOptions = fieldsValuesToOptions(fieldValues) as FilterFieldValuesByKey

      return {
        ...state,
        canceledTasks: {
          ...state.canceledTasks,
          filters: {
            ...state.canceledTasks.filters,
            fieldValues: fieldValuesOptions
          }
        }
      }
    }

    default: {
      return state
    }
  }
}

export default tasksReducer
