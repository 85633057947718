import styled from 'styled-components'

export const ContentAreaStyle = {
  overflowY: 'unset' as const
}

export const HelperText = styled.div`
  font: ${({ theme }) => theme.typography.font.caption01};
  color: ${({ theme }) => theme.palette.text.secondary};
`
