import { useEffect } from 'react'
import { AdvancedCard } from '@toriihq/design-system'
import PieChart from '@components/pieChartBox/pieChart'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrentOrg } from '@selectors/org'
import { getDashboardsData } from '@selectors/dashboards'
import { WidgetFormattedData, DynamicWidgetProps } from '@components/dashboard/widgets/types'
import { getAggsForGroupBy, getFormattedData } from '@components/dashboard/widgets/utils'
import { getEntityDataForWidget } from '@actions/dashboards'
import { EMPTY_ARRAY, EMPTY_OBJECT, fieldTypes } from '@root/constants'
import WidgetTooltip from '@components/dashboard/widgets/shared/widgetTooltip'
import { useTheme } from 'styled-components'
import Currency from '@components/currency'
import numeral from 'numeral/numeral'
import WidgetEmptyState from '@components/dashboard/widgets/shared/emptyState'
import { PieChartWidgetBox } from './style'

const PieChartWidget = (props: DynamicWidgetProps) => {
  const dispatch = useDispatch()
  const { idDashboard, widget, isSelected, primaryButton, isWidgetMenuOpen, onFilteredDrilldown } = props

  const theme = useTheme()
  const { id: idOrg } = useSelector(getCurrentOrg)
  const dashboardsData = useSelector(getDashboardsData)
  const dashboardData = dashboardsData[idDashboard]
  const widgetsDataById = dashboardData?.widgetsData || EMPTY_OBJECT
  const widgetData = widgetsDataById[widget.id]

  const { loading, aggregations = EMPTY_ARRAY, displayConfig } = widgetData?.data ?? EMPTY_OBJECT

  const fieldType = displayConfig?.groupBy?.metric?.field?.type

  const categoricalColors = Object.entries(theme.palette.chart).filter(([key, value]) => key.startsWith('categorical')).map(([key, value]) => value)
  const data = getFormattedData({ displayConfig: displayConfig, aggregations }).map((entry, i) => ({ ...entry, color: categoricalColors[i] }))

  const valueDisplayFunction = (value) => {
    if (fieldType === fieldTypes.currency) {
      return <Currency value={value ?? 0} />
    }

    return numeral(value).format('0,0')
  }

  const loadingData = loading && data.length === 0

  useEffect(() => {
    if (idOrg) {
      dispatch(getEntityDataForWidget({
        entityType: widget.entityType,
        idOrg,
        idDashboard,
        displayConfig: widget.displayConfig,
        idWidget: widget.id,
        filters: widget.dataConfig.filters,
        aggs: getAggsForGroupBy({ widget })
      }))
    }
  }, [dispatch, idOrg, widget, idDashboard])

  const handlePieClick = (selectedData: WidgetFormattedData) => {
    onFilteredDrilldown && onFilteredDrilldown(widget, data, selectedData)
  }

  const shouldShowPieChart = loadingData || data.length > 0

  return (<AdvancedCard
    title={widget?.displayConfig?.label}
    hoverable
    selected={isSelected}
    primaryButton={primaryButton}
    showPrimaryButton={isWidgetMenuOpen}
  >
    {shouldShowPieChart
      ? <PieChartWidgetBox>
        <PieChart
          data={data}
          hasData
          loading={loadingData}
          header=''
          customToolTip={<WidgetTooltip widget={widget} payload={undefined} active={undefined} />}
          valueDisplayFunction={valueDisplayFunction}
          overrideStyle={{ overflow: 'visible' }}
          onPieClick={handlePieClick}
        />
      </PieChartWidgetBox>
      : <WidgetEmptyState />
    }
  </AdvancedCard>)
}

export default PieChartWidget
