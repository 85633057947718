import React from 'react'
import { css } from 'glamor'
import colors from '../../../shared/style/colors'
import texts from '../../../shared/style/texts'
import { UPLOAD_TYPES } from '@root/constants'
import PropTypes from 'prop-types'
import { Button, ButtonType, UploadArea, toast, ToastType } from '@toriihq/design-system'
import { withTheme } from 'styled-components'
import ErrorImg from '@media/error.svg'
import SuccessImg from '@media/success-checkmark.svg'
import { castArray } from 'lodash'

const DROP_ZONE_DEFAULT = 'default'
const DROP_ZONE_SUCCESS = 'success'
const DROP_ZONE_ERROR = 'error'
const DROP_ZONE_DROP_ERROR = 'dropError'

const DROP_ZONE_FINISH_STATES = [DROP_ZONE_SUCCESS, DROP_ZONE_ERROR, DROP_ZONE_DROP_ERROR]

export const PDF_FILE_TYPES_AND_EXTENSIONS = {
  extensions: ['pdf'],
  mimeTypes: ['application/pdf']
}
export const DOCUMENT_FILE_TYPES_AND_EXTENSIONS = {
  extensions: ['doc', 'docx'],
  mimeTypes: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
}

export const SHEET_FILE_TYPES_AND_EXTENSIONS = {
  extensions: ['xls', 'xlsx', 'csv'],
  mimeTypes: ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'text/csv']
}

export const IMAGE_FILE_TYPES_AND_EXTENSIONS = {
  extensions: ['jpeg', 'jpg', 'png'],
  mimeTypes: ['image/jpeg', 'image/png']
}

export const VALID_FILE_TYPES_AND_EXTENSIONS = [PDF_FILE_TYPES_AND_EXTENSIONS, DOCUMENT_FILE_TYPES_AND_EXTENSIONS, SHEET_FILE_TYPES_AND_EXTENSIONS, IMAGE_FILE_TYPES_AND_EXTENSIONS]

class UploadFileDropzone extends React.Component {
  constructor (props) {
    super(props)
    const { theme } = props
    this.state = {
      currentDropZoneState: DROP_ZONE_DEFAULT,
      CSS: generateCSS({ theme })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { currentDropZoneState: currentDropZoneStatePrev } = prevState
    const { currentDropZoneState } = this.state
    const { isFinished: isFinishedPrev } = prevProps
    const { handleFinished, isFinished, theme } = this.props

    if (handleFinished && currentDropZoneStatePrev !== currentDropZoneState && DROP_ZONE_FINISH_STATES.includes(currentDropZoneState)) { handleFinished(true) }
    if (isFinishedPrev !== isFinished && !isFinished) { this.updateDropZoneState(DROP_ZONE_DEFAULT) }

    if (prevProps.theme !== theme) {
      this.setState({
        CSS: generateCSS({ theme })
      })
    }
  }

  onDrop = async (files) => {
    const { idOrg, idApp, uploadFile, onPostUpload, supportMultiOnUploadCallback, onPreUpload, additionalFailureText, showNotification } = this.props

    if (files.length === 0) {
      throw new Error(`Invalid file type. ${additionalFailureText ? ` ${additionalFailureText}` : ''}`)
    }

    onPreUpload && onPreUpload()

    try {
      const uploads = await Promise.all(files.map(async (file) => {
        const { idUpload } = await uploadFile({ idOrg, file, type: UPLOAD_TYPES.ATTACHMENT, idApp })
        return { idUpload, name: file.name }
      }))

      if (supportMultiOnUploadCallback) {
        onPostUpload(uploads)
      } else {
        for (const { idUpload, name } of uploads) {
          if (showNotification) {
            toast({
              message: `${name} was successfully uploaded`,
              type: ToastType.SUCCESS
            })
          }
          await onPostUpload({ idUpload, name })
        }
      }

      return uploads
    } catch (e) {
      throw new Error('Something went wrong.')
    }
  }

  updateDropZoneState (newState) {
    this.setState({ currentDropZoneState: newState })
  }

  renderFooter = (state) => {
    if ([DROP_ZONE_DEFAULT].includes(state)) {
      return null
    } else {
      return (<div {...this.state.CSS.postUploadFooter}>
        <Button type={ButtonType.secondary} onClick={() => this.updateDropZoneState(DROP_ZONE_DEFAULT)} label='Upload more files' />
      </div>)
    }
  }

  render () {
    let { currentDropZoneState, CSS } = this.state
    const { disabled, showHeader, overrideStyle, additionalFailureText, supportedFileTypesAndExtensions = VALID_FILE_TYPES_AND_EXTENSIONS, uploadingText } = this.props
    const showPreUpload = currentDropZoneState === DROP_ZONE_DEFAULT
    const showPostUpload = [DROP_ZONE_SUCCESS, DROP_ZONE_ERROR, DROP_ZONE_DROP_ERROR].includes(currentDropZoneState)

    const supportedMimeTypes = castArray(supportedFileTypesAndExtensions).map(type => type.mimeTypes).flat()
    const supportedExtension = castArray(supportedFileTypesAndExtensions).map(type => type.extensions).flat()

    const dropZoneState = dropZoneStateView({ additionalFailureText })
    return (
      <div {...overrideStyle}>
        {showHeader && <header {...CSS.header}>
          Upload file
          <div {...CSS.subHeader}>Upload your PDF/Word file here.<br />Torii will do the rest.</div>
        </header>}
        <div {...CSS.body}>
          {showPreUpload &&
            <UploadArea
              validFileTypes={supportedMimeTypes}
              description={`Supported file types: ${supportedExtension.join(', ')}`}
              loadingMessage={uploadingText}
              disabled={disabled}
              onFileSelect={this.onDrop}
              multiple={false}
            />
          }
          {showPostUpload &&
          <div {...CSS.alignment} >
            <span {...CSS.iconContainer} style={{ backgroundColor: dropZoneState[currentDropZoneState].imageBackgroundColor }}>
              <img src={dropZoneState[currentDropZoneState].image} height='24' width='24' alt={'Upload status'} {...CSS.postUploadIcon} />
            </span>
            {dropZoneState[currentDropZoneState].textElement(disabled)}
          </div>
          }
        </div>
      </div>
    )
  }
}

const generateCSS = ({ theme }) => ({
  header: css(texts.headers.regular, {
    textAlign: 'center',
    borderBottom: `1px solid ${colors.border}`,
    padding: '22px 0'
  }),
  subHeader: css(texts.body, {
    marginTop: '5px'
  }),
  postUploadFooter: css(texts.body, {
    borderTop: `1px solid ${colors.border}`,
    padding: '22px 45px',
    display: 'flex',
    justifyContent: 'flex-end'
  }),
  body: css(texts.body, {
    display: 'flex',
    justifyContent: 'center'
  }),
  dropZoneActive: css({
    borderColor: colors.blue,
    ' div': {
      color: colors.grey2
    },
    opacity: '0.5'
  }),
  displayArea: css({
    width: '100%',
    height: '100%'
  }),
  iconContainer: css({
    height: '77px',
    width: '77px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }),
  alertIcon: css({
    height: '20px',
    width: '20px',
    backgroundColor: colors.lightBlue1,
    borderRadius: '50%',
    color: colors.white,
    lineHeight: '22px',
    textAlign: 'center',
    marginRight: '12px',
    transform: 'none'
  }),
  text: css(texts.subheading, {
    marginTop: '20px',
    textAlign: 'center',
    font: theme.typography.font.body02,
    fontWeight: theme.typography.fontWeight.regular,
    color: theme.palette.text.primary,
    ' span': {
      color: colors.blue
    }
  }),
  description: css(texts.subheading, {
    textAlign: 'center',
    font: theme.typography.font.body02,
    fontWeight: theme.typography.fontWeight.regular,
    color: theme.palette.text.tertiary
  }),
  uploading: css({
    marginBottom: '38px'
  }),
  alignment: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  }),
  postUploadIcon: css({
    display: 'flex',
    color: colors.white,
    fontSize: '24px',
    transform: 'none'
  })
})

const dropZoneStateView = ({ additionalFailureText }) => ({
  [DROP_ZONE_SUCCESS]: {
    image: SuccessImg,
    imageBackgroundColor: colors.blue,
    textElement: () => <div {...CSS.text}>Done!</div>
  },
  [DROP_ZONE_ERROR]: {
    image: ErrorImg,
    imageBackgroundColor: colors.red,
    textElement: () => <div {...CSS.text}>Something went wrong, Please try again</div>
  },
  [DROP_ZONE_DROP_ERROR]: {
    image: ErrorImg,
    imageBackgroundColor: colors.red,
    textElement: () => <div {...CSS.text}>Invalid file type{additionalFailureText ? `. ${additionalFailureText}` : ''}</div>
  }
})

UploadFileDropzone.propTypes = {
  showHeader: PropTypes.bool.isRequired,
  onPostUpload: PropTypes.func.isRequired,
  onPreUpload: PropTypes.func,
  overrideStyle: PropTypes.object,
  supportMultiOnUploadCallback: PropTypes.bool,
  isInsidePopup: PropTypes.bool,
  isFinished: PropTypes.bool,
  handleFinished: PropTypes.func,
  supportedFileTypesAndExtensions: PropTypes.oneOf([PDF_FILE_TYPES_AND_EXTENSIONS, DOCUMENT_FILE_TYPES_AND_EXTENSIONS, SHEET_FILE_TYPES_AND_EXTENSIONS, IMAGE_FILE_TYPES_AND_EXTENSIONS, VALID_FILE_TYPES_AND_EXTENSIONS]),
  additionalFailureText: PropTypes.string,
  showNotification: PropTypes.bool,
  theme: PropTypes.object
}

UploadFileDropzone.defaultProps = {
  showHeader: false,
  supportMultiOnUploadCallback: false,
  isInsidePopup: false,
  showNotification: false
}

export default withTheme(UploadFileDropzone)
