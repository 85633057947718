import { WIDGET_TYPES } from '../../../../store/reducers/dashboards/types'
import { DynamicWidgetProps } from '../types'
import MetricWidget from './metricWidget'
import PieChartWidget from './pieChartWidget'
import BarChartWidget from './barChartWidget'
import TableWidget from './tableWidget'
import { ReactElement } from 'react'

const DynamicWidgetRenderer = (properties: DynamicWidgetProps): ReactElement | null => {
  const { widget } = properties

  switch (widget.type) {
    case WIDGET_TYPES.METRIC_WIDGET:
      return <MetricWidget {...properties} />

    case WIDGET_TYPES.PIE_CHART_WIDGET:
      return <PieChartWidget {...properties} />

    case WIDGET_TYPES.BAR_CHART_WIDGET:
      return <BarChartWidget {...properties} />

    case WIDGET_TYPES.TABLE_WIDGET:
      return <TableWidget {...properties} />

    default: {
      console.error('Unknown widget type', widget.type)
      return null
    }
  }
}

export default DynamicWidgetRenderer
