import View from './view'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { TABLES } from '../../constants'
import { changeAppHiddenState, getCustomApps, toggleAddApplication } from '@actions/'
import { togglePopupCopyAppData } from '@store/actions/popups'
import { getCustomApps as getCustomAppsSelector, isLoadingCustomApps } from '@selectors/apps'
import { getIsSmallScreen } from '@selectors/ui'
import { getCompanyName } from '@selectors/org'

const mapStateToProps = (state, ownProps) => {
  const { idOrg } = ownProps
  const companyName = getCompanyName(state)
  const apps = getCustomAppsSelector(state)
  const loading = isLoadingCustomApps(state) && apps.length === 0

  return {
    apps,
    companyName,
    loading,
    idOrg,
    tableKey: TABLES.customAppsTable.key,
    isSmallScreen: getIsSmallScreen(state)
  }
}

const mapDispatchToProps = {
  getCustomApps,
  changeAppHiddenState,
  toggleAddApplication,
  togglePopupCopyAppData
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(View))
