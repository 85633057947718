import send from '@shared/redux-fetch'
import { GET_CONTRACTS_METADATA, GET_RENEWALS_SUMMARY } from '@root/constants.t'
import { GetRenewalsSummary } from '@actions/contracts/types'
import { GET_CONTRACT_ATTACHMENTS } from '@root/constants'
import { isUndefined, omitBy } from 'lodash'

export const getRenewalsSummary = ({ idOrg }: GetRenewalsSummary) => {
  return send(GET_RENEWALS_SUMMARY, {
    url: `/api/orgs/${idOrg}/contracts/renewalsSummary`,
    method: 'GET'
  })
}

export const getContractAttachments = ({ idOrg, idUploads, idApp }: { idOrg: number, idUploads: number[], idApp?: number }) => {
  return send(GET_CONTRACT_ATTACHMENTS, {
    method: 'GET',
    url: `/api/orgs/${idOrg}/contracts/attachments`,
    query: omitBy({
      idApp,
      idUploads: JSON.stringify(idUploads)
    }, isUndefined) as Record<string, string | number | boolean>
  })
}

export const getContractsMetadata = ({ idOrg }: { idOrg: number }) => {
  return send(GET_CONTRACTS_METADATA, {
    url: `/api/orgs/${idOrg}/contracts/metadata`
  })
}
