import React, { Fragment, useContext, useState, useLayoutEffect, ReactNode } from 'react'
import { Form } from 'react-final-form'
import { Checkbox, Button, ButtonSize, ButtonType, IconName } from '@toriihq/design-system'
import * as Style from './style'
import BackLink from '@components/backLink'
import { ToriiPopupContext, ToriiPopupProvider } from '@components/popups/ToriiPopupV2/popupContext'
import RenderModals from '@components/popups/ToriiPopupV2/renderModals'
import EnableForIfScopes from '@components/popups/ToriiPopupV2/enableForIfScopes'
import VisibleFor from '@components/visibleFor'
import SubmitButton from '@components/submitButton'

type GetClientHeightFn = (areaId: string) => { clientHeight: number }

const getClientHeight: GetClientHeightFn = (areaId) => {
  const popupAreas = document.querySelectorAll(`#${areaId}`)
  const popupAreasArr = Array.from(popupAreas)

  return popupAreasArr && popupAreasArr.length >= 1 ? popupAreasArr[popupAreasArr.length - 1] : { clientHeight: 0 }
}

const getHeaderAndFooterHeight = () => {
  const popupHeaderArea = getClientHeight('popupHeaderArea')
  const popupFooterArea = getClientHeight('popupFooterArea')

  return popupHeaderArea.clientHeight + popupFooterArea.clientHeight
}

interface ToriiPopupProps {
  children: ReactNode;
  closeOnOverlayClick?: boolean;
  modalOverrideStyle?: React.CSSProperties;
  isOpen: boolean;
  confirmClose?: boolean;
  width?: string;
  height?: string;
  onCloseAction?: () => void;
  showCloseIcon?: boolean;
}

const ToriiPopup = (props: ToriiPopupProps) => {
  const {
    children,
    closeOnOverlayClick = true,
    modalOverrideStyle,
    isOpen,
    confirmClose = false,
    width,
    height,
    onCloseAction = () => {},
    showCloseIcon
  } = props

  return (
    <ToriiPopupProvider confirmClose={confirmClose} onCloseAction={onCloseAction}>
      <RenderModals children={children} showCloseIcon={showCloseIcon} closeOnOverlayClick={closeOnOverlayClick} modalOverrideStyle={modalOverrideStyle} isOpen={isOpen} onCloseAction={onCloseAction} width={width} height={height} />
    </ToriiPopupProvider>
  )
}

interface ToriiPopupHeaderProps {
  header?: ReactNode;
  subHeader?: ReactNode;
  children?: ReactNode;
  overrideStyle?: React.CSSProperties;
}

const ToriiPopupHeader = (props: ToriiPopupHeaderProps) => {
  const { header, subHeader, children, overrideStyle } = props

  return (
    <Style.HeaderContainer style={overrideStyle} id='popupHeaderArea'>
      {header &&
        <Style.Header>
          {header}
        </Style.Header>
      }
      {subHeader &&
        <Style.SubHeader>
          {subHeader}
        </Style.SubHeader>
      }
      {children &&
        <Style.CustomHeader>
          {children}
        </Style.CustomHeader>
      }
    </Style.HeaderContainer>
  )
}
ToriiPopup.Header = ToriiPopupHeader

interface ToriiPopupContentProps {
  children: ReactNode;
  contentAreaStyle?: React.CSSProperties;
  maxHeight?: string;
}

const ToriiPopupContent = (props: ToriiPopupContentProps) => {
  const { children, contentAreaStyle, maxHeight } = props

  const [headerAndFooterHeight, setHeaderAndFooterHeight] = useState(getHeaderAndFooterHeight())

  useLayoutEffect(() => {
    setHeaderAndFooterHeight(getHeaderAndFooterHeight())
  }, [])

  return (
    <Style.ContentArea
      style={contentAreaStyle}
      headerAndFooterHeight={headerAndFooterHeight}
      maxHeight={maxHeight}
      id='contentArea'
      data-testid='contentArea'
    >
      {children}
    </Style.ContentArea>
  )
}
ToriiPopup.Content = ToriiPopupContent

interface ToriiPopupFormProps {
  onSubmit?: (values: any) => void;
  validate?: (values: any) => object;
  render: (formProps: any) => ReactNode;
  renderFooter?: (formProps: any) => ReactNode;
  contentAreaStyle?: React.CSSProperties;
  mutators?: { [key: string]: (args: any[], state: any, utils: any) => any };
  initialValues?: object;
  validateOnBlur?: boolean;
  maxHeight?: string;
}

const ToriiPopupForm = (props: ToriiPopupFormProps) => {
  const { onSubmit, validate, render, renderFooter, contentAreaStyle, mutators, initialValues, validateOnBlur, maxHeight } = props

  const { submitAction, initialValues: initialValuesFromContext } = useContext(ToriiPopupContext)
  const headerAndFooterHeight = getHeaderAndFooterHeight()

  return (
    <Form
      onSubmit={onSubmit || submitAction.current}
      validate={validate}
      validateOnBlur={validateOnBlur}
      mutators={mutators}
      initialValues={initialValues || initialValuesFromContext}
      render={(formProps) =>
        <Fragment>
          <Style.FormArea
            style={contentAreaStyle}
            headerAndFooterHeight={headerAndFooterHeight}
            maxHeight={maxHeight}
            id='contentArea'
            data-testid='contentArea'
          >
            {render(formProps)}
          </Style.FormArea>
          {renderFooter && renderFooter(formProps)}
        </Fragment>
      }
    />
  )
}
ToriiPopup.Form = ToriiPopupForm

interface ToriiPopupFooterProps {
  showPageIndex?: boolean;
  currentPage?: number;
  numberOfPages?: number;
  showBackButton?: boolean;
  backButtonAction?: () => void;
  backButtonText?: string;
  isBackButtonDisabled?: boolean;
  showBackButtonTextOnly?: boolean;
  showCancelButton?: boolean;
  cancelButtonText?: string;
  cancelButtonAction?: () => void;
  showMainButton?: boolean;
  scopes?: string[];
  mainButtonText?: string;
  mainButtonAction?: (checked?: boolean) => void;
  mainButtonIcon?: IconName;
  isMainButtonDisabled?: boolean;
  isMainSubmit?: boolean;
  recoveryTime?: number;
  formProps?: any;
  overrideStyle?: React.CSSProperties;
  buttonsOverrideStyle?: React.CSSProperties;
  mainButtonType?: typeof ButtonType[keyof typeof ButtonType];
  showCheckbox?: boolean;
  checkboxLabel?: string;
  isCheckboxDisabled?: boolean;
  children?: ReactNode;
  mainButtonVisibilityScopes?: string[];
  turnOverOutlineButtonWithLinkButton?: boolean;
  allowPristine?: boolean;
  ignoreViewOnlyMode?: boolean;
}

const ToriiPopupFooter = (props: ToriiPopupFooterProps) => {
  const {
    showPageIndex = false,
    currentPage,
    numberOfPages,
    showBackButton = false,
    backButtonAction = () => {},
    backButtonText = 'Back',
    isBackButtonDisabled = false,
    showBackButtonTextOnly = false,
    showCancelButton = true,
    cancelButtonText,
    cancelButtonAction,
    showMainButton = true,
    scopes,
    mainButtonText,
    mainButtonAction,
    mainButtonIcon,
    isMainButtonDisabled,
    isMainSubmit = false,
    recoveryTime = 0,
    formProps = {},
    overrideStyle,
    buttonsOverrideStyle,
    mainButtonType = ButtonType.primary,
    showCheckbox,
    checkboxLabel,
    isCheckboxDisabled,
    children,
    mainButtonVisibilityScopes,
    turnOverOutlineButtonWithLinkButton = false,
    allowPristine,
    ignoreViewOnlyMode
  } = props

  const { onClose, scopes: scopesFromContext, mainButtonAction: mainButtonActionFromContext } = useContext(ToriiPopupContext)
  const [checked, setChecked] = useState(true)

  if (!(showPageIndex || showBackButton || showCancelButton || showMainButton || children)) {
    return <Style.EmptyFooter id='popupFooterArea' data-testid='popupFooterArea' />
  }

  const mainButtonActionMethod = () => {
    if (mainButtonAction) {
      return mainButtonAction(checked)
    }
    if (mainButtonActionFromContext.current) {
      return mainButtonActionFromContext.current()
    }
  }

  const [outlineButtonText, outlineButtonAction, outlineButtonDisabled, linkButtonText, linkButtonAction, linkButtonDisabled] =
  turnOverOutlineButtonWithLinkButton ? [backButtonText, backButtonAction, (currentPage === 1 || isBackButtonDisabled), cancelButtonText, (cancelButtonAction || onClose), false]
    : [cancelButtonText, (cancelButtonAction || onClose), false, backButtonText, backButtonAction, (currentPage === 1 || isBackButtonDisabled)]

  return (
    <Style.Footer style={overrideStyle} id='popupFooterArea' data-testid='popupFooterArea'>
      {showBackButton &&
        <Style.BackButtonContainer>
          <BackLink onClick={linkButtonAction} linkText={linkButtonText} overrideStyle={{ padding: '0px 6px', justifySelf: 'flex-start' }} showBackIcon={!turnOverOutlineButtonWithLinkButton && !showBackButtonTextOnly} disabled={linkButtonDisabled} />
          {showPageIndex && <Style.PageIndex>{`${currentPage} of ${numberOfPages}`}</Style.PageIndex>}
        </Style.BackButtonContainer>
      }
      {showCheckbox &&
        <Style.FooterCheckboxContainer>
          <Checkbox label={checkboxLabel} disabled={isCheckboxDisabled} onChange={e => setChecked(e.target.checked)} checked={checked} />
        </Style.FooterCheckboxContainer>
      }
      <ToriiPopup.Footer.Buttons overrideStyle={buttonsOverrideStyle}>
        {showCancelButton && (<Button onClick={outlineButtonAction} disabled={outlineButtonDisabled} type={ButtonType.secondary} size={ButtonSize.medium} label={outlineButtonText} />)}
        {showMainButton &&
          <VisibleFor scopes={mainButtonVisibilityScopes}>
            <EnableForIfScopes ignoreViewOnlyMode={ignoreViewOnlyMode} scopes={scopes || scopesFromContext}>
              {isMainSubmit ? (
                <SubmitButton
                  form={formProps}
                  label={mainButtonText || ''}
                  icon={mainButtonIcon}
                  allowPristine={allowPristine}
                  recoveryTime={recoveryTime}
                />
              )
                : (
                  <Button
                    dataTestId='popupMainButton'
                    onClick={() => mainButtonActionMethod()}
                    size={ButtonSize.medium}
                    disabled={isMainButtonDisabled}
                    type={mainButtonType as any}
                    label={mainButtonText}
                    icon={mainButtonIcon}
                  />
                )
              }
            </EnableForIfScopes>
          </VisibleFor>
        }
        {children}
      </ToriiPopup.Footer.Buttons>
    </Style.Footer>
  )
}

interface ToriiPopupFooterButtonsProps {
  children: ReactNode;
  overrideStyle?: React.CSSProperties;
}

const ToriiPopupFooterButtons = ({ children, overrideStyle }: ToriiPopupFooterButtonsProps) => (
  <Style.Buttons style={overrideStyle}>
    {children}
  </Style.Buttons>
)

interface ToriiPopupCustomFooterProps {
  children: ReactNode;
  overrideStyle?: React.CSSProperties;
}

const ToriiPopupCustomFooter = (props: ToriiPopupCustomFooterProps) => {
  return (
    <Style.Footer style={props.overrideStyle} id='popupFooterArea' data-testid='popupFooterArea'>
      {props.children}
    </Style.Footer>
  )
}

interface ToriiPopupFooterBackButtonProps {
  backButtonAction: () => void;
  currentPage?: number;
  isBackButtonDisabled?: boolean;
  showPageIndex?: boolean;
  numberOfPages?: number;
}

const ToriiPopupFooterBackButton = (props: ToriiPopupFooterBackButtonProps) => {
  return (
    <Style.BackButtonContainer>
      <BackLink onClick={props.backButtonAction} overrideStyle={{ padding: '0px 6px', justifySelf: 'flex-start' }} disabled={props.currentPage === 1 || props.isBackButtonDisabled} />
      {props.showPageIndex && <Style.PageIndex>{`${props.currentPage} of ${props.numberOfPages}`}</Style.PageIndex>}
    </Style.BackButtonContainer>
  )
}

interface ToriiPopupFooterCancelButtonProps {
  label?: string;
}

const ToriiPopupFooterCancelButton = ({ label }: ToriiPopupFooterCancelButtonProps) => {
  const { onClose } = useContext(ToriiPopupContext)
  return <Button onClick={onClose} type={ButtonType.secondary} size={ButtonSize.medium} label={label} />
}

interface ToriiPopupFooterButtonProps {
  scopes?: string[];
  mainButtonAction?: () => void;
  isMainButtonDisabled?: boolean;
  mainButtonType?: typeof ButtonType[keyof typeof ButtonType];
  mainButtonText?: string;
  ignoreViewOnlyMode?: boolean;
}

const ToriiPopupFooterButton = (props: ToriiPopupFooterButtonProps) => {
  const { scopes: scopesFromContext, mainButtonAction: mainButtonActionFromContext } = useContext(ToriiPopupContext)

  const mainButtonActionMethod = () => {
    if (props.mainButtonAction) {
      return props.mainButtonAction()
    }
    if (mainButtonActionFromContext.current) {
      return mainButtonActionFromContext.current()
    }
  }
  const mainButtonType = props.mainButtonType || ButtonType.primary
  return (
    <EnableForIfScopes scopes={props.scopes || scopesFromContext} ignoreViewOnlyMode={props.ignoreViewOnlyMode || false}>
      <Button
        onClick={() => mainButtonActionMethod()}
        size={ButtonSize.medium}
        disabled={props.isMainButtonDisabled}
        type={mainButtonType as any}
        label={props.mainButtonText}
      />
    </EnableForIfScopes>
  )
}

interface ToriiPopupFooterSubmitButtonProps {
  scopes?: string[];
  formProps: any;
  recoveryTime?: number;
  label?: string;
}

const ToriiPopupFooterSubmitButton = (props: ToriiPopupFooterSubmitButtonProps) => {
  const { scopes: scopesFromContext } = useContext(ToriiPopupContext)
  return (
    <EnableForIfScopes scopes={props.scopes || scopesFromContext} ignoreViewOnlyMode={false}>
      <SubmitButton
        form={props.formProps}
        label={props.label || ''}
        recoveryTime={props.recoveryTime}
      />
    </EnableForIfScopes>
  )
}

ToriiPopup.Footer = ToriiPopupFooter as any
ToriiPopup.CustomFooter = ToriiPopupCustomFooter;
(ToriiPopup.Footer as any).Buttons = ToriiPopupFooterButtons;
(ToriiPopup.Footer as any).BackButton = ToriiPopupFooterBackButton;
(ToriiPopup.Footer as any).CancelButton = ToriiPopupFooterCancelButton;
(ToriiPopup.Footer as any).Button = ToriiPopupFooterButton;
(ToriiPopup.Footer as any).SubmitButton = ToriiPopupFooterSubmitButton

export { ToriiPopupContext } from './popupContext'

export default ToriiPopup
