import { DatePicker, Input, Select, Tooltip } from '@toriihq/design-system'
import React from 'react'
import { Field } from 'react-final-form'
import { isNil } from 'lodash'
import { CurrencyInput } from '../../../form/currencyInput'
import moment from 'moment'
import { getContractCurrencyValue } from '../../../contracts/utils'
import { DescriptionField } from './descriptionField'

const RenderNumberInput = ({ input, meta, disabled, ...rest }: { input: any, meta: any, disabled?: boolean, [key: string]: any }) => {
  return <Input {...input} type='number' hasError={!meta.valid} disabled={disabled} {...rest}
    onChange={e => {
      const value = e.target.value
      if (value === '') {
        input.onChange(null)
        return
      }
      const positiveValue = value !== null ? Math.abs(Number(value)) : null
      input.onChange(positiveValue)
    }}
  />
}

const RenderSelect = ({ input, options, disabled, meta, ...rest }: { input: any, options: Array<{ value: number | string, label: string }>, disabled?: boolean, meta?: any, [key: string]: any }) => {
  const getOptionValue = () => {
    if (!input.value) return null
    if (typeof input.value !== 'object') {
      const existingOption = options.find(option => option.value === input.value)
      if (existingOption) {
        return existingOption
      }
      // If the value exists but not in current options, create a custom option for it
      return { value: input.value, label: `${input.value}` }
    }

    return input.value
  }

  const hasError = Boolean(meta?.error)

  return (
    <Select
      menuWidth={400}
      maxMenuHeight={180}
      isDisabled={options?.length === 0 || disabled}
      options={options}
      value={getOptionValue()}
      onChange={input.onChange}
      hasError={hasError}
      {...rest}
    />
  )
}

const renderCurrencyInput = ({ input, disabled, ...rest }: { input: any, disabled?: boolean, [key: string]: any }) => {
  const handleChange = (value) => {
    // Convert negative values to positive for currency inputs
    const positiveValue = value !== null ? Math.abs(value) : null
    input.onChange(positiveValue)
  }

  return <CurrencyInput
    {...input}
    {...rest}
    value={getContractCurrencyValue(input.value)}
    prefix={rest.currencySymbol}
    onChange={handleChange}
    disabled={disabled}
  />
}

const validateNumber = (value: number) => {
  if (isNil(value)) return undefined
  const num = Number(value)
  return isNaN(num) ? 'Invalid number' : undefined
}

const validateQuantity = (value: any) => {
  if (isNil(value)) return undefined
  const numberError = validateNumber(value)
  if (numberError) return numberError
  if (Number(value) < 0) return 'Quantity cannot be negative'
  return undefined
}

const validateDiscount = (value: any) => {
  if (isNil(value)) return undefined
  const numberError = validateNumber(value)
  if (numberError) return numberError
  // Convert negative to positive before validation
  const positiveValue = Math.abs(Number(value))
  if (positiveValue > 100) return 'Discount cannot be more than 100%'
  return undefined
}

const validatePaymentTerm = (value: any, options: Array<{ value: number | string, label: string }>) => {
  if (!value) return undefined
  const valueToCheck = typeof value === 'object' ? value.value : value
  const isValid = options.some(option => option.value === valueToCheck)
  return isValid ? undefined : 'Selected payment term is no longer valid'
}

const shouldDisableField = (name: string, fields: any, hasContractsWriteAccess: boolean) => {
  const [fieldIndex] = name.match(/\d+/) || []
  if (!fieldIndex) return false
  const description = fields.value[fieldIndex]?.description
  return !description || !hasContractsWriteAccess
}

export const lineItemsDataInTableFormat = ({ fields, appOrgLicenses, currencySymbol, paymentTermsOptions, hasLicenseWriteAccess, hasContractsWriteAccessOrAppOwner, newItemIndex }) => {
  const licenseOptions = appOrgLicenses?.map(({ id, name }) => ({ label: name, value: id }))

  return fields.map((name, index) => {
    const handleStartDateChange = (date: Date, endDateInput: any) => {
      if (date && endDateInput) {
        const endDateValue = moment.utc(date).add(1, 'year').subtract(1, 'day').format('YYYY-MM-DD')
        endDateInput.onChange(endDateValue)
        endDateInput.onBlur()
      }
    }

    return {
      description: <DescriptionField name={name} index={index} shouldFocus={index === newItemIndex} disabled={!hasContractsWriteAccessOrAppOwner} />,
      idLicense: (
        <Tooltip label={'You do not have permissions to edit licenses'} hide={hasLicenseWriteAccess}>
          <Field
            name={`${name}.idLicense`}
            render={props => {
              const options = appOrgLicenses?.length > 0 ? [
                { label: 'Do not match', value: null },
                { type: 'divider' },
                ...licenseOptions
              ] : []
              return <RenderSelect {...props} options={options} disabled={!hasLicenseWriteAccess || shouldDisableField(name, fields, hasContractsWriteAccessOrAppOwner)} />
            }}
          />
        </Tooltip>
      ),
      quantity: (
        <Field
          name={`${name}.quantity`}
          component={RenderNumberInput}
          validate={validateQuantity}
          disabled={shouldDisableField(name, fields, hasContractsWriteAccessOrAppOwner)}
        />
      ),
      pricePerUnit: (
        <Field
          name={`${name}.pricePerUnit`}
          currencySymbol={currencySymbol}
          component={renderCurrencyInput}
          disabled={shouldDisableField(name, fields, hasContractsWriteAccessOrAppOwner)}
        />
      ),
      startDate: (
        <Field
          name={`${name}.startDate`}
          render={startProps => (
            <Field
              name={`${name}.endDate`}
              render={endProps => (
                <DatePicker
                  position='top'
                  value={startProps.input.value ? moment(startProps.input.value).format('MM/DD/YYYY') : startProps.input.value}
                  onDayChange={(date) => {
                    const newValue = date && moment.utc(date).format('YYYY-MM-DD')
                    startProps.input.onChange(newValue)
                    startProps.input.onBlur()
                    handleStartDateChange(date, endProps.input)
                  }}
                  disabled={shouldDisableField(name, fields, hasContractsWriteAccessOrAppOwner)}
                />
              )}
            />
          )}
        />
      ),
      endDate: (
        <Field
          name={`${name}.endDate`}
          render={props => (
            <DatePicker
              position='top'
              value={props.input.value ? moment(props.input.value).format('MM/DD/YYYY') : props.input.value}
              onDayChange={(date) => {
                const newValue = date && moment.utc(date).format('YYYY-MM-DD')
                props.input.onChange(newValue)
                props.input.onBlur()
              }}
              disabled={shouldDisableField(name, fields, hasContractsWriteAccessOrAppOwner)}
            />
          )}
        />
      ),
      discount: (
        <Field
          name={`${name}.discount`}
          component={RenderNumberInput}
          validate={validateDiscount}
          suffix='%'
          disabled={shouldDisableField(name, fields, hasContractsWriteAccessOrAppOwner)}
        />
      ),
      tax: (
        <Field
          name={`${name}.tax`}
          currencySymbol={currencySymbol}
          component={renderCurrencyInput}
          disabled={shouldDisableField(name, fields, hasContractsWriteAccessOrAppOwner)}
        />
      ),
      paymentTerm: (
        <Field
          name={`${name}.paymentTerm`}
          validate={(value) => validatePaymentTerm(value, paymentTermsOptions)}
          render={props => <RenderSelect {...props} options={paymentTermsOptions} disabled={shouldDisableField(name, fields, hasContractsWriteAccessOrAppOwner)} />}
        />
      )
    }
  })
}
