import styled from 'styled-components'

export const UploadFileContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  border-radius: ${({ theme }) => theme.corner('radius-050')};
  margin-bottom: 28px;

  .ef-input {
    display: inline-block;
    width: 90%;
  }

  textarea.ef-input {
    height: 100px;
  }

  .ef-actions {
    padding-left: 8px;
  }
`

export const FilesContainer = styled.div`
  div:last-of-type .ef-field {
    border-bottom: 0;
  }

  .ef-field {
    border-top: 0;
  }
`

export const Description = styled.div`
  padding: 10px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
`

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 13px 20px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
  align-items: center;
`

export const Header = styled.div`
  font: ${({ theme }) => theme.typography.font.subtitle02};
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: 700;
  text-transform: uppercase;
`

export const File = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const FileIcon = styled.div`
  margin-right: 10px;
`

export const FileName = styled.div`
  display: flex;
  overflow-wrap: anywhere;
`

export const Field = styled.div`
  div {
    flex: auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const UploadFileBox = styled.div`
  padding: 40px;
`
