import React from 'react'
import { TRANSACTION_MAPPING_STATUS, TRANSACTION_MAPPING_STATUS_DISPLAY } from '@root/constants'
import { Container, StatusText, LightWrapper, StatusDot } from './styles'
import { Props } from './types'

const getStatusColor = (mappingStatus: string): string => {
  switch (mappingStatus) {
    case TRANSACTION_MAPPING_STATUS.MAPPED:
      return '#00DF95'
    case TRANSACTION_MAPPING_STATUS.IGNORED:
    case TRANSACTION_MAPPING_STATUS.RECOMMEND_TO_IGNORE:
      return '#FF3377'
    default:
      return '#80899C'
  }
}

const MatchingStatusIndicator: React.FC<Props> = ({ mappingStatus }) => {
  const getMappingStatus = () => {
    return TRANSACTION_MAPPING_STATUS_DISPLAY[mappingStatus] || 'Unmatched'
  }

  return (
    <Container>
      <LightWrapper>
        <StatusDot color={getStatusColor(mappingStatus)} />
      </LightWrapper>
      <StatusText>{getMappingStatus()}</StatusText>
    </Container>
  )
}

export default MatchingStatusIndicator
