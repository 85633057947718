import styled from 'styled-components'

export const DataPanelsContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing('space-200')};

  > * {
    flex: 1;
  }
`
