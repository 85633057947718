import React from 'react'
import EnableFor from '@components/enableFor'
import UserDetails from '@components/userDetails'
import { SCOPES } from '@root/constants'
import * as Style from '../style'
import { Tooltip, Button, ButtonType, ButtonSize } from '@toriihq/design-system'
import { Props } from './types'
import { primaryOwnerBadge } from '@components/userApps/primaryOwnerBadge'

const ToolTipComponent = () => {
  return (
    <span>
      <strong>Left the organization</strong>
    </span>
  )
}

const AppOwnerRow = ({ owner, onAssignPrimaryOwner, onRemoveOwner, isPrimary, isLoading, disableActions }: Props) => {
  return (
    <div {...Style.Body}>
      <div style={{ width: '90%' }}>
        <UserDetails
          tooltipText={owner.isDeletedInIdentitySources ? <ToolTipComponent /> : null}
          firstName={owner.firstName}
          lastName={owner.lastName}
          photoUrl={owner.photoUrl}
          email={owner.email}
          isExternal={owner.isExternal}
          idUser={owner.id}
          badge={isPrimary ? primaryOwnerBadge : null}
          isDeletedInIdentitySources={owner.isDeletedInIdentitySources}
          showPastUserBadge
        />
      </div>
      <div {...Style.OwnerButtons}>
        {!isPrimary && !disableActions ? (
          <Tooltip label={'Set as Primary'}>
            <div {...Style.EditButton}>
              <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE, SCOPES.APP_OWNER_WRITE]}>
                <Button type={ButtonType.tertiary} size={ButtonSize.small}
                  onClick={() => onAssignPrimaryOwner(owner)}
                  disabled={isLoading}
                  icon='Star'
                />
              </EnableFor>
            </div>
          </Tooltip>
        ) : null}
        <div {...Style.EditButton}>
          {
            !disableActions ? (
              <EnableFor scopes={[SCOPES.APPLICATIONS_WRITE, SCOPES.APP_OWNER_WRITE]}>
                <Button type={ButtonType.tertiary} size={ButtonSize.small}
                  disabled={isLoading}
                  onClick={() => onRemoveOwner(owner, isPrimary)}
                  icon='Trash'
                />
              </EnableFor>
            ) : null
          }
        </div>
      </div>
    </div>
  )
}

export default AppOwnerRow
