import texts from '@root/shared/style/texts'
import { css } from 'glamor'
import { fontWeight } from '@root/shared/style/sizes'
import styled from 'styled-components'

export const HeaderArea = css({
  display: 'flex',
  alignItems: 'center'
})

export const AppIcon = css({
  marginRight: '10px'
})

export const SubHeader = css(texts.subheading, {
})

export const MainArea = css({
  width: '100%',
  height: '80%',
  paddingBottom: '0'
})

export const EmptyState = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transform: 'translateY(52%)',
  marginRight: '151px'
})

export const TransactionMatchingRule = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  padding: 20px 32px;
  background-color: ${({ theme }) => theme.palette.background.primaryActive};
`

export const MarginTop = ({ margin }) => css({
  marginTop: margin
})

export const Bold = css({
  fontWeight: fontWeight.bold
})
