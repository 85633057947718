
import styled from 'styled-components'

export const FormInlineGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing('space-100')}; 

  & > *:first-child {
    flex-grow: 1;
    width: 0;
  }

  & > *:nth-child(2) {
    flex-shrink: 0;
    width: auto;
  }
`
