import React, { Component } from 'react'
import ToriiPopup from '../ToriiPopupV2'
import DetailHistoryTable from '../../detailHistoryTable'
import { TABLES } from '@root/constants'

const tableStyle = { minHeight: 300 }

class ContractDetailHistoryPopup extends Component {
  componentDidMount () {
    const { getContractDetailsHistoryValues, idOrg, idContract, detail, idApp } = this.props
    getContractDetailsHistoryValues({ idOrg, idContract, systemKey: detail.systemKey, idApp })
  }

  render () {
    const { isOpen, cancel, close, detail, data = [], currencySymbol, currencySymbolsByCode, usersMap, defaultSort, loading } = this.props
    return (
      <ToriiPopup isOpen={isOpen} onCloseAction={cancel} width='950px'>
        <ToriiPopup.Header header={`History for: ${detail.name}`} />
        <ToriiPopup.Content>
          <DetailHistoryTable
            tableKey={TABLES.appDetailHistoryPopupTable.key}
            data={data}
            detail={detail}
            usersMap={usersMap}
            loading={loading}
            defaultSort={defaultSort}
            close={close}
            tableStyle={tableStyle}
            currencySymbol={currencySymbol}
            currencySymbolsByCode={currencySymbolsByCode}
          />
        </ToriiPopup.Content>
      </ToriiPopup>
    )
  }
}

export default ContractDetailHistoryPopup
