import React, { ComponentType } from 'react'
import { Field } from 'react-final-form'
import Select from '@components/select'
import { formFieldTypes } from '@root/constants'
import { Button, ButtonSize, ButtonType, FormElement, Input } from '@toriihq/design-system'
import Filters from './components/filters'
import { FormInlineGroup } from './styles'
import { getValidFilters } from '@shared/filters'
import { blurInputWhenPressingEnter } from '@shared/utils'
import ColumnsSelection from '@components/columnsSelection'
import { getTableWidgetTableKey } from '@components/dashboard/widgets/shared'
import { useSelector } from 'react-redux'
import { getEntitiesMetadataByEntityType } from '@selectors/dashboards'

const renderSelectComponent = React.memo(({ onFieldChange, name, label, input, options, meta, ...props }:any) => {
  return (
    <FormElement label={label} errorMessage={meta.touched && meta.error}>
      <Select
        options={options}
        labelKey='label'
        valueKey='value'
        clearable={false}
        searchable
        name={name}
        {...input}
        {...props}
        onChange={(selection : { value: string }) => {
          input.onChange(selection.value)
          onFieldChange(input.name, selection.value)
        }}
      />
    </FormElement>
  )
})

const renderFiltersComponent = React.memo(({ onFieldChange, filtersOptions, fieldOptionsValuesPerKey, fetchFieldValues, label, input, meta }:any) => {
  return (
    <FormElement label={label} errorMessage={meta.touched && meta.error}>
      <Filters
        optionsKey={filtersOptions}
        optionsValuesPerKey={fieldOptionsValuesPerKey}
        filters={input.value}
        fetchFieldValues={fetchFieldValues}
        onFiltersChange={(filters) => {
          input.onChange(filters)
          onFieldChange(input.name, filters)
        }}
        onFiltersPopupClose={(filters) => {
          onFieldChange(input.name, getValidFilters(filters))
        }} />
    </FormElement>
  )
})

const renderInputComponent = React.memo(({ onFieldChange, label, input, meta }: any) => {
  return (
    <FormElement label={label} errorMessage={meta.touched && meta.error}>
      <Input
        {...input}
        onBlur={(e) => {
          input.onChange(e.target.value)
          onFieldChange(input.name, e.target.value)
        }}
      />
    </FormElement>
  )
})

const renderNumberInputComponent = React.memo(({ onFieldChange, label, input, meta, min, max, setDefaultIfInvalid }: any) => {
  return (
    <FormElement label={label} errorMessage={meta.touched && meta.error}>
      <Input
        {...input}
        onBlur={(e) => {
          const value = setDefaultIfInvalid(Number(e.target.value))
          input.onChange(value)
          onFieldChange(input.name, value)
        }}
        onKeyDown={blurInputWhenPressingEnter}
        type='number'
        min={min}
        max={max}
        placeholder='Enter number'
      />
    </FormElement>
  )
})

const renderColumnsSelectionComponent = React.memo(({ widget, onFieldChange, label, input, meta, min, max, setDefaultIfInvalid }: any) => {
  const entitiesMetadata = useSelector(getEntitiesMetadataByEntityType)
  const entityMetadata = entitiesMetadata[widget.entityType]
  const allColumns = entityMetadata.predefinedFields
    .concat(entityMetadata.customFields.filter(field => field.isShown))
  const configurableColumnsOptions = allColumns.map(column => ({
    label: column.name,
    value: column.systemKey
  }))

  return (
    <FormElement label={label} errorMessage={meta.touched && meta.error}>
      <ColumnsSelection
        onChange={(selectedColumns) => {
          onFieldChange(input.name, selectedColumns)
        }}
        tableKey={getTableWidgetTableKey(widget)}
        options={configurableColumnsOptions}
        supportViews={false}
        disabled={input.disabled}
      />
    </FormElement>
  )
})

const renderButtonWithIconComponent = React.memo(({ onFieldChange, label, input, options, meta }: any) => {
  return (
    <FormElement label={label} errorMessage={meta.touched && meta.error}>
      <Button
        size={ButtonSize.small}
        type={ButtonType.secondary}
        icon={options.find(option => option.value === input.value)?.icon}
        onClick={() => {
          const shouldToggleValue = options.length === 2
          if (shouldToggleValue) {
            const newValue = input.value === options[0].value ? options[1].value : options[0].value
            input.onChange(newValue)
            onFieldChange(input.name, newValue)
          }
        }}
      />
    </FormElement>
  )
}
)

const renderInlineGroupComponent = React.memo(({ widget, fields, label, filtersOptions, fieldOptionsValuesPerKey, fetchFieldValues, onFieldChange }: any) => (
  <FormElement label={label}>
    <FormInlineGroup>
      {fields.map((field) => {
        return renderField({ widget, field, filtersOptions, fieldOptionsValuesPerKey, fetchFieldValues, onFieldChange })
      })}
    </FormInlineGroup>
  </FormElement>
))

const formFieldTypeToConfigMap: Record<string, { component: ComponentType<any> }> = {
  [formFieldTypes.singleLine]: {
    component: renderInputComponent
  },
  [formFieldTypes.dropdown]: {
    component: renderSelectComponent
  },
  [formFieldTypes.filters]: {
    component: renderFiltersComponent
  },
  [formFieldTypes.buttonIcon]: {
    component: renderButtonWithIconComponent
  },
  [formFieldTypes.inlineGroup]: {
    component: renderInlineGroupComponent
  },
  [formFieldTypes.number]: {
    component: renderNumberInputComponent
  },
  [formFieldTypes.columnsSelection]: {
    component: renderColumnsSelectionComponent
  }
}

export const renderField = ({ widget, field, filtersOptions, fieldOptionsValuesPerKey, fetchFieldValues, onFieldChange }) => {
  const isNumberInputField = field.type === formFieldTypes.number

  return <Field
    name={field.key}
    label={field.label}
    component={formFieldTypeToConfigMap[field.type].component}
    options={field.options}
    filtersOptions={filtersOptions}
    fieldOptionsValuesPerKey={fieldOptionsValuesPerKey}
    fetchFieldValues={fetchFieldValues}
    onFieldChange={onFieldChange}
    isRequired
    disabled={field.disabled}
    fields={field.fields}
    widget={widget}
    {...(isNumberInputField && {
      min: field.min,
      max: field.max,
      setDefaultIfInvalid: field.setDefaultIfInvalid
    })}
  />
}
