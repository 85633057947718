import { createSelector } from 'reselect'
import get from 'lodash/get'
import maxBy from 'lodash/maxBy'
import identity from 'lodash/identity'
import moment from 'moment'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import range from 'lodash/range'
import sumBy from 'lodash/sumBy'
import pluralize from 'pluralize'
import keyBy from 'lodash/keyBy'
import omitBy from 'lodash/omitBy'
import { fieldTypes, TABLES } from '@root/constants'
import map from 'lodash/map'
import { categoryColors } from '@shared/style/colors'
import { getFilterOptionsValuesPerKey } from '@lenses/filters'
import { getUserPreferences } from './ui'
import { getTableInfo } from '@lenses/table'
import sortBy from 'lodash/sortBy'
import { getContractCurrencyConvertedValue } from '@components/contracts/utils'
import pick from 'lodash/pick'
import uniqBy from 'lodash/uniqBy'

export const isLoadingContracts = createSelector(
  state => get(state, ['contracts', 'contracts', 'loading'], false),
  identity
)

export const isLoadingUnmatchedContracts = createSelector(
  state => get(state, ['contracts', 'unmatchedContracts', 'loading'], false),
  identity
)

export const isLoadingSingleContract = createSelector(
  state => get(state, ['contracts', 'contracts', 'loadingSingleContract'], false),
  identity
)

export const isLoadingAppContracts = createSelector(
  state => get(state, ['contracts', 'contracts', 'loadingAppContract'], false),
  identity
)

export const getUnmatchedContracts = createSelector(
  state => get(state, ['contracts', 'unmatchedContracts', 'contracts'], []),
  identity
)

export const getUnmatchedContractsCount = createSelector(
  [getUnmatchedContracts],
  contracts => contracts.length
)

export const getContractsDoneAppMatching = createSelector(
  state => get(state, ['contracts', 'contracts', 'isAppMatchingDone'], false),
  identity
)

export const getContractsFields = createSelector(
  state => get(state, ['contracts', 'fields', 'fields'], []),
  identity
)

export const getContractsResources = createSelector(
  state => get(state, ['contracts', 'contracts', 'resources'], {}),
  identity
)

export const getContracts = createSelector(
  [state => get(state, ['contracts', 'contracts', 'contracts'], []), getContractsResources, getContractsFields],
  (contracts, resources, fields) => contracts.map(contract => {
    const multipleUsersFieldsKeys = fields.filter(field => field.type === 'usersDropdownMulti').map(field => field.systemKey)
    const contractMultipleUsersFields = pick(contract, multipleUsersFieldsKeys)

    for (const key in contractMultipleUsersFields) {
      contractMultipleUsersFields[key] = uniqBy(contractMultipleUsersFields[key]?.map(id => resources.users[id]).filter(Boolean), 'email').map(user => user.id)
    }

    return {
      ...contract,
      ...contractMultipleUsersFields
    }
  })
)

export const getContractsById = createSelector(
  [getContracts],
  contracts => keyBy(contracts, 'id')
)

export const getUnmatchedContractsById = createSelector(
  [getUnmatchedContracts],
  contracts => keyBy(contracts, 'id')
)

export const getAllContractsById = createSelector(
  [getContractsById, getUnmatchedContractsById],
  (contracts, unmatchedContracts) => {
    return { ...contracts, ...unmatchedContracts }
  }
)

export const getContractsByIdApp = createSelector(
  [getContracts],
  contracts => groupBy(contracts, 'idApp')
)

export const getUnmatchedContractsResources = createSelector(
  state => get(state, ['contracts', 'unmatchedContracts', 'resources'], {}),
  identity
)

export const getAllContractsResources = createSelector(
  [getContractsResources, getUnmatchedContractsResources],
  (contractsResources, unmatchedContractsResources) => {
    const { apps, users, contracts } = unmatchedContractsResources
    return {
      apps: keyBy(mergeById(Object.values(contractsResources.apps), Object.values(apps)), 'id'),
      users: keyBy(mergeById(Object.values(contractsResources.users), Object.values(users)), 'id'),
      contracts: keyBy(mergeById(Object.values(contractsResources.contracts || {}), Object.values(contracts)), 'id')
    }
  }
)

export const getContractsWithRenewalDate = createSelector(
  [getContracts],
  contracts => {
    const now = moment().utc().startOf('day')
    const maxYearToSupportInCalendar = now.year() + 10
    const renewals = contracts
      .filter(renewal => renewal.endDate && renewal.status !== 'closed' && (moment(renewal.endDate).year() <= maxYearToSupportInCalendar))
      .map(contract => {
        const date = moment.utc(contract.endDate).startOf('day')
        const key = `${date.year()}_${date.month() + 1}`
        const isPassed = date.isBefore(now)
        return { ...contract, date, key, isPassed }
      })

    const maxRenewalDate = (maxBy(renewals, renewal => renewal.date) || {}).date
    return {
      renewals,
      maxRenewalDate
    }
  }
)

export const getRenewalsMonthlyStats = createSelector(
  [getContractsWithRenewalDate, getContractsResources],
  ({ renewals }, { apps: appsById }) => {
    const renewalsByMonth = groupBy(renewals, 'key')

    return range(12).map(i => {
      const currentMonth = moment.utc().add(i, 'month').startOf('month')
      const year = currentMonth.year()
      const month = currentMonth.month() + 1
      const renewalsInMonth = renewalsByMonth[`${year}_${month}`]
      if (renewalsInMonth) {
        const numberOfRenewals = renewalsInMonth.filter(renewal => getContractCurrencyConvertedValue(renewal.amount)).length
        const totalAmountForMonth = sumBy(renewalsInMonth, app => parseInt(getContractCurrencyConvertedValue(app.amount)) || 0)
        const { categories, categoriesCount } = renewalsInMonth.reduce(({ categories, categoriesCount }, renewal) => {
          const app = appsById[renewal.idApp]
          if (app && getContractCurrencyConvertedValue(renewal.amount)) {
            categories[app.category] = (categories[app.category] || 0) + parseInt(getContractCurrencyConvertedValue(renewal.amount))
            categoriesCount[app.category] = (categoriesCount[app.category] || 0) + 1
          }
          return { categories, categoriesCount }
        }, { categories: {}, categoriesCount: {} })

        return {
          value: totalAmountForMonth,
          count: numberOfRenewals,
          tooltipSubHeader: `${numberOfRenewals} ${pluralize('contract', numberOfRenewals)}`,
          name: `${currentMonth.format('MMM YY')}`,
          date: currentMonth.toISOString(),
          month,
          year,
          categories,
          categoriesCount
        }
      }

      return {
        value: 0,
        name: currentMonth.format('MMM YY'),
        date: currentMonth.toISOString(),
        month: currentMonth.month(),
        year: currentMonth.year()
      }
    })
  }
)

export const getYearlyRenewalsByAppsCategory = createSelector(
  [getContractsWithRenewalDate, getContractsResources],
  ({ renewals }, { apps: appsById }) => {
    const startOfThisMonth = moment().utc().startOf('month')
    const endOf12Months = moment().utc().add(11, 'months').endOf('month')
    const thisYearRenewals = renewals.filter(renewal => renewal.date >= startOfThisMonth && renewal.date <= endOf12Months)

    const renewalsByCategory = thisYearRenewals.reduce((acc, renewal) => {
      const app = appsById[renewal.idApp]
      const contractCurrencyConvertedValue = getContractCurrencyConvertedValue(renewal.amount)
      if (app && contractCurrencyConvertedValue) {
        const appCategory = acc.find(appCategory => appCategory.name === app.category)
        if (appCategory) {
          appCategory.value += parseInt(contractCurrencyConvertedValue)
          appCategory.count++
        } else {
          acc.push({ name: app.category, value: parseInt(contractCurrencyConvertedValue), count: 1 })
        }
      }
      return acc
    }, [])
    return renewalsByCategory
  }
)

export const getYearlyGetCategoriesForPie = createSelector(
  [getYearlyRenewalsByAppsCategory],
  (yearlyRenewalsByCategory) => {
    const numberOfCategoriesToShow = 7
    let yearlyRenewalsByCategoryWithColors
    if (yearlyRenewalsByCategory.length <= numberOfCategoriesToShow) {
      yearlyRenewalsByCategoryWithColors = yearlyRenewalsByCategory.map(entry => ({ ...entry, color: categoryColors[entry.name] }))
    } else {
      const top = yearlyRenewalsByCategory.slice(0, (numberOfCategoriesToShow - 1))
      const rest = yearlyRenewalsByCategory.slice(numberOfCategoriesToShow - 1)
      const other = {
        name: 'Other',
        value: sumBy(rest, category => category.value),
        count: rest.length
      }

      yearlyRenewalsByCategoryWithColors = [...top, other].filter(entry => (entry.value > 0)).map(entry => ({ ...entry, color: categoryColors[entry.name] }))
    }
    return yearlyRenewalsByCategoryWithColors.map(renewal => ({ ...renewal, tooltipHeader: `${renewal.name} (${renewal.count} ${pluralize('app', renewal.count)})` }))
  }
)

export const isLoadingContractsFields = createSelector(
  state => get(state, ['contracts', 'fields', 'loading'], false),
  identity
)

const mandatoryFields = ['status', 'name']
const unEditableFieldTypes = [fieldTypes.fileUpload, fieldTypes.contractsDropdownMulti]
export const getEditableContractDetailsFields = createSelector(
  [getContractsFields],
  (contractDetails = []) => contractDetails
    .filter(detail => !unEditableFieldTypes.includes(detail.type))
    .filter(detail => get(detail, 'isReadonly') === 0)
    .map(detail => mandatoryFields.includes(detail.systemKey) ? { ...detail, disableDelete: true } : detail)
)

export const getContractsFieldsBySystemKey = createSelector(
  [getContractsFields],
  contractsFields => keyBy(contractsFields, 'systemKey')
)

export const getContractsFieldsById = createSelector(
  [getContractsFields],
  contractsFields => keyBy(contractsFields, 'id')
)

export const getContractStatusOptions = createSelector(
  getContractsFieldsBySystemKey,
  (contractsFieldsBySystemKey) => get(contractsFieldsBySystemKey, ['status', 'options'], [])
)

export const getContractStatusOptionsOrder = createSelector(
  getContractStatusOptions,
  (statusOptions) => {
    const order = {}
    statusOptions.forEach((option, index) => {
      order[option.value] = index + 1
    })
    return order
  }
)

export const getContractsConfigurableColumnsOptions = createSelector(
  [getContractsFields],
  (dynamicFields) => {
    const dynamicOptions = dynamicFields
      .filter(field => field.isShown)
      .map(field => ({ value: field.systemKey, label: field.name }))
    const { preDefinedColumnsMapping } = TABLES.contractsTable
    const preDefinedOptions = map(preDefinedColumnsMapping, (value, key) => {
      return { value: key, label: value }
    })

    return preDefinedOptions.concat(dynamicOptions)
  }
)

export const getContractLineItemsConfigurableColumnsOptions = createSelector(
  state => {
    const { preDefinedColumnsMapping } = TABLES.contractLineItemsTable
    return map(preDefinedColumnsMapping, (value, key) => {
      return { value: key, label: value }
    })
  })

export const getContractsGroups = createSelector(
  state => get(state, ['contracts', 'groups', 'groups'], []),
  groups => sortBy(groups, 'position')
)

export const getContractsGroupsForSelectGroup = createSelector(
  state => get(state, ['contracts', 'groups', 'groups'], []),
  groups => {
    const groupsForSelect = groups.map(group => {
      return {
        label: group.label.toUpperCase(),
        value: group.id
      }
    })
    return orderBy(groupsForSelect, [group => group.label.toLowerCase()], ['asc'])
  }
)

export const isLoadingContractsGroups = createSelector(
  state => get(state, ['contracts', 'groups', 'loading'], false),
  identity
)

export const getContractDetailsFieldsHistory = createSelector(
  state => get(state, ['contracts', 'fieldsHistory'], {}),
  identity
)

export const isContractDetailsFieldsHistoryLoading = createSelector(
  state => get(state, ['contracts', 'loadingFieldsHistory']),
  identity
)

const CONTRACTS_TABLES_WITH_INFO = [TABLES.contractsTable, TABLES.appContractsTable].map(table => table.key)
export const getContractsTablesInfo = createSelector(
  [getUserPreferences, getContractsFields],
  (userPreferences, dynamicColumns) => {
    const info = {}
    CONTRACTS_TABLES_WITH_INFO.forEach(tableKey => {
      const tableUserPreferences = get(userPreferences, tableKey, {})
      const { columnsConfiguration = [], filters, defaultSort } = tableUserPreferences
      info[tableKey] = getTableInfo(tableKey, columnsConfiguration, filters, dynamicColumns, defaultSort)
    })

    return info
  }
)

export const getUnmatchedContractsTablesInfo = createSelector(
  [getUserPreferences, getContractsFields],
  (userPreferences, dynamicColumns) => {
    const tableKey = TABLES.unmatchedContractsTable.key
    const tableUserPreferences = get(userPreferences, tableKey, {})
    const { columnsConfiguration = [], filters, defaultSort } = tableUserPreferences
    return getTableInfo(tableKey, columnsConfiguration, filters, dynamicColumns, defaultSort)
  }
)

export const getContractFilterOptionsValuesPerKey = createSelector(
  [getContracts, getContractsTablesInfo, getContractsResources, getContractStatusOptions],
  (renewals, tableInfo, resources, contractStatusOptions) => {
    return {
      ...getFilterOptionsValuesPerKey(renewals, tableInfo[TABLES.contractsTable.key], resources.users),
      status: contractStatusOptions
    }
  }
)

export const getConfigurableContractDetailsFields = createSelector(
  getContractsFields,
  (contractDetailsFields) => contractDetailsFields
    .filter(detail => detail.systemKey === 'status' || detail.category !== 'general')
)

export const getConfigurableContractDetailsFieldsByGroup = createSelector(
  getConfigurableContractDetailsFields,
  fields => groupBy(fields, 'idGroup')
)

const mergeById = (newArray = [], oldArray = []) => {
  const allValues = newArray.concat(oldArray)
  return uniqBy(allValues, 'id')
}

export const getRenewalsSummary = createSelector(
  state => get(state, ['contracts', 'renewalsSummary', 'data'], {}),
  identity
)

export const getHistoricalRenewalsSummary = createSelector(
  getRenewalsSummary,
  renewalsSummary => omitBy(renewalsSummary, (value, year) => parseInt(year) > moment().year())
)

export const isLoadingRenewalsSummary = createSelector(
  state => get(state, ['contracts', 'renewalsSummary', 'loading'], false),
  identity
)

export const getContractAttachments = createSelector(
  state => get(state, ['contracts', 'contractAttachments']),
  identity
)
