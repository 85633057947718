import React from 'react'
import { Container, Header, SubHeader, RightContent } from './style'

interface HoverableHeaderProps {
  header: string;
  subHeader: string;
  hoverClass?: string;
  overrideStyle?: any;
  overrideHeaderStyle?: any;
  overrideSubHeaderStyle?: any;
  rightContent?: React.ReactNode;
  overrideRightContentStyle?: any;
  forceHoverStyle?: boolean;
}

const HoverableHeader = ({
  header,
  subHeader,
  overrideStyle,
  hoverClass = 'headerContainer',
  overrideHeaderStyle,
  overrideSubHeaderStyle,
  rightContent,
  overrideRightContentStyle,
  forceHoverStyle
}: HoverableHeaderProps) => {
  return (
    <Container className='headerContainer' overrideStyle={overrideStyle}>
      <div>
        <Header
          hoverClass={hoverClass}
          forceHoverStyle={forceHoverStyle}
          overrideHeaderStyle={overrideHeaderStyle}
        >
          {header}
        </Header>
        <SubHeader
          hoverClass={hoverClass}
          forceHoverStyle={forceHoverStyle}
          overrideSubHeaderStyle={overrideSubHeaderStyle}
        >
          {subHeader}
        </SubHeader>
      </div>
      {rightContent && (
        <RightContent
          hoverClass={hoverClass}
          overrideRightContentStyle={overrideRightContentStyle}
        >
          {rightContent}
        </RightContent>
      )}
    </Container>
  )
}

export default HoverableHeader
