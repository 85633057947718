import View from './view'
import { connect } from 'react-redux'
import {
  deprecatedGetAppsByIds,
  getAppComplianceByIdApp,
  getAppDetailsFieldWithValue,
  isLoadedAppCompliance
} from '@selectors/apps'
import isEmpty from 'lodash/isEmpty'
import { getAppCompliance, setAppsDetails } from '@actions/'
import AccessControl from '@lenses/accessControl'
import { FEATURES } from '@shared/features'
import { getSelf } from '@selectors/me'

const mapStateToProps = (state, ownProps) => {
  const { idApp, idOrg } = ownProps
  const app = deprecatedGetAppsByIds(state)[idApp] || {}

  const { similarApps: similarAppsFromStore = {} } = state.similarApps || {}
  const similarApps = (similarAppsFromStore[idApp] || [])
  const category = getAppDetailsFieldWithValue(state, { systemKey: 'category', idApp })
  const isLoadedAppComplianceData = isLoadedAppCompliance(state)
  const appComplianceData = getAppComplianceByIdApp(state, idApp)
  const loggedInUser = getSelf(state)

  const isAppComplianceEnabledInPlan = AccessControl.isFeatureEnabledInPlan({ feature: FEATURES.APPLICATION.COMPONENTS.APP_COMPLIANCE, state })

  return {
    app,
    loggedInUser,
    idOrg,
    similarApps,
    loading: isEmpty(app) || !isLoadedAppComplianceData,
    appComplianceData,
    isAppComplianceEnabledInPlan,
    category
  }
}

const mapDispatchToProps = {
  getAppCompliance,
  setAppsDetails
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
