import React, { ReactElement } from 'react'
import Select from '@components/select'
import FormGroup from '@components/form/formGroup'
import { YearSelectorProps } from './types'
import { renewalsSummaryBaseYearChange } from '@actions/ui'
import Analytics from '@components/renewalsSummary/analytics'
import { useDispatch } from 'react-redux'

const YearSelector = ({ renewalsSummary, baseYear, onYearChange, selectWidth = 225 }: YearSelectorProps): ReactElement => {
  const dispatch = useDispatch()

  const handleChange = (year: any) => {
    dispatch(renewalsSummaryBaseYearChange({ baseYear: year }))
    Analytics.selectReportYear(year.value)
    onYearChange(year)
  }

  return (
    <FormGroup
      label='Show report for year'
    >
      <Select
        options={Object.keys(renewalsSummary).map(year => ({ value: Number(year), label: year })).reverse()}
        placeholder={<div>Select year</div>}
        value={baseYear}
        onChange={handleChange}
        clearable={false}
        searchable={false}
        key='renewals_summary_base_year_select'
        name='renewals_summary_base_year_select'
        openOnFocus
        selectWidth={selectWidth}
      />
    </FormGroup>
  )
}

export default YearSelector
