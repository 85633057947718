import { useState, useCallback, type ReactElement } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { getIdOrg, getCompanyName } from '@selectors/org'
import { copyAppData, changeAppHiddenState } from '@actions/'
import { togglePopupCopyAppData } from '@store/actions/popups'
import { getCopyAppDataPopup } from '@selectors/ui'
import { ContentAreaStyle } from './styles'
import { toast, ToastType, Icon, Link, Stack, Body2, Toggle, FormElement } from '@toriihq/design-system'
import {
  trackLearnMoreClick,
  trackModalButtonClick
} from './analytics'
import { ReviewDetails } from './components/reviewDetails'
import SelectApps from '@components/selectApps'
import type { PossibleDuplicateApp } from '@root/store/actions/popups/types'

const CopyAppDataPopup = (): ReactElement => {
  const [hideCustomApp, setHideCustomApp] = useState<boolean>(true)

  const idOrg = useSelector(getIdOrg)
  const companyName = useSelector(getCompanyName)

  const copyAppDataPopup = useSelector(getCopyAppDataPopup)
  const { isOpen, idApp, appName, possibleDuplicateApp } = copyAppDataPopup || {}
  const dispatch = useDispatch()

  const [selectedApp, setSelectedApp] = useState<PossibleDuplicateApp | null>(
    possibleDuplicateApp ? {
      id: possibleDuplicateApp.id,
      name: possibleDuplicateApp.name,
      imageUrl: possibleDuplicateApp.imageUrl,
      category: possibleDuplicateApp.category
    } : null
  )

  const handleHideAppClick = () => {
    setHideCustomApp(prev => !prev)
  }

  const handleLearnMoreClick = () => {
    trackLearnMoreClick({
      appName,
      companyName
    })
  }

  const onClose = useCallback(() => {
    dispatch(togglePopupCopyAppData({ isOpen: false } as any))
  }, [dispatch])

  const handleModalButtonClick = (buttonLabel: string) => {
    trackModalButtonClick({
      appName,
      companyName,
      buttonLabel,
      targetAppName: selectedApp?.name
    })
  }

  const handleAppSelect = (option: PossibleDuplicateApp) => {
    if (!option) return

    setSelectedApp({
      id: option.id,
      name: option.name,
      imageUrl: option.imageUrl || '',
      category: option.category
    })
  }

  const handleCopyAppData = useCallback(async (): Promise<void> => {
    if (!selectedApp) return

    try {
      await Promise.all([
        dispatch(copyAppData({ idOrg, targetIdApp: selectedApp.id, sourceIdApp: idApp, hideCustomApp })),
        hideCustomApp && dispatch(changeAppHiddenState({ idOrg, idApp, isHidden: true }))
      ])

      toast({
        message: 'App data is now being copied. The\n changes will take effect soon.',
        type: ToastType.SUCCESS,
        position: 'bottom-right'
      })

      onClose()
    } catch (error) {
      console.error(error)
    }
  }, [selectedApp, idOrg, idApp, hideCustomApp, dispatch, onClose])

  const filterNativeAppsOnly = (apps) => {
    return apps.filter(app => !app.isCustom)
  }

  return (
    <ToriiPopup
      isOpen={isOpen}
      onCloseAction={onClose}
      width='690px'
    >
      <ToriiPopup.Header header='Copy app data' />

      <ToriiPopup.Content contentAreaStyle={ContentAreaStyle}>
        <Stack gap='space-200'>
          <ReviewDetails />
          <FormElement label='Copy to'>
            <SelectApps
              onChange={handleAppSelect}
              placeholder='Select a native app'
              value={selectedApp}
              searchable
              transformApps={filterNativeAppsOnly}
            />
          </FormElement>
          <Stack direction='row' gap='space-150'>
            <Body2>Also hide {appName} after copying the data</Body2>
            <Toggle
              checked={hideCustomApp}
              onChange={handleHideAppClick}
            />
          </Stack>

          <Stack direction='row' gap='space-100'>
            <Icon name='Info' color='secondary' />
            <Link
              onClick={handleLearnMoreClick}
              href='https://support.toriihq.com/hc/en-us/articles/34398645540251-Consolidate-Your-SaaS-Stack-Dealing-with-duplicate-applications'
              target='_blank'>
              Learn more
            </Link>
            <span>
              about handling duplicate apps and how to fully complete app data transfer.
            </span>
          </Stack>
        </Stack>
      </ToriiPopup.Content>
      <ToriiPopup.Footer
        showMainButton
        mainButtonText='Copy app data'
        mainButtonAction={() => {
          handleModalButtonClick('copy app data')
          handleCopyAppData()
        }}
        isMainButtonDisabled={!selectedApp}
        showCancelButton
        cancelButtonText='Cancel'
        cancelButtonAction={() => {
          handleModalButtonClick('cancel')
          onClose()
        }}
      />
    </ToriiPopup>
  )
}

export default CopyAppDataPopup
