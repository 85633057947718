import React, { ReactElement, useState, useEffect } from 'react'
import Table from '@components/table'
import { useDispatch, useSelector } from 'react-redux'
import { Column, getColumns } from './columns'
import { Button, ButtonType, EmptyState } from '@toriihq/design-system'
import { toggleLaunchAccessReviewsPopup } from '@store/actions'
import { getAccessReviews as getAccessReviewsAction } from '@store/actions/accessReviews'
import NoAccessReviews from '@media/no-accessReview.svg'
import { getAccessReviews, getAccessReviewsResources, getIsLoadingAccessReviews } from '@selectors/accessReviews'
import { EmptyStateContainer, EmptyStateDescription } from './styles'
import { getCurrentOrg } from '@selectors/org'
import { TABLES } from '@root/constants'

const AccessReviewsTable = (): ReactElement => {
  const { id: idOrg } = useSelector(getCurrentOrg)
  const dispatch = useDispatch()
  const [columns, setColumns] = useState<Column[]>([])
  const resources = useSelector(getAccessReviewsResources)
  const accessReviews = useSelector(getAccessReviews)
  const loading = useSelector(getIsLoadingAccessReviews)

  useEffect(() => {
    dispatch(getAccessReviewsAction({ idOrg }))
  }, [idOrg, dispatch])

  useEffect(() => {
    if (idOrg && resources.users) {
      setColumns(getColumns({ idOrg, usersById: resources.users, appsById: resources.apps }))
    }
  }, [idOrg, resources.users, resources.apps])

  const launchAccessReviewsOnClick = async () => {
    await dispatch(toggleLaunchAccessReviewsPopup(true))
  }

  const launchAccessReviewsButton = <Button type={ButtonType.primary} onClick={launchAccessReviewsOnClick} label='Launch access review' />

  const renderEmptyState = () => {
    return <EmptyStateContainer>
      <EmptyState
        image={<img src={NoAccessReviews} alt='No access reviews' />}
        title='No access reviews yet'
        description={<EmptyStateDescription>
          Ensure user access to applications is accurate, appropriate, and aligned with security and<br />compliance policies.
        </EmptyStateDescription>
        }
        buttons={[
          launchAccessReviewsButton
        ]}
      />
    </EmptyStateContainer>
  }

  const searchFilterMethod = (row, search) => {
    const appsById = resources.apps
    const app = appsById[row.idApp]

    const values = [
      row.name,
      app?.name
    ]

    return values.some(value => value && value.toString().toLowerCase().includes(search))
  }

  return (
    <Table
      tableKey={TABLES.accessReviewsTables.key}
      data={accessReviews}
      columns={columns}
      forceShowNumberOfResults
      searchable
      searchFilterMethod={searchFilterMethod}
      loading={loading && accessReviews.length === 0}
      emptyStateMessage={renderEmptyState()}
      extraHeaderComponent={accessReviews.length > 0 && launchAccessReviewsButton}
      defaultSorted={[{ id: 'creationTime', desc: true }]}
    />
  )
}

export default AccessReviewsTable
