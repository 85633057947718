import { ReactElement, useState } from 'react'
import { getCurrentUsersFieldValues } from '@store/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getIdOrg } from '@selectors/org'
import { SCOPES } from '@root/constants'
import AccessControl from '@lenses/accessControl'
import { getColumns as getUserColumns } from '@components/usersTabs/usersV2/view'
import { getCurrentUsersFieldValues as getCurrentUsersFieldValuesSelector } from '@selectors/users'
import { isFieldSupportAutocompleteValues } from '@shared/fieldUtils'
import { mapSortArray } from '@shared/utils'
import config from '@root/config'
import { DynamicWidgetInternalDataPopupProps } from '../types'
import { useGetEntityTableData } from '../shared.hooks'
import useDeepCompareEffect from 'use-deep-compare-effect'

const UserDataPopup = (props: DynamicWidgetInternalDataPopupProps): ReactElement => {
  const dispatch = useDispatch()
  const { renderFunc, fields = [], widget } = props

  const { columnsConfiguration, preDefinedColumnsMapping, configurableColumnsOptions, filtersOptions, customFields } = useGetEntityTableData({ entityType: widget.entityType, fields })

  const idOrg = useSelector(getIdOrg)
  const hasLicenseCostAndChargebackAccess = useSelector(state => AccessControl.isAccessibleWithState({ scopes: [SCOPES.LICENSE_AND_CHARGEBACK_READ], state }))
  const columnsRestrictions = { hasLicenseCostAndChargebackAccess }

  const [columns, setColumns] = useState([])

  const fieldValues = useSelector(getCurrentUsersFieldValuesSelector)
  const fetchFieldValues = (field) => {
    const options = filtersOptions

    const isFieldSupportValues = isFieldSupportAutocompleteValues({
      fieldOptions: options,
      fieldOption: field
    })
    if (isFieldSupportValues) {
      dispatch(getCurrentUsersFieldValues({ idOrg, fields: [field] }))
    }
  }

  const dynamicColumnsInfo = customFields
    .filter(detail => columnsConfiguration[detail.systemKey] && !preDefinedColumnsMapping[detail.systemKey])

  useDeepCompareEffect(() => {
    setColumns(getUserColumns({
      preDefinedColumnsMapping,
      dynamicColumnsInfo,
      columnsConfiguration,
      columnsRestrictions,
      fieldIdentifier: 'systemKey'
    }) as any
    )
  }, [ preDefinedColumnsMapping, dynamicColumnsInfo, columnsConfiguration, columnsRestrictions ])

  const exportToCsv = ({ sort, query, filters }) => {
    const columnsOrder = fields
    const columnsOrdered = columns.sort((a: any, b:any) => columnsOrder.indexOf(a.id || a.accessor) - columnsOrder.indexOf(b.id || b.accessor))
    const csvFields = columnsOrdered.filter((col: any) => ((col.show === undefined || col.show) && !col.hideFromCSV)).map((col: any) => col.id || col.accessor).flatMap(field => field === 'fullName' ? ['firstName', 'lastName', 'email'] : field)

    const sortParam = 'sort=' + mapSortArray(sort).join(',')
    const queryParam = `q=${query}`
    const fieldsParam = `fields=${csvFields.join(',')}`
    const filtersParam = `filters=${encodeURIComponent(JSON.stringify([...filters, { 'key': 'idParentUser', 'op': 'isNotSet' }]))}`

    const url = `${config.apiBaseUrl}/api/orgs/${idOrg}/users/csv?${sortParam}&${queryParam}&${fieldsParam}&${filtersParam}`
    const newWindow = window.open(url, '_blank') as any
    newWindow.opener = null
  }

  return renderFunc({ columns, filtersOptions, fieldValues, configurableColumnsOptions, fetchFieldValues, exportToCsv })
}

export default UserDataPopup
