import React from 'react'
import { Button, ButtonSize, ButtonType, IconName } from '@toriihq/design-system'
import KeyOpValueFilter from '@components/filters/keyOpValueFilter'
import { KeyOption, ValueOption } from '@components/filters/keyOpValueFilter/types'
import { Filter } from '@shared/filters/types'
import * as Style from './style'

interface AdvancedFiltersRowProps {
  filter: Filter
  optionsKey: KeyOption[]
  optionsValues: ValueOption[]
  disabled?: boolean
  onRemove?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onChange: (filter: Filter) => void
  fieldsAutoFocus?: boolean
}

const AdvancedFiltersRow = ({
  filter = { id: 'default' },
  optionsKey = [],
  optionsValues,
  onChange,
  disabled,
  onRemove,
  fieldsAutoFocus = true
}: AdvancedFiltersRowProps) => {
  return (
    <Style.Container>
      <Style.FiltersContainer>
        <KeyOpValueFilter
          filter={filter}
          optionsKey={optionsKey}
          optionsValues={optionsValues}
          onChange={onChange}
          disabled={disabled}
          fieldsAutoFocus={fieldsAutoFocus}
          styles={Style.KeyOpValueFilterStyles}
          layout='horizontal'
        />
      </Style.FiltersContainer>
      {onRemove && (
        <Style.DeleteButtonContainer>
          <Button
            type={ButtonType.compact}
            size={ButtonSize.small}
            icon={'Trash' as IconName}
            onClick={onRemove}
            disabled={disabled}
          />
        </Style.DeleteButtonContainer>
      )}
    </Style.Container>
  )
}

export default AdvancedFiltersRow
