import { css } from 'glamor'
import colors from '@shared/style/colors'
import texts from '@shared/style/texts'
import * as SharedStyle from '../shared/style'

/* ----- UsersOverlap ----- */
export const { EmptyStateContainer, MissingDataText } = SharedStyle

export const Container = css({
  minHeight: 425
})

export const Title = css(texts.headers.small, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  textTransform: 'uppercase'
})

export const TabsContainer = css({
  width: '260px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
})

export const Tab = css({
  minHeight: '48px',
  borderRadius: 0,
  boxShadow: 'none !important',
  border: 'unset',
  paddingBottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '4px',
  ':hover': {
    border: 'unset',
    boxShadow: 'none !important',
    backgroundColor: colors.transparent
  }
})

export const CurrentTab = css({
  borderBottom: `3px solid ${colors.blue}`,
  color: colors.black,
  ':hover': {
    borderBottom: `3px solid ${colors.blue}`
  }
})

export const TitleSeparator = css({
  margin: '0 0 5px'
})

export const Wrapper = css({
  display: 'flex'
})

export const FlexColumnWrapper = css(Wrapper, {
  flexDirection: 'column'
})

/* ----- OverlapLicenseTable ----- */
export const NoOverlapTableWrapper = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 'calc(100% - 400px)',
  height: 350,
  border: `1px solid ${colors.lightBlue2}`,
  overflow: 'auto'
})

export const NoOverlapTableMainTitle = css(texts.heading, { color: colors.text, marginBottom: 20 })

export const OverlapsTableWrapper = (overlapsCount) => css({
  height: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gridTemplateRows: `repeat(${overlapsCount}, 110px)`,
  width: 'calc(100% - 400px)',
  borderRadius: '4px',
  border: `1px solid ${colors.lightBlue2}`
})

export const OverlapsTableAppsColumnWrapper = (addBackgroundColor) => css({
  display: 'grid',
  gridTemplateRows: 'repeat(2, 1fr)',
  padding: '20px',
  borderBottom: `1px solid ${colors.lightBlue2}`,
  background: addBackgroundColor ? colors.lightBlue5 : 'transparent',
  gridColumn: 1
})

export const AppIcon = css({
  border: `1px solid ${colors.border}`,
  borderRadius: '2px',
  transition: 'border-color .2s',
  ':hover': {
    borderColor: colors.blue
  }
})

export const DescriptionRowWrapper = css({
  display: 'flex',
  alignSelf: 'flex-end',
  alignItems: 'center',
  lineHeight: '32px'
})

export const buttonWrapper = css({
  marginRight: '2px'
})

export const ViewUsersColumnWrapper = (addBackgroundColor) => css({
  display: 'flex',
  alignItems: 'center',
  borderBottom: `1px solid ${colors.lightBlue2}`,
  borderLeft: `1px solid ${colors.lightBlue2}`,
  background: addBackgroundColor ? colors.lightBlue5 : 'transparent'
})

/* ----- UsersOverlapsPopup ----- */
export const Footer = css({
  padding: '0 0 20px',
  display: 'flex',
  justifyContent: 'flex-end'
})

export const TableHeaderTitle = css(texts.heading, { textTransform: 'uppercase' })

export const MainTableArea = css({
  width: '100%',
  height: '476px'
})
