import React, { useState, useEffect, useMemo } from 'react'
import emptyImage from '@components/transactionMatchingRules/images/ledger.svg'
import { SCOPES } from '@root/constants'
import Placeholder from '@components/placeholder'
import { useDispatch, useSelector } from 'react-redux'
import { getLocalMatchingRules, getMatchingRules, isLoadingMatchingRules } from '@selectors/transactions'
import * as Style from './style'
import { TransactionMatchingRulesComponent } from '@components/transactionMatchingRules/transactionMatchingRulesComponent'
import { getOrgMatchingRules, resetSearchTransactions } from '@actions/'
import { getCurrentOrg } from '@selectors/org'
import CreateMatchingRulePopup from '@root/components/popups/createMatchingRulePopup'
import analytics from '@root/helpers/analytics'
import { css } from 'glamor'
import { Body2, Button, ButtonType, EmptyState, H4, Spacer, Stack, toast, ToastType } from '@toriihq/design-system'
import EnableFor from '@components/enableFor'

export const TransactionMatchingRules = (props) => {
  const { isEditable } = props

  const [isAddRuleModalOpen, setIsAddRuleModalOpen] = useState(false)

  const { id: idOrg } = useSelector(getCurrentOrg)
  const localMatchingRules = useSelector(getLocalMatchingRules)
  const matchingRules = useSelector(getMatchingRules)
  const rules = isEditable ? localMatchingRules : matchingRules
  const isLoading = useSelector(isLoadingMatchingRules)

  const dispatch = useDispatch()

  const loading = useMemo(() => isLoading && rules.length === 0, [isLoading, rules])

  const isEmptyState = useMemo(() => rules.length === 0 && !loading && !isEditable, [rules, loading, isEditable])

  useEffect(() => {
    if (idOrg) {
      dispatch(getOrgMatchingRules({ idOrg }))
    }
  }, [dispatch, idOrg])

  const renderEmptyState = () => {
    return (
      <div {...css({ display: 'flex', justifyContent: 'center', alignItems: 'center' })}>
        <Spacer top='space-300'>
          <EmptyState
            image={<img src={emptyImage} alt='No matching rules' />}
            buttons={[
              <EnableFor scopes={[SCOPES.EXPENSE_WRITE]} allowForToriiAdmin>
                <Button type={ButtonType.primary} onClick={() => toggleCreateMatchRulePopup(true)} label='Add rule' />
              </EnableFor>
            ]}
          />
        </Spacer>
      </div>
    )
  }

  const toggleCreateMatchRulePopup = (isOpen) => {
    analytics.track('Open expense-matching-rules', { 'Button label': 'Add rule', 'Dialog name': 'Add a matching rule' })
    setIsAddRuleModalOpen(isOpen)
    isOpen && dispatch(resetSearchTransactions())
  }

  const onCreate = () => {
    setIsAddRuleModalOpen(false)
    toast({
      message: 'Your matching rule was created successfully. Please allow a few minutes for matching updates.',
      type: ToastType.SUCCESS
    })
    dispatch(getOrgMatchingRules({ idOrg }))
  }

  return (
    <div {...Style.Main}>
      <Stack direction='column' gap='space-100'>
        <H4>Configure Expense Rules</H4>
        <Body2>
          Define custom rules for matching transactions coming from Netsuite, Expensify, expense reports and others.
          <br />
          Each rule will apply one by one in the order you define.
        </Body2>
        <Placeholder loading={loading} type='text' rows={6} style={{ height: '80px' }}>
          {isEmptyState ? renderEmptyState() : <TransactionMatchingRulesComponent {...props} onAddRuleClick={() => toggleCreateMatchRulePopup(true)} />}
        </Placeholder>
      </Stack>
      {idOrg && <CreateMatchingRulePopup idOrg={idOrg} isOpen={isAddRuleModalOpen} onClose={() => toggleCreateMatchRulePopup(false)} onCreate={onCreate} />}
    </div>
  )
}

export default TransactionMatchingRules
