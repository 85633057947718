import { css } from 'glamor'
import { fontSize, fontWeight } from '@shared/style/sizes'
import colors from '@shared/style/colors'
import styled from 'styled-components'

export const AddRuleButton = css({
  marginTop: '20px',
  fontSize: fontSize.small,
  fontWeight: fontWeight.normal,
  color: colors.darkText,
  verticalAlign: 'middle'
})

export const AddIcon = css({
  fontSize: fontSize.extraSmall,
  fontWeight: fontWeight.light,
  marginRight: '5px',
  transform: 'translateY(-0.1px)'
})

export const TopTitleContainer = ({ isMatchingRulesChanged }) => css({
  width: '100%',
  backgroundColor: 'white',
  position: isMatchingRulesChanged ? 'sticky' : 'static',
  top: isMatchingRulesChanged ? '0' : 'auto',
  zIndex: isMatchingRulesChanged ? 10 : 'auto',
  marginBottom: isMatchingRulesChanged ? '0' : 'auto'
})

export const MatchingRulesTableContainer = ({ isMatchingRulesChanged }) => css({
  width: '100%',
  maxHeight: isMatchingRulesChanged ? 'calc(100vh - 200px)' : 'auto',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  overflow: isMatchingRulesChanged ? 'hidden' : 'visible'
})

export const MatchingRulesTableContent = ({ isMatchingRulesChanged }) => css({
  minWidth: '1050px',
  flex: '1 1 auto',
  overflowY: isMatchingRulesChanged ? 'auto' : 'visible',
  overflowX: isMatchingRulesChanged ? 'auto' : 'visible',
  paddingBottom: '2px',
  position: 'relative'
})

export const StyledTopTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${props =>
    props.hasErrors
      ? props.theme.palette.border.error
      : props.isChanged
        ? props.theme.palette.border.active
        : props.theme.palette.border.primary
};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 16px;
  background-color: ${props =>
    props.hasErrors
      ? 'rgba(245,48,89,0.1)'
      : props.isChanged
        ? colors.lightBlue5
        : colors.white
};
  font-size: ${fontSize.small};
`

export const TitleCotainer = css({
  display: 'flex',
  alignItems: 'center'
})

export const Title = css({
  marginTop: '2px'
})

export const ButtonsContainer = css({
  display: 'inline-flex',
  flexWrap: 'wrap',
  gap: '16px'
})
