import { ReactElement } from 'react'
import { FIXED_WIDGET_TYPES } from '@store/reducers/dashboards/types'
import RenewalYearlySummaryDataPopup from './renewalYearlySummaryDataPopup'
import { FixedWidgetDataPopupProps } from './types'
import UsersMapDataPopup from './usersMapDataPopup'
import { UsersMapDataPopupProps } from './usersMapDataPopup/types'
const FixedWidgetDataPopupContent = (props: FixedWidgetDataPopupProps): ReactElement => {
  const { widget } = props

  switch (widget.fixedWidgetType) {
    case FIXED_WIDGET_TYPES.RENEWAL_YEARLY_SUMMARY:
      return <RenewalYearlySummaryDataPopup />
    case FIXED_WIDGET_TYPES.USERS_MAP:
      return <UsersMapDataPopup {...props as UsersMapDataPopupProps} />
    default:
      return <div />
  }
}

export default FixedWidgetDataPopupContent
