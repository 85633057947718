import { State } from '@reducers/types'
import { createSelector } from 'reselect'
import get from 'lodash/get'
import identity from 'lodash/identity'
import { AccessReview } from '@reducers/accessReviews/types'

export const getAccessReviews = createSelector<State, AccessReview[], AccessReview[]>(
  state => get(state, ['accessReviews', 'accessReviews'], []),
  identity
)

export const getIsLoadingAccessReviews = createSelector<State, boolean, boolean>(
  state => get(state, ['accessReviews', 'isLoading']),
  identity
)

export const getAccessReviewsResources = createSelector(
  (state: State) => get(state, ['accessReviews', 'resources'], {}),
  identity
)
