import { createSelector } from 'reselect'
import get from 'lodash/get'
import keyBy from 'lodash/keyBy'
import pick from 'lodash/pick'
import identity from 'lodash/identity'
import { fieldsValuesToOptions } from '@lenses/utils'
import { USER_TYPE } from '@root/constants'

export const isUsersLoadingES = createSelector(
  state => state.users.currentUsers.loading || state.users.pastUsers.loading || state.users.externalUsers.loading,
  identity
)

export const isTeamMembersLoadingES = createSelector(
  state => get(state, ['users', 'teamMembers', 'loading']),
  identity
)

export const isTeamMembersLoadedES = createSelector(
  state => get(state, ['users', 'teamMembers', 'isLoaded']),
  identity
)

export const isUsersAmountLoadingES = createSelector(
  state => state.users.loadingCurrentUsersTotalAmount || state.users.loadingPastUsersTotalAmount || state.users.loadingExternalUsersTotalAmount || state.users.loadingUserTypeAmount,
  identity
)

export const getLoadingLazyLoadedUsers = createSelector(
  state => get(state, ['users', 'loadingLazyLoadedUsers']),
  identity
)

export const getLazyLoadedUsers = createSelector(
  state => get(state, ['users', 'lazyLoadedUsers']),
  identity
)

export const getCatalogUsersInfo = createSelector(
  state => get(state, ['users', 'catalogUsers']),
  identity
)

export const getCurrentUsersES = createSelector(
  state => get(state, ['users', 'currentUsers']),
  identity
)

export const getTeamMembers = createSelector(
  state => get(state, ['users', 'teamMembers']),
  identity
)

export const getTeamMembersES = createSelector(
  [getTeamMembers],
  (teamMembers) => teamMembers.users.filter(user => user.isAdmin)
)

export const getCurrentUsersAmount = createSelector(
  state => get(state, ['users', 'currentUsersTotalAmount']),
  identity
)

export const getCurrentUsersWithChildrenAmount = createSelector(
  state => get(state, ['users', 'currentUsersWithChildrenTotalAmount']),
  identity
)

export const getEmployeeAmount = createSelector(
  state => get(state, ['users', 'userTypeAmount', USER_TYPE.EMPLOYEE]),
  identity
)

export const getPastUsersES = createSelector(
  state => get(state, ['users', 'pastUsers']),
  identity
)

export const getExternalUsersES = createSelector(
  state => get(state, ['users', 'externalUsers']),
  identity
)

export const getPastUsersAmount = createSelector(
  state => get(state, ['users', 'pastUsersTotalAmount']),
  identity
)

export const getExternalUsersAmount = createSelector(
  state => get(state, ['users', 'externalUsersTotalAmount']),
  identity
)

export const getInactiveUsersInfo = createSelector(
  state => get(state, ['appUsers', 'inactiveUsers']),
  identity
)

export const getInactiveUsersReportData = createSelector(
  [getInactiveUsersInfo],
  ({ users = [], resources }) => {
    const appsById = keyBy(resources.apps, 'id')
    return users.map(inactiveUser => ({
      ...pick(resources.users[inactiveUser.idUser], ['firstName', 'lastName', 'email', 'isExternal', 'photoUrl', 'isDeletedInIdentitySources']),
      ...pick(appsById[inactiveUser.idApp], ['name', 'imageUrl', 'category']),
      ...inactiveUser
    }))
  }
)

export const getCurrentUsersFieldValues = createSelector(
  state => get(state, ['users', 'currentUsersFieldsValues'], {}),
  fieldsValuesToOptions
)

export const getPastUsersFieldValues = createSelector(
  state => get(state, ['users', 'pastUsersFieldsValues'], {}),
  fieldsValuesToOptions
)

export const getExternalUsersFieldValues = createSelector(
  state => get(state, ['users', 'externalUsersFieldsValues'], {}),
  fieldsValuesToOptions
)

export const getUsersMetadata = createSelector(
  state => get(state, ['users', 'metadata']),
  identity
)
