import { css } from 'glamor'
import colors from '@shared/style/colors'
import texts from '@shared/style/texts'
import styled from 'styled-components'

export const Main = styled.div`
  position: relative;
  align-items: center;
  border: 1px solid ${props => props.theme.palette.border.primary};
  border-radius: 8px;
  background: ${props => props.theme.palette.background.primary};
  color: ${props => props.theme.palette.text.secondary};
`

export const Body = css({
  display: 'flex',
  verticalAlign: 'middle',
  width: '100%',
  alignItems: 'center',
  height: '65px',
  padding: '0 20px',
  ':not(:last-child)': {
    borderBottom: `solid 1px ${colors.lightBlue2}`
  }
})

export const Header = css(texts.subheading, {
  color: colors.black,
  fontSize: '14px',
  lineHeight: '32px',
  marginRight: '10px',
  display: 'flex'
})

export const HeaderLabel = css({
  display: 'flex',
  width: '80%'
})

export const HeaderAction = css({
  display: 'inline-block'
})

export const Details = css({
  textAlign: 'center'
})

export const OwnerButtons = css({
  marginLeft: 'auto',
  display: 'flex'
})

export const HeaderButton = css({
  color: `${colors.black} !important`,
  backgroundColor: `${colors.white} !important`,
  padding: '12px 6px',
  border: `solid 1px ${colors.lightBlue2}`
})

export const HeaderWrapper = styled.div`
  width: 100%;
  padding: 20px;
  border-bottom: solid 1px ${props => props.theme.palette.border.primary};
  display: flex;
  justify-content: space-between;
`

export const EditButton = css({
  opacity: '0 !important',
  [`.${Body}:hover &, [data-${Body}]:hover &`]: {
    opacity: '1 !important',
    ':disabled': {
      opacity: '0.5 !important'
    }
  }
})
