import React, { useRef, useEffect } from 'react'
import { Field } from 'react-final-form'
import { Input, Tooltip } from '@toriihq/design-system'
import { LeftPadding } from '../style'

const validateDescription = (value, allValues, fieldName) => {
  if (value) return undefined

  const [rowIndex] = fieldName.match(/\d+/) || []
  if (!rowIndex) return undefined

  const lineItemRow = allValues.lineItems?.[rowIndex]
  if (!lineItemRow) return undefined

  const { quantity, pricePerUnit, discount, idLicense, paymentTerm, tax, startDate, endDate } = lineItemRow
  const hasOtherValues = quantity || pricePerUnit || discount || idLicense || paymentTerm || tax || startDate || endDate

  return hasOtherValues ? 'Description is required when other fields have values' : undefined
}

interface DescriptionFieldProps {
  name: string
  index: number
  shouldFocus: boolean
  disabled?: boolean
}

export const DescriptionField: React.FC<DescriptionFieldProps> = ({ name, index, shouldFocus, disabled }) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (shouldFocus && inputRef.current) {
      inputRef.current.focus()
      inputRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
  }, [shouldFocus, index])

  return (
    <LeftPadding>
      <Field
        name={`${name}.description`}
        render={({ input, meta }) => (
          <Tooltip label={'Description is required'} hide={meta.valid}>
            <Input
              {...input}
              onBlur={(e) => {
                const trimmedValue = e.target.value.trim()
                input.onChange(trimmedValue)
                input.onBlur(e)
              }}
              type='text'
              hasError={!meta.valid}
              ref={inputRef}
              disabled={disabled}
            />
          </Tooltip>
        )}
        validate={(value, allValues) => validateDescription(value, allValues, name)}
      />
    </LeftPadding>
  )
}
