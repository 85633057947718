import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux/es'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { isEmpty } from 'lodash'
import { getHistoricalRenewalsSummary, isLoadingRenewalsSummary } from '@selectors/contracts'
import RenewalsListTable from '@components/renewalsSummary/renewalsListTable'
import {
  EmptyStateContainer,
  FlexColumnDiv,
  FlexRowDiv,
  StyledPlaceholder,
  StyledPlaceholderContainer,
  TableWrapper
} from '@components/renewalsSummary/styles'
import { bucketSummarySortOrder } from '@components/renewalsSummary/utils/constants'
import { getUserPreferences } from '@selectors/ui'
import { Body2, Button, EmptyState, H4, Link, Spacer } from '@toriihq/design-system'
import reportSVG from '@media/report.svg'
import { toggleContractPopup } from '@actions/'
import Analytics from '@components/renewalsSummary/analytics'
import { RENEWALS_SUMMARY_CUSTOM_SELECT_OPTIONS } from '@root/constants'
import RenewalsChart from './renewalsChart'
import YearSelector from './yearSelector'
import { getLastAvailableYear, getSortedBaseYearRenewals } from './utils/utils'

const RenewalsSummary = () => {
  const dispatch = useDispatch()
  const renewalsSummary = useSelector(getHistoricalRenewalsSummary)
  const isLoading = useSelector(isLoadingRenewalsSummary)
  const userPreferences = useSelector(getUserPreferences)
  const baseYearPreference = userPreferences?.renewalsSummary?.baseYear

  const [baseYear, setBaseYear] = useState(baseYearPreference || { value: 0, label: '' })
  const [bucketOption, setBucketOption] = useState(RENEWALS_SUMMARY_CUSTOM_SELECT_OPTIONS.all)

  useDeepCompareEffect(() => {
    getLastAvailableYear(renewalsSummary, baseYear, onYearChange)
  }, [renewalsSummary])

  const baseYearRenewals = getSortedBaseYearRenewals(renewalsSummary, baseYear, bucketSummarySortOrder)

  const onYearChange = (year) => {
    setBaseYear(year)
  }

  const onBucketChange = (filterMethod) => (bucket) => {
    setBucketOption(bucket)
    Analytics.filterTableView(bucket.label, filterMethod)
  }

  const LearnMoreLink = <Link href='https://support.toriihq.com/hc/en-us/articles/27435239563675-Renewals-Summary-Report' target='_blank' >Learn more</Link>

  const emptyState =
    <EmptyStateContainer>
      <EmptyState
        orientation='vertical'
        align='start'
        image={<img src={reportSVG} alt='no-renewals-summary' />}
        title='Add contracts to view report'
        description='The yearly summary report helps you review past and upcoming contract renewals, analyze savings, and monitor rising app costs. Add your contracts to start tracking.'
        buttons={[
          <Button key='a' onClick={() => dispatch(toggleContractPopup({ isOpen: true, isDuplicated: false, idApp: null, idContract: null, buttonLabel: null, initialValues: null, onSuccessCB: null }))} label='Add Contracts' />
        ]}
        link={LearnMoreLink}
      />
    </EmptyStateContainer>

  return (!isLoading && isEmpty(renewalsSummary)) ? emptyState : (
    <StyledPlaceholderContainer>
      <StyledPlaceholder loading={isLoading} type='rect'>
        <FlexRowDiv>
          <FlexColumnDiv>
            <Spacer bottom={'space-100'}>
              <H4>
                Renewals Yearly Summary
              </H4>
            </Spacer>
            <Body2>
              Get a birds eye view of your renewals for the selected year as well as aggregated savings.
            </Body2>
          </FlexColumnDiv>
          <div>
            <YearSelector
              renewalsSummary={renewalsSummary}
              baseYear={baseYear}
              onYearChange={onYearChange}
            />
          </div>
        </FlexRowDiv>
        <div>
          <RenewalsChart
            baseYear={baseYear}
            baseYearRenewals={baseYearRenewals}
            onBucketChange={onBucketChange('Click on chart')}
          />
        </div>
        <TableWrapper>
          <RenewalsListTable baseYearRenewals={baseYearRenewals} baseYear={baseYear} bucketOption={bucketOption} onBucketChange={onBucketChange('Filter dropdown')} />
        </TableWrapper>
      </StyledPlaceholder>
    </StyledPlaceholderContainer>
  )
}

export default RenewalsSummary
