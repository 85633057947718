import { Icon, Body2, Card, Stack, Subtitle2 } from '@toriihq/design-system'
import { DataPanelsContainer } from './styles'
import type { ReactElement } from 'react'

export const ReviewDetails = (): ReactElement => {
  return (
    <>
      <Body2>Review the copied data details</Body2>
      <DataPanelsContainer>
        <Card padding='small' >
          <Stack gap='space-200'>
            <Stack direction='row' gap='space-100'>
              <Icon name='CheckCircle' color='success' />
              <Subtitle2 color='success'>Data that will be copied</Subtitle2>
            </Stack>
            <Stack gap='space-100'>
              <Body2>• Custom app details</Body2>
              <Body2>• Matched contracts</Body2>
            </Stack>
          </Stack>
        </Card>
        <Card padding='small'>
          <Stack gap='space-200'>
            <Stack direction='row' gap='space-100'>
              <Icon name='CircleX' color='tertiary' />
              <Subtitle2 color='tertiary'>Data that will not be copied</Subtitle2>
            </Stack>
            <Stack gap='space-100'>
              <Body2>• Matched expenses</Body2>
              <Body2>• Users & usage</Body2>
              <Body2>• Offboarding configuration</Body2>
              <Body2>• Workflow triggers & actions</Body2>
              <Body2>• Discovered external apps</Body2>
            </Stack>
          </Stack>
        </Card>
      </DataPanelsContainer>
    </>
  )
}
