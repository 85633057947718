import styled from 'styled-components'

export const ContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${({ theme }) => theme.spacing('space-300')};
`

export const ContentTop = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  height: 177px;
  gap: ${({ theme }) => theme.spacing('space-400')};
`

export const ContentBottom = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${({ theme }) => theme.spacing('space-100')};
`
export const ContentLeftSide = styled.div`
  display: flex;
  flex-flow: column;
  width: 50%;
  gap: ${({ theme }) => theme.spacing('space-200')};
`

export const ContentRightSide = styled.div`
  border: ${({ theme }) => `1px solid ${theme.palette.border.secondary}`};
  border-radius: ${({ theme }) => theme.corner('radius-100')};
  display: flex;
  flex-flow: column;
  width: 50%;
  height: 177px;
  padding: 16px 24px 16px 24px;

`

export const BillingCycleWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${({ theme }) => theme.spacing('space-150')};
`

export const RadioButtonWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${({ theme }) => theme.spacing('space-100')};
`

export const EmployeeSeatsWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${({ theme }) => theme.spacing('space-050')};
`

export const EmployeeSeats = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
`

export const EmployeeCost = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${({ theme }) => theme.spacing('space-050')};
  margin-bottom: 24px;
`

export const TotalCost = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${({ theme }) => theme.spacing('space-050')};
`
