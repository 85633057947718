import { last } from 'lodash'
import { RenewalsSummaryData, RenewalsSummaryYear } from '../types'

export const getLastAvailableYear = (
  renewalsSummary: RenewalsSummaryData | null,
  baseYear: RenewalsSummaryYear,
  onYearChange: (year: RenewalsSummaryYear) => void
): void => {
  if (renewalsSummary) {
    const lastAvailableYear = last(Object.keys(renewalsSummary))
    if (lastAvailableYear && (!baseYear.value || !renewalsSummary[baseYear.value])) {
      onYearChange({ value: Number(lastAvailableYear), label: lastAvailableYear })
    }
  }
}

export const getSortedBaseYearRenewals = (
  renewalsSummary: RenewalsSummaryData,
  baseYear: RenewalsSummaryYear,
  bucketSummarySortOrder: { [key: string]: number }
): any[] => {
  return (renewalsSummary[baseYear.value] || [])
    .sort((a, b) => bucketSummarySortOrder[a.bucket] - bucketSummarySortOrder[b.bucket])
}
