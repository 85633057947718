import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/app'
import { BrowserRouter } from 'react-router-dom'
import { Provider, useSelector } from 'react-redux'
import store from './store/store'
import './index.less'
import Cookie from 'cookie'
import { configure } from './shared/redux-fetch'
import config from './config'
import { LOAD_USER_PREFERENCES } from './constants'
import { DragDropContext } from 'react-beautiful-dnd'
import DraggableOptions from './components/draggableOptions'
import { ThemeProvider } from '@toriihq/design-system'
import { GlobalStyle } from './global.style'

configure({
  successSuffix: '_RESPONSE',
  baseUrl: config.proxyApiRequests ? (config.proxyApiRequestsPath || '') : config.apiBaseUrl
})

const defaultHeaders: {
  'Content-Type': string
  'x-csrf-token': () => string | undefined
} = {
  'Content-Type': 'application/json',
  'x-csrf-token': () => {
    const cookies = Cookie.parse(document.cookie)
    return cookies.crumb || undefined
  }
}

configure({
  defaultHeaders
})

store.dispatch({ type: LOAD_USER_PREFERENCES })

const ThemedApp = () => {
  const theme = useSelector(state => state.ui.userPreferences.theme?.value || 'light')

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <App />
    </ThemeProvider>
  )
}

ReactDOM.render(
  <DragDropContext onDragEnd={(DraggableOptions as any).onDragEnd}>
    <Provider store={store}>
      <BrowserRouter>
        <ThemedApp />
      </BrowserRouter>
    </Provider>
  </DragDropContext>,
  document.getElementById('root')
)
