import moment from 'moment'
import { Body2, Caption1 } from '@toriihq/design-system'
import { getFormattedDate } from '@lenses/utils'
import UserDetails from '@components/userDetails'
import UsersIconsList from '@components/usersIconsList/view'
import { User } from '@components/userDetails/types'

export const serialNumberColumn = {
  Header: 'ID',
  accessor: 'serialNumber',
  Cell: ({ value: serialNumber }) => (
    <Body2 color='primary'>T-{serialNumber}</Body2>
  ),
  maxWidth: 80
}

export const taskNameColumn = {
  Header: 'Task Name',
  accessor: 'description',
  Cell: ({ value: description }) => (
    <Body2 color='primary'>{description}</Body2>
  ),
  minWidth: 150
}

export const taskTypeColumn = {
  Header: 'Task Type',
  accessor: 'typeLabel',
  Cell: ({ value: typeLabel }) => (
    <Body2 color='primary'>{typeLabel}</Body2>
  ),
  width: 200
}

export const assigneesColumn = {
  Header: 'Assignees',
  accessor: 'assignees',
  Cell: ({ value: assignees }) => {
    if (!assignees || assignees?.length === 0) {
      return '-'
    }

    const userList: User[] = assignees.map((assignee: { user: User }) => assignee.user)

    return (
      userList.length > 1
        ? <UsersIconsList users={userList} maxUsers={4} />
        : <UserDetails idUser={userList[0].id} {...userList[0]} />
    )
  },
  minWidth: 170
}

export const dateTimeColumn = {
  Cell: ({ value }) => value ? (
    <div>
      <Body2 color='primary'>{getFormattedDate({ date: value })}</Body2>
      <Caption1 color='tertiary'>{moment.utc(value).format('h:mm A')}</Caption1>
    </div>
  ) : '-'
}
