import { Button, ButtonType, ButtonSize, ButtonState, Stack, AlertBox, AlertBoxType, FormElement, Body2 } from '@toriihq/design-system'
import Analytics from '../../helpers/analytics'
import React from 'react'
import PropTypes from 'prop-types'
import Headers from '../signup/headers/index'
import { AUTH_STATE } from '../../constants'
import { Field, Form } from 'react-final-form'
import Input from '@components/form/input'

class EmailForm extends React.Component {
  constructor (props) {
    super(props)

    this.input = {}
  }

  async componentDidMount () {
    try {
      const me = await this.props.getMe()

      Analytics.identify(me)

      if (this.input.email) {
        this.input.email.focus()
      }
    } catch (e) {
      return e
    }
  }

  componentDidUpdate (prevProps) {
    const { onAuthenticated } = prevProps
    const { authState } = this.props
    if (authState === AUTH_STATE.AUTHENTICATED) {
      onAuthenticated()
    }
  }

  async handleSubmit ({ email }) {
    const { onSubmit } = this.props
    try {
      onSubmit(email)
    } catch (e) {
      return e
    }
  }

  backToLogin () {
    window.location.pathname = '/login'
  }

  renderEmailField = props => {
    const { loginInfo = {} } = this.props
    const { hasError, help } = loginInfo
    const { meta } = props

    return <FormElement label='Email' error={(hasError && help?.email) || (meta.touched && meta.error)}>
      <Input
        {...props.input}
        placeholder='Enter your work email address'
        ref={(c) => (this.input.email = c)}
      />
    </FormElement>
  }

  render () {
    const alerts = []
    const { authState, loginInfo, header, buttonLabel, backToLoginText, onSuccessMsg, onAuthenticatedMsg } = this.props
    const { success, error, loading } = loginInfo
    const isAuthenticated = (authState === AUTH_STATE.AUTHENTICATED)

    if (isAuthenticated) {
      alerts.push(
        <AlertBox key='success' type={AlertBoxType.POSITIVE} description={onAuthenticatedMsg} />
      )
    }

    if (success) {
      alerts.push(
        <AlertBox key='success' type={AlertBoxType.POSITIVE} description={onSuccessMsg} />
      )
    }

    if (error) {
      alerts.push(
        <AlertBox key='error' type={AlertBoxType.NEGATIVE} description={error} />
      )
    }

    let formElements = null

    if (!isAuthenticated && !success) {
      formElements = (
        <fieldset>
          <Stack direction='column' gap='space-200'>
            <Field
              name='email'
              label='Enter your work email address'
              component={this.renderEmailField}
            />
            <Stack direction='row' gap='space-200' justifyContent='space-between'>
              <Stack direction='row' gap='space-050'>
                <Body2 color='secondary'>{backToLoginText}</Body2>
                <Button type={ButtonType.compact} size={ButtonSize.small} onClick={this.backToLogin} label='Sign in' htmlButtonType='button' />
              </Stack>
              <Button
                label={buttonLabel}
                buttonState={loading ? ButtonState.loading : ButtonState.neutral}
              />
            </Stack>
          </Stack>
        </fieldset>
      )
    }

    return (
      <div>
        <Headers headerText={header} />
        <Form
          key={'signupEmailForm'}
          onSubmit={this.handleSubmit.bind(this)}
          render={(formProps) => {
            const { handleSubmit } = formProps
            return <form onSubmit={handleSubmit}>
              <Stack direction='column' gap='space-200'>
                {alerts}
                {formElements}
              </Stack>
            </form>
          }}
        />
      </div>
    )
  }
}

EmailForm.propTypes = {
  header: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSuccessMsg: PropTypes.string.isRequired,
  loginInfo: PropTypes.shape({
    success: PropTypes.bool,
    error: PropTypes.string,
    hasError: PropTypes.object,
    help: PropTypes.object,
    loading: PropTypes.bool
  })
}

EmailForm.defaultProps = {
  onSubmit: () => {},
  loginInfo: {
    hasError: {},
    help: {}
  }
}

export default EmailForm
