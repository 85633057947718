import { Reducer } from 'redux'
import { WidgetsActions, DashboardsState } from './types'
import sortBy from 'lodash/sortBy'

const initialState = {
  isLoading: false,
  dashboardsConfig: [],
  dashboardsData: {}
}

const dashboardsReducer: Reducer<DashboardsState, WidgetsActions> = (state = initialState, action): DashboardsState => {
  switch (action.type) {
    case 'GET_DASHBOARDS_PENDING': {
      return {
        ...state,
        isLoading: true
      }
    }
    case 'GET_DASHBOARDS_FAILED': {
      return {
        ...state,
        isLoading: false
      }
    }
    case 'GET_DASHBOARDS_RESPONSE': {
      const { dashboards } = action.payload

      return {
        ...state,
        isLoading: false,
        dashboardsConfig: sortBy(dashboards, 'id')
      }
    }

    case 'UPDATE_DASHBOARD_PENDING': {
      const { idDashboard, dashboard } = action.meta

      const newDashboard = { ...dashboard, id: idDashboard }
      const newDashboards = state.dashboardsConfig.map(d => d.id === idDashboard ? newDashboard : d)

      return {
        ...state,
        isLoading: false,
        dashboardsConfig: newDashboards
      }
    }

    case 'UPDATE_DASHBOARD_FAILED': {
      return {
        ...state,
        isLoading: false
      }
    }

    case 'UPDATE_DASHBOARD_RESPONSE': {
      const { idDashboard } = action.meta
      const { dashboard } = action.payload

      const newDashboards = state.dashboardsConfig.map(d => d.id === idDashboard ? dashboard : d)

      return {
        ...state,
        isLoading: false,
        dashboardsConfig: newDashboards
      }
    }

    case `GET_DATA_FOR_WIDGET_PENDING`: {
      const { idDashboard, idWidget } = action.meta

      return {
        ...state,
        dashboardsData: {
          ...state?.dashboardsData,
          [idDashboard]: {
            widgetsData: {
              ...state?.dashboardsData[idDashboard]?.widgetsData,
              [idWidget]: {
                ...state?.dashboardsData[idDashboard]?.widgetsData[idWidget],
                data: {
                  ...state?.dashboardsData[idDashboard]?.widgetsData[idWidget]?.data,
                  loading: true
                }
              }
            }
          }
        }
      }
    }
    case `GET_DATA_FOR_WIDGET_FAILED`: {
      const { idDashboard, idWidget } = action.meta

      return {
        ...state,
        dashboardsData: {
          ...state?.dashboardsData,
          [idDashboard]: {
            widgetsData: {
              ...state?.dashboardsData[idDashboard]?.widgetsData,
              [idWidget]: {
                ...state?.dashboardsData[idDashboard]?.widgetsData[idWidget],
                data: {
                  ...state?.dashboardsData[idDashboard]?.widgetsData[idWidget]?.data,
                  loading: false
                }
              }
            }
          }
        }
      }
    }
    case `GET_DATA_FOR_WIDGET_RESPONSE`: {
      const { total, resources, aggregations } = action.payload
      const { apps, users, ...restResources } = resources ?? {}
      const { idDashboard, idWidget, displayConfig, entityType, reset } = action.meta

      const currRows = action.payload[entityType]
      const previousRows = state?.dashboardsData[idDashboard]?.widgetsData[idWidget]?.data?.rows ?? []
      const { apps: previousApps = {}, users: previousUsers = {} } = state?.dashboardsData[idDashboard]?.widgetsData[idWidget]?.data?.resources ?? {}

      return {
        ...state,
        dashboardsData: {
          ...state?.dashboardsData,
          [idDashboard]: {
            widgetsData: {
              ...state?.dashboardsData[idDashboard]?.widgetsData,
              [idWidget]: {
                ...state?.dashboardsData[idDashboard]?.widgetsData[idWidget],
                data: {
                  ...state?.dashboardsData[idDashboard]?.widgetsData[idWidget]?.data,
                  loading: false,
                  rows: reset ? currRows : previousRows.concat(currRows),
                  total,
                  resources: {
                    ...restResources,
                    apps: reset ? apps : { ...previousApps, ...apps },
                    users: reset ? users : { ...previousUsers, ...users }
                  },
                  aggregations,
                  displayConfig
                }
              }
            }
          }
        }
      }
    }

    case `GET_DRILL_DOWN_DATA_FOR_WIDGET_PENDING`: {
      const { idDashboard, idWidget } = action.meta

      return {
        ...state,
        dashboardsData: {
          ...state?.dashboardsData,
          [idDashboard]: {
            widgetsData: {
              ...state?.dashboardsData[idDashboard]?.widgetsData,
              [idWidget]: {
                ...state?.dashboardsData[idDashboard]?.widgetsData[idWidget],
                drillDownData: {
                  ...state?.dashboardsData[idDashboard]?.widgetsData[idWidget]?.drillDownData,
                  loading: true
                }
              }
            }
          }
        }
      }
    }

    case `GET_DRILL_DOWN_DATA_FOR_WIDGET_FAILED`: {
      const { idDashboard, idWidget } = action.meta

      return {
        ...state,
        dashboardsData: {
          ...state?.dashboardsData,
          [idDashboard]: {
            widgetsData: {
              ...state?.dashboardsData[idDashboard]?.widgetsData,
              [idWidget]: {
                ...state?.dashboardsData[idDashboard]?.widgetsData[idWidget],
                drillDownData: {
                  ...state?.dashboardsData[idDashboard]?.widgetsData[idWidget]?.drillDownData,
                  loading: false
                }
              }
            }
          }
        }
      }
    }
    case `GET_DRILL_DOWN_DATA_FOR_WIDGET_RESPONSE`: {
      const { total, resources } = action.payload
      const { idDashboard, idWidget, entityType, reset } = action.meta

      const currRows = action.payload[entityType]
      const previousRows = state?.dashboardsData[idDashboard]?.widgetsData[idWidget]?.drillDownData?.rows ?? []

      const { apps = {}, users = {}, contracts = {} } = resources ?? {}
      const { apps: previousApps = {}, users: previousUsers = {}, contracts: previousContracts = {} } = state?.dashboardsData[idDashboard]?.widgetsData[idWidget]?.drillDownData?.resources ?? {}

      return {
        ...state,
        dashboardsData: {
          ...state?.dashboardsData,
          [idDashboard]: {
            widgetsData: {
              ...state?.dashboardsData[idDashboard]?.widgetsData,
              [idWidget]: {
                ...state?.dashboardsData[idDashboard]?.widgetsData[idWidget],
                drillDownData: {
                  loading: false,
                  total,
                  rows: reset ? currRows : previousRows.concat(currRows),
                  resources: {
                    apps: reset ? apps : { ...previousApps, ...apps },
                    users: reset ? users : { ...previousUsers, ...users },
                    contracts: reset ? contracts : { ...previousContracts, ...contracts }
                  }
                }
              }
            }
          }
        }
      }
    }

    default: {
      return state
    }
  }
}

export default dashboardsReducer
