import React, { ReactElement } from 'react'
import { AlertBox, AlertBoxType } from '@toriihq/design-system'
import pluralize from 'pluralize'

interface Props {
   numberOfTasks: number
}

const NeedsAttentionAlertBox = ({ numberOfTasks }: Props): ReactElement => {
  if (numberOfTasks === 0) {
    return <></>
  }

  const filterTasks = () => {
    // TODO: Implement filter
  }

  const text = `Please review ${pluralize('task', numberOfTasks, true)} that requires your attention`

  return (
    <AlertBox
      type={AlertBoxType.NOTICE}
      secondaryButton={{ label: 'Review tasks', onClick: filterTasks }}
      description={text} />
  )
}

export default NeedsAttentionAlertBox
