import React from 'react'
import { AppIcon, TextBadge, Link, Stack } from '@toriihq/design-system'
import LinkableAvatar from '@components/_shared/linkableAvatar'
import moment from 'moment'
import { EMPTY_OBJECT } from '@root/constants'
import RelativeTeamLink from '@components/relativeTeamLink'
import { hoverActions } from '@root/shared/style/mixins'
import { ACCESS_REVIEW_STATUS } from '@store/reducers/accessReviews/types'

export interface Column {
  id?: string
  Header: string
  accessor: string | any
  minWidth: number
  Cell: ({ value, original, row }: { value: any, original: any, row: any }) => any
  sortable?: boolean
  resizable?: boolean
  fixed?: 'left' | 'right'
}

const formatDate = (value: string | null): string => value ? moment(value).format('MMMM D, YYYY') : '-'

export const getColumns = ({ idOrg, usersById = EMPTY_OBJECT, appsById = EMPTY_OBJECT }): Column[] => [
  {
    id: 'actions',
    Header: '',
    accessor: 'id',
    sortable: false,
    resizable: false,
    fixed: 'right',
    minWidth: 100,
    Cell: () => {
      return <div {...hoverActions}>
        <Link onClick={() => {}}>Start review</Link>
      </div>
    }
  },
  {
    Header: 'Application',
    accessor: 'idApp',
    minWidth: 200,
    Cell: ({ value: idApp }) => {
      const app = appsById[idApp] || {}
      const { imageUrl, name } = app
      return <Stack direction='row' gap='space-100'>
        <RelativeTeamLink idOrg={idOrg} to={`/app/${idApp}`}>
          <AppIcon
            appImageUrl={imageUrl}
            appName={name}
            hoverable
          />
        </RelativeTeamLink>
        {name}
      </Stack>
    }
  },
  {
    Header: 'Reviewers',
    accessor: 'reviewers',
    minWidth: 150,
    Cell: ({ value: reviewers }) => {
      return (
        reviewers.map(reviewerId => {
          const user = usersById[reviewerId] || {}
          return <LinkableAvatar key={reviewerId} user={user} />
        })
      )
    }
  },
  {
    Header: 'Launched on',
    accessor: 'creationTime',
    minWidth: 150,
    Cell: ({ value }) => formatDate(value)
  },
  {
    Header: 'Report name',
    accessor: 'reportName',
    minWidth: 200,
    Cell: ({ value }) => value || '-'
  },
  {
    Header: 'Started on',
    accessor: 'startTime',
    minWidth: 150,
    Cell: ({ value }) => formatDate(value)
  },
  {
    Header: 'Progress',
    accessor: 'progressPercent',
    minWidth: 100,
    Cell: ({ value }) => value ? `${value}%` : '-'
  },
  {
    Header: 'Status',
    accessor: 'status',
    minWidth: 100,
    Cell: ({ value }) => {
      const statusMap = {
        [ACCESS_REVIEW_STATUS.PENDING]: { color: 'gray', text: 'To do' },
        [ACCESS_REVIEW_STATUS.ONGOING]: { color: 'blue', text: 'In Progress' },
        [ACCESS_REVIEW_STATUS.COMPLETED]: { color: 'success', text: 'Completed' }
      }
      const statusConfig = statusMap[value]
      return (
        <div data-status={value}>
          <TextBadge color={statusConfig.color} size='Small'>{statusConfig.text}</TextBadge>
        </div>
      )
    }
  },
  {
    Header: 'Submitted on',
    accessor: 'submitTime',
    minWidth: 150,
    Cell: ({ value }) => formatDate(value)
  },
  {
    Header: 'Submitted by',
    accessor: 'submittedBy',
    minWidth: 150,
    Cell: ({ value }) => value || '-'
  }
]
