import React, { useMemo } from 'react'
import colors from '../../shared/style/colors'
import texts from '../../shared/style/texts'
import { css } from 'glamor'
import Chart from './chart'
import { stackedBarChartType } from './chart/view'
import Placeholder from '../placeholder'
import PropTypes from 'prop-types'
import { EmptyState } from '@toriihq/design-system'
import { useTheme } from 'styled-components'

const StackedBarChartBox = ({ loading, header, data, fill, overrideStyle, barSize = 25, chartHeight, showEmptyState, emptyStateInfo, showLegend, tooltipContent, imagesAxis = false, boxHeight = '284px', chartOverrideStyle, analyticsEvent, showCustomDynamicBarsChart, interval, customDynamicBarsChartDefaultFill, formatter }) => {
  const theme = useTheme()
  const CSS = useMemo(() => {
    const SIDE_PADDING = '21px'
    return {
      main: css({
        border: `1px solid ${theme.palette.border.primary}`,
        borderRadius: '8px'
      }),
      header: css(texts.heading, {
        padding: `18px ${SIDE_PADDING}`,
        marginBottom: '6px',
        color: colors.darkText,
        textTransform: 'uppercase',
        borderBottom: `1px solid ${theme.palette.border.primary}`
      }),
      emptyState: css({
        display: 'flex',
        justifyContent: 'center',
        padding: 20
      })
    }
  }, [theme])

  return (
    <div {...css(CSS.main, overrideStyle, { height: boxHeight })}>
      {header && <header {...CSS.header}>{header}</header>}
      <Placeholder loading={loading} rows={6} style={{ maxWidth: '70%', padding: '20px', height: boxHeight }}>
        {showEmptyState ? <div {...CSS.emptyState}><EmptyState {...emptyStateInfo} /></div> : (
          <Chart
            overrideStyle={chartOverrideStyle}
            data={data}
            fill={fill}
            barSize={barSize}
            showLegend={showLegend}
            tooltipContent={tooltipContent}
            chartHeight={chartHeight}
            imagesAxis={imagesAxis}
            analyticsEvent={analyticsEvent}
            showCustomDynamicBarsChart={showCustomDynamicBarsChart}
            interval={interval}
            customDynamicBarsChartDefaultFill={customDynamicBarsChartDefaultFill}
            formatter={formatter}
          />
        )}
      </Placeholder>
    </div>
  )
}

const emptyStateInfoType = PropTypes.shape({
  header: PropTypes.string,
  img: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired
  }),
  buttonText: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.shape({
    navTo: PropTypes.string,
    callback: PropTypes.func,
    analytics: PropTypes.shape({
      event: PropTypes.string,
      properties: PropTypes.object
    })
  }),
  links: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string,
    onClick: PropTypes.shape({
      navTo: PropTypes.string,
      callback: PropTypes.func,
      analytics: PropTypes.shape({
        event: PropTypes.string,
        properties: PropTypes.object
      })
    })
  }))
})

StackedBarChartBox.propTypes = {
  loading: PropTypes.bool,
  header: PropTypes.string,
  overrideStyle: PropTypes.object,
  showEmptyState: PropTypes.bool,
  ...stackedBarChartType,
  emptyStateInfo: emptyStateInfoType,
  chartHeight: PropTypes.number,
  imagesAxis: PropTypes.bool,
  chartOverrideStyle: PropTypes.object,
  formatter: PropTypes.func
}

export default StackedBarChartBox
