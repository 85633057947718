import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import Placeholder from '../placeholder'
import { ToriiSelectAsync } from '../select'
import noop from 'lodash/noop'
import { MAX_ITEMS_TO_SHOW } from '../../constants'
import { Checkbox } from '@toriihq/design-system'

const CSS = {
  option: css({
    display: 'flex',
    alignItems: 'center'
  }),
  checkbox: css({
    marginRight: '8px',
    height: '16px'
  }),
  optionContent: css({
    flex: 1,
    minWidth: 0
  })
}

class MultipleCheckboxSelectAsync extends React.Component {
  open = () => {
    this.selectRef.open()
  }

  renderOption = ({ data: option }) => {
    const { renderOption, value, valueKey = 'value' } = this.props
    const optionChecked = value && value.filter(Boolean).some(selectedOption => selectedOption && option[valueKey] === selectedOption[valueKey])

    return (
      <div key={option.value} {...CSS.option}>
        {option.noCheckbox ? null : <div {...CSS.checkbox}><Checkbox checked={Boolean(optionChecked) || false} onChange={noop} /></div>}
        <div {...CSS.optionContent}>{renderOption ? renderOption(option) : option.label}</div>
      </div>
    )
  }

  render () {
    const { value, options, isLoading, onSelectResetsInput, alignPopover, valueRenderer, ...restSelectProps } = this.props

    return <Placeholder loading={isLoading} type='rect' style={{ width: '114px', height: '20px' }}>
      <ToriiSelectAsync
        optionRenderer={this.renderOption}
        valueRenderer={(props) => valueRenderer(props.data)}
        clearable={false}
        searchable
        openOnFocus
        multi
        value={value}
        removeSelected={false}
        closeOnSelect={false}
        tabSelectsValue={false}
        onSelectResetsInput={onSelectResetsInput}
        attachment={`top ${alignPopover}`}
        targetAttachment={`bottom ${alignPopover}`}
        ref={select => (this.selectRef = select)}
        {...restSelectProps}
      />
    </Placeholder>
  }
}

MultipleCheckboxSelectAsync.propTypes = {
  onChange: PropTypes.func,
  renderOption: PropTypes.func,
  maxItemsToShow: PropTypes.number,
  alignPopover: PropTypes.oneOf(['right', 'left'])
}

MultipleCheckboxSelectAsync.defaultProps = {
  maxItemsToShow: MAX_ITEMS_TO_SHOW,
  alignPopover: 'left'
}

export default MultipleCheckboxSelectAsync
