import styled, { css } from 'styled-components'

export const TabText = styled.span<{ isSelected: boolean }>`
  font: ${({ theme }) => theme.typography.font.component03};
  color: ${({ theme, isSelected }) => isSelected ? theme.palette.link.primary : theme.palette.text.secondary};
  text-align: center;
  transition: color .2s;
  
  &:hover {
    color: ${({ theme }) => theme.palette.link.primary};
  }
`

export const TabList = styled.ul<{ isVertical: boolean }>`
  list-style: none;
  display: flex;
  margin-top: ${({ isVertical }) => isVertical ? '0' : '20px'};
  margin-bottom: 0;
  width: ${({ isVertical }) => isVertical ? '200px' : '100%'};
  min-width: ${({ isVertical }) => isVertical ? '200px' : 'auto'};
  padding-left: 0;
  overflow-x: auto;
  ${({ isVertical, theme }) => !isVertical && css`
    border-bottom: 1px solid ${theme.palette.border.primary};
  `}
  ${({ isVertical }) => isVertical && css`
    flex-direction: column;
  `}
`

export const Tab = styled.li<{ isSelected: boolean; isVertical: boolean }>`
  height: ${({ isVertical }) => isVertical ? '36px' : '52px'};
  width: auto;
  padding: ${({ isVertical }) => isVertical ? '0 20px 0 0' : '0'};
  margin-right: ${({ isVertical }) => isVertical ? '0' : '32px'};
  display: flex;
  align-items: center;
  justify-content: ${({ isVertical }) => isVertical ? 'flex-start' : 'center'};
  box-shadow: ${({ theme, isSelected, isVertical }) => !isVertical && `0px -2px 0px 0px ${isSelected ? theme.palette.link.primary : theme.palette.background.primary} inset`};
  
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }
  
  &:hover {
    color: ${({ theme }) => theme.palette.link.primary};
    transition: color .2s;
    
    [class*="Counter__Wrapper-design-system"] {
      background-color: ${({ theme }) => theme.palette.background.secondaryActive};
      color: ${({ theme }) => theme.palette.text.primary};
    }
    
    a > span {
      color: ${({ theme }) => theme.palette.link.primary} !important;
      transition: color .2s;
    }
    
    .lockIcon {
      opacity: 1;
    }
  }
`

export const TabLink = styled.a<{ isVertical: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ isVertical }) => isVertical ? 'flex-start' : 'center'};
  height: 100%;
  width: 100%;
  
  ${({ isVertical }) => isVertical && css`
    padding: 8px 0;
    gap: 8px !important;
    
    > span {
      padding-right: 0;
    }
  `}
`

export const TabContainer = styled.div<{ isVertical: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ isVertical }) => isVertical && css`
    width: 200px;
    min-width: 200px;
    position: relative;
    
    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 1px;
      background-color: ${({ theme }) => theme.palette.border.primary};
    }
  `}
`

export const ContentWrapper = styled.div<{ showVertical: boolean }>`
  ${({ showVertical }) => showVertical && css`
    display: flex;
    width: 100%;
    margin-top: 32px;
    gap: 24px;
  `}
`

export const TabContent = styled.div<{ isVertical: boolean }>`
  overflow: initial;
  flex: 1;
  min-width: 0;
  ${({ isVertical }) => !isVertical && css`
    padding-top: 30px;
  `}
`

export const LockIcon = styled.div<{ show: boolean }>`
  margin-left: 8px;
  opacity: ${({ show }) => show ? 1 : 0};
  transition: opacity 200ms;
  height: 20px;
  width: 20px;
`
