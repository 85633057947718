import { css } from 'glamor'
import colors from '@shared/style/colors'

export const CSS = {
  divWrapSelectWithError: css({
    display: 'flex',
    flexDirection: 'column'
  }),
  outer: css({
    ' .Select-option:first-child': {
      borderBottom: `1px solid ${colors.lightBlue2}`
    }
  })
}
