import React, { ReactElement, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { toggleDashboardWidgetEditConfigDrawer } from '@store/actions/dashboards'
import { getUserPreferences } from '@selectors/ui'
import { getCurrentOrg } from '@selectors/org'
import { updateDashboard } from '@actions/dashboards'
import DashboardWidgets from '@components/dashboard'
import { AlertBox, AlertBoxType, Body1, Button, ButtonSize, ButtonType, Stack } from '@toriihq/design-system'
import {
  ButtonsContainer,
  DashboardChangesText,
  DashboardChangesContainer,
  DashboardContentElement,
  ReminderWrapper,
  AnimatedDashboardTitle
} from './styles'
import Placeholder from '@components/placeholder'
import PageHeader from '@root/components/pageHeader'
import { SCOPES } from '@root/constants.t'
import { Dashboard as DashboardType, WIDGET_TYPES } from '@reducers/dashboards/types'
import { isEqual } from 'lodash'
import { getWidgetTableKey } from '@components/dashboard/widgets/shared'
import { UPDATE_USER_PREFERENCES } from '@root/constants'
import keyBy from 'lodash/keyBy'
import Analytics from '@components/dashboard/analytics'
import { DashboardPageProps } from './types'
import { FEATURES } from '@shared/features'
import useUnsavedChanges from '@shared/hooks/useUnsavedChanges'
import CancelChangesPopup from '@components/popups/unsavedChangesPopup'
import initialDashboardsBySystemKey from '@actions/initialDashboards'
import ButtonOfFeature from '@root/components/buttonOfFeature'
import EnableFor from '@root/components/enableFor'

const EditModeButtons = React.memo(({
  scrolledDown,
  onCancelButtonClick,
  onApplyButtonClick,
  isDashboardEdited,
  disableApplyButton = false
}: {
  scrolledDown: boolean
  onCancelButtonClick: () => void
  onApplyButtonClick: () => void
  isDashboardEdited: boolean,
  disableApplyButton: boolean
}) => (
  <ButtonsContainer scrolledDown={scrolledDown}>
    <Stack direction='row' gap='space-100' justifyContent='end'>
      <DashboardChangesText><div>{isDashboardEdited ? 'Dashboard has changes' : 'No changes made' }</div></DashboardChangesText>
      <Button type={ButtonType.secondary} size={ButtonSize.small} onClick={onCancelButtonClick} label='Cancel' />
      <Button disabled={!isDashboardEdited || disableApplyButton} type={ButtonType.primary} size={ButtonSize.small} onClick={onApplyButtonClick} label='Apply changes' />
    </Stack>
  </ButtonsContainer>
))

export const BANNER_DESCRIPTION = 'Dashboard customization features are now available at the Enterprise plan tier'

const Dashboard = ({
  dashboard: initialDashboard,
  switchDashboardsMenu,
  isDrawerOpen
}: DashboardPageProps): ReactElement => {
  const dispatch = useDispatch()

  const { id: idOrg } = useSelector(getCurrentOrg)
  const userPreferences = useSelector(getUserPreferences)

  const [isDashboardEdited, setIsDashboardEdited] = useState(false)
  const [editedDashboard, setEditedDashboard] = useState<DashboardType>(initialDashboard)
  const [originalDashboard, setOriginalDashboard] = useState<DashboardType>(initialDashboard)
  const [userClosedReminder, setUserClosedReminder] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)

  const idOrgsToShowReminder = [15527, 15752, 15847, 15850, 15893, 15929, 16199, 16222, 16395, 16451, 16555,
    16580, 16623, 16662, 16682, 16693, 16720, 16722, 16741, 16742, 16756, 16775, 16832, 16878, 16885]

  const showReminder = idOrgsToShowReminder.includes(idOrg) && !isEditMode && !userClosedReminder

  useEffect(() => {
    setEditedDashboard(initialDashboard)
    setOriginalDashboard(initialDashboard)
    setIsEditMode(false)
  }, [initialDashboard])

  useEffect(() => {
    if (!isDrawerOpen || !isEditMode) {
      dispatch(toggleDashboardWidgetEditConfigDrawer({ isOpen: false, widget: {}, idDashboard: null, sections: [], onWidgetUpdate: () => {} }))
    }
  }, [isDrawerOpen, isEditMode, dispatch])

  const { shouldShowUnsavedChangesPopup, handleCancel, confirmNavigation, cancelNavigation } = useUnsavedChanges(isDashboardEdited)

  const onEditButtonClick = () => {
    editedDashboard && Analytics.clickOnEditButton({ dashboardName: editedDashboard?.title, dashboardSystemKey: editedDashboard?.systemKey })
    setIsEditMode(true)
  }

  const onApplyButtonClick = async () => {
    editedDashboard && Analytics.clickOnSaveDashboardChanges({ dashboardName: editedDashboard.title, dashboardSystemKey: editedDashboard.systemKey })
    setIsEditMode(false)

    if (editedDashboard) {
      const { id: idDashboard, ...restUpdatedDashboard } = editedDashboard
      await dispatch(updateDashboard({ idOrg, idDashboard, dashboard: restUpdatedDashboard }))
      setIsDashboardEdited(false)
    }
  }

  const updateWidgetsUserPreferences = () => {
    const editDashboardWidgetsByKey = keyBy(editedDashboard?.widgets, getWidgetTableKey)

    const updatedWidgets = originalDashboard?.widgets.reduce<Record<string, { fields: string[] }>>((acc, widget) => {
      const widgetTableKey = getWidgetTableKey(widget)
      const editedWidget = editDashboardWidgetsByKey[widgetTableKey]

      if (widget.type !== WIDGET_TYPES.FIXED_WIDGET && editedWidget.type !== WIDGET_TYPES.FIXED_WIDGET) {
        if (!isEqual(widget.drillDownConfig.fields, editedWidget.drillDownConfig.fields)) {
          acc[widgetTableKey] = { fields: widget.drillDownConfig.fields }
        }
      }

      return acc
    }, {})

    updatedWidgets && Object.keys(updatedWidgets).forEach((widgetKey) => {
      const widget = updatedWidgets[widgetKey]
      const widgetTableUserPreferences = userPreferences[widgetKey]
      const updatedWidgetUserPreferences = { ...widgetTableUserPreferences, columnsConfiguration: widget.fields }

      dispatch({
        type: UPDATE_USER_PREFERENCES,
        payload: { table: widgetKey, userPreference: updatedWidgetUserPreferences }
      })
    })
  }

  const discardChanges = () => {
    Analytics.clickOnCancelDashboardChanges({ dashboardName: editedDashboard.title, dashboardSystemKey: editedDashboard.systemKey })
    setIsEditMode(false)
    setIsDashboardEdited(false)
    setEditedDashboard(originalDashboard)
    updateWidgetsUserPreferences()
  }

  const onCancelButtonClick = () => {
    isDashboardEdited ? handleCancel() : discardChanges()
  }

  const onDashboardUpdate = (updatedDashboard: DashboardType) => {
    const isEdited = !isEqual(originalDashboard, updatedDashboard)
    setEditedDashboard(updatedDashboard)
    setIsDashboardEdited(isEdited)
  }

  const onCloseCancelChangesPopup = () => {
    cancelNavigation()
  }

  const onDiscardChanges = async () => {
    onCloseCancelChangesPopup()
    discardChanges()
    confirmNavigation()
  }

  const [scrolledDown, setScrolledDown] = useState(false)

  useEffect(() => {
    const elements = [
      document.querySelector('.drawerLeftContent'),
      document.querySelector('.mainWrapper')
    ].filter(Boolean) as HTMLElement[]

    const handleScroll = () => {
      setScrolledDown(elements.some(el => el.scrollTop > 0))
    }

    elements.forEach(el => {
      el.addEventListener('scroll', handleScroll)
    })

    return () => {
      elements.forEach(el => {
        el.removeEventListener('scroll', handleScroll)
      })
    }
  }, [])

  const dashboardTitleAndMenu = (
    <AnimatedDashboardTitle key={editedDashboard?.id}>
      <Stack gap={'space-200'}>
        <Stack direction='row' gap='space-200' alignItems='center'>
          <div>{editedDashboard?.title}</div>
          {isEditMode ? null : switchDashboardsMenu}
        </Stack>
        <Body1 color='secondary'>{editedDashboard?.description}</Body1>
      </Stack>
    </AnimatedDashboardTitle>
  )

  const editDashboardButton = isEditMode
    ? null : (
      <EnableFor scopes={[SCOPES.DASHBOARDS_WRITE]}>
        <ButtonOfFeature feature={FEATURES.DASHBOARDS.FEATURES.UPDATE_WIDGET_CONFIG} type={ButtonType.secondary} onClick={onEditButtonClick} label='Edit dashboard' />
      </EnableFor>
    )

  return (
    <>
      <CancelChangesPopup
        isOpen={shouldShowUnsavedChangesPopup}
        onKeepEditing={onCloseCancelChangesPopup}
        onCancelChanges={onDiscardChanges}
        content="This dashboard has changes that haven't been applied. If you discard now, all changes will be lost."
      />
      <div>
        {isEditMode && (
          <EditModeButtons
            scrolledDown={scrolledDown}
            onCancelButtonClick={onCancelButtonClick}
            onApplyButtonClick={onApplyButtonClick}
            isDashboardEdited={isDashboardEdited}
            disableApplyButton={editedDashboard.id === initialDashboardsBySystemKey.productTest.id as any}
          />
        )}
        <DashboardChangesContainer isEditMode={isEditMode}>
          <DashboardContentElement>
            <PageHeader
              title={dashboardTitleAndMenu}
              contentRight={editDashboardButton}
              overrideStyle={isEditMode ? { style: { paddingTop: 0 } } : {}}
            />
            <Placeholder loading={false} rows={10} style={{ maxWidth: '80%', marginTop: '10px' }}>
              {showReminder && <ReminderWrapper>
                <AlertBox type={AlertBoxType.INFORMATIVE}
                  description={BANNER_DESCRIPTION}
                  dismissible
                  onDismiss={() => setUserClosedReminder(true)}
                />
              </ReminderWrapper>
              }
              <DashboardWidgets
                dashboard={editedDashboard}
                isEditMode={isEditMode}
                onDashboardUpdate={onDashboardUpdate}
              />
            </Placeholder>
          </DashboardContentElement>
        </DashboardChangesContainer>
      </div>
    </>
  )
}

export default Dashboard
