import WaterfallChart from '@components/waterfallChartBox'
import { calculateGraphData } from '@components/renewalsSummary/utils/calculateGraphData'
import { barsConfig, legendMapping } from '@components/renewalsSummary/utils/graphConfigs'
import { CustomBar, CustomizedLabel, renderToolTip } from '@components/renewalsSummary/utils/graphCustomComponents'
import Currency from '@components/currency'
import { RenewalsChartProps } from './types'
import { ReactElement } from 'react'

const RenewalsChart = ({ baseYear, baseYearRenewals, onBucketChange }: RenewalsChartProps): ReactElement => {
  return (
    <WaterfallChart
      data={calculateGraphData({ baseYear, baseYearRenewals })}
      chartHeight={320}
      tooltipContent={renderToolTip(baseYear)}
      analyticsEvent={undefined}
      barsConfig={barsConfig({ CustomBar: CustomBar(baseYear), CustomizedLabel, onBucketChange })}
      legendMapping={legendMapping}
      formatter={(value) => <Currency value={value * 100} />}
    />
  )
}

export default RenewalsChart
