import { Reducer } from 'redux'
import { AccessReviewsState } from '@reducers/accessReviews/types'
import { CREATE_ACCESS_REVIEWS, GET_ACCESS_REVIEWS } from '@root/constants'

const initialState: AccessReviewsState = {
  isLoading: false,
  resources: {
    users: {},
    apps: {}
  },
  accessReviews: []
}

const accessReviewsReducer: Reducer<AccessReviewsState> = (state = initialState, action) => {
  switch (action.type) {
    case `${CREATE_ACCESS_REVIEWS}_PENDING`: {
      return {
        ...state,
        isLoading: true
      }
    }

    case `${CREATE_ACCESS_REVIEWS}_RESPONSE`: {
      const { accessReviews, resources } = action.payload
      return {
        ...state,
        accessReviews: [...state.accessReviews, ...accessReviews],
        resources: {
          users: { ...state.resources.users, ...resources.users },
          apps: { ...state.resources.apps, ...resources.apps }
        },
        isLoading: false
      }
    }

    case `${GET_ACCESS_REVIEWS}_PENDING`: {
      return {
        ...state,
        isLoading: true
      }
    }

    case `${GET_ACCESS_REVIEWS}_RESPONSE`: {
      const { accessReviews, resources } = action.payload
      return {
        ...state,
        accessReviews: accessReviews,
        resources: {
          users: resources.users,
          apps: resources.apps
        },
        isLoading: false
      }
    }

    default: {
      return state
    }
  }
}

export default accessReviewsReducer
