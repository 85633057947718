import { RENEWALS_SUMMARY_BASE_YEAR_CHANGE } from '../../../constants'

export const renewalsSummaryBaseYearChange = ({ baseYear }) => async (dispatch) => {
  dispatch({
    type: RENEWALS_SUMMARY_BASE_YEAR_CHANGE,
    payload: { renewalsSummaryBaseYear: baseYear }
  })
}

export * from './theme'
