import { getCurrentUsersFieldValues, getAppsFieldValues, getAppsUsersFieldValues } from '@store/actions'
import { useSelector, useDispatch } from 'react-redux'
import { sortBy } from 'lodash'
import { TABLES, fieldTypes, formFieldTypes, formFieldTypesToFieldTypes } from '@root/constants'
import { getExpensesTablesInfo } from '@selectors/tables'
import {
  getCurrentUsersFieldValues as getCurrentUsersFieldValuesSelector,
  getUsersMetadata as getUsersMetadataSelector
} from '@selectors/users'
import {
  getAppsMetadata as getAppsMetadataSelector,
  getFieldValues as getAppFieldValuesSelector
} from '@selectors/apps'
import {
  getAppUsersMetadata as getAppUsersMetadataSelector,
  getAppUsersFieldValues as getAppUsersFieldValuesSelector
} from '@selectors/appUsers'
import { getContractsFields as getContractsFieldsSelector, getContractsTablesInfo, getContractFilterOptionsValuesPerKey } from '@selectors/contracts'
import { getTransactionsFilterOptionsValuesPerKey } from '@selectors/transactions'
import { getCurrentOrg } from '@root/store/selectors/org'
import { ENTITY_TYPES } from '@root/store/reducers/dashboards/types'
import { removeRestrictedFilterOptions } from '@shared/filters'
import { getSelf } from '@selectors/me'
import { APP_USERS_EXPORT_LIMIT, TRANSACTIONS_EXPORT_LIMIT } from '@root/constants.t'

export const DATE_FIELD_TYPES = [formFieldTypes.datePicker, fieldTypes.date]

export const getWidgetTableKey = (widget) => `${widget.entityType}-widget-${widget.id}`
export const getTableWidgetTableKey = (widget) => `${widget.entityType}-tableWidget-${widget.id}`

export const useGetWidgetFieldsDataByEntityType = (entityType: ENTITY_TYPES) => {
  const dispatch = useDispatch()
  const org = useSelector(getCurrentOrg)
  const me = useSelector(getSelf)

  const userFieldValues = useSelector(getCurrentUsersFieldValuesSelector)
  const usersMetadata = useSelector(getUsersMetadataSelector)
  const appFieldValues = useSelector(getAppFieldValuesSelector)
  const appsMetadata = useSelector(getAppsMetadataSelector)
  const contractFields = useSelector(getContractsFieldsSelector)
  const contractTableInfo = useSelector(getContractsTablesInfo)[TABLES.contractsTable.key]
  const contractFilterOptionsValuesPerKey = useSelector(getContractFilterOptionsValuesPerKey)
  const expensesTableInfo = useSelector(getExpensesTablesInfo)[TABLES.allExpensesTable.key]
  const expensesFieldValuesPerKey = useSelector(getTransactionsFilterOptionsValuesPerKey)
  const appUsersFieldValues = useSelector(getAppUsersFieldValuesSelector)
  const appUsersMetadata = useSelector(getAppUsersMetadataSelector)

  let fieldsOptions
  let filtersOptions
  let fieldOptionsValuesPerKey
  let fetchFieldValues

  switch (entityType) {
    case ENTITY_TYPES.USERS:
      const usersFieldsData = getUsersFieldsData({ org, me, usersMetadata, userFieldValues, dispatch })

      fieldsOptions = usersFieldsData.fieldsOptions
      filtersOptions = usersFieldsData.filtersOptions
      fieldOptionsValuesPerKey = usersFieldsData.fieldOptionsValuesPerKey
      fetchFieldValues = usersFieldsData.fetchFieldValues
      break
    case ENTITY_TYPES.APPS:
      const appsFieldsData = getAppsFieldsData({ org, me, appsMetadata, appFieldValues, dispatch })

      fieldsOptions = appsFieldsData.fieldsOptions
      filtersOptions = appsFieldsData.filtersOptions
      fieldOptionsValuesPerKey = appsFieldsData.fieldOptionsValuesPerKey
      fetchFieldValues = appsFieldsData.fetchFieldValues
      break
    case ENTITY_TYPES.CONTRACTS:
      fieldsOptions = sortBy([
        ...TABLES.contractsTable.filtersOptions,
        ...(contractFields.filter(field => field.isShown).map(field => ({ label: field.name, value: field.systemKey, type: field.type })))
      ], 'label')
      filtersOptions = contractTableInfo.filtersOptions
      fieldOptionsValuesPerKey = contractFilterOptionsValuesPerKey
      fetchFieldValues = () => {}
      break
    case ENTITY_TYPES.TRANSACTIONS:
      const TRANSACTIONS_FIELDS_VALUES_TO_EXCLUDE = new Set(['idExternalTransaction'])
      fieldsOptions = sortBy(TABLES.allExpensesTable.filtersOptions.filter(f => !TRANSACTIONS_FIELDS_VALUES_TO_EXCLUDE.has(f.value)), 'label')
      filtersOptions = expensesTableInfo.filtersOptions
      fieldOptionsValuesPerKey = expensesFieldValuesPerKey
      fetchFieldValues = () => {}
      break
    case ENTITY_TYPES.APP_USERS:
      const appUsersFieldsData = getAppUsersFieldsData({ org, me, appUsersMetadata, appUsersFieldValues, dispatch })

      fieldsOptions = appUsersFieldsData.fieldsOptions
      filtersOptions = appUsersFieldsData.filtersOptions
      fieldOptionsValuesPerKey = appUsersFieldsData.fieldOptionsValuesPerKey
      fetchFieldValues = appUsersFieldsData.fetchFieldValues
      break
    default:
      break
  }

  return { fieldsOptions, filtersOptions, fieldOptionsValuesPerKey, fetchFieldValues }
}

export const getAppsFieldsData = ({ org, me, appsMetadata, appFieldValues, dispatch }) => {
  const APP_FIELDS_VALUES_TO_EXCLUDE = new Set(['id'])
  const allFields = appsMetadata.predefinedFields.concat(appsMetadata.customFields).map(field => ({ ...field, label: field.name, value: field.systemKey }))
  const allFieldsWithRestrictions = removeRestrictedFilterOptions(allFields, { me: me, org: org })

  const fieldsOptions = sortBy(allFields.filter(f => !APP_FIELDS_VALUES_TO_EXCLUDE.has(f.systemKey)), 'name')
  const filtersOptions = allFieldsWithRestrictions.map(field => ({ ...field, type: formFieldTypesToFieldTypes[field.type] }))
  const fieldOptionsValuesPerKey = appFieldValues
  const fetchFieldValues = (field) => {
    const selectedFilterOption = filtersOptions.find(f => f.value === field) || {}
    const isFieldSupportValues = [fieldTypes.text, fieldTypes.user, fieldTypes.dropdown, fieldTypes.dropdownMulti, fieldTypes.userMulti].includes(selectedFilterOption.type)
    if (isFieldSupportValues) {
      dispatch(getAppsFieldValues({ idOrg: org.id, fields: [field] }))
    }
  }

  return { fieldsOptions, filtersOptions, fieldOptionsValuesPerKey, fetchFieldValues }
}

export const getUsersFieldsData = ({ org, me, usersMetadata, userFieldValues, dispatch }) => {
  const allFields = usersMetadata.predefinedFields.concat(usersMetadata.customFields).map(field => ({ ...field, label: field.name, value: field.systemKey }))
  const allFieldsWithRestrictions = removeRestrictedFilterOptions(allFields, { me: me, org: org })

  const fieldsOptions = sortBy(allFields, 'name')
  const filtersOptions = allFieldsWithRestrictions.map(field => ({ ...field, type: formFieldTypesToFieldTypes[field.type] }))
  const fieldOptionsValuesPerKey = userFieldValues

  const fetchFieldValues = (field) => {
    const selectedFilterOption = filtersOptions.find(f => f.value === field) || {}
    const isFieldSupportValues = [fieldTypes.text, fieldTypes.user, fieldTypes.dropdownMulti].includes(selectedFilterOption.type)
    if (isFieldSupportValues) {
      dispatch(getCurrentUsersFieldValues({ idOrg: org.id, fields: [field] }))
    }
  }

  return { fieldsOptions, filtersOptions, fieldOptionsValuesPerKey, fetchFieldValues }
}

export const getAppUsersFieldsData = ({ org, me, appUsersMetadata, appUsersFieldValues, dispatch }) => {
  const allFields = appUsersMetadata.predefinedFields.concat(appUsersMetadata.customFields).map(field => ({ ...field, label: field.name, value: field.systemKey }))
  const fieldsOptions = sortBy(allFields, 'name')

  const filtersOptions = allFields.map(field => ({ ...field, type: formFieldTypesToFieldTypes[field.type] }))
  const fieldOptionsValuesPerKey = appUsersFieldValues
  const fetchFieldValues = (field) => {
    const selectedFilterOption = filtersOptions.find(f => f.value === field) || {}
    const isFieldSupportValues = [fieldTypes.name, fieldTypes.text, fieldTypes.dropdownMulti, fieldTypes.user].includes(selectedFilterOption.type)
    if (isFieldSupportValues) {
      dispatch(getAppsUsersFieldValues({ idOrg: org.id, status: undefined, fields: [field] }))
    }
  }

  return { fieldsOptions, filtersOptions, fieldOptionsValuesPerKey, fetchFieldValues }
}

export const getExportRowLimit = (entityType) => {
  switch (entityType) {
    case ENTITY_TYPES.TRANSACTIONS:
      return TRANSACTIONS_EXPORT_LIMIT
    case ENTITY_TYPES.APP_USERS:
      return APP_USERS_EXPORT_LIMIT
    default:
      return undefined
  }
}
