import { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts'
import { css } from 'glamor'
import Placeholder from '../../placeholder'
import pick from 'lodash/pick'
import { H4, Body2 } from '@toriihq/design-system'
import { clearButton } from '@root/shared/style/mixins'

const CSS = {
  main: css({
    width: '100%',
    height: '100%',
    minHeight: '197px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row'
  }),
  legend: (isOverflowing) => css({
    width: '60%',
    minWidth: '150px',
    marginLeft: '-50px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: isOverflowing ? 'flex-start' : 'center'
  }),
  chartContainer: css({
    width: '40%',
    minWidth: '325px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingRight: '5px',
    '> .recharts-responsive-container': {
      maxWidth: '150px'
    }
  }),
  table: css({
    borderCollapse: 'separate',
    borderSpacing: '0 1em',
    padding: '9px 0px'
  }),
  square: css({
    display: 'inline-block',
    width: '10px',
    height: '10px',
    marginRight: '8px',
    borderRadius: '3px'
  }),
  button: css(clearButton, {
    whiteSpace: 'nowrap'
  })
}

const ToriiPieChart = ({ header, data, loading, hasData, customToolTip, valueDisplayFunction, overrideStyle, onPieClick }) => {
  const legendRef = useRef(null)
  const [isOverflowing, setIsOverflowing] = useState(false)

  useEffect(() => {
    if (legendRef.current) {
      setIsOverflowing(legendRef.current.scrollHeight > legendRef.current.clientHeight)
    }
  }, [data, loading])

  data = hasData ? data : [{ name: 'None', value: 1, color: '#EBEBEB' }]
  const pieData = data.map(entry => pick(entry, ['name', 'value', 'color', 'minValue', 'count']))

  // Function to determine if an entry is clickable
  const isEntryClickable = (entry) => onPieClick && hasData && entry.name !== 'Other'

  const handlePieClick = (data, index, event) => {
    if (isEntryClickable(data)) {
      if (event && event.stopPropagation) {
        event.stopPropagation()
      }
      onPieClick(pieData[index])
    }
  }

  const handleLegendClick = (entry, index, event) => {
    if (onPieClick && hasData) {
      event?.stopPropagation()
      onPieClick(entry)
    }
  }

  return (
    <div {...css(CSS.main, overrideStyle)}>
      <div {...CSS.chartContainer}>
        <Placeholder loading={loading} type='round' style={{ margin: 'auto', maxWidth: '150px', maxHeight: '150px' }}>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                data={pieData}
                dataKey='value'
                innerRadius={'65%'}
                outerRadius={'100%'}
                startAngle={90}
                endAngle={-270}
                onClick={handlePieClick}
              >
                {data.map((entry) => {
                  return (
                    <Cell
                      key={entry.name}
                      fill={entry.color}
                      cursor={isEntryClickable(entry) ? 'pointer' : 'default'}
                    />
                  )
                })}
              </Pie>
              {hasData && <Tooltip content={customToolTip} cursor={false} />}
            </PieChart>
          </ResponsiveContainer>
        </Placeholder>
      </div>
      <div {...CSS.legend(isOverflowing)} ref={legendRef}>
        {header && <H4>{header}</H4>}
        <Placeholder loading={loading} rows={5} style={{ maxWidth: '100%', margin: 'auto' }}>
          <table {...CSS.table}>
            <tbody>{hasData && data.map((entry, index) => {
              return (
                <tr key={`${entry.name}-${index}`}>
                  {entry.icon ? <td> {entry.icon}</td> : <td><span {...CSS.square} style={{ backgroundColor: entry.color }} /></td>}
                  <td width='60%'>
                    <Body2>
                      <button
                        type='button'
                        onClick={isEntryClickable(entry) ? (event) => handleLegendClick(entry, index, event) : undefined}
                        disabled={!isEntryClickable(entry)}
                        {...CSS.button}
                      >
                        {entry.name}
                      </button>
                    </Body2>
                  </td>
                  <td width='40%'>{valueDisplayFunction(entry.value)}</td>
                </tr>
              )
            })}</tbody>
          </table>
        </Placeholder>
      </div>
    </div>
  )
}

ToriiPieChart.propTypes = {
  hasData: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.element,
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired
  })),
  loading: PropTypes.bool,
  customTooltip: PropTypes.element,
  valueDisplayFunction: PropTypes.func,
  onPieClick: PropTypes.func
}

ToriiPieChart.defaultProps = {
  valueDisplayFunction: (value) => value
}

export default ToriiPieChart
