import React, { Component } from 'react'
import ToriiPopup from '../ToriiPopupV2'
import DetailHistoryTable from '../../detailHistoryTable'
import { TABLES } from '@root/constants'

const tableStyle = { minHeight: 300 }

class AppDetailHistoryPopup extends Component {
  componentDidMount () {
    const { getAppDetailsHistoryValues, idOrg, idApp, detail } = this.props
    getAppDetailsHistoryValues({ idOrg, idApp, idField: detail.idField })
  }

  render () {
    const { isOpen, cancel, close, detail, data = [], currencySymbol, usersMap, defaultSort, loading } = this.props

    return (
      <ToriiPopup
        isOpen={isOpen}
        onCloseAction={cancel}
        width='950px'
        height='548px'
        center={false}
      >
        <ToriiPopup.Header header={`History for: ${detail.name}`} />
        <ToriiPopup.Content>
          <DetailHistoryTable
            tableKey={TABLES.appDetailHistoryPopupTable.key}
            data={data}
            detail={detail}
            usersMap={usersMap}
            loading={loading}
            defaultSort={defaultSort}
            close={close}
            tableStyle={tableStyle}
            currencySymbol={currencySymbol}
          />
        </ToriiPopup.Content>
      </ToriiPopup>
    )
  }
}

export default AppDetailHistoryPopup
