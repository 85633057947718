import styled from 'styled-components'

export const WidgetContainer = styled.div`
  position: fixed;
  right: 40px;
  bottom: 20px;
  z-index: 9999;
`

export const WidgetButton = styled.button`
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.background.primary};
  color: white;
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`

export const ChatWindow = styled.div<{
  $isOpen: boolean;
}>`
  position: absolute;
  bottom: 80px;
  right: 0;
  width: 450px;
  height: 550px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transform-origin: bottom right;
  opacity: ${props => props.$isOpen ? 1 : 0};
  visibility: ${props => props.$isOpen ? 'visible' : 'hidden'};
  transform: scale(${props => props.$isOpen ? 1 : 0.9});
  transition: opacity 0.2s ease-out, transform 0.2s ease-out, visibility 0s ${props => props.$isOpen ? '0s' : '0.2s'};
  
  @keyframes scaleIn {
    from { transform: scale(0.9); opacity: 0; }
    to { transform: scale(1); opacity: 1; }
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.background.primary};
  color: ${({ theme }) => theme.palette.text.primary};
`

export const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`

export const IconContainer = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity 0.2s ease-out, transform 0.2s ease-out;
  
  &.active {
    opacity: 1;
    transform: scale(1);
  }
`
