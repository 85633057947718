import React from 'react'
import { css } from 'glamor'
import { colors } from '../../../shared/style/colors'
import AppDetails from '../../appDetails'
import Table from '../../table'
import { fontWeight } from '../../../shared/style/sizes'
import { Checkbox } from '@toriihq/design-system'
import debounce from 'lodash/debounce'
import EnableFor from '../../enableFor'
import { SCOPES } from '../../../constants'

const CSS = {
  nameAndIcon: css({
    ':hover': {
      color: colors.blue
    }
  }),
  radioButtonsContainer: css({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    lineHeight: '35px'
  }),
  radioButtonContainer: css({
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '10px',
    minWidth: '250px',
    maxWidth: '250px',
    alignItems: 'center'
  }),
  radioText: css({
    paddingLeft: '10px',
    fontWeight: fontWeight.bold,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  })
}

class LicensesSelect extends React.Component {
  debouncedOnChange = debounce(this.props.onChange, 2000)

  onChange = (idLicense, selected) => {
    const { value, onChange } = this.props
    let newValue
    if (selected) {
      newValue = [...value, idLicense]
    } else {
      newValue = value.filter(id => id !== idLicense)
    }

    onChange(newValue, true)
    this.debouncedOnChange(newValue, false)
  }

  getColumns = () => {
    return [
      {
        id: 'application',
        accessor: 'name',
        Header: 'Application',
        resizable: false,
        minWidth: 200,
        maxWidth: 250,
        sortable: true,

        className: CSS.nameAndIcon.toString(),
        Cell: ({ original: { id, name, category, imageUrl } }) => (
          <AppDetails
            id={id}
            name={name}
            category={category}
            imageUrl={imageUrl}
            component='Workflow user stopped using license'
          />
        )
      },
      {
        id: 'licenses',
        getProps: () => ({ props: this.props }),
        sortable: false,
        Cell: ({ original: { licenses } }) => {
          return (
            <div {...CSS.radioButtonsContainer}>
              {
                licenses.map(license => {
                  return (
                    <div {...CSS.radioButtonContainer} key={license.idLicense}>
                      <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
                        <Checkbox checked={license.isSelected} onChange={(e) => this.onChange(license.idLicense, e.target.checked)} />
                      </EnableFor>
                      <EnableFor scopes={[SCOPES.AUTOMATION_WRITE]}>
                        <span {...CSS.radioText} onClick={() => this.onChange(license.idLicense, !license.isSelected)}>{license.name}</span>
                      </EnableFor>
                    </div>
                  )
                })
              }
            </div>
          )
        }
      }
    ]
  }

  componentDidMount () {
    const { idOrg, getAppsLicenses } = this.props
    getAppsLicenses({ idOrg })
  }

  getTrGroupProps = (_, { original: { anyLicenseSelected } }) => {
    return {
      style: {
        backgroundColor: anyLicenseSelected ? colors.lightBlue3 : ''
      }
    }
  }

  getTableStyle = () => {
    return {
      style: {
        flex: 'initial',
        height: '600px'
      }
    }
  }

  columns = this.getColumns()

  render () {
    const { appsLicenses, tableInfo, loading } = this.props

    return (
      <Table
        tableKey={tableInfo.key}
        setTrStyleClassName='workflow-apps-licenses-table'
        data={appsLicenses}
        columns={this.columns}
        getTrGroupProps={this.getTrGroupProps}
        getTableProps={this.getTableStyle}
        loading={loading}
      />
    )
  }
}

export default LicensesSelect
