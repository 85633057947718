import React, { ReactNode, useMemo } from 'react'
import { Counter, Spacer, Icon, generateAttributeId } from '@toriihq/design-system'
import Page from '../../components/page'
import PageHeader from '../../components/pageHeader'
import { Link, useLocation, useParams } from 'react-router-dom'
import Analytics from '@helpers/analytics'
import capitalize from 'lodash/capitalize'
import VisibleFor from '@root/components/visibleFor'
import SuggestToUpgrade from '@components/suggestToUpgrade'
import { useSelector } from 'react-redux'
import { getIsSmallScreen } from '@selectors/ui'
import { compact } from 'lodash'
import AccessControl from '@lenses/accessControl'
import * as Style from './style'
import { getOrgFeatures } from '@root/store/selectors/org'

interface TabHeader {
  header: string | ReactNode
  counter?: number
  isLoading?: boolean
  needsAttention?: boolean
  accessScopes?: string[]
}

export interface Props {
  pageHeader: string | React.ReactElement
  menuButtonText?: string
  menuButtonOnClick?: () => void
  menuButtonOutline?: boolean
  pageTitle: string
  tabsName: string[]
  path: string
  tabsHeader: TabHeader[]
  tabsContent: ReactNode[]
  tabsFeature?: string[]
  headerButtonAllowForToriiAdmin?: boolean
  vertical?: boolean
  contentRight?: ReactNode
  contentBelow?: ReactNode
  pageName: string
  hideTabs?: boolean
  isSecondary?: boolean
  backLink?: React.ReactElement
  allowedScopes?: string[]
}

interface RootState {
  ui: {
    isSmallScreen: boolean
  }
}

const TabsPage = ({
  tabsHeader,
  tabsContent,
  pageHeader,
  pageTitle,
  tabsFeature = [],
  menuButtonText = '',
  menuButtonOnClick = () => true,
  menuButtonOutline = true,
  allowedScopes = [],
  path,
  tabsName,
  headerButtonAllowForToriiAdmin = false,
  vertical = false,
  contentRight,
  contentBelow,
  pageName,
  hideTabs = false,
  isSecondary = false,
  backLink
}: Props) => {
  const location = useLocation()
  const params = useParams() as { tabName?: string }
  const isSmallScreen = useSelector((state: RootState) => getIsSmallScreen(state) || false)
  const orgFeatures = useSelector(getOrgFeatures)

  const nonAccessibleTabIndices = useMemo(() => {
    return compact(tabsFeature.map((feature, index) =>
      AccessControl.isFeatureEnabledInPlan({ feature, state: { org: { features: orgFeatures } } }) ? null : index
    )).filter((index): index is number => index !== null)
  }, [tabsFeature, orgFeatures])

  const tabName = useMemo(() => {
    if (isSecondary) {
      const pathBreakdown = location.pathname.split('/')
      return pathBreakdown[pathBreakdown.length - 1]
    }
    return params.tabName || tabsName[0]
  }, [isSecondary, location.pathname, params.tabName, tabsName])

  const selectedIndex = useMemo(() => {
    const tabIndex = tabsName.indexOf(tabName)
    return tabIndex === -1 ? 0 : tabIndex
  }, [tabName, tabsName])

  const showVertical = useMemo(() => vertical && !isSmallScreen, [vertical, isSmallScreen])
  const isNonAccessibleTabSelected = useMemo(() => nonAccessibleTabIndices.includes(selectedIndex), [nonAccessibleTabIndices, selectedIndex])

  const onTabClick = (tabName: string) => {
    Analytics.track(`Click on tab / ${pageName}`, {
      Tab: capitalize(tabName)
    })
  }

  const tabHeaderCounter = (
    header: string | ReactNode,
    counter: number | undefined,
    isSelected?: boolean,
    isLoading?: boolean,
    needsAttention?: boolean
  ) => {
    const shouldShowCounter = !isLoading && Boolean(counter)
    const counterComponent = shouldShowCounter && counter !== undefined ? (
      <Counter value={counter} selected={isSelected} needsAttention={needsAttention} />
    ) : null
    const counterComponentWrap = showVertical
      ? counterComponent
      : counterComponent && (
        <Spacer left={'space-100'}>
          {counterComponent}
        </Spacer>
      )
    return <>
      <Style.TabText isSelected={!!isSelected}>{header}</Style.TabText>
      {counterComponentWrap}
    </>
  }

  const tabsMenu = (
    <Style.TabContainer isVertical={showVertical}>
      <Style.TabList isVertical={showVertical}>
        {tabsHeader.map((tabHeader, index) => {
          const isSelected = selectedIndex === index
          const { header, counter, isLoading, needsAttention = false, accessScopes } = tabHeader
          const isTabLocked = nonAccessibleTabIndices.includes(index)
          const shouldShowLockIcon = ((isSelected || !showVertical) && isTabLocked)

          return (
            <VisibleFor key={`visibleFor-header-${header}`} scopes={accessScopes || []}>
              <Style.Tab
                key={`header_${index}`}
                isSelected={isSelected}
                isVertical={showVertical}
                data-intercom-target={generateAttributeId(`intercom-tab-${header}`)}
              >
                <Link
                  onClick={() => onTabClick(tabsName[index])}
                  to={`${path}/${tabsName[index]}`}
                >
                  {tabHeaderCounter(header, counter, isSelected, isLoading, needsAttention)}
                </Link>
                {isTabLocked && (
                  <Style.LockIcon show={shouldShowLockIcon} className='lockIcon'>
                    <Icon name='Lock' color='inherit' />
                  </Style.LockIcon>
                )}
              </Style.Tab>
            </VisibleFor>
          )
        })}
      </Style.TabList>
    </Style.TabContainer>
  )

  const header = (
    <div style={{ flex: 1 }}>
      {pageHeader && <PageHeader
        allowForToriiAdmin={headerButtonAllowForToriiAdmin}
        title={pageHeader}
        menuButtonText={menuButtonText}
        menuButtonOnClick={menuButtonOnClick}
        menuButtonOutline={menuButtonOutline}
        allowedScopes={allowedScopes}
        contentRight={contentRight as any}
        contentBelow={contentBelow as any}
        isSecondary={isSecondary}
        overrideStyle={{ style: { marginBottom: 0, paddingBottom: 0 } }}
      />}
      {!showVertical && !hideTabs && tabsMenu}
    </div>
  )

  const overrideHeaderStyle = { marginBottom: 0, paddingBottom: 0 }
  return (
    <Page title={pageTitle} overrideHeaderStyle={overrideHeaderStyle}>
      {backLink}
      {header}
      <Style.ContentWrapper showVertical={showVertical}>
        {showVertical && tabsMenu}
        <Style.TabContent isVertical={showVertical}>
          {isNonAccessibleTabSelected ? (
            <SuggestToUpgrade feature={tabsFeature[selectedIndex]} />
          ) : (
            tabsContent[selectedIndex]
          )}
        </Style.TabContent>
      </Style.ContentWrapper>
    </Page>
  )
}

export default TabsPage
