import React from 'react'
import PropTypes from 'prop-types'
import { css } from 'glamor'
import { oneLiner } from '@shared/style/mixins'
import moment from 'moment'
import openConnectServicePopup from '@shared/services/openConnectServicePopup'
import Placeholder from '../../placeholder'
import InputPopup from '../../popups/inputPopup'
import RelativeTeamLink from '../../relativeTeamLink'
import Analytics from '@helpers/analytics'
import * as Style from './style'
import OverflowTooltip from '@components/overflowTooltip'
import { Icon, Tooltip } from '@toriihq/design-system'
import { APP_PERMISSIONS_LABEL, DATE_FORMAT } from '@root/constants'

class Service extends React.Component {
  state = {
    isEditAppAccountNamePopupOpen: false,
    showDisconnectConfirmation: false,
    isDropDownMenuOpen: false
  }

  renderNotConnected = () => {}
  renderConnected = () => {}
  renderStatusIcon = () => {}
  renderLogo = () => {}
  renderDisconnectServiceConfirmationPopup = () => {}

  onConnect = ({ isReconnect = false }) => {
    const { id, idApp, idAppToken, idAppAccount, integrationType, appName, toggleConnectSource, toggleConnectService, toggleConnectSCIMService, supportsMultipleAccounts = false, hideConnectByLink } = this.props

    // e.preventDefault()
    Analytics.track('Click to add integration / Integrations', {})

    const connectedServiceData = { idAppAccount, idAppToken }
    const dispatchers = { toggleConnectSource, toggleConnectService, toggleConnectSCIMService }

    openConnectServicePopup({
      idApp,
      appName,
      integrationType,
      source: id,
      supportsMultipleAccounts,
      calledFrom: 'Services',
      dispatchers,
      connectedServiceData,
      isReconnect,
      hideConnectByLink
    })
  }

  onDisconnect = () => {
    const { appName, appAccountName, source, integrationType } = this.props
    this.setState({ showDisconnectConfirmation: true })
    Analytics.track('Click on disconnect integration', {
      'App name': appName,
      'Account name': appAccountName,
      'Source': source,
      'Integration Type': integrationType
    })
  }

  onConfirmDisconnection = async () => {
    const { getServicesSyncData, disconnectService, getOrg, idOrg, getAppDetailsFields, getStateDetails, appName, appAccountName, integrationType } = this.props
    await disconnectService()
    await getOrg({ idOrg })
    await getAppDetailsFields({ idOrg })
    await getStateDetails({ idOrg })
    await getServicesSyncData({ idOrg })
    this.setState({ showDisconnectConfirmation: false })
    Analytics.track('Confirmation popup', {
      'Type': 'Disconnect service',
      'App name': appName,
      'Account name': appAccountName,
      'Value': 'yes',
      'Integration Type': integrationType
    })
  }

  onDeclineDisconnection = () => {
    const { appName, appAccountName, integrationType } = this.props
    this.setState({ showDisconnectConfirmation: false })
    Analytics.track('Confirmation popup', {
      'Type': 'Disconnect service',
      'App name': appName,
      'Account name': appAccountName,
      'Value': 'no',
      'Integration Type': integrationType
    })
  }

  setEditAppAccountNamePopupIdOpen = (isOpen) => {
    this.setState({ isEditAppAccountNamePopupOpen: isOpen })
  }

  onSubmitNewAppAppAccountName = ({ fieldName: appAccountName }) => {
    const { source, appName } = this.props
    Analytics.track('update integration name', {
      'App name': source,
      'Previous name': appName,
      'New name': appAccountName
    })
    const { updateAppAccountName, idOrg, idAppAccount } = this.props

    return updateAppAccountName({ idOrg, idAppAccount, appAccountName })
  }

  onEditAppAccountNameCancelClick = () => {
    const { source } = this.props
    this.setEditAppAccountNamePopupIdOpen(false)
    Analytics.track('close rename integration dialog', {
      'App name': source
    })
  }

  renderEditAppAccountNamePopup = () => {
    const { isEditAppAccountNamePopupOpen } = this.state
    const { appName, appAccountName } = this.props

    return (<InputPopup
      isOpen={isEditAppAccountNamePopupOpen}
      header='Rename account'
      subHeader={appName}
      cancelButton={{ label: 'Cancel', onClick: this.onEditAppAccountNameCancelClick }}
      submitButton={{ label: 'Change', onClick: this.onSubmitNewAppAppAccountName }}
      input={{ label: 'Account Name', fieldName: appAccountName }}
      showCheckBox={false}
    />)
  }

  onMenuClickCallback = ({ isOpen }) => {
    this.setState({ isDropDownMenuOpen: isOpen })
  }

  options = (integrationType) => {
    const { excludedUIOptions } = this.props

    const options = [
      {
        type: 'item',
        label: 'Rename',
        icon: 'Edit',
        integrationType: ['native', 'custom'],
        disabled: !this.props.appAccountName,
        onClick: () => this.onMenuOptionClick({ label: 'Rename' }, integrationType)
      },
      {
        type: 'item',
        label: 'Reconnect',
        icon: 'Plug',
        integrationType: ['native'],
        onClick: () => this.onMenuOptionClick({ label: 'Reconnect' }, integrationType)
      },
      {
        type: 'item',
        label: 'Disconnect',
        icon: 'Disconnect',
        integrationType: ['native', 'custom'],
        disabled: !this.props.allowDisconnection,
        onClick: () => this.onMenuOptionClick({ label: 'Disconnect' }, integrationType)
      }
    ]
    return options.filter(option => option.integrationType.includes(integrationType) && !excludedUIOptions.includes(option.label))
  }

  onMenuOptionClick = (option, integrationType, e) => {
    const { source } = this.props
    const analyticsEvent = `Clicked to open ${option.label} ${integrationType} integration dialog`
    const analyticsProps = { 'Dialog name': `${option.label.toLowerCase()}-${integrationType}-integration-dialog`, 'Button label': option.label, 'App name': source }
    Analytics.track(analyticsEvent, analyticsProps)
    if (option.label === 'Rename') {
      this.setEditAppAccountNamePopupIdOpen(true)
    } else if (option.label === 'Reconnect') {
      this.onConnect({ isReconnect: true })
    } else if (option.label === 'Disconnect') {
      this.onDisconnect()
    }
  }

  buildConnectByMessage = ({ connectedAt, permission, connectedViaLink, connectedBy, returnAsString }) => {
    const viaSharedLinkText = connectedViaLink ? 'by a link, shared ' : ''
    const userDetails = connectedBy.firstName ? `${connectedBy.firstName} ${connectedBy.lastName}` : `${connectedBy.email}`
    if (returnAsString) {
      return `Connected on ${moment(connectedAt).format(DATE_FORMAT)} with ${APP_PERMISSIONS_LABEL[permission]} permission ${viaSharedLinkText}by ${userDetails}`
    }

    return (
      <>
        Connected on <strong>{moment(connectedAt).format(DATE_FORMAT)}</strong> with <strong>{APP_PERMISSIONS_LABEL[permission]}</strong> permission
        <br />
        {viaSharedLinkText}by <strong>{userDetails}</strong>
      </>
    )
  }

  render () {
    const { showDisconnectConfirmation } = this.state
    const {
      isConnected,
      connectedBy,
      connectedAt,
      permission,
      connectedViaLink,
      loading,
      source,
      appAccountName,
      appName,
      idApp,
      isServiceEnabledInPlan
    } = this.props

    const isToriiBot = source === 'toriiBot'

    return (
      <>
        <div {...Style.Main}>
          {this.renderEditAppAccountNamePopup()}
          <div {...Style.Container}>
            {this.renderLogo()}
            <div {...css(Style.Card, Style.CardPosition, this.state.isDropDownMenuOpen && Style.DropDownMenuOpen)}>
              <Placeholder loading={loading} type='rect' style={{ width: '100%', maxWidth: '100%', height: '20px', marginTop: '15px' }}>
                <div {...Style.CardTop}>
                  <div {...Style.Name(isConnected)}>
                    <div {...Style.NameAndLock}>
                      <span {...oneLiner}>
                        <RelativeTeamLink to={`/app/${idApp}`}>{appName}</RelativeTeamLink>
                      </span>
                      {isServiceEnabledInPlan || isConnected ? '' : <span {...Style.LockIcon}><Icon name='Lock' color='inherit' /></span>}
                    </div>
                    {appAccountName &&
                    <div {...css(oneLiner, Style.AccountNameContainer)}>
                      <OverflowTooltip label={appAccountName}><Style.AccountName>{appAccountName}</Style.AccountName></OverflowTooltip>
                    </div>
                    }
                  </div>
                  {isConnected && connectedBy && connectedAt && <Tooltip
                    label={this.buildConnectByMessage({ connectedAt, permission, connectedViaLink, connectedBy })}>
                    <div {...Style.InfoIcon}><Icon name='Info' /></div></Tooltip>}
                </div>
                <div {...Style.CardContent}>
                  {isConnected ? this.renderConnected(isToriiBot) : this.renderNotConnected()}
                </div>
              </Placeholder>
            </div>
          </div>
          {!loading && isConnected && this.renderStatusIcon()}
        </div>
        {showDisconnectConfirmation && this.renderDisconnectServiceConfirmationPopup()}
      </>
    )
  }
}

Service.propTypes = {
  source: PropTypes.string,
  lastSyncTime: PropTypes.string,
  syncStatus: PropTypes.string,
  displayName: PropTypes.string,
  logo: PropTypes.string,
  icon: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  connectionNote: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onConnect: PropTypes.func,
  onDisconnect: PropTypes.func,
  isConnected: PropTypes.bool,
  isSyncDisabled: PropTypes.bool,
  syncError: PropTypes.string,
  errorMessage: PropTypes.string,
  allowDisconnection: PropTypes.bool,
  overrideStyle: PropTypes.object,
  loading: PropTypes.bool,
  connectedViaLink: PropTypes.bool,
  showConnectAnotherService: PropTypes.bool,
  excludedUIOptions: PropTypes.arrayOf(PropTypes.string)
}

Service.defaultProps = {
  allowDisconnection: true,
  excludedUIOptions: []
}

export default Service
