import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ToriiPopup from '../ToriiPopupV2'
import { Field } from 'react-final-form'
import SelectApps from '@components/selectApps'
import SelectUsers from '@components/selectUsers'
import FormGroup from '../../form/formGroup'
import { Tooltip, Icon, ToastType, toast } from '@toriihq/design-system'
import {
  LaunchAccessReviewsPopupProps
} from '@components/popups/launchAccessReviewsPopup/types'
import {
  toggleLaunchAccessReviewsPopup,
  getAppDetailsFields as getAppDetailsFieldsAction
} from '@store/actions'
import { getLaunchAccessReviewsPopup } from '@selectors/ui'
import { getCurrentOrg } from '@selectors/org'
import { getAppDetailsFields, isAppDetailsFieldsLoading } from '@selectors/apps'
import { getAccessReviews } from '@selectors/accessReviews'
import { ACCESS_REVIEW_STATUS } from '@reducers/accessReviews/types'
import { extractSpecialUsersOptions, mandatoryFieldValidator } from './utils'
import * as Style from './style'
import { createAccessReviews } from '@actions/accessReviews'

const LaunchAccessReviewsPopup = (): JSX.Element => {
  const dispatch = useDispatch()
  const { id: idOrg } = useSelector(getCurrentOrg)
  const { isOpen }: LaunchAccessReviewsPopupProps = useSelector(getLaunchAccessReviewsPopup)
  const appFields = useSelector(getAppDetailsFields)
  const accessReviews = useSelector(getAccessReviews)
  const [formValues, setFormValues] = useState({
    appsForReview: undefined,
    reviewers: undefined
  })
  const specialUsersOptions = appFields
    ? extractSpecialUsersOptions(appFields)
    : []
  const isAppFieldsLoading = useSelector(isAppDetailsFieldsLoading)

  const getAppReviewStatus = (appId: number) => {
    const review = accessReviews.find(review =>
      review.idApp === appId &&
      (review.status === ACCESS_REVIEW_STATUS.PENDING || review.status === ACCESS_REVIEW_STATUS.ONGOING)
    )
    return review?.status
  }

  const transformApp = (app) => {
    const reviewStatus = getAppReviewStatus(app.id)
    const isDisabled = Boolean(reviewStatus)
    const tooltip = reviewStatus
      ? `This app has ${
        reviewStatus === ACCESS_REVIEW_STATUS.ONGOING ? 'an' : 'a'
      } ${reviewStatus} access review`
      : undefined

    return { ...app, disabled: isDisabled, tooltip }
  }

  useEffect(() => {
    dispatch(getAppDetailsFieldsAction({
      idOrg,
      includeDeletedOptions: false,
      shouldFilterFieldsByUserRole: false
    }))
  }, [dispatch, idOrg])

  const selectAppComponent = (props) => {
    const { input, label, meta } = props
    return (
      <FormGroup
        label={label}
        error={meta.touched && meta.error}
        isRequired
      >
        <SelectApps
          {...input}
          disableHiddenApps
          value={formValues.appsForReview}
          onChange={(app) => {
            input.onChange(app)
          }}
          multi
          closeOnSelect={false}
          transformApps={(apps) => apps.map(transformApp)}
        />
      </FormGroup>
    )
  }

  const selectReviewersComponent = (props) => {
    const { input, label, meta } = props
    return (
      <FormGroup
        label={label}
        isRequired
        error={meta.touched && meta.error}
        tooltip={
          <Tooltip
            label='An email will be sent to reviewers, prompting them to begin the user access review.'>
            <Icon name='Info' />
          </Tooltip>
        }
        styles={Style.formGroupStyle}
      >
        <SelectUsers
          {...input}
          value={input.value}
          onChange={(app) => {
            input.onChange(app)
          }}
          closeOnSelect={false}
          multi
          excludePastUsers
          specialUsers={specialUsersOptions}
          useCheckboxes
        />
      </FormGroup>
    )
  }

  const renderLaunchAccessReviewsForm = (formProps) => {
    const { handleSubmit } = formProps

    return <form onSubmit={handleSubmit} data-testid='access-review-form'>
      <div>
        <Field
          name='appsForReview'
          label='Perform access review for:'
          component={selectAppComponent}
          validate={mandatoryFieldValidator}
        />
        <Field
          name='reviewers'
          label='Reviewer:'
          component={selectReviewersComponent}
          validate={mandatoryFieldValidator}
          isLoading={isAppFieldsLoading}
        />
      </div>
    </form>
  }

  const onCancel = () => {
    // Analytics.track('Clicked to close popup: launch access reviews')
    onClose()
  }

  const onClose = () => {
    dispatch(toggleLaunchAccessReviewsPopup(false))
  }

  const handleSubmit = async (values) => {
    const appIds = values.appsForReview.map(app => app.id)
    const reviewerIds = values.reviewers.map(reviewer => reviewer.id)
    setFormValues(values)
    const { accessReviews } = await dispatch(createAccessReviews({ idOrg, idApps: appIds, reviewers: reviewerIds }))

    if (accessReviews && accessReviews.length) {
      toast({
        message: `User access review launched for ${accessReviews.length} applications and emails sent to reviewers`,
        type: ToastType.SUCCESS
      })
      onClose()
    }
  }

  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={onCancel} closeOnOverlayClick={false}>
      <ToriiPopup.Header header={'Launch access review'} />
      <ToriiPopup.Form
        initialValues={formValues}
        onSubmit={handleSubmit}
        render={renderLaunchAccessReviewsForm}
        contentAreaStyle={Style.contentAreaStyle}
        renderFooter={(formProps) => {
          const { submitError } = formProps
          return (<ToriiPopup.Footer
            cancelButtonText={'Cancel'}
            mainButtonText={'Launch'}
            isMainSubmit
            formProps={formProps}
          >
            {submitError && <div>{submitError}</div>}
          </ToriiPopup.Footer>
          )
        }} />

    </ToriiPopup>)
}

export default LaunchAccessReviewsPopup
