import styled from 'styled-components'

export const LeftPadding = styled.div`
  padding-left: 8px;
`

export const ActionCell = styled.div`
  opacity: 0;
  transition: opacity .2s;
  [role="row"]:hover &,
  [class*="rt-tr"]:hover & {
    opacity: 1;
  }
`

export const Clickable = styled.div`
  cursor: pointer;
`

export const Padding = styled.div`
  padding: 16px 20px;
`
