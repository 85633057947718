import React from 'react'
import { withRouter } from 'react-router-dom'
import { useSelector } from 'react-redux'
import TabsPage from '@pages/tabs'
import Reports from './reportsTabs/reports'
import ScheduledReports from '@components/scheduledReportsTable'
import { FEATURES } from '@shared/features'
import { getIdOrg } from '@selectors/org'
import { TABLES } from '@root/constants'

const ReportsPage = ({ match }) => {
  const idOrg = useSelector(getIdOrg)

  const tabsName = [
    'reports',
    'scheduledReports'
  ]

  const tabsHeader = [
    { header: 'Reports' },
    { header: 'Scheduled reports' }
  ]

  const tabsContent = [
    <Reports idOrg={idOrg} />,
    <ScheduledReports idOrg={idOrg} tableKey={TABLES.scheduledReportsTable.key} />
  ]

  const tabsFeature = [
    FEATURES.REPORTS.TABS.REPORTS,
    FEATURES.REPORTS.TABS.SCHEDULED_REPORTS
  ]

  return (
    <TabsPage
      pageHeader='Reports'
      pageTitle='Reports'
      pageName='Reports'
      path={`/team/${idOrg}/reports`}
      tabsName={tabsName}
      tabsHeader={tabsHeader}
      tabsContent={tabsContent}
      tabsFeature={tabsFeature}
    />
  )
}

export default withRouter(ReportsPage)
