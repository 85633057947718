import styled from 'styled-components'

interface ContainerProps {
  width?: string
  gap?: string
}

export const Container = styled.div<ContainerProps>`
  display: grid;
  grid-template-columns: 30% 1fr;
  width: ${props => props.width || '160px'};
  gap: ${props => props.gap || '32px'};
`
