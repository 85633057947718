import React, { createRef } from 'react'
import { Field } from 'react-final-form'
import { withRouter } from 'react-router-dom'
import { Stack, H3, Body1, FormElement, Input } from '@toriihq/design-system'

const required = fieldName => value => (value ? undefined : `Please choose a ${fieldName}`)

class BasicInformationSignupStep extends React.Component {
  input = {
    firstName: createRef(),
    lastName: createRef(),
    companyName: createRef()
  }

  renderField = (props) => {
    const { meta, input, ...rest } = props
    return (
      <FormElement
        label={rest.placeholder}
        errorMessage={meta.touched && Boolean(meta.error) ? meta.error : null}
      >
        <Input
          ref={this.input[input.name]}
          hasError={meta.touched && Boolean(meta.error)}
          {...input}
          {...rest}
        />
      </FormElement>
    )
  }

  render () {
    return (
      <Stack direction='column' gap='space-500'>
        <Stack direction='column' gap='space-0'>
          <H3>Welcome to Torii</H3>
          <Body1>What should we call you?</Body1>
        </Stack>
        <fieldset>
          <Stack direction='column' gap='space-300'>
            <Field
              name='firstName'
              placeholder='First Name'
              validate={required('First Name')}
              autoFocus
              component={this.renderField} />
            <Field
              name='lastName'
              placeholder='Last Name'
              validate={required('Last Name')}
              component={this.renderField} />
            <Field
              name='companyName'
              placeholder='Company Name'
              validate={required('Company Name')}
              component={this.renderField}
              disabled={Boolean(this.props.org)} />
          </Stack>
        </fieldset>
      </Stack>
    )
  }
}

export default withRouter(BasicInformationSignupStep)
