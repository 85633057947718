import { css } from 'glamor'
import colors from '@shared/style/colors'
import styled from 'styled-components'

export const Container = css({
  position: 'relative'
})
export const Color = css({
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  border: `1px solid ${colors.grey3}`
})
export const Swatch = styled.div`
  padding: 5px;
  background: #fff;
  display: flex;
  align-items: center;
  height: 32px;
  width: 51px;
  margin-right: 4px;
  justify-content: space-around;
  cursor: pointer;
  border: none;
  transition: outline .2s, box-shadow .2s, background-color .2s;
  border-radius: ${({ theme }) => theme.corner('radius-100')};
  outline: 1px solid ${({ theme }) => theme.palette.border.primary} !important;
  
  &:hover:not([disabled]) {
    outline: 1px solid ${({ theme }) => theme.palette.border.primaryHover} !important;
  }
  
  &:focus-visible:not([disabled]) {
    outline: 1px solid ${({ theme }) => theme.palette.border.active} !important;
    box-shadow: 0 0 0 3px ${({ theme }) => theme.palette.border.focus} !important;
  }
  
  &[disabled] {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.palette.background.disabledLight} !important;
    color: ${({ theme }) => theme.palette.text.textOnDisabled};
    ::placeholder {
      color: ${({ theme }) => theme.palette.text.textOnDisabled};
    }
  }
`
export const Popover = css({
  position: 'absolute',
  zIndex: '2',
  cursor: 'pointer'
})
export const Cover = css({
  position: 'fixed',
  top: '0px',
  right: '0px',
  bottom: '0px',
  left: '0px'
})
