import { DynamicWidget, Widget, FixedWidget } from '@reducers/dashboards/types'
import { IdDashboard } from '@store/types'
import { type ButtonProps } from '@toriihq/design-system'
import { formFieldTypes } from '@root/constants'

export type OnWidgetUpdateFunction = (props: { updatedWidget: Widget }) => void

export interface WidgetFormattedData {
  key: string | number
  value: number
  name: string
}

export interface WidgetProps {
  idDashboard: IdDashboard
  isSelected: boolean
  primaryButton?: JSX.Element
  isWidgetMenuOpen: boolean
  isEditMode: boolean
  onWidgetUpdate: OnWidgetUpdateFunction
}

export interface FixedWidgetProps extends WidgetProps {
  widget: FixedWidget
  onFixedWidgetFilteredDrillDown: (props: { widget: FixedWidget, [key: string]: any }) => void
}

export interface DynamicWidgetProps extends WidgetProps {
  widget: DynamicWidget
  onFilteredDrilldown?: (widget: DynamicWidget, allData: WidgetFormattedData[], selectedData?: WidgetFormattedData) => void
}

interface DropdownOption {
  label: string
  value: string
}

interface ButtonWithIconOption {
  icon: ButtonProps['icon']
  value: string
}

export enum DropdownOptionsSource {
  CLOSED_LIST = 'closedList',
  ENTITY_FIELDS = 'entityFields'
}

interface ShowOnFieldSpecificCondition {
  fieldKey: string
  comparisonType: 'value' | 'type'
  value: string | string[]
  op: string
}

interface EditableBaseField {
  key: string
  label?: string
  type: string
  showOnFieldSpecificCondition?: ShowOnFieldSpecificCondition
  disabled?: boolean
}

interface EditableBaseFieldWithGetValueFunc extends EditableBaseField {
  getValueFunc: (widget: DynamicWidget) => string | undefined
}

interface EditableBaseFieldWithValuePathInWidget extends EditableBaseField {
  valuePathInWidget: string
}

export type EditableBaseFieldWithValue = EditableBaseFieldWithGetValueFunc | EditableBaseFieldWithValuePathInWidget

type EditableNumberField = EditableBaseFieldWithValue & {
  type: typeof formFieldTypes.number
  min: number
  max: number
  setDefaultIfInvalid: (value: number) => number
}

type EditableCloseListDropdownField = EditableBaseFieldWithValue & {
  type: typeof formFieldTypes.dropdown
  dropdownOptionsSource: DropdownOptionsSource.CLOSED_LIST
  options?: DropdownOption[]
}

type EditableDynamicDropdownField = EditableBaseFieldWithValue & {
  type: typeof formFieldTypes.dropdown
  dropdownOptionsSource: DropdownOptionsSource.ENTITY_FIELDS
  filterOptions?: (fields: any[], widget?: Widget) => any[]
}

type ButtonIconField = EditableBaseFieldWithValue & {
  type: typeof formFieldTypes.buttonIcon
  dropdownOptionsSource: DropdownOptionsSource.CLOSED_LIST
  options: ButtonWithIconOption[]
}

type EditableInlineGroupField = EditableBaseField & {
  type: typeof formFieldTypes.inlineGroup
  // eslint-disable-next-line no-use-before-define
  fields: EditableField[]
}

export type EditableField = EditableBaseFieldWithValue | EditableNumberField | EditableCloseListDropdownField | EditableDynamicDropdownField | ButtonIconField | EditableInlineGroupField

export interface EditableSection {
  key: string
  label?: string
  fields: EditableField[]
}

export interface WidgetConfig {
  editableSections: EditableSection[]
}
