import styled, { css } from 'styled-components'

const borderStyle = css`
  border: none;
  transition: outline .2s, box-shadow .2s, background-color .2s;
  border-radius: ${({ theme }) => theme.corner('radius-100')};
  outline: 1px solid ${({ theme }) => theme.palette.border.primary} !important;
  &:hover:not([disabled]) {
    outline: 1px solid ${({ theme }) => theme.palette.border.primaryHover} !important;
  }
  &:focus-visible:not([disabled]) {
    outline: 1px solid ${({ theme }) => theme.palette.border.active} !important;
    box-shadow: 0 0 0 3px ${({ theme }) => theme.palette.border.focus} !important;
  }
  &[disabled] {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.palette.background.disabledLight} !important;
    color: ${({ theme }) => theme.palette.text.textOnDisabled};
    &::placeholder {
      color: ${({ theme }) => theme.palette.text.textOnDisabled};
    }
  }
`

const textStyle = css`
  font: ${({ theme }) => theme.typography.font.body02};
`

const sizeStyle = css`
  min-height: 30px;
  width: 100%;
  padding: 0 15px;
`

const controlStyle = css`
  ${borderStyle}
  ${textStyle}
  ${sizeStyle}
`

export const StyledControl = styled.div`
  ${controlStyle}
`

export const StyledControlNestedInput = styled.div`
  input {
    ${controlStyle}
  }
`

export const StyledControlNestedTextArea = styled.div`
  textarea {
    ${controlStyle}
  }
`
