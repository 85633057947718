import { formFieldTypes } from '@root/constants'
import { SpecialUserOptions } from './types'
import sortBy from 'lodash/sortBy'

export const extractSpecialUsersOptions = (fields): SpecialUserOptions[] => {
  return sortBy(
    fields
      .filter(field => [formFieldTypes.usersDropdown, formFieldTypes.usersDropdownMulti].includes(field.type))
      .map(field => ({
        id: field.systemKey,
        value: field.systemKey,
        label: field.name,
        description: field.formQuestion || 'Custom application field'
      })),
    'label'
  )
}

export const mandatoryFieldValidator = value => {
  return !(value && value?.length) ? 'Mandatory field' : undefined
}
