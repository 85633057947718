import React, { createContext, useCallback, useRef, useState, ReactNode } from 'react'

interface ToriiPopupContextType {
  onClose: () => void;
  isConfirmCloseOpen: boolean;
  setIsConfirmCloseOpen: (isOpen: boolean) => void;
  submitAction: { current: () => void };
  scopes: string[];
  setScopes: (scopes: string[]) => void;
  initialValues: Record<string, any>;
  setInitialValues: (values: Record<string, any>) => void;
  mainButtonAction: { current: () => void };
}

export const ToriiPopupContext = createContext<ToriiPopupContextType>({
  onClose: () => {},
  isConfirmCloseOpen: false,
  setIsConfirmCloseOpen: () => {},
  submitAction: { current: () => {} },
  scopes: [],
  setScopes: () => {},
  initialValues: {},
  setInitialValues: () => {},
  mainButtonAction: { current: () => {} }
})

interface ToriiPopupProviderProps {
  children: ReactNode;
  confirmClose?: boolean;
  onCloseAction: () => void;
}

export const ToriiPopupProvider = ({ children, confirmClose, onCloseAction }: ToriiPopupProviderProps) => {
  const [isConfirmCloseOpen, setIsConfirmCloseOpen] = useState(false)
  const closeAction = useCallback(() => onCloseAction(), [onCloseAction])
  const [scopes, setScopes] = useState<string[]>([])
  const [initialValues, setInitialValues] = useState<Record<string, any>>({})
  const submitAction = useRef(() => {})
  const mainButtonAction = useRef(() => {})
  const onClose = () => {
    if (confirmClose) {
      setIsConfirmCloseOpen(true)
    } else {
      closeAction()
    }
  }

  return (
    <ToriiPopupContext.Provider value={{ onClose, isConfirmCloseOpen, setIsConfirmCloseOpen, submitAction, scopes, setScopes, initialValues, setInitialValues, mainButtonAction }}>
      {children}
    </ToriiPopupContext.Provider>
  )
}
