import React from 'react'
import { Tooltip, Popover, Checkbox, ButtonSize, ButtonType, Button } from '@toriihq/design-system'
import { SELECTABLE_COLUMN_ID } from '../table/view'
import noop from 'lodash/noop'
import TableSearch from '@components/table/tableSearch'
import * as Style from './style'

class ColumnsSelection extends React.Component {
  state = {
    search: ''
  }

  getOptions = () => {
    const { search } = this.state
    const { options, disabledColumns, hiddenColumns } = this.props
    return options.filter(option => option.label.toLowerCase().includes(search.toLowerCase()))
      .filter(option => !hiddenColumns.includes(option.label))
      .map(option => ({ ...option, disabled: disabledColumns.includes(option.value) }))
  }

  sortColumns = (a, b) => {
    const { columnsOrder } = this.props

    const aIndex = columnsOrder.indexOf(a)
    const bIndex = columnsOrder.indexOf(b)

    if (aIndex > bIndex) {
      if (bIndex === -1) {
        return -1
      }
      return 1
    } else if (aIndex < bIndex) {
      if (aIndex === -1) {
        return 1
      }
      return -1
    }

    return 0
  }

  SortNewColumnToBeTheLastOne = (selectedValues) => selectedValues.sort((a, b) => this.sortColumns(a, b))

  onChange = (e) => {
    const { onChange, tableKey, options, configureTableColumns, supportViews, selectableTable, selectedValues } = this.props

    const value = e.target.value
    if (selectedValues.includes(value)) {
      const selectedColumns = selectableTable ? [SELECTABLE_COLUMN_ID, ...selectedValues.filter(selectedValue => selectedValue !== value)] : selectedValues.filter(selectedValue => selectedValue !== value)
      onChange && onChange(selectedColumns)
      configureTableColumns(tableKey, selectedColumns, options, supportViews)
      return
    }

    const newColumns = this.SortNewColumnToBeTheLastOne([...selectedValues, value])

    const selectedColumns = selectableTable ? [SELECTABLE_COLUMN_ID, ...newColumns] : newColumns
    onChange && onChange(selectedColumns)
    configureTableColumns(tableKey, selectedColumns, options, supportViews)
  }

  onToggle = ({ isOpen }) => {
    if (isOpen) {
      setTimeout(() => this.tableSearchInput.focus(), 0)
    } else {
      this.setState({ search: '' })
    }
  }

  onSearchChange = e => {
    this.setState({ search: e.target.value })
  }

  render () {
    const { loading, selectedValues, disabled, disabledReason, isScrolling } = this.props
    const options = this.getOptions()
    return <Tooltip placement='top' label={disabledReason || 'Select Columns'}>
      <Popover
        position='bottom'
        align='start'
        showArrow={false}
        isOpen={isScrolling ? false : undefined}
        openOnClick
        onToggle={this.onToggle}
        content={<Style.PopoverContent>
          <TableSearch onSearch={this.onSearchChange} ref={(c) => (this.tableSearchInput = c)} />
          <Style.OptionsContainer>
            {options.length === 0 ? 'No columns found' : options.map(option => (
              <Style.Option key={option.value}>
                <Checkbox label={option.label} value={option.value} disabled={option.disabled} onChange={this.onChange} checked={selectedValues.includes(option.value)} />
              </Style.Option>
            ))}
          </Style.OptionsContainer>
        </Style.PopoverContent>}
      >
        <Button
          size={ButtonSize.small}
          type={ButtonType.secondary}
          icon='Columns'
          disabled={disabled || loading}
          label={selectedValues.length === 0 ? '' : selectedValues.length}
          onClick={noop}
        />
      </Popover>
    </Tooltip>
  }
}

export default ColumnsSelection
