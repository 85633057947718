import { PopupStepConfig } from '../../wizardPopup/types'
import { AddOrEditContract } from './addOrEditContract'
import NewContractAI from './newContractAI'
import { SharedProps, SharedState, WIZARD_STEPS } from './types'

export const stepsConfig = (isNewContract: boolean, hasContractAIInPlan: boolean): PopupStepConfig<SharedState, SharedProps, WIZARD_STEPS>[] => {
  const steps: PopupStepConfig<SharedState, SharedProps, WIZARD_STEPS>[] = [
    {
      stepName: WIZARD_STEPS.ADD_OR_EDIT_CONTRACT,
      stepRenderer: AddOrEditContract,
      stepWidth: '100%',
      stepModalOverrideStyle: {
        maxWidth: '90%',
        maxHeight: '1000px'
      }
    }
  ]
  if (isNewContract && hasContractAIInPlan) {
    steps.unshift({ stepName: WIZARD_STEPS.NEW_CONTRACT_AI, stepRenderer: NewContractAI })
  }
  return steps
}
