import styled from 'styled-components'

export const Main = styled.div`
  position: relative;
  flex-direction: left;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.border.primary};
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.background.primary};
  color: ${({ theme }) => theme.palette.text.primary};
`

export const HeaderWrapper = styled.div`
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.border.primary};
  display: flex;
`

export const Header = styled.header`
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 14px;
  line-height: 22px;
  margin-right: 10px;
`

export const Details = styled.div`
  text-align: center;
`

export const OwnerButtons = styled.div`
  grid-column: 3;
`

export const HoverContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 25%) 1fr 70px;
  height: 65px;
  align-items: center;
  
  &:not(:last-child) {
    border-bottom: solid 1px ${({ theme }) => theme.palette.border.primary};
  }
`

export const EditButton = styled.div`
  opacity: 0;
  
  ${HoverContainer}:hover & {
    opacity: 1;
  }
`
