import React, { useState } from 'react'
import * as Style from './style'
import { useDispatch, useSelector } from 'react-redux'
import ToriiPopup from '@components/popups/ToriiPopupV2'
import { toggleSubscribePopup } from '@actions/'
import {
  AlertBox,
  AlertBoxType,
  Body2,
  Caption1,
  Icon,
  Link,
  RadioButton,
  Spacer,
  Subtitle2,
  TextBadge,
  Tooltip
} from '@toriihq/design-system'
import { getInsightsStats } from '@selectors/stats'
import { getSubscriptionCheckoutSessionUrl } from '@actions/subscriptions'
import { Field, FormSpy } from 'react-final-form'
import InputField from '@components/popups/inputPopup/input'
import { max } from 'lodash'
import {
  MINIMUM_NUMBER_OF_USERS,
  MONTHLY_COST_PER_USER,
  MONTHLY_COST_PER_USER_ANNUAL_SUBSCRIPTION
} from '@components/subscription/constants'
import { SUPPORT_ARTICLES } from '@root/articles'
import { getOrgAllowOnlyYearlySubscription } from '@selectors/org'

const roundToFirstDigitAfterDecimal = (num) => {
  return Math.round(num * 10) / 10
}

const SubscribePopup = ({ idOrg }) => {
  const dispatch = useDispatch()

  const [isMonthly, setIsMonthly] = useState(false)

  const { isOpen } = useSelector(state => state.ui.subscribePopup)
  const insightData = useSelector(getInsightsStats)
  const { users: numberOfUsers } = insightData
  const adjustedNumberOfUsers = max([numberOfUsers, MINIMUM_NUMBER_OF_USERS])
  const allowOnlyYearlySubscription = useSelector(getOrgAllowOnlyYearlySubscription)

  const onClose = () => {
    dispatch(toggleSubscribePopup({ isOpen: false }))
  }

  const onBillingCycleChange = () => {
    setIsMonthly(prev => !prev)
  }

  const getPerMonthPrice = (numberOfEnteredUsers) => {
    if (isMonthly) {
      return `$${roundToFirstDigitAfterDecimal(adjustedNumberOfUsers * MONTHLY_COST_PER_USER)} / month`
    }

    if (numberOfEnteredUsers >= MINIMUM_NUMBER_OF_USERS) {
      return `$${roundToFirstDigitAfterDecimal(numberOfEnteredUsers * MONTHLY_COST_PER_USER_ANNUAL_SUBSCRIPTION)} / month`
    }

    return '-'
  }

  const getAnnualPrice = (numberOfEnteredUsers) => {
    if (isMonthly) {
      return 'Billed monthly'
    }

    if (numberOfEnteredUsers >= MINIMUM_NUMBER_OF_USERS) {
      return `$${roundToFirstDigitAfterDecimal(numberOfEnteredUsers * MONTHLY_COST_PER_USER_ANNUAL_SUBSCRIPTION * 12).toLocaleString()} billed yearly`
    }

    return ''
  }

  const onSubmit = async (form) => {
    const params = { idOrg, interval: isMonthly ? 'month' : 'year', quantity: isMonthly ? undefined : form.numberOfUsers }
    const response = await dispatch(getSubscriptionCheckoutSessionUrl(params))
    window.location.replace(response.redirectUrl)
  }

  const renderContent = (formProps) => {
    const { handleSubmit } = formProps

    return <form onSubmit={handleSubmit}>
      <Style.ContentWrapper>
        <Style.ContentTop>
          <Style.ContentLeftSide>
            <Style.BillingCycleWrapper>
              <Subtitle2>Billing Cycle</Subtitle2>
              <Style.RadioButtonWrapper>
                <RadioButton
                  label={
                    <span>
                      Annual
                      <Spacer left='space-100'><TextBadge size='Small' color='blue'>Save 10%</TextBadge></Spacer>
                    </span>
                  }
                  checked={!isMonthly}
                  onChange={onBillingCycleChange}
                />
                { !allowOnlyYearlySubscription && <RadioButton
                  label='Monthly'
                  checked={isMonthly}
                  onChange={onBillingCycleChange}
                /> }
              </Style.RadioButtonWrapper>
            </Style.BillingCycleWrapper>

            <Style.EmployeeSeatsWrapper>
              <Style.EmployeeSeats>
                <Subtitle2>Employee Seats</Subtitle2>
                <Spacer left='space-050'>
                  <Tooltip label={`Minimum ${MINIMUM_NUMBER_OF_USERS} Employees`}>
                    <Icon name='Info' />
                  </Tooltip>
                </Spacer>
              </Style.EmployeeSeats>
              {!isMonthly && (
                <Caption1 color='secondary'>
                  Select your initial employee count
                </Caption1>
              )}
              {isMonthly
                ? <Body2>{adjustedNumberOfUsers.toLocaleString()}</Body2>
                : <Field name='numberOfUsers' type='number' component={InputField as any} validate={value => !value || value < MINIMUM_NUMBER_OF_USERS ? `Minimum ${MINIMUM_NUMBER_OF_USERS} employees` : undefined} />}
            </Style.EmployeeSeatsWrapper>
          </Style.ContentLeftSide>
          <Style.ContentRightSide>
            <Style.EmployeeCost>
              <Caption1 color='secondary'>Employee cost</Caption1>
              <Subtitle2>${isMonthly ? MONTHLY_COST_PER_USER : MONTHLY_COST_PER_USER_ANNUAL_SUBSCRIPTION} / month</Subtitle2>
            </Style.EmployeeCost>

            <FormSpy>
              {({ values }) => (
                <Style.TotalCost>
                  <Caption1 color='secondary'>Total</Caption1>
                  <Subtitle2>{getPerMonthPrice(values.numberOfUsers)}</Subtitle2>
                  <Caption1>{getAnnualPrice(values.numberOfUsers)}</Caption1>
                </Style.TotalCost>
              )}
            </FormSpy>
          </Style.ContentRightSide>
        </Style.ContentTop>

        <FormSpy>
          {({ values }) =>
            values.numberOfUsers < adjustedNumberOfUsers && values.numberOfUsers >= MINIMUM_NUMBER_OF_USERS && <AlertBox
              type={AlertBoxType.INFORMATIVE}
              description='You are purchasing less than your number of employees, which can result in extra charges '
            />
          }
        </FormSpy>

        <Style.ContentBottom>
          <Body2 color='secondary'>
            {isMonthly
              ? "Your monthly plan automatically adjusts to your team's size at the end of each billing cycle"
              : `For any increase in team size, an additional $${MONTHLY_COST_PER_USER.toFixed(2)} per employee will be charged monthly`}
          </Body2>

          <Link href={SUPPORT_ARTICLES.BILLING} target='_blank'>Learn more about Torii billing</Link>
        </Style.ContentBottom>
      </Style.ContentWrapper>
    </form>
  }

  const renderFooter = (formProps) => {
    return <ToriiPopup.Footer
      showCancelButton
      mainButtonAction={onSubmit}
      mainButtonText='Pay Now'
      mainButtonIcon='ExternalLink'
      cancelButtonAction={onClose}
      cancelButtonText='Cancel'
      isMainSubmit
      formProps={formProps}
      allowPristine
    />
  }

  return (
    <ToriiPopup isOpen={isOpen} onCloseAction={onClose} width='518px'>
      <ToriiPopup.Header
        header='Subscribe to our Basic plan'
        subHeader={`Starting at ${MINIMUM_NUMBER_OF_USERS} employees`}
      />

      <ToriiPopup.Form
        onSubmit={onSubmit}
        initialValues={{ numberOfUsers: adjustedNumberOfUsers }}
        render={renderContent}
        renderFooter={renderFooter} />
    </ToriiPopup>
  )
}

export default SubscribePopup
