import { css } from 'glamor'

export const connectLink = css({
  marginRight: '10px',
  width: '100%'
})

export const footer = {
  flexDirection: 'row-reverse',
  width: '100%'
}

export const MainArea = css({
  height: 500,
  width: '100%'
})
