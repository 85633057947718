import send from '@shared/redux-fetch'
import {
  GET_APP_USER_LAST_VISIT_TIMES,
  GET_APPS_USERS_FIELD_VALUES,
  GET_APP_USERS_METADATA
} from '@root/constants'
import { IdApp, IdOrg, IdUser } from '@store/types'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'

export const getAppUserLastVisitTimes = ({ idOrg, idApp, idUser }: { idOrg: IdOrg, idApp: IdApp, idUser: IdUser }) => {
  return send(GET_APP_USER_LAST_VISIT_TIMES, {
    url: `/api/orgs/${idOrg}/apps/${idApp}/users/${idUser}/lastVisitTimes`
  })
}

export const getAppUsersMetadata = ({ idOrg }: { idOrg: IdOrg }) => {
  return send(GET_APP_USERS_METADATA, {
    url: `/api/orgs/${idOrg}/appUsers/metadata`
  })
}

export const getAppsUsersFieldValues = ({ idOrg, status, fields }) =>
  send(GET_APPS_USERS_FIELD_VALUES, {
    url: `/api/orgs/${idOrg}/appUsers/fieldValues`,
    query: omitBy({
      status,
      fields: JSON.stringify(fields)
    }, isUndefined)
  })
