import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { noop } from 'lodash'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { AdvancedCard } from '@toriihq/design-system'
import { getHistoricalRenewalsSummary, isLoadingRenewalsSummary } from '@selectors/contracts'
import { getUserPreferences } from '@selectors/ui'
import { bucketSummarySortOrder } from '@components/renewalsSummary/utils/constants'
import { FixedWidgetProps } from '@components/dashboard/widgets/types'
import * as Style from './style'
import { getCurrentOrg } from '@root/store/selectors/org'
import { getContracts } from '@root/store/actions'
import { getRenewalsSummary } from '@root/store/actions/contracts'
import RenewalsChart from '@root/components/renewalsSummary/renewalsChart'
import YearSelector from '@components/renewalsSummary/yearSelector'
import { getLastAvailableYear, getSortedBaseYearRenewals } from '@components/renewalsSummary/utils/utils'
import BarChartLoadingState from '../../shared/barChartLoadingState'

const RenewalYearlySummaryWidget = (props: FixedWidgetProps): ReactElement => {
  const { isSelected, widget, primaryButton, isWidgetMenuOpen } = props

  const dispatch = useDispatch()
  const { id: idOrg } = useSelector(getCurrentOrg)
  const renewalsSummary = useSelector(getHistoricalRenewalsSummary)
  const isLoading = useSelector(isLoadingRenewalsSummary)
  const userPreferences = useSelector(getUserPreferences)
  const baseYearPreference = userPreferences?.renewalsSummary?.baseYear

  const [baseYear, setBaseYear] = React.useState(baseYearPreference || { value: 0, label: '' })

  useEffect(() => {
    if (idOrg) {
      dispatch(getContracts({ idOrg }))
      dispatch(getRenewalsSummary({ idOrg }))
    }
  }, [dispatch, idOrg])

  useDeepCompareEffect(() => {
    getLastAvailableYear(renewalsSummary, baseYear, onYearChange)
  }, [renewalsSummary])

  const baseYearRenewals = getSortedBaseYearRenewals(renewalsSummary, baseYear, bucketSummarySortOrder)

  const onYearChange = (year) => {
    setBaseYear(year)
  }

  return (
    <AdvancedCard
      title={widget?.displayConfig?.label}
      selected={isSelected}
      primaryButton={primaryButton}
      showPrimaryButton={isWidgetMenuOpen}
    >
      {isLoading ? <BarChartLoadingState isVertical numberOfBars={6} /> : (
        <Style.Container>
          <YearSelector
            renewalsSummary={renewalsSummary}
            baseYear={baseYear}
            onYearChange={onYearChange}
          />
          <RenewalsChart
            baseYear={baseYear}
            baseYearRenewals={baseYearRenewals}
            onBucketChange={noop}
          />
        </Style.Container>
      )}
    </AdvancedCard>
  )
}

export default RenewalYearlySummaryWidget
