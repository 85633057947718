import React from 'react'
import { useSelector } from 'react-redux'
import { AppIcon, Stack, TextBadge } from '@toriihq/design-system'
import Analytics from '../../helpers/analytics'
import EnableFor from '../enableFor'
import { SCOPES } from '@root/constants'
import { deprecatedGetAppsByIds } from '@selectors/apps'
import * as Style from './styles'
import Placeholder from '../placeholder'
import { DetailsBadge } from '@shared/types'

interface AppDetailsProps {
  id?: number
  name?: string
  category?: string
  imageUrl?: string
  isNew?: boolean
  badge?: DetailsBadge
  loading?: boolean
  component?: string
  blueNameOnHover?: boolean
  overrideStyle?: any
}

const AppDetails = (props: AppDetailsProps) => {
  const { blueNameOnHover = true, overrideStyle } = props

  const appData = useSelector(state => {
    const apps = deprecatedGetAppsByIds(state)
    return props.id ? apps[props.id] || {} : {}
  })

  const mergedProps = { ...appData, ...props }
  const { id, name, category, imageUrl, isNew, badge, loading, component } = mergedProps

  const onNavToApp = (name: string, component?: string): void => {
    Analytics.track('Click on app', {
      Component: component,
      'App name': name
    })
  }

  const isNewBadge = isNew && <TextBadge color='blue' size='Small'>New</TextBadge>
  const badgeComponent = badge && <TextBadge color={badge.color} size='Small'>{badge.text}</TextBadge>

  const singleAppPageNeededScopes = [SCOPES.APPLICATIONS_READ, SCOPES.APP_OWNER_READ]

  const content = (
    <Style.ContentWrapper>
      <Stack gap='space-150' direction='row'>
        <AppIcon appName={name} appImageUrl={imageUrl} />
        <Style.Details>
          <Style.Name hasCategory={!!category} blueNameOnHover={blueNameOnHover}>
            {name} {isNewBadge} {badgeComponent}
          </Style.Name>
          {category && <Style.Category>{category}</Style.Category>}
        </Style.Details>
      </Stack>
    </Style.ContentWrapper>
  )

  return (
    <Style.Container className='appDetailsContainer' overrideStyle={overrideStyle}>
      <Placeholder loading={loading} type='text' rows={2} style={{ height: '40px', width: '50px' }}>
        <Style.MainWrapper>
          <EnableFor scopes={singleAppPageNeededScopes} allowForToriiAdmin>
            {id ? (
              <Style.StyledRelativeTeamLink
                to={`/app/${id}`}
                onClick={() => onNavToApp(name, component)}
              >
                {content}
              </Style.StyledRelativeTeamLink>
            ) : content}
          </EnableFor>
        </Style.MainWrapper>
      </Placeholder>
    </Style.Container>
  )
}

export default AppDetails
