import React from 'react'
import { Avatar, Tooltip, TextBadge, Stack } from '@toriihq/design-system'
import Placeholder from '../placeholder'
import { getDisplayName } from '@lenses/users'
import Analytics from '../../helpers/analytics'
import { SCOPES } from '../../constants'
import { DetailsBadge, USER_LIFECYCLE_STATUS } from '@shared/types'
import VisibleFor from '../visibleFor'
import capitalize from 'lodash/capitalize'
import * as Style from './styles'

interface UserDetailsProps {
  idUser?: number
  firstName?: string | null
  lastName?: string | null
  email?: string
  isSupportTeamUser?: boolean
  emailColor?: string
  badge?: DetailsBadge | DetailsBadge[] | null
  linkToUserPage?: boolean
  showExternalUserBadge?: boolean
  tooltipText?: React.ReactElement | null
  blueNameOnHover?: boolean
  photoUrl?: string | null
  isExternal?: boolean | number
  loading?: boolean
  overrideStyle?: any
  additionalAnalyticsInfo?: Record<string, any>
  lifecycleStatus?: string
  isDeletedInIdentitySources?: boolean
  showPastUserBadge?: boolean
}

const onClick = (id: number, email: string, additionalAnalyticsInfo?: Record<string, any>) => {
  Analytics.track('Click on user', {
    'Component': 'UserDetails',
    'UserId': id,
    'UserEmail': email,
    ...additionalAnalyticsInfo
  })
}

const UserDetails = (props: UserDetailsProps) => {
  const {
    tooltipText,
    idUser,
    firstName,
    lastName,
    email,
    photoUrl,
    isExternal,
    linkToUserPage = true,
    loading,
    overrideStyle,
    showExternalUserBadge = true,
    badge,
    additionalAnalyticsInfo,
    lifecycleStatus,
    blueNameOnHover = true,
    isSupportTeamUser,
    isDeletedInIdentitySources,
    showPastUserBadge,
    emailColor: propEmailColor
  } = props

  const userName = getDisplayName({ firstName, lastName, email })
  const isUserOffboardingOrOffboarded = [USER_LIFECYCLE_STATUS.OFFBOARDING, USER_LIFECYCLE_STATUS.OFFBOARDED].includes(lifecycleStatus as USER_LIFECYCLE_STATUS)

  let emailColor = propEmailColor
  const badgeComponents = badge ? ([] as DetailsBadge[]).concat(badge).map((badge: DetailsBadge) =>
    <TextBadge color={badge.color} size='Small' key={badge.text}>{badge.text}</TextBadge>
  ) : []

  if (!isSupportTeamUser) {
    if (showExternalUserBadge && isExternal) {
      emailColor = 'orange'
      badgeComponents.push(<TextBadge color='orange' size='Small' key='externalUserBadge'>External</TextBadge>)
    }

    if (isUserOffboardingOrOffboarded) {
      badgeComponents.push(
        lifecycleStatus === USER_LIFECYCLE_STATUS.OFFBOARDING
          ? <VisibleFor key={lifecycleStatus} scopes={[SCOPES.OFFBOARDING_READ]}><TextBadge color='gray' size='Small'>{capitalize(lifecycleStatus)}</TextBadge></VisibleFor>
          : <TextBadge key={lifecycleStatus} color='gray' size='Small'>{capitalize(lifecycleStatus)}</TextBadge>
      )
    } else if (showPastUserBadge && isDeletedInIdentitySources) {
      badgeComponents.push(<TextBadge color='gray' size='Small' key='pastUserBadge'>Past user</TextBadge>)
    }
  }

  const content = (
    <Style.ContentWrapper>
      <Stack gap='space-150' direction='row'>
        <Tooltip label={tooltipText} hide={!tooltipText}>
          <Avatar firstName={firstName} lastName={lastName} imageUrl={photoUrl} />
        </Tooltip>
        <Style.Details>
          <Style.Name blueNameOnHover={blueNameOnHover} hasEmail={!!email}>
            <Stack gap='space-100' direction='row'>{userName}{badgeComponents}</Stack>
          </Style.Name>
          {email && <Style.Email emailColor={emailColor}>{email}</Style.Email>}
        </Style.Details>
      </Stack>
    </Style.ContentWrapper>
  )

  return (
    <Style.Container className='userDetailsContainer' overrideStyle={overrideStyle}>
      <Placeholder loading={loading} type='text' rows={2} style={{ height: '40px', width: '50px' }}>
        <Style.MainWrapper>
          {linkToUserPage && idUser && !isSupportTeamUser ? (
            <Style.StyledRelativeTeamUserLink
              idUser={idUser}
              onClick={() => onClick(idUser, email || '', additionalAnalyticsInfo)}
            >
              {content}
            </Style.StyledRelativeTeamUserLink>
          ) : content}
        </Style.MainWrapper>
      </Placeholder>
    </Style.Container>
  )
}

export default UserDetails
