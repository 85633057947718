import { useState, useMemo } from 'react'
import { debounce } from 'lodash'
import { GetTasksProps } from './types'

interface UseTaskSearchProps {
  getTasks: (props: GetTasksProps) => Promise<void>
  debounceTime?: number
}

interface UseTaskSearchReturn {
  searchText: string
  onSearch: (text: string) => void
}

export const useTaskSearch = ({ getTasks, debounceTime = 500 }: UseTaskSearchProps): UseTaskSearchReturn => {
  const [searchText, setSearchText] = useState('')

  const debouncedSearch = useMemo(
    () => debounce((text: string) => {
      setSearchText(text)
      getTasks({ reset: true, q: text })
    }, debounceTime),
    [getTasks, debounceTime]
  )

  return {
    searchText,
    onSearch: debouncedSearch
  }
}
